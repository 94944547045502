import { FC, useMemo } from 'react';

import { KeyboardArrowLeft } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';

import { useApp } from '@hooks/AppHooks';

import './SettingsTitle.scss';

interface SettingsTitleProps {
    label: string;
}

const SettingsTitle: FC<SettingsTitleProps> = ({ label }) => {
    const { history, appIsMobile } = useApp();

    const baseLabel = <span>{label}</span>;
    const title = useMemo(() => {
        return appIsMobile ? (
            <ClickableArea className="title-settings" onClick={() => history.goBack()}>
                <KeyboardArrowLeft id="settings-back-button" />
                {baseLabel}
            </ClickableArea>
        ) : (
            baseLabel
        );
    }, [appIsMobile]);

    return title;
};

export default SettingsTitle;
