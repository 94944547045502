import { FC } from 'react';

import Tooltip from '@components/layout/Tooltip';

import { Badge } from '@app/componentLibrary/Badge';

interface TruncatedMarketsListProps {
    markets?: string[];
    limit?: number;
    totalMarkets?: number;
}

const TruncatedMarketsList: FC<TruncatedMarketsListProps> = ({
    markets,
    limit = 3,
    totalMarkets,
}) => {
    if (!markets?.length) return '';

    if (markets.length <= limit) {
        return markets.join(', ');
    }

    const visibleMarkets = markets.slice(0, limit).join(', ');
    return (
        <>
            {totalMarkets && totalMarkets <= markets.length ? (
                'All Markets'
            ) : (
                <>
                    {visibleMarkets},{' '}
                    <Tooltip title={markets.slice(limit).join(', ')}>
                        <span>
                            <Badge label={`+${markets.length - limit}`} color="blue" size="sm" />
                        </span>
                    </Tooltip>
                </>
            )}
        </>
    );
};

export default TruncatedMarketsList;
