// TODO do better at importing icons
import { useMemo } from 'react';

import { UIEvent } from '@sparkplug/lib';

import { getEventDisplayStatus } from '@features/events/helpers/eventUtils';
import { useEventNavigation } from '@features/events/hooks/useEventNavigation';

import Button from '@components/buttons/Button';
import { CustomArrowRightAltIcon } from '@components/icons';
import Modal from '@components/overlays/Modal';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { EventDetails } from '../../EventDetails';

export const InformationalEventContent = ({ event }: { event: UIEvent }) => {
    const { account } = useSparkplugAccount();
    const eventStatus = useMemo(
        () => getEventDisplayStatus({ event, accountType: account?.type as 'retailer' | 'brand' }),
        [event, account?.type],
    );

    const nav = useEventNavigation();
    const handleViewEvents = () => {
        if (eventStatus === 'declined' || eventStatus === 'expired') {
            nav.actions.goToInboxView();
        } else {
            nav.actions.goToScheduledView();
        }
    };

    return (
        <Modal.Content className="flex w-full items-center gap-4 flex-col">
            <EventDetails
                className="w-full"
                event={event}
                accountType={account?.type === 'retailer' ? 'retailer' : 'brand'}
            />
            <div className="w-full flex justify-center">
                <Button
                    className="w-[200px] max-w-full"
                    color="blue"
                    variant="filled"
                    endIcon={<CustomArrowRightAltIcon className="[&_path]:fill-white" />}
                    onClick={handleViewEvents}
                >
                    View Events
                </Button>
            </div>
        </Modal.Content>
    );
};
