import { UIEvent } from '@sparkplug/lib';

import { EventDisplayStatus } from '@features/events/helpers/eventUtils';

import { IAccount } from '@app/types/AccountsTypes';

import { EventTableRowData } from '../types';

export const formatEventType = (event: UIEvent) => {
    if (event.type === 'popup') return 'Pop-up';
    if (event.type === 'training') return 'Training';
    if (event.type === 'promo') return 'Promo';
    return 'Other';
};

export const adminCanEditOrDelete = ({
    account,
    eventRow,
}: {
    account?: IAccount;
    eventRow: EventTableRowData;
}) => {
    const uneditableStatuses: EventDisplayStatus[] = ['complete', 'expired', 'declined'];
    if (uneditableStatuses.includes(eventRow.displayStatus) || !account) {
        return false;
    }

    // Vendors and Retailers can only edit/delete their own events
    if (account.type === 'retailer') {
        return account._id === eventRow.retailerAccount._id && !eventRow.vendorAccount?._id;
    }
    return account._id === eventRow.vendorAccount?._id;
};
