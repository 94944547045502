import React, { forwardRef } from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import clsx from 'clsx';

const typographyVariants = cva('', {
    variants: {
        variant: {
            sm: 'text-sm tracking-[0.0175em]',
            base: 'text-base tracking-[0.0175em]',
            lg: 'text-lg tracking-[0.0175em]',
            xl: 'text-xl',
            '2xl': 'text-2xl',
        },
    },
    defaultVariants: {
        variant: 'base',
    },
});

export type TypographyProps = React.HTMLAttributes<HTMLElement> &
    VariantProps<typeof typographyVariants> & {
        /**
         * The HTML element to render the component as.
         * @default 'p'
         */
        as?: React.ElementType;
    };

/**
 * Configurable Text component with variant styling.
 * @param variant - Typography variant (includes size and letter spacing). One of:
 *   - 'sm' - Small text with tracking
 *   - 'base' - Base text with tracking (default)
 *   - 'lg' - Large text with tracking
 *   - 'xl' - Extra large text
 *   - '2xl' - Double extra large text
 * @param as - HTML element type @default 'p'
 * @param className - Additional CSS classes
 * @param children - Inner content
 * @param htmlProps - HTML attributes
 * @returns Text component with specified variant
 */
export const Typography = forwardRef<HTMLElement, TypographyProps>(
    ({ variant, as, className, children, ...htmlProps }, ref) => {
        const HTMLTextElement = as || 'p';

        return (
            <HTMLTextElement
                ref={ref}
                className={clsx(typographyVariants({ variant }), className)}
                {...htmlProps}
            >
                {children}
            </HTMLTextElement>
        );
    },
);

Typography.displayName = 'Typography';

export default Typography;
