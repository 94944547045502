import { forwardRef } from 'react';

import { Spark, parseCurrencyToNumber } from '@sparkplug/lib';

import Form from '@components/form/Form';
import toast from '@components/toast';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { getMinThresholdDescription, isPercentIncreaseSpark } from '@helpers/sparks';

import './MinThresholdField.scss';

const leaderboardMetricMessages = {
    total_sales: 'dollar amount',
    order_average: 'order average',
    guest_check_average: 'guest check average',
    transaction_count: 'number of transactions',
    total_units: 'number of units',
    units_per_transaction: 'number of units per transactions',
    percent_of_total_sales: 'percentage of total sales',
    percent_increase: 'percent increase',
};

const getMinThresholdLabel = (spark: Spark): string | null => {
    if (spark.type === 'commission') {
        return 'Minimum Dollar Amount (Optional)';
    }

    if (spark.type === 'leaderboard') {
        if (isPercentIncreaseSpark(spark)) {
            return `Minimum ${
                leaderboardMetricMessages[spark.percentIncreaseData!.metric]
            } % Increase (Optional)`;
        }

        return `Minimum ${leaderboardMetricMessages[spark.metric]} (Optional)`;
    }

    return null;
};

const MinThresholdField = forwardRef<HTMLDivElement | null, { disabled?: boolean }>(
    ({ disabled }, ref) => {
        const { spark, updateSpark } = useSpark();

        // The `MinThresholdField` component can only render for `leaderboard` and `commission` sparks
        const isValidSparkType = ['commission', 'leaderboard'].includes(spark.type);
        if (!isValidSparkType) {
            return <></>;
        }

        const minThreshold =
            spark.minimumThresholdToQualify != null &&
            !Number.isNaN(spark.minimumThresholdToQualify)
                ? spark.minimumThresholdToQualify
                : undefined;

        const thresholdLabel = getMinThresholdLabel(spark);
        const thresholdDescription = getMinThresholdDescription(spark);

        const showPercentageIcon =
            spark.metric === 'percent_of_total_sales' || isPercentIncreaseSpark(spark);
        const fieldMax = showPercentageIcon ? 100 : undefined;

        const showCurrencyIcon =
            !showPercentageIcon &&
            (spark.type === 'commission' ||
                (spark.type === 'leaderboard' &&
                    ['total_sales', 'order_average'].includes(spark.metric)));

        return (
            <div ref={ref} className="min-threshold-field-container max-sm:pb-3">
                {thresholdLabel && (
                    <Form.Label className="threshold-label">{thresholdLabel}</Form.Label>
                )}

                <Form.TextField
                    disabled={disabled}
                    isMoneyField={showCurrencyIcon && !showPercentageIcon}
                    moneyFieldIsActive={showCurrencyIcon}
                    endIcon={showPercentageIcon && <span>%</span>}
                    name="spark-minimum-Threshold"
                    defaultValue={minThreshold}
                    type="number"
                    min={0}
                    max={showPercentageIcon ? 100 : undefined}
                    onChange={(e) => {
                        updateSpark({
                            // note that useMoneyField _always_ returns a string with $ sign
                            minimumThresholdToQualify:
                                e.target.value != null
                                    ? parseCurrencyToNumber(e.target.value)
                                    : undefined,
                        });
                    }}
                    onValidationError={(value?: string) => {
                        try {
                            const hasMinimumTheshold = !!value;

                            if (!hasMinimumTheshold) {
                                return;
                            }

                            const minimumThreshold = Number(value);

                            const isGreaterThanZero = minimumThreshold > 0;
                            const isBelowMax = fieldMax ? minimumThreshold <= fieldMax : true;

                            if (!isGreaterThanZero) {
                                toast.error('Minimum threshold must be greater than zero');
                                return;
                            }

                            if (!isBelowMax) {
                                toast.error(
                                    `Minimum threshold cannot be greater than "${fieldMax}"`,
                                );
                                return;
                            }
                        } catch {
                            toast.error('Minimum threshold is invalid');
                        }
                    }}
                />

                {thresholdDescription && (
                    <Form.Description>{thresholdDescription}</Form.Description>
                )}
            </div>
        );
    },
);

export default MinThresholdField;
