import { InputHTMLAttributes, forwardRef } from 'react';

import clsx from 'clsx';

import { InputLabel } from '../InputLabel/InputLabel';

interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    id: string;
    label?: string;
}

// TODO: Revamp this, likely with a shadcn component
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ label, className, id, ...props }, ref) => {
        return (
            <label className="flex items-center gap-2 cursor-pointer w-fit" htmlFor={id}>
                <input
                    type="checkbox"
                    id={id}
                    ref={ref}
                    className={clsx(
                        'h-4 w-4 rounded border-gray-300 accent-cerulean-600',
                        className,
                    )}
                    {...props}
                />
                {label && (
                    <InputLabel as="span" className="select-none">
                        {label}
                    </InputLabel>
                )}
            </label>
        );
    },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
