import React, { FC, useRef } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment/moment';

import { useFetchRetailerInventoryEnabled } from '@features/inventory/queries';

import { TableProvider } from '@contexts/TableContext';

import { Info } from '@components/icons';
import Skeleton from '@components/layout/Skeleton';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { TApplyFn } from '@app/types/UITypes';

import {
    InventoryDisabledEmptyState,
    InventoryNotAvailableEmptyState,
} from '../InventoryEmptyStates/InventoryEmptyStates';
import { ProductInventoryRow, useProductInventoryHeadCells } from './useProductInventoryHeadCells';

import './ProductInventoryTable.scss';

export interface InventoryTableProps {
    accountType?: 'brand' | 'retailer';
    products: ProductInventoryRow[];
    inventoryEnabled: boolean;
    retailerAccountId: string;
    isLoading: boolean;
    isRetailerDashboard?: boolean;
    numProductsMissingInventory: number;
    filters: TApplyFn[];
    containerRef?: React.RefObject<HTMLDivElement>;
    userIsSuperAdmin?: boolean;
    shareSalesData?: boolean;
}

const ProductInventoryTable: FC<InventoryTableProps> = ({
    accountType,
    products,
    inventoryEnabled,
    retailerAccountId,
    isLoading,
    isRetailerDashboard = false,
    numProductsMissingInventory,
    filters,
    containerRef,
    userIsSuperAdmin = false,
    shareSalesData = false,
}) => {
    const inventoryChartRef = useRef(null);

    const headCells = useProductInventoryHeadCells(
        isRetailerDashboard,
        userIsSuperAdmin,
        shareSalesData,
    );
    const {
        inventoryEnabled: retailerInventoryEnabled,
        posConfigId: activePosConfigId,
        retailerInventoryEnabledIsReady,
    } = useFetchRetailerInventoryEnabled(retailerAccountId);

    let emptyState;
    if (!inventoryEnabled && accountType === 'brand') {
        emptyState = <InventoryDisabledEmptyState />;
    } else if (
        ((products.length > 0 && numProductsMissingInventory === products.length) ||
            !retailerInventoryEnabled) &&
        !isLoading &&
        retailerInventoryEnabledIsReady &&
        accountType === 'brand' &&
        !isRetailerDashboard
    ) {
        emptyState = <InventoryNotAvailableEmptyState />;
    }
    const last90days = moment().subtract(90, 'days');

    return (
        <>
            {isLoading ? (
                <Skeleton height={400} />
            ) : (
                <>
                    {!emptyState ? (
                        <>
                            <div
                                className="product-inventory-table-container"
                                ref={containerRef ?? inventoryChartRef}
                            >
                                <TableProvider
                                    showPagination={false}
                                    headCells={headCells}
                                    rows={products}
                                    isLoading={isLoading}
                                    filters={filters}
                                    defaultOptions={{
                                        orderBy: 'inventory.quantityAvailable',
                                        order: 'desc',
                                    }}
                                >
                                    <Table
                                        useInfiniteScrollStyling
                                        useExternalProvider
                                        variant="smooth"
                                        className="product-inventory-table [&_.empty-state]:!w-full [&_.empty-state]:justify-center"
                                    >
                                        <Table.RenderHead />
                                        <Table.RenderBody
                                            emptyStateText={
                                                products.length === 0 ? (
                                                    <div className="flex flex-row justify-center items-center">
                                                        No Active Products&nbsp;
                                                        <Tooltip
                                                            placement="right"
                                                            title={`"Active Products" are products that have sold at any location within since ${last90days.format(
                                                                DATE_DISPLAY_FORMAT,
                                                            )} (last 90 days)`}
                                                        >
                                                            <Info
                                                                fontSize="small"
                                                                className="text-gray-700 !h-[13px] !w-[13px]"
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                ) : (
                                                    'No products with these filters'
                                                )
                                            }
                                            dynamicHeight={{
                                                infiniteScroll: true,
                                                containerRef: containerRef ?? inventoryChartRef,
                                                scrollToTopElement: {
                                                    getText: (itemCount) =>
                                                        `Viewing ${itemCount} products`,
                                                },
                                            }}
                                            highlightRowOnHover
                                        />
                                    </Table>
                                </TableProvider>
                            </div>
                        </>
                    ) : (
                        <>{emptyState}</>
                    )}
                </>
            )}
        </>
    );
};

export default ProductInventoryTable;
