import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCourseCompletionStats } from '@features/courses/hooks/useCourseCompletionStats';
import { useGetSurveyResponses } from '@features/courses/queries/useGetCourses';

import CalloutMessage from '@components/layout/CalloutMessage';
import Table from '@components/table/Table/Table';
import TableBody from '@components/table/TableRenderBody';
import TableHeader from '@components/table/TableRenderHead/';
import Toolbar from '@components/toolbar/Toolbar';

import { Card, CardContent } from '@app/componentLibrary/Card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@app/componentLibrary/Tabs';
import Typography from '@app/componentLibrary/Typography';

import { getCourseCompletionTableConfig } from '../utils/getCourseCompletionTableConfig';

const TABLE_CELL_CLASSNAME =
    'text-cerulean-600 hover:text-cerulean-800 text-left font-medium bg-transparent border-none shadow-none cursor-pointer text-base';

export const CourseCompletionCard = () => {
    const { learningResourceId } = useParams<{ learningResourceId: string }>();

    const [activeView, setActiveView] = useState<'market' | 'retailer' | 'location' | 'employee'>(
        'market',
    );
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedMarket, setSelectedMarket] = useState<string>('');
    const [selectedRetailer, setSelectedRetailer] = useState<string>('');
    const [selectedLocation, setSelectedLocation] = useState<string>('');
    const { surveyResponses, isLoadingSurveyResponses } = useGetSurveyResponses(learningResourceId);

    const stats = useCourseCompletionStats(surveyResponses || [], {
        onMarketClick: (market) => {
            setActiveView('retailer');
            setSelectedMarket(market);
        },
        onRetailerClick: (retailerId, market) => {
            setActiveView('location');
            setSelectedMarket(market);
            const retailer = stats.byRetailer.find((r) => r.retailerId === retailerId);
            if (retailer) {
                setSelectedRetailer(retailer.retailerName);
            }
        },
        onLocationClick: (locationKey, market) => {
            setActiveView('employee');
            setSelectedMarket(market);
            const [locationName] = locationKey.split('-');
            setSelectedLocation(locationName);
        },
    });
    const activeConfig = getCourseCompletionTableConfig(stats, TABLE_CELL_CLASSNAME)[activeView];

    // Get unique markets and retailers for filters
    const markets = [...new Set(stats.byMarket.map((row) => row.market))];
    const retailers = [...new Set(stats.byRetailer.map((row) => row.retailerName))];

    // Get unique locations for the selected retailer
    const locations = [...new Set(stats.byLocation.map((row) => row.locationName))];

    // Filter data based on active view
    const getFilteredData = () => {
        const data = activeConfig.data;
        return data.filter((row) => {
            const searchFields: Record<typeof activeView, string[]> = {
                market: ['market'],
                retailer: ['retailerName'],
                location: ['locationName', 'retailerName'],
                employee: ['firstName', 'lastName', 'retailerName', 'locationName'],
            };

            const matchesSearch = searchFields[activeView].some((field) =>
                String((row as unknown as Record<string, unknown>)[field])
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
            );

            const matchesMarket = !selectedMarket || row.market === selectedMarket;
            const matchesRetailer = !selectedRetailer || row.retailerName === selectedRetailer;
            const matchesLocation = !selectedLocation || row.locationName === selectedLocation;

            switch (activeView) {
                case 'retailer':
                    return matchesSearch && matchesMarket;
                case 'location':
                    return matchesSearch && matchesRetailer;
                case 'employee':
                    return matchesSearch && matchesLocation;
                default:
                    return matchesSearch;
            }
        });
    };

    return (
        <Card className="mb-4">
            <CardContent>
                <div className="flex items-center justify-between mb-4">
                    <div className="flex flex-col gap-2">
                        <Typography variant="lg" className="font-bold">
                            Course Completion
                        </Typography>
                        <CalloutMessage
                            message="Drill down into course completion stats. These numbers can change as
                    new retailer links are created and new employees join SparkPlug."
                        />
                    </div>
                </div>

                <Tabs
                    value={activeView}
                    onValueChange={(value) => setActiveView(value as typeof activeView)}
                >
                    <TabsList>
                        <TabsTrigger value="market">By Market</TabsTrigger>
                        <TabsTrigger value="retailer">By Retailer</TabsTrigger>
                        <TabsTrigger value="location">By Location</TabsTrigger>
                        <TabsTrigger value="employee">By Employee</TabsTrigger>
                    </TabsList>

                    <TabsContent value={activeView}>
                        <div className="flex gap-4 mb-4">
                            <Toolbar.Group>
                                <Toolbar.Search
                                    name="search"
                                    onChange={(event) => setSearchTerm(event.target.value)}
                                    className="max-w-xs"
                                />

                                {activeView === 'retailer' && (
                                    <Toolbar.Dropdown
                                        label=""
                                        value={selectedMarket}
                                        onSelect={setSelectedMarket}
                                        options={[
                                            { label: 'All Markets', value: '' },
                                            ...markets
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((market) => ({
                                                    label: market,
                                                    value: market,
                                                })),
                                        ]}
                                        className="max-w-xs"
                                        clear={{
                                            active: selectedMarket !== '',
                                            onClear: () => setSelectedMarket(''),
                                        }}
                                    />
                                )}

                                {activeView === 'location' && (
                                    <Toolbar.Dropdown
                                        label=""
                                        value={selectedRetailer}
                                        onSelect={(value) => {
                                            setSelectedRetailer(value);
                                            setSelectedLocation('');
                                        }}
                                        options={[
                                            { label: 'All Retailers', value: '' },
                                            ...retailers
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((retailer) => ({
                                                    label: retailer,
                                                    value: retailer,
                                                })),
                                        ]}
                                        className="max-w-xs"
                                        clear={{
                                            active: selectedRetailer !== '',
                                            onClear: () => setSelectedRetailer(''),
                                        }}
                                    />
                                )}

                                {activeView === 'employee' && (
                                    <Toolbar.Dropdown
                                        label=""
                                        value={selectedLocation}
                                        onSelect={(value) => setSelectedLocation(value)}
                                        options={[
                                            { label: 'All Locations', value: '' },
                                            ...locations
                                                .sort((a, b) => (a || '').localeCompare(b || ''))
                                                .map((location) => ({
                                                    label: location || '',
                                                    value: location || '',
                                                })),
                                        ]}
                                        className="max-w-xs"
                                        clear={{
                                            active: selectedLocation !== '',
                                            onClear: () => setSelectedLocation(''),
                                        }}
                                    />
                                )}
                            </Toolbar.Group>
                        </div>

                        <Table
                            variant="flat"
                            rows={getFilteredData()}
                            headCells={activeConfig.headCells}
                            showPagination
                            useExternalProvider={false}
                        >
                            <TableHeader />
                            <TableBody />
                        </Table>
                    </TabsContent>
                </Tabs>
            </CardContent>
        </Card>
    );
};
