import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useParams } from 'react-router';
import { Model, Survey, SurveyModel } from 'survey-react-ui';

import { CompleteEvent, CompletingEvent, Question, ValueChangedEvent } from 'survey-core';

import {
    useSaveSurveyResponseProgess,
    useSubmitSurveyResponse,
} from '@features/learn-and-earn/mutations/useSubmitSurveyResponse';
import { useGetSurveyUserResponse } from '@features/learn-and-earn/queries/useGetEmployeeSurveyResponse';
import { useGetLearningResourceSurvey } from '@features/learn-and-earn/queries/useGetSurvey';

import { Close as CloseIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import PageLoading from '@components/layout/PageLoading';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useEmployeeLocationsQuery } from '@hooks/QueryHooks/EmployeeDataQueries';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import 'survey-core/defaultV2.min.css';

// Adds "Correct" or "Incorrect" to a question title
export function checkAnswer(q: Question) {
    if (!q) return;
    const id = q.id;
    const isCorrect = q.isAnswerCorrect();
    const existingContainer = document.getElementById(`${id}-incorrect-answer`);
    const errorClasses = ['border-sienna-500', 'border-2', 'border-solid'];
    const targetQuestionContainer = document.getElementById(id);

    if (isCorrect) {
        existingContainer?.remove();
        targetQuestionContainer?.classList.remove(...errorClasses);
        return;
    }

    if (q.correctAnswer === null || q.correctAnswer === undefined) {
        return;
    }

    if (!existingContainer && targetQuestionContainer) {
        targetQuestionContainer.classList.add(...errorClasses);
        const wrongIcon = document.createElement('span');
        wrongIcon.className =
            'absolute top-0 right-0 rounded-full bg-sienna-500 text-white m-1 p-[2px] leading-[0]';
        wrongIcon.id = `${id}-incorrect-answer`;
        targetQuestionContainer.appendChild(wrongIcon);
        createRoot(wrongIcon).render(<CloseIcon style={{ fontSize: '12px' }} />);
    }
}

const LearnAndEarnSurvey: React.FC = () => {
    const { user } = useApp();
    const { account } = useSparkplugAccount();
    const { learningResourceId } = useParams<{
        learningResourceId: string;
    }>();

    const surveyModelRef = useRef(new Model());

    const { mutate: submitSurveyResponse } = useSubmitSurveyResponse();
    const { mutate: saveSurveyResponseProgress } = useSaveSurveyResponseProgess();
    const { survey, isLoadingSurvey } = useGetLearningResourceSurvey(learningResourceId);
    const { surveyUserResponse, isLoadingSurveyUserResponse } = useGetSurveyUserResponse(
        survey?._id || '',
        user?._id || '',
    );
    const { employeeLocations } = useEmployeeLocationsQuery();

    surveyModelRef.current.mode =
        surveyUserResponse?.data.status === 'completed' ? 'display' : 'edit';

    const saveSurveyDataToAPI = async (currentPageNo: number) => {
        // Only return if we have a response with a non-in_progress status
        if (
            surveyUserResponse?.data &&
            surveyUserResponse.data.status &&
            surveyUserResponse.data.status !== 'in_progress'
        ) {
            return;
        }

        const data = { ...surveyModelRef.current.data, pageNo: currentPageNo };
        // Save the survey response progress to the API and the page number.
        if (!survey) {
            toast.error('Survey not found');
            return;
        }
        await saveSurveyResponseProgress({
            surveyId: survey._id,
            data,
            learningResourceId,
            status: 'in_progress',
            markets: account?.metaData?.markets || [],
            retailerId: account?._id || '',
            locationName: employeeLocations?.[0]?.name,
            locationId: employeeLocations?.[0]?._id,
        });
    };

    const handleCompleteSurvey = async (model: Model, options: CompleteEvent) => {
        const data = { ...surveyModelRef.current.data, pageNo: model.currentPageNo };
        const incorrectAnswerCount = model.getInCorrectAnswerCount();
        if (incorrectAnswerCount > 0) {
            return;
        }
        // Submit the survey response and set the status to completed
        if (!survey) {
            toast.error('Survey not found');
            return;
        }
        await submitSurveyResponse({
            surveyId: survey._id,
            data,
            learningResourceId,
            status: 'completed',
            markets: account?.metaData?.markets || [],
            retailerId: account?._id || '',
        });
    };

    // Initialize survey with data
    useEffect(() => {
        const model = surveyModelRef.current;

        // Only proceed if we have survey data
        if (survey?.surveyData) {
            // Set the survey structure/questions
            model.setJsonObject(survey.surveyData);

            // If we have user response data, load that
            if (surveyUserResponse?.data) {
                model.data = surveyUserResponse.data;
                // Optionally, if you need to jump to a specific page

                if (surveyUserResponse.data.pageNo !== undefined) {
                    model.currentPageNo = Number(surveyUserResponse.data.pageNo);
                }
            }
        }
    }, [survey?.surveyData, isLoadingSurveyUserResponse, surveyUserResponse?.data]);

    const onCompleting = (model: SurveyModel, options: CompletingEvent) => {
        if (model.getInCorrectAnswerCount() > 0) {
            // eslint-disable-next-line no-param-reassign
            options.allow = false;
            model.getAllQuestions().forEach(checkAnswer);

            // Note: This was the best way I could think of to show an error message
            // when the user tries to submit an incomplete survey.
            toast.error(
                'Please answer all questions correctly to complete the survey and get rewarded!',
            );
        }
    };

    // Set up event handlers in a separate effect
    useEffect(() => {
        const model = surveyModelRef.current;
        const isCompleted = surveyUserResponse?.data.status === 'completed';

        if (survey?.themeData) {
            model.applyTheme(survey.themeData);
        }
        if (isCompleted) {
            return undefined;
        }

        const pageChangedHandler = (sender: any) => saveSurveyDataToAPI(sender.currentPageNo);
        const completeHandler = (sender: SurveyModel, options: CompleteEvent) =>
            handleCompleteSurvey(sender, options);
        const valueChangedHandler = async (sender: SurveyModel, options: ValueChangedEvent) => {
            await saveSurveyDataToAPI(sender.currentPageNo);
            checkAnswer(options.question);
        };
        const onCompletingHandler = (sender: SurveyModel, options: CompletingEvent) =>
            onCompleting(sender, options);

        // Add handlers only for incomplete surveys
        model.onCurrentPageChanged.add(pageChangedHandler);
        model.onCompleting.add(onCompletingHandler);
        model.onComplete.add(completeHandler);
        model.onValueChanged.add(valueChangedHandler);

        // Cleanup
        return () => {
            model.onCurrentPageChanged.remove(pageChangedHandler);
            model.onComplete.remove(completeHandler);
            model.onValueChanged.remove(valueChangedHandler);
            model.onCompleting.remove(onCompletingHandler);
        };
    }, [survey, surveyUserResponse?.data.status]);

    // Add loading state
    if (isLoadingSurvey) {
        return <PageLoading label="Loading survey..." />;
    }

    return (
        <>
            <div>
                <AppHeaderMobile
                    hideAppFooter
                    back={{ to: '/user/sparks?tab=learn-and-earn', component: 'Learn & Earn' }}
                />
                <div className="absolute top-[60px] left-0 right-0 bottom-0 overflow-y-auto">
                    {survey?.surveyData ? (
                        <Survey model={surveyModelRef.current} />
                    ) : (
                        <div>No survey data available</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LearnAndEarnSurvey;
