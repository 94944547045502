import { Box, Typography } from '@mui/material';

import { AccountLink } from '@sparkplug/lib';

import IconButton from '@components/buttons/IconButton';
import { Cancel as CancelIcon } from '@components/icons';
import AccountLinkLabel from '@components/labels/AccountLinkLabel';
import Paper from '@components/layout/Paper';

interface RequestForSparkAccountLabelProps {
    accountLink: AccountLink;
    onRemoveAccountLink?: () => void;
}
export const RequestForSparkAccountLabel = ({
    accountLink,
    onRemoveAccountLink,
}: RequestForSparkAccountLabelProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography variant="body" color="gray90" style={{ margin: 0 }}>
                {accountLink.accountType === 'retailer' ? 'Retailer' : 'Vendor'}
            </Typography>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 6px',
                    position: 'relative',
                }}
            >
                <AccountLinkLabel
                    accountLink={accountLink}
                    showVerboseSubtitle
                    className="max-sm:[&_.link-title-container]:pr-5 max-sm:[&_.link-title-container]:pl-2 max-sm:[&_.link-title-container]:py-2"
                />

                {!!onRemoveAccountLink && (
                    <IconButton
                        className="remove-account-link"
                        size="small"
                        onClick={onRemoveAccountLink}
                        sx={{
                            padding: '10px',
                            position: 'absolute',
                            top: '16px',
                            right: '10px',
                        }}
                    >
                        <CancelIcon style={{ color: '#62676e' }} />
                    </IconButton>
                )}
            </Paper>
        </Box>
    );
};
