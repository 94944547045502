import axios from 'axios';

import { useAdvancedMutation } from '@hooks/QueryHooks';

const API = {
    getQuestionResponseSummary: async (learningResourceId: string, questionId: string) => {
        return (
            await axios.get<{ summary: string }>(
                `/api/v1/learning-resource/${learningResourceId}/response/summary/${questionId}`,
            )
        ).data?.summary;
    },
};

export const useGetQuestionResponseSummary = () => {
    const { mutateAsync: getQuestionResponseSummary } = useAdvancedMutation(
        ({ learningResourceId, questionId }: { learningResourceId: string; questionId: string }) =>
            API.getQuestionResponseSummary(learningResourceId, questionId),
    );
    return { getQuestionResponseSummary };
};
