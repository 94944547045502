import { FC, useState } from 'react';

import SettingsTitle from '@views/admin/components/SettingsTitle';
import { capitalize } from 'lodash';

import {
    sendExternalReferralRequestEmail,
    useGroupPlanPromoBalance,
    useRetailerReferralBalances,
} from '@features/spark-credits/queries';

import Form from '@components/form/Form';
import { AirplaneIcon } from '@components/icons';
import AppView from '@components/layout/AppView';
import Grid from '@components/layout/Grid';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import { Route, Switch } from '@components/router';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import AvailableCredits from './AvailableCredits';
import { ReferralCreditsPanel } from './ReferralCreditsPanel';
import SparkCreditHistory from './SparkCreditHistory';

import './AdminCreditsView.scss';

export const AdminCreditsView: FC = () => {
    const [prospectCompanyName, setProspectCompanyName] = useState<string>();
    const [prospectContactName, setProspectContactName] = useState<string>();
    const [prospectEmail, setProspectEmail] = useState<string>();
    const [prospectWebsite, setProspectWebsite] = useState<string>();
    const [prospectPhone, setProspectPhone] = useState<string>();
    const { account, accountIsReady } = useSparkplugAccount();
    const basePath = `/${account?._id}/settings/credits/manage`;

    const { groupPlanPromoBalance, groupPlanPromoBalanceIsReady } = useGroupPlanPromoBalance({
        groupId: account?._id,
    });

    const { retailerReferralBalances, retailerReferralBalancesAreReady } =
        useRetailerReferralBalances(account);

    const canSubmitReferralRequest = !!(
        account?._id &&
        prospectCompanyName?.length &&
        prospectContactName?.length &&
        prospectEmail?.length &&
        prospectWebsite?.length
    );

    const handleSubmitExternalReferralRequest = () => {
        if (canSubmitReferralRequest) {
            toast.promise(
                sendExternalReferralRequestEmail(account._id, {
                    prospectCompanyName,
                    prospectContactName,
                    prospectEmail,
                    prospectWebsite,
                    prospectPhone,
                }),
                {
                    loading: 'Submitting Referral...',
                    success: () => {
                        setProspectCompanyName(undefined);
                        setProspectContactName(undefined);
                        setProspectEmail(undefined);
                        setProspectWebsite(undefined);
                        return 'Referral Submitted';
                    },
                    error: 'Something went wrong.',
                },
            );
        }
    };

    const oppositeGroupType = account?.type === 'brand' ? 'retailer' : 'vendor';

    return (
        <AppView.Content title={<SettingsTitle label="Referral Credits" />}>
            {!accountIsReady || !groupPlanPromoBalanceIsReady ? (
                <Spinner />
            ) : (
                <Grid className="spark-credits-wrapper">
                    <Grid.Item md={4}>
                        <Paper className="card-paper">
                            <Switch>
                                <Route exact path={basePath}>
                                    <AvailableCredits
                                        basePath={basePath}
                                        balance={groupPlanPromoBalance}
                                    />
                                </Route>
                                <Route path={`${basePath}/history`}>
                                    <SparkCreditHistory
                                        basePath={basePath}
                                        accountId={account?._id}
                                    />
                                </Route>
                            </Switch>
                        </Paper>
                    </Grid.Item>
                    <Grid.Item md={8}>
                        <Paper className="credits-form">
                            <h1>Refer a partner and earn Spark Credits!</h1>
                            <div className="subtext">
                                Enter the {oppositeGroupType} name and point of contact and our team
                                at SparkPlug will reach out to this {oppositeGroupType} to help
                                bring them onto the SparkPlug platform
                                {account?.referralUrl && (
                                    <span>
                                        , or&nbsp;
                                        <a
                                            href={account.referralUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            share your referral landing page
                                        </a>
                                    </span>
                                )}
                                .
                            </div>
                            <Form>
                                <Form.TextField
                                    label={`${capitalize(oppositeGroupType)} Name`}
                                    name="prospectCompany"
                                    value={prospectCompanyName ?? ''}
                                    onChange={(e) => setProspectCompanyName(e.target.value)}
                                    required
                                    placeholder={`${capitalize(oppositeGroupType)} Name Here`}
                                />
                                <Form.TextField
                                    label={`${capitalize(oppositeGroupType)} Website URL`}
                                    name="prospectWebsite"
                                    value={prospectWebsite ?? ''}
                                    onChange={(e) => setProspectWebsite(e.target.value)}
                                    required
                                    placeholder="example.com"
                                />
                                <Form.TextField
                                    label="Point of Contact"
                                    name="prospectContactName"
                                    value={prospectContactName ?? ''}
                                    onChange={(e) => setProspectContactName(e.target.value)}
                                    required
                                    placeholder={
                                        account?.industries?.includes('cannabis')
                                            ? 'Mary Jane'
                                            : 'Jane Doe'
                                    }
                                />
                                <Form.TextField
                                    label="Contact Phone"
                                    name="prospectPhone"
                                    value={prospectPhone ?? ''}
                                    onChange={(e) => setProspectPhone(e.target.value)}
                                    required
                                    placeholder="123-456-7890"
                                />
                                <Form.TextField
                                    label="Contact Email"
                                    name="prospectEmail"
                                    value={prospectEmail ?? ''}
                                    onChange={(e) => setProspectEmail(e.target.value)}
                                    required
                                    placeholder="email@example.com"
                                />
                                <Form.Button
                                    color="blue"
                                    variant="smooth"
                                    startIcon={<AirplaneIcon />}
                                    disabled={!canSubmitReferralRequest}
                                    onClick={handleSubmitExternalReferralRequest}
                                >
                                    Send Invite
                                </Form.Button>
                            </Form>
                        </Paper>
                    </Grid.Item>

                    {account?.type === 'brand' && (
                        <Grid.Item className="referral-credits-wrapper">
                            <ReferralCreditsPanel
                                isReady={retailerReferralBalancesAreReady}
                                retailerReferralBalances={retailerReferralBalances}
                            />
                        </Grid.Item>
                    )}
                </Grid>
            )}
        </AppView.Content>
    );
};
