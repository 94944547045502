export type EventDateTimeOnChange = ({
    startDateTime,
    endDateTime,
}: {
    startDateTime: string;
    endDateTime: string;
}) => void;

export interface EventFormFields {
    _id?: string;
    type?: UIEvent['type'];
    locationIds: string[];
    startTime?: string;
    endTime?: string;
    name?: string;
    description?: string;
    brandId?: string;
    retailerAccountId?: string;
    requestNote?: string;
}

export type EventFormErrors = Record<keyof Omit<EventFormFields, 'requestNote' | '_id'>, boolean>;

export const EVENT_NAVIGATION_QUERY_PARAMS = {
    create: 'create',
    editId: 'editId',
    detailId: 'detailId',
    deleteId: 'deleteId',
    quickApprovalCode: 'quickApprovalCode',
} as const;
export type EventSearchParams = {
    [EVENT_NAVIGATION_QUERY_PARAMS.create]: 'true' | 'false';
    [EVENT_NAVIGATION_QUERY_PARAMS.editId]: string;
    [EVENT_NAVIGATION_QUERY_PARAMS.detailId]: string;
    [EVENT_NAVIGATION_QUERY_PARAMS.deleteId]: string;
    [EVENT_NAVIGATION_QUERY_PARAMS.quickApprovalCode]: string;
};
