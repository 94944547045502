import { FC } from 'react';

import UserGroupRoleChip from '@components/chips/UserGroupRoleChip';
import Table from '@components/table/Table';

import { THeadCell } from '@app/types/TableTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import './ExternalGroupsTable.scss';

interface ExternalGroupsTableProps {
    user: IAccountUser;
}

const ExternalGroupsTable: FC<ExternalGroupsTableProps> = ({ user }) => {
    const externalGroups = (user?.externalGroups ?? []).map((externalGroup) => ({
        key: externalGroup.groupId,
        ...externalGroup,
    }));

    const headCells: THeadCell<(typeof externalGroups)[number]>[] = [
        {
            id: 'fullName',
            label: 'Name',
            render: () => <Table.Cell>{user.fullName}</Table.Cell>,
        },
        {
            id: 'groupRole',
            label: 'Type',
            render: ({ groupRole }) => (
                <Table.Cell>
                    <UserGroupRoleChip groupRole={groupRole} />
                </Table.Cell>
            ),
        },
        {
            id: 'groupName',
            label: 'Account',
            render: ({ groupName }) => <Table.Cell>{groupName}</Table.Cell>,
        },
    ];

    return (
        <Table showPagination={false} rows={externalGroups} headCells={headCells} variant="raised">
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};

export default ExternalGroupsTable;
