import { useMemo } from 'react';

import { omit } from 'lodash';

import { queryToString, useLocation, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { EVENT_NAVIGATION_QUERY_PARAMS, EventSearchParams } from '../types';

export const useEventNavigation = () => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();
    const queryParams = useQueryParams<EventSearchParams>();

    const { pathname } = useLocation();

    const currentView: 'inbox' | 'sent' | 'scheduled' = useMemo(() => {
        if (pathname.includes('inbox')) {
            return 'inbox';
        }
        if (pathname.includes('sent')) {
            return 'sent';
        }
        return 'scheduled';
    }, [pathname]);

    // Query params without navigation query params
    const nonNavQueryParams = useMemo(() => {
        const navSearchParams = Object.values(EVENT_NAVIGATION_QUERY_PARAMS);
        return queryToString(omit(queryParams, navSearchParams));
    }, [queryParams]);

    return useMemo(
        () => ({
            state: {
                currentView,
                editId: queryParams.editId,
                create: queryParams.create,
                detailId: queryParams.detailId,
                deleteId: queryParams.deleteId,
                quickApprovalCode: queryParams.quickApprovalCode,
            },
            actions: {
                // Event view level navigation
                goToInboxView: () => {
                    history.push({
                        pathname: `/${account?._id}/events/inbox`,
                        search: nonNavQueryParams,
                    });
                },
                goToSentView: () => {
                    history.push({
                        pathname: `/${account?._id}/events/sent`,
                        search: nonNavQueryParams,
                    });
                },
                goToScheduledView: () => {
                    history.push({
                        pathname: `/${account?._id}/events`,
                        search: nonNavQueryParams,
                    });
                },

                // Event drawer/modal level navigation
                goToCreateEventDrawer: () => {
                    history.push({ search: queryToString({ ...queryParams, create: 'true' }) });
                },
                goToEditEventDrawer: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            editId: eventId,
                        }),
                    });
                },
                goToDeleteEventModal: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            deleteId: eventId,
                        }),
                    });
                },
                goToEventDetailModal: (eventId: string) => {
                    history.push({
                        search: queryToString({
                            ...queryParams,
                            detailId: eventId,
                        }),
                    });
                },
                resetNavParameters: () => {
                    history.push({
                        search: nonNavQueryParams,
                    });
                },
            },
        }),
        [queryParams, history, currentView],
    );
};
