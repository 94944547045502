import { ReactNode } from 'react';

import Typography from '@app/componentLibrary/Typography';

interface MetadataItemProps {
    label: string;
    value: string | ReactNode;
}

export const MetadataItem = ({ label, value }: MetadataItemProps) => (
    <div>
        <Typography variant="sm" className="font-medium text-gray-800">
            {label}
        </Typography>
        <Typography variant="sm">{value}</Typography>
    </div>
);
