import axios from 'axios';

import {
    ListPosProductsMappedToVendorQueryParams,
    ListPosProductsResponseBody,
    RetailerSupportsInventoryResponseBody,
} from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { fetchBatchedData } from '@helpers/api';
import { sortByString } from '@helpers/ui';

export default class InventoryAPI {
    static async getAllRetailerProductsMappedToVendor(
        params: ListPosProductsMappedToVendorQueryParams,
    ): Promise<ListPosProductsResponseBody> {
        const { vendorAccountId, retailerAccountId, shareSalesData } = params;
        return (
            await axios.post(
                `/api/v1/pos/products/retailer-products-by-vendor`,
                { vendorAccountId, retailerAccountId, shareSalesData },
                {
                    params: {
                        ...params,
                        offset: params.offset ?? 0,
                        limit: params.limit ?? 100,
                    },
                },
            )
        ).data;
    }

    static async retailerPOSConfigSupportsInventory(
        groupId: string,
    ): Promise<RetailerSupportsInventoryResponseBody> {
        return (await axios.get(`/api/v1/pos/config/${groupId}/inventory-enabled`)).data;
    }
}

export const getAllRetailerPosProductsMappedToVendor = async (
    vendorAccountId: string,
    retailerAccountId: string,
    shareSalesData: boolean,
) => {
    const fetchFn = (offset: number, limit: number) => {
        return InventoryAPI.getAllRetailerProductsMappedToVendor({
            vendorAccountId,
            retailerAccountId,
            shareSalesData,
            offset,
            limit,
        });
    };

    const { data } = await fetchBatchedData(fetchFn);

    return data
        .map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj._id,
        }))
        .sort(sortByString('label', 'asc'));
};

export const useRetailerProductsMappedToVendor = ({
    vendorAccountId,
    retailerAccountId,
    shareSalesData,
    enabled,
}: {
    vendorAccountId: string;
    retailerAccountId: string;
    shareSalesData: boolean;
    enabled?: boolean;
}) => {
    const { isLoading, data: retailerProducts = [] } = useAdvancedQuery(
        ['retailer-products-mapped-to-vendor', vendorAccountId, retailerAccountId],
        () =>
            getAllRetailerPosProductsMappedToVendor(
                vendorAccountId,
                retailerAccountId,
                shareSalesData,
            ),
        { enabled: enabled && Boolean(vendorAccountId) && Boolean(retailerAccountId) },
    );

    return {
        retailerProductsAreReady: !isLoading,
        retailerProducts,
    };
};

export const useFetchRetailerInventoryEnabled = (accountId: string) => {
    const { data: retailerInventoryEnabled, isFetched: retailerInventoryEnabledIsReady } =
        useAdvancedQuery(`fetchPosConfigInventoryEnabled - ${accountId}`, () =>
            InventoryAPI.retailerPOSConfigSupportsInventory(accountId),
        );

    return {
        inventoryEnabled: retailerInventoryEnabled?.data.inventoryEnabled,
        posConfigId: retailerInventoryEnabled?.data.posConfigId,
        retailerInventoryEnabledIsReady,
    };
};
