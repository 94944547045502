import { PlayCircle } from '@mui/icons-material';

import { useLearningResource } from '@features/courses/context/LearningResourceContext';
import { useGetAllCourseAssetsQuery } from '@features/courses/queries/useGetAllCourseAssets';

import Drawer from '@components/overlays/Drawer';

const CourseAssetsModal = ({
    isVisible,
    onCloseHandler,
}: {
    isVisible: boolean;
    onCloseHandler: () => void;
}) => {
    const [{ accountId }] = useLearningResource();
    const { assets, isLoadingAssets, assetsError } = useGetAllCourseAssetsQuery(accountId);
    return (
        <Drawer
            isVisible={isVisible}
            onCloseHandler={onCloseHandler}
            title="Gallery"
            variant="right"
        >
            {!accountId && <div>Please select an account first</div>}
            {isLoadingAssets && <div>Loading assets...</div>}
            <div className="grid grid-cols-3 gap-4">
                {assets.map(({ url, type, name }) => (
                    <div
                        key={url}
                        className="aspect-square bg-gray-200 flex flex-col items-center justify-center rounded-lg overflow-hidden"
                    >
                        {['jpg', 'jpeg', 'png'].includes(type) && (
                            <img
                                src={url}
                                alt={name ?? 'course asset'}
                                className="w-full h-full object-cover "
                                title={name}
                            />
                        )}
                        {['mp4', 'mov'].includes(type) && (
                            <div
                                className="flex flex-col items-center justify-center p-2 gap-2"
                                title={name}
                            >
                                <PlayCircle className="text-white" style={{ fontSize: '4rem' }} />
                                <p className="text-gray-800 text-sm">
                                    {name.length > 10
                                        ? `${name.slice(0, 5)}...${name.slice(-5)}`
                                        : name}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </Drawer>
    );
};

export default CourseAssetsModal;
