import React from 'react';

// import Fade from 'embla-carousel-fade';
import {
    Carousel,
    CarouselApi,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from '@app/componentLibrary/Carousel/carousel';
import { cn } from '@app/componentLibrary/utils';

type ImageCarouselProps = {
    images: string[];
};

export default ({ images }: ImageCarouselProps) => {
    const [carouselApi, setCarouselApi] = React.useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);

    React.useEffect(() => {
        if (!carouselApi) {
            return;
        }

        setCurrent(carouselApi.selectedScrollSnap() + 1);

        carouselApi.on('select', () => {
            setCurrent(carouselApi.selectedScrollSnap() + 1);
        });
    }, [carouselApi]);

    return (
        <div className="flex flex-col gap-4 h-full bg-cerulean-600 items-center max-sm:w-full max-sm:mt-[64px]">
            <Carousel
                setCarouselApi={setCarouselApi}
                className="flex flex-col gap-8 h-full sm:w-9/12"
                // plugins={[Fade()]}
            >
                <div className="h-full [&_>div]:h-full [&_>div]:flex">
                    <CarouselContent
                        className="flex-grow"
                        style={{ width: `${Math.round(100 / images.length)}%` }}
                    >
                        {images.map((src, index) => (
                            <CarouselItem key={index} className="flex items-center justify-center">
                                <img src={src} alt="" style={{ height: '80vh' }} />
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </div>
                <CarouselPrevious className="max-sm:hidden" />
                <CarouselNext className="max-sm:hidden" />
                <div className="flex gap-1 w-full justify-center absolute bottom-12">
                    {Array.from({ length: images.length }).map((_, index) => (
                        // eslint-disable-next-line jsx-a11y/control-has-associated-label
                        <button
                            key={index}
                            type="button"
                            className={cn(
                                'w-2 h-2 rounded-full bg-cerulean-300 cursor-pointer border-none p-0 hover:bg-cerulean-400',
                                index + 1 === current && 'bg-white',
                            )}
                            onClick={() => carouselApi?.scrollTo(index)}
                        />
                    ))}
                </div>
            </Carousel>
        </div>
    );
};
