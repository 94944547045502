import axios from 'axios';

import {
    CreateLearningResourceRequestBody,
    CreateLearningResourceResponseBody,
} from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { coursesCacheKey } from '../queries/useGetAllCourses';

const API = {
    createLearningResource: async (payload: CreateLearningResourceRequestBody) => {
        const sanitizedPayload = Object.fromEntries(
            Object.entries(payload).filter(([_, value]) => value != null),
        );
        return (
            await axios.post<CreateLearningResourceResponseBody>(
                '/api/v1/learning-resource',
                sanitizedPayload,
            )
        ).data;
    },
};

export const useCreateLearningResource = () => {
    return useAdvancedMutation(
        (payload: CreateLearningResourceRequestBody) => API.createLearningResource(payload),
        {
            updateQuery: { queryKey: coursesCacheKey },
            toastOptions: {
                loading: 'Saving Course...',
                success: 'Course Saved!',
                error: 'Failed to save Course.',
            },
        },
    );
};
