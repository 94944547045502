import { FC } from 'react';

import moment from 'moment';

import { WalletEmployeeDeposit } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { RightArrow } from '@components/icons';
import MobileList from '@components/layout/MobileList';
import { SparkCardLogo } from '@components/sparks/SparkCardLogo';

import { CashDiffLabel } from '../../CashDiffLabel';
import { ClaimButton } from './ClaimButton';

import './RewardsListItem.scss';

interface RewardsListItemProps {
    reward: WalletEmployeeDeposit;
    onClick: () => void;
}

const ClaimedRewardLabel: FC<{ reward: WalletEmployeeDeposit }> = ({ reward }) => {
    if (reward.amount) {
        return <CashDiffLabel type="deposit" amount={reward.amount} />;
    }

    return <span className="non-cash-reward-label">See details</span>;
};

const ClaimedContent: FC<{ reward: WalletEmployeeDeposit }> = ({ reward }) => {
    const isNonFbs = !reward.amount;
    const isNonFbsReceiptPending = !reward.nonFbsReceiptConfirmedAt;
    if (isNonFbs && isNonFbsReceiptPending) {
        return (
            <Button className="w-fit" variant="smooth" color="green">
                Confirm
            </Button>
        );
    }
    return (
        <>
            <ClaimedRewardLabel reward={reward} />
            <RightArrow className="claimed-arrow" />
        </>
    );
};

export const RewardsListItem: FC<RewardsListItemProps> = ({ reward, onClick }) => {
    const { spark } = reward;

    const formattedStartDate = moment(spark?.startDate).format('M/D/YY');
    const formattedEndDate = moment(spark?.endDate).format('M/D/YY');
    const description = `${formattedStartDate} - ${formattedEndDate}`;

    const isClaimed = reward.status === 'Claimed';

    const endElement = isClaimed ? <ClaimedContent reward={reward} /> : <ClaimButton />;

    const itemData = {
        name: spark?.name ?? reward.name,
        description: spark ? description : 'Course',
        endElement,
        graphic: spark ? (
            <SparkCardLogo
                emoji={spark.emoji}
                sparkBrand={spark.sparkBrand}
                recurringSchedule={spark.recurringSchedule}
            />
        ) : (
            <SparkCardLogo
                sparkBrand={{
                    name: reward.learningResourceBrandInfo?.name ?? '',
                    photo: reward.learningResourceBrandInfo?.photo ?? '',
                }}
            />
        ),
    };
    return (
        <MobileList.Item
            className="spark-rewards-list-item"
            label={itemData.name}
            description={itemData.description}
            startElement={itemData.graphic}
            endElement={endElement}
            onClick={onClick}
        />
    );
};
