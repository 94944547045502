import EventTable from '@features/events/components/EventTable';
import { useEventNavigation } from '@features/events/hooks/useEventNavigation';

import CalloutMessage from '@components/layout/CalloutMessage';

export const EventsInbox = () => {
    const nav = useEventNavigation();
    return (
        <div data-testid="events-inbox">
            <CalloutMessage
                className="mb-4"
                message="Events in your inbox have been requested by your Vendor partners. Approve or decline to add to your employee’s calendar."
            />
            <EventTable
                isSentInbox
                onRowClick={(event) => nav.actions.goToEventDetailModal(event._id)}
            />
        </div>
    );
};
