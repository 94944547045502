/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';

import { DateTime } from 'luxon';

import Tooltip from '@components/layout/Tooltip';
import { PlaceholderBrandLogo } from '@components/logos';
import Table from '@components/table/Table';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import { useEventNavigation } from '../../../hooks/useEventNavigation';
import EventStatusChip from '../../EventStatusChip';
import { EventTableRowData } from '../types';
import { EventTableActionMenu } from './EventTableActionMenu';
import { adminCanEditOrDelete, formatEventType } from './utils';

const useEventsHeadCell = () => {
    const { userIsSuperAdmin, user } = useApp();
    const { account, userCan } = useSparkplugAccount();
    const hasEventPermissions = userCan('createSpark');
    const accountType = account?.type ?? 'brand';

    const nav = useEventNavigation();

    const headCells: THeadCell<EventTableRowData>[] = useMemo(() => {
        return [
            {
                id: 'brand.name',
                label: 'Brand',
                isHidden: accountType === 'retailer',
                render: (row) => {
                    return <Table.Cell className="message-cell">{row.brand?.name}</Table.Cell>;
                },
            },
            {
                id: 'type',
                label: 'Type',
                render: (row) => (
                    <Table.Cell className="message-cell">{formatEventType(row)}</Table.Cell>
                ),
            },
            {
                id: 'location',
                label: 'Location',
                render: (row) => (
                    <Table.Cell className="message-cell ">
                        {row.locations.length === row.totalRetailerLocationCount ? (
                            'All Locations'
                        ) : row.locations.length === 1 ? (
                            row.locations[0].name
                        ) : (
                            <Tooltip title={row.locations.map((l) => l.name).join(', ')}>
                                <div className="flex flex-nowrap items-center gap-1">
                                    <span>{row.locations[0].name}</span>
                                    <div className="w-5 h-4 flex items-center justify-center text-[10px] text-gray-800 border border-solid border-gray-400 rounded">{` +${
                                        row.locations.length - 1
                                    }`}</div>
                                </div>
                            </Tooltip>
                        )}
                    </Table.Cell>
                ),
            },
            {
                id: 'startTime',
                sortType: 'date',
                label: 'Start Date',
                render: (row) => (
                    <Table.Cell className="message-cell">
                        {DateTime.fromISO(row.startTime).toFormat('MMM d, yyyy')}
                    </Table.Cell>
                ),
            },
            {
                id: 'endTime',
                sortType: 'date',
                label: 'End Date',
                render: (row) => (
                    <Table.Cell className="message-cell">
                        {DateTime.fromISO(row.endTime).toFormat('MMM d, yyyy')}
                    </Table.Cell>
                ),
            },
            {
                id: 'eventTime',
                label: 'Time',
                render: (row) => {
                    const startDate = DateTime.fromISO(row.startTime);
                    const endDate = DateTime.fromISO(row.endTime);
                    const isMultiDayEvent = !startDate.hasSame(endDate, 'day');
                    return (
                        <Table.Cell className="message-cell">
                            {isMultiDayEvent
                                ? `All day`
                                : `${startDate.toFormat('h:mm a')} - ${endDate.toFormat('h:mm a')}`}
                        </Table.Cell>
                    );
                },
            },
            {
                id: 'name',
                sortType: 'string',
                label: 'Event Name',
                render: (row) => <Table.Cell className="message-cell">{row.name}</Table.Cell>,
            },
            {
                id: 'description',
                label: 'Event Description',
                render: (row) => (
                    <Table.Cell className="message-cell">{row.description}</Table.Cell>
                ),
            },
        ];
    }, [accountType]);

    const stickyLeftCells: THeadCell<EventTableRowData>[] = useMemo(() => {
        return [
            {
                id: 'creator',
                label: () => (
                    <div className="sticky-header">
                        <div className="label">
                            <span>{accountType === 'retailer' ? 'Event Creator' : 'Retailer'}</span>
                        </div>
                    </div>
                ),
                sticky: 'left',
                render: (row) => {
                    const photo =
                        accountType === 'retailer'
                            ? row.brand?.photo ?? row.retailerAccount.photo
                            : row.retailerAccount.photo;
                    return (
                        <Table.Cell className="no-wrap [&>div]:pr-4 [&_.sticky-wrap]:justify-between">
                            <div className="flex items-center pl-4 gap-3 pr-4">
                                {photo ? (
                                    <img
                                        className="rounded h-10 w-10 object-contain"
                                        src={photo ?? ''}
                                        alt="Logo"
                                    />
                                ) : (
                                    <PlaceholderBrandLogo className="rounded h-10 w-10 object-contain" />
                                )}
                                <span>
                                    {accountType === 'retailer'
                                        ? row.brand?.name ?? row.retailerAccount.name
                                        : row.retailerAccount.name}
                                </span>
                            </div>
                            <EventStatusChip event={row} accountType={accountType} />
                        </Table.Cell>
                    );
                },
            },
        ];
    }, [accountType]);

    const stickyRightCells: THeadCell<EventTableRowData>[] = useMemo(() => {
        return [
            {
                id: 'action',
                columnManagerLabel: 'action',
                sticky: 'right',
                render: (eventRow) => {
                    return (
                        <Table.Cell align="left">
                            <EventTableActionMenu
                                event={eventRow}
                                account={account}
                                user={user}
                                showApprove={
                                    accountType === 'retailer' &&
                                    eventRow.displayStatus === 'pending'
                                }
                                showDecline={
                                    accountType === 'retailer' &&
                                    eventRow.displayStatus === 'pending'
                                }
                                showEdit={
                                    userIsSuperAdmin || adminCanEditOrDelete({ account, eventRow })
                                }
                                showDelete={
                                    userIsSuperAdmin || adminCanEditOrDelete({ account, eventRow })
                                }
                                userIsPermitted={hasEventPermissions}
                            />
                        </Table.Cell>
                    );
                },
            },
        ];
    }, [nav.state.currentView, user]);

    return { headCells, stickyLeftCells, stickyRightCells };
};

export default useEventsHeadCell;
