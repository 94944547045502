import { useCallback, useEffect, useState } from 'react';

import moment from 'moment';

import { saveSparkplugConfig } from '@core/accounts';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { SaveAlt } from '@components/icons';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import SMSNotificationsFormFields, { ISMSSettings } from './components/SMSNotificationsFormFields';

const DEFAULT_SETTINGS = {
    isUpdateEnabled: false,
    isAnnounceEnabled: true,
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    timeOfDay: '12:00:00',
    timeZone: moment.tz.guess(true),
};

const AdminSMSNotificationsView = () => {
    const { account, refetchAccount } = useSparkplugAccount();
    const [settings, setSettings] = useState<ISMSSettings>(DEFAULT_SETTINGS);

    const onChangeSettings = (newSettings: Partial<ISMSSettings>) => {
        setSettings((prevState) => {
            return {
                ...prevState,
                ...newSettings,
            };
        });
    };

    const onSave = useCallback(() => {
        console.log('settings', settings);
        if (settings.daysOfWeek.length < 2) {
            toast.error('Please select at least two days of the week');
            return;
        }
        if (account) {
            toast.promise(
                saveSparkplugConfig(account.sparkplugConfig._id, {
                    groupId: account._id,
                    smsNotificationSettings: settings,
                }),
                {
                    loading: 'Saving...',
                    success: () => {
                        refetchAccount();
                        return `Saved`;
                    },
                    error: `Could not save settings`,
                },
            );
        }
    }, [refetchAccount, account, settings]);

    useEffect(() => {
        if (account?.sparkplugConfig?.smsNotificationSettings) {
            const notificationSettings: ISMSSettings =
                account.sparkplugConfig.smsNotificationSettings;

            if (!notificationSettings.timeOfDay) {
                notificationSettings.timeOfDay = DEFAULT_SETTINGS.timeOfDay;
            }

            if (!notificationSettings.timeZone) {
                notificationSettings.timeZone = DEFAULT_SETTINGS.timeZone;
            }

            setSettings(notificationSettings);
        }
    }, [account]);

    if (!account) {
        return <></>;
    }

    return (
        <div className="admin-sms-notifications-view">
            <Form className="[&_.form-label-custom]:text-base [&_.form-label-custom]:text-gray-900">
                <SMSNotificationsFormFields settings={settings} onChange={onChangeSettings} />
                <Form.ActionGroup>
                    <Button
                        className="input-group-text config-save-button"
                        color="blue"
                        variant="smooth"
                        onClick={onSave}
                        startIcon={<SaveAlt />}
                    >
                        Save
                    </Button>
                </Form.ActionGroup>
            </Form>
        </div>
    );
};

export default AdminSMSNotificationsView;
