import { useEffect, useRef } from 'react';

import { EventFormFields } from '@features/events/types';

export type EventFormDependencyManagerProps = {
    form: EventFormFields;
    updateForm: (updates: Partial<EventFormFields>) => void;
};

/**
 * Manage the data dependencies between the form fields.
 * Note: Only the vendor form experience has form dependencies.
 */
export const useEventFormDependencyManager = ({
    form,
    updateForm,
}: EventFormDependencyManagerProps) => {
    // The refs allow us to be more precise with the dependencies
    const previousRetailerRef = useRef<string | undefined>();
    const previousBrandRef = useRef<string | undefined>();

    // Switching from a previous brand to a new brand should clear the selected retailer
    useEffect(() => {
        const previousBrand = previousBrandRef.current;
        previousBrandRef.current = form.brandId;
        if (previousBrand && form.brandId !== previousBrand) {
            updateForm({ retailerAccountId: '' });
        }
    }, [form.brandId]);

    // Clearing the selected retailer should clear the form field.
    // This is necessary because the retailer input is a react-select input.
    const retailerInputRef = useRef<any>();
    useEffect(() => {
        const previousRetailer = previousRetailerRef.current;
        if (previousRetailer && !form.retailerAccountId) {
            retailerInputRef?.current?.clearValue?.();
        }
    }, [form.retailerAccountId]);

    // Switching from a previous retailer to a new retailer should clear the selected locations
    useEffect(() => {
        const previousRetailer = previousRetailerRef.current;
        previousRetailerRef.current = form.retailerAccountId;
        if (previousRetailer && form.retailerAccountId !== previousRetailer) {
            updateForm({ locationIds: [] });
        }
    }, [form.retailerAccountId]);

    return { retailerInputRef };
};
