import { DO_NOT_HAVE_PERMISSIONS_MESSAGE, UIEvent } from '@sparkplug/lib';

import { useApproveEventMutation, useDeclineEventMutation } from '@features/events/mutations';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Modal from '@components/overlays/Modal';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Typography from '@app/componentLibrary/Typography';

import { EventDetails } from '../../EventDetails';

export const PendingEventContent = ({
    event,
    onDecline,
    onApprove,
}: {
    event: UIEvent;
    onDecline: () => void;
    onApprove: () => void;
}) => {
    const { userCan } = useSparkplugAccount();
    const userIsPermitted = userCan('createSpark');
    const userPermissionTooltip = !userIsPermitted ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined;

    const { declineEventAsync } = useDeclineEventMutation();
    const handleDeclineEvent = async () => {
        await declineEventAsync(event._id);
        onDecline();
    };

    const { approveEventAsync } = useApproveEventMutation();
    const handleApproveEvent = async () => {
        await approveEventAsync(event._id);
        onApprove();
    };
    return (
        <>
            <Modal.Content className="flex flex-col gap-4 w-full">
                <CalloutMessage
                    title="Event Note:"
                    message={`${event.vendorAccount?.name} at ${
                        event.brand?.name
                    } scheduled an event ${event.requestNote ? `"${event.requestNote}"` : ''}`}
                />
                <section className="w-full flex flex-col gap-1">
                    <Typography variant="base" className="text-gray-900">
                        Event Details
                    </Typography>
                    <Paper className="w-full">
                        <EventDetails className="w-full" event={event} accountType="retailer" />
                    </Paper>
                </section>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    tooltip={userPermissionTooltip}
                    disabled={!userIsPermitted}
                    color="red"
                    variant="smooth"
                    onClick={handleDeclineEvent}
                >
                    Decline
                </Button>
                <Button
                    tooltip={userPermissionTooltip}
                    disabled={!userIsPermitted}
                    color="green"
                    variant="smooth"
                    onClick={handleApproveEvent}
                >
                    Approve
                </Button>
            </Modal.Actions>
        </>
    );
};
