import { useState } from 'react';

import { EventFormErrors, EventFormFields } from '@features/events/types';

import { IAccount } from '@app/types/AccountsTypes';

export const useEventFormValidator = ({ account }: { account: IAccount }) => {
    const [errors, setErrors] = useState<EventFormErrors>({
        type: false,
        locationIds: false,
        startTime: false,
        endTime: false,
        name: false,
        description: false,
        brandId: false,
        retailerAccountId: false,
    });

    return {
        errors,
        validate: (formFields: EventFormFields) => {
            const newErrors: EventFormErrors = {
                type: !formFields.type,
                locationIds: !formFields.locationIds?.length,
                startTime: !formFields.startTime,
                endTime: !formFields.endTime,
                name: !formFields.name,
                description: !formFields.description,
                brandId: account.type === 'brand' && !formFields.brandId,
                retailerAccountId: account.type === 'brand' && !formFields.retailerAccountId,
            };
            setErrors(newErrors);

            return Object.values(newErrors).every((error) => !error);
        },
    };
};
