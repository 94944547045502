import { ReactNode } from 'react';

import { Drawer as MuiDrawer, SwipeableDrawer, SwipeableDrawerProps } from '@mui/material';
import clsx from 'clsx';

import IconButton from '@components/buttons/IconButton';
import { Close as CloseIcon } from '@components/icons';

import DrawerFooter from './DrawerFooter';

import './Drawer.scss';

type Anchor = 'bottom' | 'right';

interface DrawerProps {
    className?: string;
    drawerBodyClassName?: string;
    title: string;
    variant: 'bottomPartial' | 'bottomFull' | 'right';
    isVisible: boolean;
    onCloseHandler: (doActionOnClose: boolean) => void;
    children: ReactNode;
    swipeable?: boolean;
    disableEnforceFocus?: boolean;
    disableTransition?: boolean;
    drawerHeader?: ReactNode;
    drawerFooter?: ReactNode;
    PaperProps?: SwipeableDrawerProps['PaperProps'];
}

const Drawer = ({
    title,
    variant,
    isVisible,
    onCloseHandler,
    children,
    className = '',
    drawerBodyClassName = '',
    swipeable = true,
    disableEnforceFocus = false,
    disableTransition = false,
    drawerHeader,
    drawerFooter,
    PaperProps,
}: DrawerProps) => {
    let anchor: Anchor = 'right';

    if (variant === 'bottomFull' || variant === 'bottomPartial') {
        anchor = 'bottom';
    }

    const DrawerComponent = swipeable ? SwipeableDrawer : MuiDrawer;
    const onOpen = (swipeable ? () => {} : undefined) as SwipeableDrawerProps['onOpen'];
    const disableSwipeToOpen = (
        swipeable ? true : undefined
    ) as SwipeableDrawerProps['disableSwipeToOpen'];

    return (
        <DrawerComponent
            className={`drawer ${variant} ${className}`.trim()}
            anchor={anchor}
            open={isVisible}
            onClose={() => onCloseHandler(true)}
            onOpen={onOpen}
            disableSwipeToOpen={disableSwipeToOpen}
            disableEnforceFocus={disableEnforceFocus}
            SlideProps={{
                appear: !disableTransition,
            }}
            PaperProps={PaperProps}
        >
            <div className="drawer-header">
                {drawerHeader ?? (
                    <>
                        <h1 className="drawer-title">{title}</h1>
                        <IconButton
                            className="drawer_close-button"
                            onClick={() => onCloseHandler(true)}
                        >
                            <CloseIcon />
                        </IconButton>{' '}
                    </>
                )}
            </div>
            <div className={clsx('drawer-body', drawerBodyClassName)}>{children}</div>
            {drawerFooter ? <div className="drawer-footer">{drawerFooter}</div> : null}
        </DrawerComponent>
    );
};

Drawer.Footer = DrawerFooter;

export default Drawer;
