import { z } from 'zod';
import {
  CALCULATOR_FREQUENCIES,
  RETAILER_CHART_CALCULATORS,
  RETAILER_CHART_CALCULATOR_BREAKDOWNS,
  VENDOR_CHART_CALCULATORS,
  VENDOR_CHART_CALCULATOR_BREAKDOWNS,
} from '../calculator';
import { PERCENT_INCREASE_SPARK_METRICS } from '../spark';
import { objectIdRegex } from '../util';

export type TComparisonPeriodOption =
  | 'previousPeriodMatchDay'
  | 'previousYearMatchDay'
  | 'previousPeriod'
  | 'previousYear';

export type TChartBreakdownType =
  | 'total'
  | 'retailer'
  | 'location'
  | 'product'
  | 'brand'
  | 'category'
  | 'employee';

export type TChartDataMetric =
  | 'order_average'
  | 'total_sales'
  | 'total_units'
  | 'transaction_count'
  | 'units_per_transaction'
  | 'commission'
  | 'percent_of_total_sales'
  | 'percent_increase'
  | 'guest_check_average';

export interface UISparkStanding {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  locationName?: string;
  flexibleEmployeeId: string;
  posEmployeeProfileIds: string[];
  primaryValue: number;
  rank: number;
  secondaryValue?: number;
  transactionCount?: number;
  lastTransactionLocationId?: string;
  percentIncreaseComparison?: {
    currentTotal: number;
    previousTotal: number;
  };
}

// Percent Increase charts use a different endpoint
export interface GetChartPathParams {
  type: Omit<TChartDataMetric, 'percent_increase'>;
}

export interface GetSparkEmployeeStandingsChartPathParams {
  sparkId: string;
}

export const getChartSchema = z.object({
  groupId: z.string().min(1),
  dateStart: z.string().min(1),
  dateEnd: z.string().min(1),

  brandGroupId: z.string().optional(),
  frequency: z.enum(CALCULATOR_FREQUENCIES).optional(),
  locationIds: z.union([z.array(z.string()), z.string()]).optional(),
  productIds: z.union([z.array(z.string()), z.string()]).optional(),
  productInternalKeys: z.array(z.string().optional()).optional(),
  sparkId: z.string().optional(),
  percentIncreaseData: z
    .object({
      metric: z.enum(PERCENT_INCREASE_SPARK_METRICS),
      startDate: z.string(),
    })
    .optional(),
});

export type GetChartRequestBody = z.infer<typeof getChartSchema>;

export type GetChartResponseBody =
  | FullChartResponseBody
  | EmployeeChartResponseBody
  | RetailerChartLocationBody
  | RetailerChartEmployeeBody;

export const getVendorChartSchema = z.object({
  dateStart: z.string().min(1),
  dateEnd: z.string().min(1),
  frequency: z.enum(CALCULATOR_FREQUENCIES),
  retailerAccountIds: z.array(z.string().regex(objectIdRegex)),
  exclusionFilterParams: z
    .object({
      locationIds: z.array(z.string()).optional(),
      productInternalKeys: z.array(z.string()).optional(),
    })
    .optional(),
  legacyLocationIds: z.array(z.string().regex(objectIdRegex)).optional(),
  productIds: z.array(z.string().regex(objectIdRegex)).optional(),
});
export type GetVendorChartRequestBody = z.infer<typeof getVendorChartSchema>;

export const getVendorChartBucketsBreakdownPathParamsSchema = z.object({
  vendorAccountId: z.string().regex(objectIdRegex),
  breakdownType: z.enum(VENDOR_CHART_CALCULATOR_BREAKDOWNS),
  calcType: z.enum(VENDOR_CHART_CALCULATORS),
});
export type GetVendorChartBucketsBreakdownPathParams = z.infer<
  typeof getVendorChartBucketsBreakdownPathParamsSchema
>;

export const getVendorChartTotalsBreakdownPathParamsSchema = z.object({
  vendorAccountId: z.string().regex(objectIdRegex),
  breakdownType: z.enum(VENDOR_CHART_CALCULATOR_BREAKDOWNS),
  calcType: z.enum(VENDOR_CHART_CALCULATORS),
});
export type GetVendorChartTotalsBreakdownPathParams = z.infer<
  typeof getVendorChartTotalsBreakdownPathParamsSchema
>;

export type GetVendorChartPathParms = GetVendorChartTotalsBreakdownPathParams;

export const getVendorChartTotalPathParamsSchema = z.object({
  vendorAccountId: z.string().regex(objectIdRegex),
  calcType: z.enum(VENDOR_CHART_CALCULATORS),
});
export type GetVendorChartTotalPathParams = z.infer<typeof getVendorChartTotalPathParamsSchema>;

export const getVendorChartProductsWithSalesPathParamsSchema = z.object({
  vendorAccountId: z.string().regex(objectIdRegex),
});
export type GetVendorChartProductsWithSalesPathParams = z.infer<
  typeof getVendorChartProductsWithSalesPathParamsSchema
>;

export type VendorProductsWithSaleslResponseBody = {
  productsWithSales: string[];
};
export type VendorCalcTotalResponseBody = {
  total: number;
};
export type VendorTotalsChartResponseBody = {
  keys: string[];
  rows: {
    key: string;
    value?: number;
    previousValue?: number;
    [key: string]: number | string | undefined;
  }[];
};

export interface BucketsKeyedByLabel {
  [label: string]: {
    [bucketName: string]: number;
  };
}

export type VendorBreakdownBucketsChartResponseBody = {
  buckets: BucketsKeyedByLabel;
};

export interface FullChartResponseBody {
  locationBuckets: {
    [locationId: string]: {
      [bucketName: string]: number;
    };
  };
  locationCumulativeBuckets?: {
    [locationId: string]: {
      [bucketName: string]: number;
    };
  };
  locationTotals: {
    [locationId: string]: number;
  };
  employeeBuckets: {
    [flexibleEmployeeId: string]: {
      [bucketName: string]: number;
    };
  };
  /**
   * @description The value breaks down to be [primary, comparison]
   */
  employeeComparisonTotals?: {
    [flexibleEmployeeId: string]: [number, number];
  };
  employeeCumulativeBuckets?: {
    [flexibleEmployeeId: string]: {
      [bucketName: string]: number;
    };
  };
  employeeTotals: {
    [flexibleEmployeeId: string]: number;
  };
  employeeTransactionCounts?: {
    [flexibleEmployeeId: string]: number;
  };
  productBuckets?: {
    [productName: string]: {
      [bucketName: string]: number;
    };
  };
  commissions?: {
    [flexibleEmployeeId: string]: {
      commissionEarned: number;
      totalQuantity: number;
      totalPrice: number;
      hasMissingCommissions?: boolean;
    };
  };
  productsWithSales?: string[];
  productsWithSalesByKey?: string[];
  productsWithSalesByKeyArchive?: string[];
  total: number;
  totalCumulativeBuckets?: { [bucketName: string]: number };
  datePercentage: number;
  brandBuckets?: {
    [brandName: string]: {
      [bucketName: string]: number;
    };
  };
  categoryBuckets?: {
    [categoryName: string]: {
      [bucketName: string]: number;
    };
  };
}

export type EmployeeChartResponseBody = Pick<
  FullChartResponseBody,
  | 'total'
  | 'locationTotals'
  | 'employeeTotals'
  | 'employeeComparisonTotals'
  | 'employeeTransactionCounts'
  | 'commissions'
>;

export const getRetailerChartSchema = z.object({
  dateStart: z.string().min(1),
  dateEnd: z.string().min(1),
  frequency: z.enum(CALCULATOR_FREQUENCIES).optional(),
  legacyLocationIds: z.array(z.string().regex(objectIdRegex)).optional(),
});

export type GetRetailerChartRequestBody = z.infer<typeof getRetailerChartSchema>;

export type GetChartRequestBodyMerged = GetChartRequestBody & GetRetailerChartRequestBody;

export const getRetailerChartBucketsBreakdownPathParamsSchema = z.object({
  retailerAccountId: z.string().regex(objectIdRegex),
  breakdownType: z.enum(RETAILER_CHART_CALCULATOR_BREAKDOWNS),
  calcType: z.enum(RETAILER_CHART_CALCULATORS),
});

export const getRetailerChartTotalsBreakdownPathParamsSchema = z.object({
  retailerAccountId: z.string().regex(objectIdRegex),
  breakdownType: z.enum(RETAILER_CHART_CALCULATOR_BREAKDOWNS),
  calcType: z.enum(RETAILER_CHART_CALCULATORS),
});

export type GetRetailerChartTotalsBreakdownPathParams = z.infer<
  typeof getRetailerChartTotalsBreakdownPathParamsSchema
>;

export type GetRetailerChartPathParms = GetRetailerChartTotalsBreakdownPathParams;

export type RetailerBreakdownBucketsChartResponseBody = {
  buckets: BucketsKeyedByLabel;
  datePercentage: number;
};

export type RetailerBreakdownsTotalsChartResponseBody = {
  [newKey: string]: number;
};

export type RetailerChartLocationBody = {
  locationBuckets: BucketsKeyedByLabel;
  locationTotals: RetailerBreakdownsTotalsChartResponseBody;
  datePercentage: number;
  total: number;
  // TODO: these exist on the old chart api response, but not here - need to have as optional and "never" until we're fully transitioned
  employeeTotals?: never;
  commissions?: never;
};

export type RetailerChartEmployeeBody = {
  employeeTotals: RetailerBreakdownsTotalsChartResponseBody;
  // TODO: this exists on the old chart api response, but not here - need to have as optional and "never" until we're fully transitioned
  commissions?: never;
};
