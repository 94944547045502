import { FC, ReactElement, ReactNode, useLayoutEffect, useState } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'lodash';

import Button from '@components/buttons/Button';
import { CustomArrowLeftIcon } from '@components/icons';
import toast from '@components/toast';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';

import './FullscreenNav.scss';

interface FixedHeaderProps {
    className?: string;
    leftElements?: ReactElement;
    rightElements?: ReactElement;
    title?: string | ReactElement;
}

const FixedHeader: FC<FixedHeaderProps> = ({ className, leftElements, rightElements, title }) => {
    const combinedClassNames = clsx('app-navbar-fixed-header', className);

    return (
        <div className={combinedClassNames}>
            <div className="app-navbar-fixed-header-left">{leftElements}</div>
            <div className="app-navbar-fixed-header-title">{title}</div>
            <div className="app-navbar-fixed-header-right">{rightElements}</div>
        </div>
    );
};

/**
 * If the user is scrolled at all down the page that will navigate to this
 * page, there is a possibility that this page will render scrolled as
 * well if the content is immediately ready. This custom hook delays the
 * initial render, so that this view renders fully scrolled to the top
 */
const useDelayedRender = (dependencies: any[] = []): { contentIsVisible: boolean } => {
    const [contentIsVisible, setContentIsVisible] = useState(false);

    // Initial render delay
    useLayoutEffect(() => {
        requestAnimationFrame(() => {
            setContentIsVisible(true);
        });
    }, []);

    // Separate scroll effect that runs on content changes
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, dependencies);

    return { contentIsVisible };
};
interface FullscreenNavViewSharedProps {
    fixedHeaderProps?: FixedHeaderProps;
    className?: string;
    backLinkText?: string;
    title?: string | ReactElement;
    variant?: 'default' | 'sidebar';
    sidebar?: ReactNode;
    backLinkFallbackUrl?: string;
    children: ReactNode;
    hideBackLink?: boolean;
    validation?: () => { success: boolean; message?: string };
    disableAppNavbar?: boolean;
}

interface FullscreenNavDefaultViewProps extends FullscreenNavViewSharedProps {
    variant?: 'default';
}

interface FullscreenNavSidebarViewProps extends FullscreenNavViewSharedProps {
    variant: 'sidebar';
    sidebar: ReactNode;
}

type FullscreenNavViewProps = FullscreenNavDefaultViewProps | FullscreenNavSidebarViewProps;

const FullscreenNav: FC<FullscreenNavViewProps> = ({
    title,
    className = '',
    variant = 'default',
    backLinkText,
    children,
    sidebar,
    backLinkFallbackUrl,
    fixedHeaderProps,
    validation,
    hideBackLink = false,
    disableAppNavbar = false,
}) => {
    const { history } = useApp();
    useAppBodyClasses(['app-fullscreen-nav', 'app-content-fullWidth', 'app-content-noPadding'], []);

    const classNamesAppended = clsx([
        className,
        'fullscreen-nav',
        `fullscreen-nav-variant-${variant}`,
        !isEmpty(fixedHeaderProps) ? 'has-fixed-header' : '',
    ]);

    const handleBackLinkClick = () => {
        const { success, message } = validation ? validation() : { success: true, message: '' };
        if (!success) {
            toast.error(message || 'Something went wrong');
            return;
        }
        // optionally override the back button behavior if the user has no history
        if (backLinkFallbackUrl && history.action === 'POP') {
            history.push(backLinkFallbackUrl);
        } else {
            history.goBack();
        }
    };

    const defaultContent = (
        <>
            <div className="app-header-fullscreen">
                {fixedHeaderProps && <FixedHeader {...fixedHeaderProps} />}
                {!disableAppNavbar && (
                    <div className="app-navbar">
                        {!hideBackLink && (
                            <div className="app-header-fullscreen_back-link">
                                {backLinkText && (
                                    <Button
                                        className="back-link-text"
                                        startIcon={<CustomArrowLeftIcon />}
                                        onClick={handleBackLinkClick}
                                        variant="flat"
                                        color="neutral"
                                    >
                                        {backLinkText}
                                    </Button>
                                )}
                            </div>
                        )}
                        {title && <div className="app-header-fullscreen_title">{title}</div>}
                    </div>
                )}
            </div>
            <div className={clsx(!disableAppNavbar ? 'app-content-fullscreen' : 'h-full')}>
                {children}
            </div>
        </>
    );

    const sidebarContent = (
        <>
            <div className="fullscreen-sidebar">
                <div className="app-navbar">
                    <Button
                        className="back-link-text"
                        startIcon={<CustomArrowLeftIcon />}
                        onClick={handleBackLinkClick}
                        variant="flat"
                        color="neutral"
                    >
                        {backLinkText}
                    </Button>
                </div>
                {sidebar}
            </div>
            <div className="app-content-fullscreen">{children}</div>
        </>
    );

    return (
        <div className={classNamesAppended}>
            {variant === 'default' && defaultContent}
            {variant === 'sidebar' && sidebarContent}
        </div>
    );
};

export default FullscreenNav;
