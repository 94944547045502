import axios from 'axios';

import {
    CreateLearningResourceResponseBody,
    UpdateLearningResourceRequestBody,
} from '@sparkplug/lib';

import { useAdvancedMutation, useQueryClient } from '@hooks/QueryHooks';

import { coursesCacheKey } from '../queries/useGetAllCourses';

const API = {
    updateLearningResource: async (id: string, payload: UpdateLearningResourceRequestBody) => {
        const sanitizedPayload = Object.fromEntries(
            Object.entries(payload).filter(([_, value]) => value != null),
        );
        return (
            await axios.put<CreateLearningResourceResponseBody>(
                `/api/v1/learning-resource/${id}`,
                sanitizedPayload,
            )
        ).data;
    },
};

export const useUpdateLearningResource = (learningResourceId: string) => {
    const queryClient = useQueryClient();
    return useAdvancedMutation(
        (payload: UpdateLearningResourceRequestBody) =>
            API.updateLearningResource(learningResourceId, payload),
        {
            customOptions: {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['learning-resource'] });
                    queryClient.invalidateQueries({ queryKey: ['survey'] });
                    queryClient.invalidateQueries({ queryKey: [coursesCacheKey] });
                },
            },
            toastOptions: {
                loading: 'Saving Course...',
                success: 'Course Saved!',
                error: 'Failed to save Course.',
            },
        },
    );
};
