import { TableCell } from '@mui/material';
import moment from 'moment';

import { formatCurrency } from '@sparkplug/lib';

import { useCourseCompletionStats } from '@features/courses/hooks/useCourseCompletionStats';
import {
    EmployeeStats,
    LocationStats,
    MarketStats,
    RetailerStats,
    TableConfig,
} from '@features/courses/types';

import { Badge } from '@app/componentLibrary/Badge';
import { Progress } from '@app/componentLibrary/Progress';
import { ITableRow } from '@app/types/TableTypes';

export const formatCompletion = (completion: number): number => Math.round(completion * 100);
export const getCourseCompletionTableConfig = (
    stats: ReturnType<typeof useCourseCompletionStats>,
    TABLE_CELL_CLASSNAME: string,
): TableConfig => ({
    market: {
        data: stats.byMarket as ITableRow<MarketStats>[],
        headCells: [
            {
                id: 'market',
                label: 'Market',
                sortType: 'string',
                render: (row: ITableRow<MarketStats>) => (
                    <TableCell>
                        <button
                            type="button"
                            onClick={row.onClick}
                            className={TABLE_CELL_CLASSNAME}
                        >
                            {row.market}
                        </button>
                    </TableCell>
                ),
            },
            {
                id: 'completion',
                label: 'Completion',
                sortType: 'numeric',
                render: (row: ITableRow<MarketStats>) => (
                    <TableCell>
                        <div className="flex items-center gap-2 font-medium">
                            <div>{formatCompletion(row.completion)}%</div>
                            <div className="flex-1">
                                <Progress
                                    value={row.completion * 100}
                                    color="aqua"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </TableCell>
                ),
            },
            { id: 'retailers', label: 'Retailers', sortType: 'numeric' },
            { id: 'locations', label: 'Locations', sortType: 'numeric' },
            {
                id: 'activeEmployees',
                label: 'Active Employees',
                sortType: 'numeric',
            },
            {
                id: 'lastCompletion',
                label: 'Last Completion',
                sortType: 'date',
            },
        ],
    },
    retailer: {
        data: stats.byRetailer as ITableRow<RetailerStats>[],
        headCells: [
            {
                id: 'retailerName',
                label: 'Retailer',
                sortType: 'string',
                render: (row: ITableRow<RetailerStats>) => (
                    <TableCell>
                        <button
                            type="button"
                            onClick={row.onClick}
                            className={TABLE_CELL_CLASSNAME}
                        >
                            {row.retailerName}
                        </button>
                    </TableCell>
                ),
            },
            {
                id: 'completion',
                label: 'Completion',
                sortType: 'numeric',
                render: (row: ITableRow<RetailerStats>) => (
                    <TableCell>
                        <div className="flex items-center gap-2 font-medium">
                            <div>{formatCompletion(row.completion)}%</div>
                            <div className="flex-1">
                                <Progress
                                    value={row.completion * 100}
                                    color="aqua"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </TableCell>
                ),
            },
            { id: 'locations', label: 'Locations', sortType: 'numeric' },
            { id: 'activeEmployees', label: 'Active Employees', sortType: 'numeric' },
            { id: 'lastCompletion', label: 'Last Completion', sortType: 'date' },
            { id: 'market', label: 'Market', sortType: 'string' },
        ],
    },
    location: {
        data: stats.byLocation as ITableRow<LocationStats>[],
        headCells: [
            {
                id: 'locationName',
                label: 'Retailer Location',
                sortType: 'string',
                render: (row: ITableRow<LocationStats>) => (
                    <TableCell>
                        <button
                            type="button"
                            onClick={row.onClick}
                            className={TABLE_CELL_CLASSNAME}
                        >
                            {row.locationName}
                        </button>
                    </TableCell>
                ),
            },
            {
                id: 'completion',
                label: 'Completion',
                sortType: 'numeric',
                render: (row: ITableRow<LocationStats>) => (
                    <TableCell>
                        <div className="flex items-center gap-2 font-medium">
                            <div>{formatCompletion(row.completion)}%</div>
                            <div className="flex-1">
                                <Progress
                                    value={row.completion * 100}
                                    color="aqua"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </TableCell>
                ),
            },
            { id: 'activeEmployees', label: 'Active Employees', sortType: 'numeric' },
            { id: 'lastCompletion', label: 'Last Completion', sortType: 'date' },
        ],
    },
    employee: {
        data: stats.byEmployee as ITableRow<EmployeeStats>[],
        headCells: [
            {
                id: 'name',
                label: 'Name',
                sortType: 'string',
                render: (row: ITableRow<EmployeeStats>) => (
                    <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                ),
            },
            {
                id: 'retailerName',
                label: 'Retailer Location',
                sortType: 'string',
                render: (row: ITableRow<EmployeeStats>) => (
                    <TableCell>
                        {row.retailerName} {row.locationName ? `- ${row.locationName}` : ''}
                    </TableCell>
                ),
            },
            {
                id: 'amount',
                label: 'Reward',
                sortType: 'string',
                render: (row: ITableRow<EmployeeStats>) => (
                    <TableCell>
                        {row.amount ? (
                            <Badge label={formatCurrency(row.amount)} color="green" size="sm" />
                        ) : (
                            <></>
                        )}
                    </TableCell>
                ),
            },
            {
                id: 'completedAt',
                label: 'Completion Date',
                sortType: 'date',
                render: (row: ITableRow<EmployeeStats>) => (
                    <TableCell>
                        {row.completedAt ? moment(row.completedAt).fromNow() : ''}
                    </TableCell>
                ),
            },
        ],
    },
});
