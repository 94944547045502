/* eslint-disable no-nested-ternary */
import { SparkNotFound } from '@components/graphics';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import Skeleton from '@components/layout/Skeleton';
import { useParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useEmployeeLocationsQuery } from '@hooks/QueryHooks/EmployeeDataQueries';

import { EventDetails } from '../components';
import { useGetEventQuery } from '../queries';

const EmployeeEventsMobile = () => {
    const { history } = useApp();
    const { eventId } = useParams<{ eventId: string }>();
    const { event, eventIsLoading: isLoading } = useGetEventQuery(eventId, { enabled: !!eventId });
    const { employeeLocations, employeeLocationsAreLoading } = useEmployeeLocationsQuery();

    const prevComponent = history?.location?.state?.prevTitle || 'Events';
    const back = {
        to: '/user/sparks?tab=events',
        component: prevComponent,
    };

    return (
        <>
            <AppHeaderMobile hideAppFooter back={back} />
            {isLoading || employeeLocationsAreLoading ? (
                <div className="-mx-4 -mt-4">
                    <Skeleton height={300} />
                </div>
            ) : event !== undefined ? (
                <EventDetails
                    event={event}
                    isFullScreen
                    employeeLocations={employeeLocations}
                    accountType="retailer"
                    captureActiveView
                    isEmployee
                />
            ) : (
                <div className="text-center text-gray-600 text-lg font-semibold mt-12 flex flex-col items-center">
                    <SparkNotFound />
                    <span className="text-nowrap pt-4">Event not found :(</span>
                </div>
            )}
        </>
    );
};

export default EmployeeEventsMobile;
