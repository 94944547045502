import { AccountPosImportStatus, AccountType, Industry } from '@sparkplug/lib';

import { TStandardColors } from '@app/types/UITypes';

const BrandLinkStateLabels = [
    'None',
    'Pending',
    'Disabled',
    'Rejected',
    'Declined',
    'Enabled',
    'Requested',
    'Unmapped',
] as const;
export const BrandLinkStates = [
    'disabled',
    'enabled',
    'none',
    'pending',
    'pending-assignment',
    'rejected',
    'unmapped',
] as const;
type BrandLinkStateLabel = (typeof BrandLinkStateLabels)[number];
export type BrandLinkState = (typeof BrandLinkStates)[number];

export interface BrandLinkStatusOptions {
    label: BrandLinkStateLabel;
    color: TStandardColors;
    value: BrandLinkState;
}

export const BrandLinkStatuses: BrandLinkStatusOptions[] = [
    {
        label: 'Declined',
        color: 'red',
        value: 'rejected',
    },
    {
        label: 'Pending',
        color: 'yellow',
        value: 'pending-assignment',
    },
    { label: 'Disabled', color: 'neutral', value: 'disabled' },
    { label: 'Enabled', color: 'green', value: 'enabled' },
    {
        label: 'Requested',
        color: 'yellow',
        value: 'pending',
    },
    { label: 'None', color: 'neutral', value: 'none' },
    { label: 'Unmapped', color: 'red', value: 'unmapped' },
];

export const AccountTypeOptions: Array<{
    label: string;
    value: AccountType;
}> = [
    { label: 'Brand', value: 'brand' },
    { label: 'Retailer', value: 'retailer' },
];

const IndustryOptionsMap: Record<Industry, string> = {
    cannabis: 'Cannabis',
    'food service': 'Food Service',
    apparel: 'Apparel',
    cosmetics: 'Cosmetics',
    'nutritional supplements': 'Nutritional Supplements',
    'outdoor gear': 'Outdoor Gear',
};

export const IndustryOptions: { label: string; value: string }[] = Object.entries(
    IndustryOptionsMap,
).map(([value, label]) => ({ label, value }));

export const SubscriptionTypeOptions = [
    { label: 'Paid', value: 'paid' },
    { label: 'None', value: 'none' },
];

export const PosStatusOptions: Array<{
    label: string;
    value: AccountPosImportStatus;
}> = [
    { label: 'Pending', value: 'pending' },
    { label: 'Failing', value: 'failing' },
    { label: 'Caution', value: 'caution' },
    { label: 'Working', value: 'working' },
    { label: 'Inactive', value: 'inactive' },
];
