import { ReactElement } from 'react';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import { getDetailedSparkType, getSelectedLocationsFromSparkSubGroups } from '@helpers/sparks';

import { ISparkSubGroup } from '@app/types/SparksTypes';

const sparkTypeTitles: Partial<Record<DetailedSparkType, string>> = {
    leaderboard: 'Single Leaderboard Spark',
    leaderboardMulti: 'Multi-Leaderboard Spark',
    goal: 'Individual Goal Spark',
    goalTeam: 'Team Goal Spark',
    goalManager: 'Manager Goal Spark',
};

const LocationLeaderboardCalloutMessage = ({ locationCount }: { locationCount: number }) => {
    return (
        <>
            {"You're "} creating a <strong>Location Leaderboard Spark</strong> where all{' '}
            <strong>{`${locationCount} locations`}</strong> selected will compete against one
            another. Prizes below will be awarded to each team, based on their final standing at the
            end of the Spark.
        </>
    );
};

const LeaderboardCalloutMessage = ({
    detailedSparkType = 'leaderboard',
    locationCount,
}: {
    detailedSparkType?: DetailedSparkType;
    locationCount: number;
}) => {
    if (detailedSparkType === 'leaderboardLocation') {
        return <LocationLeaderboardCalloutMessage locationCount={locationCount} />;
    }

    const isLeaderboardMulti = detailedSparkType === 'leaderboardMulti';
    const endingText = isLeaderboardMulti
        ? ` ${locationCount > 1 ? 'times' : ''} over to each set of leaderboard winners.`
        : ` to a single set of leaderboard winners.`;
    return (
        <span>
            {"You're creating a "}
            <strong>{sparkTypeTitles[detailedSparkType]}</strong>
            {` which will create a ${isLeaderboardMulti ? 'unique' : 'single'} leaderboard for ${
                locationCount > 1 ? 'each of' : ''
            } the`}
            <strong>{` ${locationCount} location${locationCount > 1 ? 's' : ''} `}</strong>
            {'selected. '}
            <br />
            {'Prizes below will be awarded '}
            <strong>{isLeaderboardMulti && locationCount !== 1 ? locationCount : 'once'}</strong>
            {endingText}
        </span>
    );
};

const GoalCalloutMessage = ({
    detailedSparkType = 'goal',
}: {
    detailedSparkType?: DetailedSparkType;
}) => {
    const isGroupGoal = ['goalTeam', 'goalManager'].includes(detailedSparkType);
    const endingText = isGroupGoal
        ? '. If the selected locations collectively hit the goal(s) below, the following reward method will apply.'
        : '. If an employee hits the goal(s) below, they will be awarded the corresponding reward.';
    return (
        <span>
            {`You're creating ${isGroupGoal ? 'a' : 'an'} `}
            <strong>{sparkTypeTitles[detailedSparkType]}</strong>
            {endingText}
        </span>
    );
};

export const SparkPrizeCalloutMessage = ({
    spark,
    sparkSubGroups,
}: {
    spark: Spark;
    sparkSubGroups: ISparkSubGroup[];
}) => {
    let prizeCalloutMessage: ReactElement | undefined;
    const detailedSparkType = getDetailedSparkType(spark);

    if (spark?.type === 'leaderboard') {
        const locationCount =
            detailedSparkType === 'leaderboardMulti'
                ? getSelectedLocationsFromSparkSubGroups(sparkSubGroups).length
                : (spark?.locationIds || []).length;

        prizeCalloutMessage = (
            <LeaderboardCalloutMessage
                detailedSparkType={detailedSparkType}
                locationCount={locationCount}
            />
        );
    }

    if (spark?.type === 'goal') {
        prizeCalloutMessage = <GoalCalloutMessage detailedSparkType={detailedSparkType} />;
    }

    return (
        <>
            {prizeCalloutMessage && (
                <CalloutMessage
                    className="spark-prize-callout-message max-sm:text-sm max-sm:[&>svg]:h-[14px] max-sm:[&>svg]:w-[14px]"
                    variant="default"
                    color="neutral"
                    message={prizeCalloutMessage}
                />
            )}
        </>
    );
};

export default SparkPrizeCalloutMessage;
