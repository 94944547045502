import { useEffect, useMemo, useState } from 'react';

import moment, { MomentInput } from 'moment';

import {
    FullChartResponseBody,
    GetChartResponseBody,
    TChartDataMetric,
    TComparisonPeriodOption,
} from '@sparkplug/lib';

import {
    calculatePercentIncrease,
    getDefaultPrecisionByDateRange,
    isCumulativeChartMetric,
    transformCloudChartDataToResult,
} from '@helpers/charts';
import { getSparkPosArchive, hydrateSparkPosArchive } from '@helpers/sparks';

import { ICalculatorBucketName } from '@app/types/CalculatorTypes';
import {
    IBarChartData,
    IBarChartPoint,
    IChartDataResult,
    IChartDataSettings,
    ILineChartData,
    ILineDataPoint,
    ITableChartData,
    ITableChartDataRow,
    TChartData,
    TChartType,
} from '@app/types/ChartDataTypes';
import { ObjectMap } from '@app/types/UtilTypes';

import { getChartFactories } from '../../helpers/charts/charts';
import { useAdvancedQuery } from '../QueryHooks';
import { useAccountPosDataQuery, useSparkplugBrandRetailer } from '../SparkplugAccountsHooks';
import { transformCloudChartDataToChartLeaders, useChartStandings } from './useChartStandings';
import { useCloudChartDataQuery } from './useCloudChartDataQuery';

function getUpdatedSettings(
    settings: Partial<IChartDataSettings>,
    newSettings: Partial<IChartDataSettings>,
    adjustPrecision: boolean,
): Partial<IChartDataSettings> {
    const { dateStart, dateEnd } = newSettings;

    const updatedSettings = {
        ...settings,
        ...newSettings,
    };

    if (dateStart && dateEnd && adjustPrecision) {
        updatedSettings.precision = getDefaultPrecisionByDateRange(dateStart, dateEnd);
    }

    return updatedSettings;
}

const useCompletedSparkCloudChartData = (
    sparkId: string | undefined,
    chartSettings: IChartDataSettings,
    isEnabled = false,
) => {
    const { isLoading, data: sparkPosArchiveData } = useAdvancedQuery(
        ['sparkPosArchive', sparkId],
        () => getSparkPosArchive(sparkId),
        {
            enabled: !!sparkId && isEnabled,
        },
    );

    const rawChartData = useMemo<FullChartResponseBody>(() => {
        const { charts, otherCharts = {} } = sparkPosArchiveData ?? {};

        const { sparkPosArchiveKey = '', metric } = chartSettings;

        const chartMetric = metric;

        const chartSettingsMetricRawChartData: FullChartResponseBody | undefined =
            // This checks if there is raw chart data for the "otherChart" metric
            (otherCharts?.[chartMetric] as any)?.[sparkPosArchiveKey];

        if (chartSettingsMetricRawChartData) {
            return chartSettingsMetricRawChartData;
        }

        const sparkMetricRawChartData: FullChartResponseBody | undefined =
            // This gets the chart data for the original spark metric
            (charts as any)?.[sparkPosArchiveKey];

        if (sparkMetricRawChartData) {
            return sparkMetricRawChartData;
        }

        return {} as FullChartResponseBody;
    }, [chartSettings, sparkPosArchiveData]);

    const data = useMemo<IChartDataResult>(() => {
        const { charts, productsWithSalesByKeyArchive } = sparkPosArchiveData ?? {};
        const {
            participants = [],
            nonParticipantsWithSales = [],
            locations = [],
            products = [],
        } = hydrateSparkPosArchive(sparkPosArchiveData);

        const { precision = 'day', type = 'line' } = chartSettings;

        const { bucketFactory, chartFactory } = getChartFactories({
            settings: { precision, type },
        });

        // If single-location is active, use the leaderboard chart data for that location
        const useSingleLocationData =
            Object.keys(rawChartData?.locationTotals ?? {}).length > 1 &&
            chartSettings.locationIds.length === 1;
        if (useSingleLocationData) {
            const employeeLocationChartData =
                charts?.employeeLocations?.[chartSettings.locationIds?.[0]];
            rawChartData.employeeBuckets = employeeLocationChartData?.employeeBuckets ?? {};
            rawChartData.employeeTotals = employeeLocationChartData?.employeeTotals ?? {};
            rawChartData.commissions = employeeLocationChartData?.commissions ?? {};
        }

        const chartDataUsers = [...participants, ...nonParticipantsWithSales];

        const chartDataResult = transformCloudChartDataToResult(
            rawChartData,
            chartSettings,
            bucketFactory,
            chartFactory,
            {
                accountPosLocations: locations,
                accountPosProducts: products,
            },
        );

        const chartDataValue =
            chartSettings.locationIds.length === 1
                ? rawChartData?.locationTotals?.[chartSettings.locationIds?.[0]]
                : chartDataResult.chartDataValue;

        return {
            ...chartDataResult,
            chartDataValue,
            ...transformCloudChartDataToChartLeaders({
                settings: chartSettings,
                cloudChartData: rawChartData,
                posData: {
                    accountUsers: chartDataUsers,
                    accountPosLocations: locations,
                },
            }),
            productsWithSalesByKeyArchive,
        };
    }, [sparkPosArchiveData, rawChartData, chartSettings]);

    return {
        isLoading,
        data,
    };
};

const useCloudChartData = ({
    accountId,
    settings,
    isEnabled,
    includeChartDisplayData = true,
}: {
    accountId: string;
    settings: IChartDataSettings;
    isEnabled: boolean;
    includeChartDisplayData?: boolean;
}) => {
    const { isLoading, data: rawData = {} as GetChartResponseBody } = useCloudChartDataQuery(
        accountId,
        settings,
        isEnabled,
    );

    const { bucketFactory, chartFactory } = getChartFactories({ settings });

    const { accountPosLocationsDataIsReady, accountAllPosLocations } = useAccountPosDataQuery(
        accountId,
        {
            includedDatasets: ['locations'],
        },
    );

    const { brandRetailer } = useSparkplugBrandRetailer();

    const { products: brandRetailerProducts = [] } = brandRetailer ?? {};

    const { chartStandingsAreReady, chartDataLeaders } = useChartStandings({
        retailerAccountId: accountId,
        settings,
        isEnabled,
        includeChartDisplayData,
    });

    const data = useMemo<IChartDataResult>(
        () => ({
            ...transformCloudChartDataToResult(rawData, settings, bucketFactory, chartFactory, {
                accountPosLocations: accountAllPosLocations,
                accountPosProducts: brandRetailerProducts,
            }),
            chartDataLeaders,
        }),
        [rawData, settings, accountAllPosLocations, brandRetailerProducts, chartDataLeaders],
    );

    return {
        isLoading: isLoading || !accountPosLocationsDataIsReady || !chartStandingsAreReady,
        data,
    };
};

const defaultChartSettings: IChartDataSettings = {
    locationIds: [],
    dateStart: new Date(),
    dateEnd: new Date(),
    type: 'bar',
    precision: 'day',
    metric: 'total_units',
    breakdown: 'none',
};

/**
 * `useChartSettings` manages the chart state
 */
export const useChartSettings = (initialSettings: Partial<IChartDataSettings>) => {
    const [updatedSettings, setUpdatedSettings] = useState<Partial<IChartDataSettings>>({});

    const settings = useMemo(() => {
        return {
            ...defaultChartSettings,
            ...initialSettings,
            ...updatedSettings,
        };
    }, [updatedSettings, initialSettings, updatedSettings]);

    const updateSettings = (
        newSettings: Partial<IChartDataSettings>,
        adjustPrecision: boolean = true,
    ) => {
        setUpdatedSettings((prevSettings) =>
            getUpdatedSettings(prevSettings, newSettings, adjustPrecision),
        );
    };

    const onUpdateSetting = (key: keyof IChartDataSettings) => {
        return (value: any) => {
            updateSettings({
                [key]: value,
            });
        };
    };

    return {
        updateChartSettings: updateSettings,
        onUpdateChartSetting: onUpdateSetting,
        chartSettings: settings,
    };
};

/**
 * `useChartCalculator` calulates base on the incoming `settings`. Particularly useful
 * for a chart whose settings aren't changed as dynamically, such as a spark chart
 */
const useChartCalculator = ({
    settings,
    isEnabled,
    accountId,
    includeChartDisplayData = true,
}: {
    settings: IChartDataSettings;
    isEnabled: boolean;
    accountId: string;
    includeChartDisplayData?: boolean;
}) => {
    const isArchivedSpark = !!(settings.sparkId && settings.sparkPosArchiveKey);
    const { isLoading: isLoadingCloudChartData, data: cloudChartData } = useCloudChartData({
        accountId,
        settings,
        isEnabled: isEnabled && !isArchivedSpark,
        includeChartDisplayData,
    });

    const { isLoading: isLoadingCompletedSparkCloudChartData, data: completedSparkCloudChartData } =
        useCompletedSparkCloudChartData(settings?.sparkId, settings, isEnabled && isArchivedSpark);

    const isLoading = isArchivedSpark
        ? isLoadingCompletedSparkCloudChartData
        : isLoadingCloudChartData;

    const data = useMemo<IChartDataResult>(
        () => (isArchivedSpark ? completedSparkCloudChartData : cloudChartData),
        [isArchivedSpark, cloudChartData, completedSparkCloudChartData],
    );

    const chartHasTransactions = !!(data?.chartDataDatePercentage > 0);

    return {
        isCalculatingChartData: isLoading,
        chartHasTransactions,
        ...data,
    };
};

export const useChartData = ({
    initialSettings = {},
    isEnabled = true,
    accountId,
    includeChartDisplayData = true,
}: {
    initialSettings: Partial<IChartDataSettings>;
    isEnabled: boolean;
    accountId: string;
    includeChartDisplayData?: boolean;
}) => {
    const settingsData = useChartSettings(initialSettings);

    const calculatedData = useChartCalculator({
        settings: settingsData.chartSettings,
        isEnabled: !!(isEnabled && accountId),
        accountId,
        includeChartDisplayData,
    });

    return {
        ...settingsData,
        ...calculatedData,
    };
};

const _combineLineChartData = (
    currentChartData: ILineChartData[],
    previousChartData: ILineChartData[],
    chartBuckets: ICalculatorBucketName[],
): ILineChartData[] => {
    const previousDataPoints = previousChartData?.[0]?.data || [];

    const newDataPoints = previousDataPoints.map((dataPoint, i) => {
        const bucketName = chartBuckets[i]?.nameFormatted;
        const bucketDate = chartBuckets[i]?.name;

        return {
            ...dataPoint,
            x: bucketName,
            meta: {
                ...(dataPoint?.meta || {}),
                isComparisonDate: true,
                currentDate: bucketDate,
            },
        } as ILineDataPoint;
    });

    const remappedPreviousChartData: ILineChartData[] = [
        {
            id: 'Previous Period',
            data: newDataPoints,
        },
    ];

    return [...currentChartData, ...remappedPreviousChartData];
};

const _combineBarChartData = (
    currentChartData: IBarChartData,
    previousChartData: IBarChartData,
    chartBuckets: ICalculatorBucketName[],
): IBarChartData => {
    const currentValueKey = currentChartData?.keys?.[0];
    const previousValueKey = 'Previous Period';

    if (currentChartData?.keys?.[0] !== '*' || previousChartData?.keys?.[0] !== '*') {
        throw new Error('`chartData.keys` and `comparisonData.keys` must equal `["*"]`');
    }

    if (!previousChartData?.data?.length) {
        // no historical data for this period - return current and parent component will handle message
        return {
            keys: currentChartData.keys,
            data: currentChartData.data,
        };
    }

    const keys = [...(currentChartData?.keys || []), previousValueKey];
    const data = (previousChartData?.data || []).map((dataPoint, i) => {
        const currentDataPoint: IBarChartPoint | undefined = currentChartData?.data?.[i];
        const bucketName = chartBuckets[i]?.nameFormatted;
        const bucketDate = chartBuckets[i]?.name;

        return {
            [currentValueKey]: 0,
            ...(currentDataPoint || {}),
            id: bucketName,
            [previousValueKey]: dataPoint?.['*'],
            meta: {
                ...(currentDataPoint?.meta || {}),
                ...(dataPoint?.meta || {}),
                tooltipTitle: '',
                date: bucketDate,
                currentDateKey: currentValueKey,
                comparisonDateKey: previousValueKey,
                comparisonDate: dataPoint?.meta?.date,
                comparisonLabel: currentDataPoint?.meta?.comparisonLabel,
                comparisonLabelPrevious: dataPoint?.meta?.comparisonLabel,
            },
        };
    });

    return {
        keys,
        data,
    } as IBarChartData;
};

const _combineTableChartData = (
    currentChartData: ITableChartData,
    previousChartData: ITableChartData,
): ITableChartData => {
    const labelMap: ObjectMap<Partial<ITableChartDataRow>> = {};

    const currentValueMap: any = currentChartData.rows.reduce((res, { key, value, ...labels }) => {
        if (Object.keys(labels).length) {
            labelMap[key] = labels;
        }

        return {
            ...res,
            [key]: value,
        };
    }, {});

    const previousValueMap: any = previousChartData.rows.reduce(
        (res, { key, value, ...labels }) => {
            if (Object.keys(labels).length) {
                labelMap[key] = labels;
            }

            return {
                ...res,
                [key]: value,
            };
        },
        {},
    );

    const keys = [...new Set([...Object.keys(currentValueMap), ...Object.keys(previousValueMap)])];

    const rows = keys.reduce((res, key) => {
        const value = currentValueMap?.[key] ?? 0;
        const previousValue = previousValueMap?.[key] ?? 0;
        const [percentDiff] = calculatePercentIncrease(value, previousValue);
        const labels = labelMap?.[key] ?? {};

        res.push({
            key,
            value,
            previousValue,
            comparisonValue: Number.isNaN(percentDiff)
                ? Number.MIN_SAFE_INTEGER
                : (percentDiff as number),
            ...labels,
        });

        return res;
    }, [] as ITableChartDataRow[]);

    return {
        total: currentChartData.total,
        showComparisonWindow: true,
        keys: [...currentChartData.keys, 'previousValue', 'comparisonValue'],
        rows,
    };
};

export const combinePreviousChartDataWithCurrentChartData = {
    line: _combineLineChartData,
    bar: _combineBarChartData,
    table: _combineTableChartData,
};

export const generateCombinedComparisonWindowData = (
    currentChartData: TChartData,
    previousChartData: TChartData,
    chartBuckets: ICalculatorBucketName[] = [],
    chartType: TChartType,
    isEnabled: boolean,
) => {
    try {
        if (!isEnabled) {
            return currentChartData;
        }

        // TODO: Make generic so we don't have to infer `any`
        return combinePreviousChartDataWithCurrentChartData[chartType](
            currentChartData as any,
            previousChartData as any,
            chartBuckets,
        );
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }

    return currentChartData;
};

const generatePreviousValueFromCombinedComparisonData = ({
    previousChartData,
    metric,
    type,
    currentStartDate,
}: {
    previousChartData: any;
    metric: TChartDataMetric;
    type: TChartType;
    currentStartDate: string;
}): number => {
    let previousValue = 0;

    try {
        if (type === 'table') {
            return previousChartData?.total ?? 0;
        }

        const data = type === 'line' ? previousChartData?.[0]?.data : previousChartData?.data;

        if (data == null || data?.length === 0) {
            return previousValue;
        }

        /**
         * Calculate number of days based on number of days from today or
         * the number of chart data points
         *
         * NOTE: Comparison windows only compare against completed days, so
         * this function subtracts 1 day from `numberOfDays`
         */
        const numberOfDays = moment().diff(currentStartDate, 'days') - 1; // See NOTE
        const currentPosition = Math.min(numberOfDays, data.length - 1);

        if (type === 'line') {
            previousValue = data?.[currentPosition]?.y;
        } else {
            if (!isCumulativeChartMetric(metric)) {
                previousValue = data?.[currentPosition]?.['*'];
            } else {
                const subArr = [...(data || [])];
                subArr.length = currentPosition + 1;

                previousValue = subArr.reduce((result, current) => {
                    return result + (current?.['*'] || 0);
                }, 0);
            }
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }

    return previousValue;
};

export function calculateComparisonPeriod(
    dateStart: MomentInput,
    dateEnd: MomentInput,
    comparisonPeriod: TComparisonPeriodOption,
) {
    let previousDateStart: moment.Moment = moment();
    let previousDateEnd: moment.Moment = moment();

    const numberOfDays = moment(dateEnd).diff(dateStart, 'days');
    // if the spark duration is < 7 days, we need a slight tweak for previousPeriodMatchDay
    const matchDayOffset = numberOfDays + 1 >= 7 ? numberOfDays + 1 : 7;
    const startDateDayOfWeek = moment(dateStart).day();

    switch (comparisonPeriod) {
        case 'previousPeriodMatchDay':
            previousDateStart = moment(dateStart)
                .subtract(matchDayOffset, 'days')
                .day(startDateDayOfWeek);
            previousDateEnd = moment(previousDateStart.toISOString()).add(numberOfDays, 'days');

            break;
        case 'previousYearMatchDay':
            previousDateStart = moment(dateStart).subtract(1, 'year').day(startDateDayOfWeek);
            previousDateEnd = moment(previousDateStart.toISOString()).add(numberOfDays, 'days');

            break;
        case 'previousPeriod':
            previousDateStart = moment(dateStart).subtract(numberOfDays + 1, 'days');
            previousDateEnd = moment(dateEnd).subtract(numberOfDays + 1, 'days');

            break;
        case 'previousYear':
            previousDateStart = moment(dateStart).subtract(1, 'year');
            previousDateEnd = moment(dateEnd).subtract(1, 'year');

            break;
        default:
            break;
    }

    return {
        previousDateStart: previousDateStart.format('YYYY-MM-DD'),
        previousDateEnd: previousDateEnd.format('YYYY-MM-DD'),
    };
}

/**
 * Takes calculator data from a higher-order `useChartData` and
 * Adds implements a `useEffect` to calculate related historical data
 * based on the comparison period.
 *
 * Note: The higher order `useChartData` MUST have
 * calculator setting `calculateForAllLocations: true`
 */
export function useComparisonWindow(
    comparisonPeriod: TComparisonPeriodOption = 'previousPeriod',
    settings: IChartDataSettings,
    calculatorData: IChartDataResult,
    currentChartIsCalculating: boolean,
    isEnabled: boolean,
    accountId: string,
) {
    // chartDataValue can sometimes be undefined, so we need to default to 0 so that the chart doesn't break
    const { chartDataAllLocations, chartDataBuckets, chartDataValue = 0 } = calculatorData;

    const [didInitializeComparisonChartData, setDidInitializeComparisonChartData] = useState(false);

    const { previousDateStart, previousDateEnd } = useMemo(() => {
        return calculateComparisonPeriod(settings.dateStart, settings.dateEnd, comparisonPeriod);
    }, [settings.dateStart, settings.dateEnd, comparisonPeriod]);

    const {
        isCalculatingChartData,
        chartDataAllLocations: comparisonChartData,
        ...comparisonCalculatorData
    } = useChartCalculator({
        settings: {
            ...settings,
            dateStart: previousDateStart,
            dateEnd: previousDateEnd,
            sparkPosArchiveKey: settings.sparkPosArchiveKey ? comparisonPeriod : undefined,
        },
        isEnabled,
        accountId,
    });

    useEffect(() => {
        if (!currentChartIsCalculating && !didInitializeComparisonChartData) {
            setDidInitializeComparisonChartData(true);
        }
    }, [currentChartIsCalculating, didInitializeComparisonChartData]);

    const hasNoHistoricalSalesData = useMemo(() => {
        return isEnabled && !isCalculatingChartData && !currentChartIsCalculating
            ? comparisonCalculatorData.chartDataDatePercentage < 0.75
            : false;
    }, [isEnabled, isCalculatingChartData, comparisonCalculatorData.chartDataDatePercentage]);

    const comparisonValues = useMemo(() => {
        const currentValue = chartDataValue;

        const comparisonValue = generatePreviousValueFromCombinedComparisonData({
            previousChartData: chartDataAllLocations,
            metric: settings.metric,
            type: settings.type,
            currentStartDate: settings.dateStart,
        });

        const previousValue = isEnabled
            ? generatePreviousValueFromCombinedComparisonData({
                  previousChartData: comparisonChartData,
                  metric: settings.metric,
                  type: settings.type,
                  currentStartDate: settings.dateStart,
              })
            : -1;

        return {
            currentValue,
            comparisonValue,
            previousValue,
        };
    }, [
        isEnabled,
        chartDataValue,
        chartDataAllLocations,
        settings.type,
        settings.dateStart,
        comparisonChartData,
    ]);

    const combinedChartData = useMemo(() => {
        return !hasNoHistoricalSalesData
            ? generateCombinedComparisonWindowData(
                  chartDataAllLocations,
                  comparisonChartData,
                  chartDataBuckets,
                  settings.type,
                  isEnabled,
              )
            : chartDataAllLocations;
    }, [hasNoHistoricalSalesData, comparisonChartData, chartDataBuckets, isEnabled, settings.type]);

    const isCalculatingCombinedChartData = useMemo(() => {
        return isEnabled && (isCalculatingChartData || currentChartIsCalculating);
    }, [isEnabled, isCalculatingChartData, currentChartIsCalculating]);

    const value = useMemo(() => {
        return {
            isCalculatingCombinedChartData,
            didInitializeComparisonChartData,
            hasNoHistoricalSalesData,
            combinedChartData,
            comparisonValues,
            comparisonDateStart: previousDateStart,
            comparisonDateEnd: previousDateEnd,
        };
    }, [
        isCalculatingCombinedChartData,
        didInitializeComparisonChartData,
        hasNoHistoricalSalesData,
        combinedChartData,
        comparisonValues,
        previousDateStart,
        previousDateEnd,
    ]);

    return value;
}

export interface InitialComparisonWindowState {
    showComparisonWindows?: boolean;
    comparisonPeriod?: TComparisonPeriodOption;
}
export const useComparisonWindowState = ({
    initialState,
    precision,
}: {
    initialState?: InitialComparisonWindowState;
    precision?: string;
}) => {
    const [showComparisonWindows, setShowComparisonWindows] = useState(
        initialState?.showComparisonWindows ?? false,
    );
    const [comparisonPeriod, setComparisonPeriod] = useState<TComparisonPeriodOption>(
        initialState?.comparisonPeriod ?? 'previousPeriod',
    );

    useEffect(() => {
        if (!initialState?.comparisonPeriod && precision?.includes('week')) {
            setComparisonPeriod('previousPeriodMatchDay');
        }
    }, [initialState, precision]);

    return {
        showComparisonWindows,
        setShowComparisonWindows: (updatedValue: boolean) => setShowComparisonWindows(updatedValue),
        comparisonPeriod,
        setComparisonPeriod,
    };
};
