import { FC, useEffect, useMemo, useState } from 'react';

import { orderBy } from 'lodash';

import {
    EmployeeSpark,
    SnapEngagementEventProperties,
    SparkDisplayStatus,
    checkForTrainingComplete,
} from '@sparkplug/lib';

import { useUserSnapEventsQuery } from '@features/spark-snaps';
import { useSnapViewQueue } from '@features/spark-snaps/hooks';

import Grid from '@components/layout/Grid';
import LazyLoad from '@components/layout/LazyLoad';
import { forceCheck } from '@components/layout/LazyLoad/LazyLoad';

import { useApp } from '@hooks/AppHooks';

import SparkCard from '../SparkCard';

import './SparksListGrid.scss';

/**
 * @description In the employee sparks view, filter out completed sparks with incomplete required training
 */
export const useSparksFilterer = ({
    sparks = [],
    isCompleteView,
    isEmployeeView,
}: {
    sparks: EmployeeSpark[];
    isCompleteView: boolean;
    isEmployeeView: boolean;
}) => {
    const { user } = useApp();

    return useMemo(() => {
        if (isEmployeeView && isCompleteView) {
            return sparks.filter(({ courseData, trainingEnabled }) => {
                const trainingNotRequired = !trainingEnabled || !courseData?.required;
                const trainingIsComplete = checkForTrainingComplete({
                    userId: user?._id,
                    courseData,
                });
                return trainingNotRequired || trainingIsComplete;
            });
        }

        return sparks;
    }, [sparks, isEmployeeView, isCompleteView, user]);
};

interface GetStatusBasedSortInfoReturn {
    iteratee: 'startDate' | 'endDate';
    order: 'desc' | 'asc';
}
const getStatusBasedSortInfo = (sparkStatus?: SparkDisplayStatus): GetStatusBasedSortInfoReturn => {
    switch (sparkStatus) {
        case 'upcoming':
        case 'inbox/sent':
            return { iteratee: 'startDate', order: 'asc' };
        case 'complete':
            return { iteratee: 'endDate', order: 'desc' };
        case 'active':
        default:
            return { iteratee: 'endDate', order: 'asc' };
    }
};
/**
 * @description Sparks that have required training that need to be completed should
 * appear at the top of the list followed by ascending sorting based on sparks remaining days.
 */
export const sortSparks = ({
    sparks = [],
    sparkStatus,
    isEmployeeView,
    userId,
    userSnapEvents,
}: {
    sparks: EmployeeSpark[];
    sparkStatus?: SparkDisplayStatus;
    isEmployeeView: boolean;
    userId?: string;
    userSnapEvents?: SnapEngagementEventProperties[];
}) => {
    const sortByEmployeeMustCompleteRequiredTraining = ({
        courseData,
        trainingEnabled,
    }: EmployeeSpark) => {
        if (isEmployeeView) {
            const trainingRequired = !!trainingEnabled && !!courseData?.required;
            const trainingIsComplete = checkForTrainingComplete({
                userId,
                courseData,
            });
            return trainingRequired && !trainingIsComplete;
        }
        return true;
    };

    const sortByUnseenSnaps = ({ snaps }: EmployeeSpark) => {
        if (snaps?.snapIds?.length && userSnapEvents) {
            const userSnapEventIds = userSnapEvents.map((event) => event.snapId);

            const unseenSnaps = snaps.snapIds.some((snapId) => !userSnapEventIds.includes(snapId));
            return !unseenSnaps;
        }
        return true; // Neutral value when there are no snapIds
    };

    const { iteratee, order } = getStatusBasedSortInfo(sparkStatus);
    return orderBy(
        sparks,
        [
            sortByEmployeeMustCompleteRequiredTraining,
            ...(sparkStatus !== 'complete' ? [sortByUnseenSnaps] : []),
            iteratee,
        ],
        ['desc', order],
    );
};

const { REACT_APP_EMPLOYEE_LIVE_STATS = false } = import.meta.env;

interface SparkListGridProps {
    sparks: EmployeeSpark[];
    sparkStatus?: SparkDisplayStatus;
    showTrainingIcon?: boolean;
    isEmployeeView?: boolean;
    isUserSparksView?: boolean;
    onSparkCardSelected: (spark: Pick<EmployeeSpark, '_id' | 'requestState'>) => void;
}
const SparksListGrid: FC<SparkListGridProps> = ({
    sparks,
    sparkStatus,
    isUserSparksView = false,
    showTrainingIcon = false,
    isEmployeeView = false,
    onSparkCardSelected,
}) => {
    const [initialRenderWithSnaps, setInitialRenderWithSnaps] = useState(false);
    const displayedSparks = useSparksFilterer({
        sparks,
        isCompleteView: sparkStatus === 'complete',
        isEmployeeView,
    });
    const { userSnapEventsAreReady, userSnapEvents } = useUserSnapEventsQuery({
        eventType: 'story_complete',
    });
    const { user } = useApp();

    useEffect(() => {
        if (userSnapEventsAreReady && !initialRenderWithSnaps) {
            setInitialRenderWithSnaps(userSnapEventsAreReady);
        }
    }, [userSnapEventsAreReady, sparkStatus]);

    const sortedDisplayedSparks = useMemo(() => {
        return sortSparks({
            sparks: displayedSparks,
            sparkStatus,
            isEmployeeView,
            userId: user?._id,
            userSnapEvents,
        });
    }, [displayedSparks, sparkStatus, user?._id, initialRenderWithSnaps]);

    const { handleViewSnap, SnapViewer } = useSnapViewQueue();

    // forceCheck is used to combat some lazy-load weirdness where you have to physically touch the screen for the components to update,
    // we should look into finding a better lazy load library
    useEffect(() => {
        forceCheck();
    }, [sortedDisplayedSparks]);

    // forceCheck();

    return (
        <>
            <Grid className="app-sparks-list-grid px-[2px]">
                {sortedDisplayedSparks.map((spark, index) => (
                    <Grid.Item
                        key={`spark-list-grid-item-${spark._id}`}
                        className="spark-list-grid-item"
                    >
                        <LazyLoad
                            className="lazy-load-wrapper"
                            height={200}
                            offset={1000}
                            key={spark._id}
                        >
                            <SparkCard
                                index={index}
                                showStats={REACT_APP_EMPLOYEE_LIVE_STATS === 'true'}
                                showTrainingRequired
                                isUserSparksView={isUserSparksView}
                                spark={spark}
                                showTrainingIcon={showTrainingIcon}
                                onSparkCardSelected={onSparkCardSelected}
                                handleViewSnap={handleViewSnap}
                                enableSnapsLauncher
                            />
                        </LazyLoad>
                    </Grid.Item>
                ))}
            </Grid>
            <SnapViewer />
        </>
    );
};

export default SparksListGrid;
