import { ElementType, HTMLAttributes, ReactNode, forwardRef } from 'react';

import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

export interface InputLabelProps extends HTMLAttributes<HTMLLabelElement> {
    children: ReactNode;
    className?: string;
    error?: boolean;
    as?: ElementType;
}

// TODO: Revamp this, likely with a shadcn component
export const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(
    ({ children, className, error, as = 'span', ...labelProps }, ref) => {
        return (
            <Typography
                variant="base"
                className={cn(error ? 'text-sienna-500' : 'text-gray-900', className)}
                ref={ref}
                as={as}
                {...labelProps}
            >
                {children}
            </Typography>
        );
    },
);

InputLabel.displayName = 'InputLabel';
