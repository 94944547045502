import { FC } from 'react';

import Button from '@components/buttons/Button';
import { ArrowRight as ArrowRightIcon } from '@components/icons';
import Toolbar from '@components/toolbar/Toolbar';

import './ProductsMatched.scss';

interface ProductsMatchedProps {
    onChangeSearchFilter: (event: any) => void;
    matchingProducts: {}[];
    total: number;
    onViewNonQualifying?: () => void;
}

const ProductsMatched: FC<ProductsMatchedProps> = ({
    onChangeSearchFilter,
    matchingProducts,
    total,
    onViewNonQualifying,
}) => {
    const showNonQualifyingBtn = matchingProducts.length !== total && onViewNonQualifying;

    return (
        <div className="products-selected max-sm:flex-col max-sm:text-sm">
            <div className="products-selected-left max-sm:flex-col">
                <div>
                    <strong>{`${matchingProducts.length ?? 0} products match `}</strong>
                    <span>{`of ${total} total`}</span>
                </div>
                {showNonQualifyingBtn && (
                    <Button onClick={onViewNonQualifying} endIcon={<ArrowRightIcon />}>
                        View non-qualifying
                    </Button>
                )}
            </div>
            <Toolbar>
                <Toolbar.Search
                    name="brand-spark-product-selector_search"
                    onChange={onChangeSearchFilter}
                />
            </Toolbar>
        </div>
    );
};

export default ProductsMatched;
