import axios from 'axios';

import { useAdvancedMutation } from '@hooks/QueryHooks';

const API = {
    async captureActiveEventView(eventId: string) {
        return (
            await axios.post<null>(
                `/api/v1/employee-data/events/${eventId}/engagements/active-view`,
            )
        ).status;
    },
    async capturePassiveEventView(eventId: string) {
        return (
            await axios.post<null>(
                `/api/v1/employee-data/events/${eventId}/engagements/passive-view`,
            )
        ).status;
    },
};

export const useCaptureActiveEventView = () => {
    const { mutateAsync, isLoading } = useAdvancedMutation((eventId: string) =>
        API.captureActiveEventView(eventId),
    );

    return {
        isCapturingActiveView: isLoading,
        captureActiveViewAsync: mutateAsync,
    };
};

export const useCapturePassiveEventView = () => {
    const { mutateAsync, isLoading } = useAdvancedMutation((eventId: string) =>
        API.capturePassiveEventView(eventId),
    );

    return {
        isCapturingPassiveView: isLoading,
        capturePassiveViewAsync: mutateAsync,
    };
};
