import { useMemo } from 'react';

import { TChartDataMetric } from '@sparkplug/lib';

import PercentDifferenceChip from '@components/chips/PercentDifferenceChip';
import { Dot as DotIcon } from '@components/icons';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useSearch } from '@hooks/UIHooks';

import { currencyFormatterFactory, numberFormatterFactory } from '@helpers/chartFormatters';
import { getChartMetricLabel } from '@helpers/charts';
import { capitalizeFirstLetter } from '@helpers/ui';

import {
    ITableChartData,
    ITableChartDataRow,
    TChartDataBreakdown,
} from '@app/types/ChartDataTypes';
import { THeadCell } from '@app/types/TableTypes';

import { ComparisonColors } from '../nivo/Nivo.Constants';

import './TableChart.scss';

interface ITableChartProps {
    breakdown: TChartDataBreakdown;
    data: ITableChartData;
    locationCount: number;
    metric: TChartDataMetric;
}

const getAllHeadCells = (
    metric: TChartDataMetric,
    metricLabel: string,
): THeadCell<ITableChartDataRow>[] => {
    const formatter = (() => {
        switch (metric) {
            case 'total_sales':
                return currencyFormatterFactory(2);
            case 'total_units':
            default:
                return numberFormatterFactory(0);
        }
    })();

    return [
        {
            id: 'value',
            sortType: 'numeric',
            label: metricLabel,
            render: (row) => <Table.Cell>{formatter(row.value)}</Table.Cell>,
        },
        {
            id: 'previousValue',
            sortType: 'numeric',
            label: metricLabel,
            render: (row) => <Table.Cell>{formatter(row.previousValue!)}</Table.Cell>,
        },
        {
            id: 'comparisonValue',
            sortType: 'numeric',
            label: 'Comparison',
            render: (row) => (
                <Table.Cell>
                    <PercentDifferenceChip
                        currentValue={row.value}
                        prevValue={row.previousValue!}
                    />
                </Table.Cell>
            ),
        },
        {
            id: 'label',
            sortType: 'string',
            label: 'Label',
            render: (row) => <Table.Cell>{row.label}</Table.Cell>,
        },
        {
            id: 'category',
            sortType: 'string',
            label: 'Category',
            render: (row) => <Table.Cell>{row.category}</Table.Cell>,
        },
        {
            id: 'brand',
            sortType: 'string',
            label: 'Brand',
            render: (row) => <Table.Cell>{row.brand}</Table.Cell>,
        },
    ];
};

const TableChart = ({ breakdown = 'none', data, locationCount = 0, metric }: ITableChartProps) => {
    const { showComparisonWindow = false, keys, rows } = data;
    const { applySearch, searchFilter, onChangeSearchFilter } = useSearch(['label']);

    const metricLabel = getChartMetricLabel(metric);

    const headCells = useMemo(() => {
        const allHeadCells = getAllHeadCells(metric, metricLabel);

        const filteredHeadCells = allHeadCells.filter(({ id }) => keys.includes(id));

        if (showComparisonWindow) {
            const currentValueIndex = filteredHeadCells.findIndex(({ id }) => id === 'value');
            const previousValueIndex = filteredHeadCells.findIndex(
                ({ id }) => id === 'previousValue',
            );

            if (currentValueIndex > -1) {
                filteredHeadCells[currentValueIndex].label = () => (
                    <>
                        <DotIcon style={{ color: ComparisonColors[0] }} />
                        {metricLabel}
                    </>
                );
            }
            if (previousValueIndex > -1) {
                filteredHeadCells[previousValueIndex].label = () => (
                    <>
                        <DotIcon style={{ color: ComparisonColors[1] }} />
                        {metricLabel}
                    </>
                );
            }
        }

        return filteredHeadCells;
    }, [metricLabel, keys, metric, showComparisonWindow]);

    const hasLabel = keys.includes('label');

    if (showComparisonWindow) {
        const currentValueIndex = headCells.findIndex(({ id }) => id === 'value');
        const previousValueIndex = headCells.findIndex(({ id }) => id === 'previousValue');

        if (currentValueIndex > -1) {
            headCells[currentValueIndex].label = () => (
                <>
                    <DotIcon style={{ color: ComparisonColors[0] }} />
                    {metricLabel}
                </>
            );
        }
        if (previousValueIndex > -1) {
            headCells[previousValueIndex].label = () => (
                <>
                    <DotIcon style={{ color: ComparisonColors[1] }} />
                    {metricLabel}
                </>
            );
        }
    }

    if (hasLabel) {
        const labelIndex = headCells.findIndex(({ id }) => id === 'label');

        if (labelIndex > -1) {
            headCells[labelIndex].label = capitalizeFirstLetter(breakdown);
        }
    }

    if (headCells.length === 1 && headCells[0].id === 'value') {
        headCells.push({
            id: 'description',
            render: () => (
                <Table.Cell>
                    {metricLabel} of All {locationCount} Locations
                </Table.Cell>
            ),
        });
    }

    const rowRenderKeyFn = (row: any) => JSON.stringify(row);

    return (
        <>
            <Toolbar>
                <Toolbar.Search
                    className="toolbar-group-start"
                    name="search"
                    defaultValue={searchFilter}
                    onChange={onChangeSearchFilter}
                />
            </Toolbar>
            <Table
                variant="raised"
                rows={rows}
                headCells={headCells}
                filters={[applySearch]}
                defaultOptions={{
                    rowsPerPage: 20,
                    orderBy: 'value',
                    order: 'desc',
                }}
            >
                <Table.RenderHead />
                <Table.RenderBody rowRenderKeyFn={rowRenderKeyFn} />
            </Table>
        </>
    );
};

export default TableChart;
