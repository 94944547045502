import { Suspense, lazy, useMemo } from 'react';

import moment from 'moment-timezone';

import Form from '@components/form/Form';
import { Notifications } from '@components/icons';
import DayOfWeekPicker from '@components/inputs/DayOfWeekPicker';
import TimePicker from '@components/inputs/TimePicker';
import CalloutMessage from '@components/layout/CalloutMessage';
import Spinner from '@components/layout/Spinner';

import { useApp } from '@hooks/AppHooks';

import Typography from '@app/componentLibrary/Typography';

const SamplePhoneMessages = lazy(() => import('./SamplePhoneMessages'));
export interface ISMSSettings {
    isUpdateEnabled: boolean;
    isAnnounceEnabled: boolean;
    daysOfWeek: number[];
    timeOfDay: string;
    timeZone: string;
}

interface IProps {
    settings: ISMSSettings;
    onChange: (settings: Partial<ISMSSettings>) => void;
}

const NotificationsContainer = ({ settings, onChange }: IProps) => {
    const { user } = useApp();

    const userName = user ? `${user.firstName} ${user.lastName}` : '[[logged_in user]]';

    const {
        isUpdateEnabled = false,
        isAnnounceEnabled = true,
        daysOfWeek = [0, 1, 2, 3, 4, 5, 6],
        timeOfDay = '12:00:00',
        timeZone = moment.tz.guess(true),
    } = settings;

    const timezoneNames = useMemo(() => {
        return moment.tz.names().map((tzName) => {
            return {
                label: tzName,
                value: tzName,
            };
        });
    }, []);

    return (
        <div className="flex flex-row gap-4 p-[2px]">
            <div className="notifications-container flex flex-col gap-4">
                <div>
                    <CalloutMessage message="Enrolled employees will receive SMS notifications based on the following settings. They can reply “Pause” to opt out of updates." />
                </div>
                <div>
                    <Typography variant="lg" className="font-bold">
                        Spark Updates
                    </Typography>
                    <Form.Description>
                        <p className="!m-0">
                            Text message notifications are sent to participating employees updating
                            them on their rankings and earnings for ongoing Sparks.
                        </p>
                    </Form.Description>
                </div>
                <div>
                    <Form.Group className="form-control">
                        <Form.Label required>Days of Week</Form.Label>
                        <DayOfWeekPicker
                            value={daysOfWeek}
                            onChange={(value) => {
                                onChange({ daysOfWeek: value });
                            }}
                        />
                    </Form.Group>
                    <Form.InputHelperText>
                        <p className="flex items-center text-sm">
                            <Notifications className="!h-4 !w-4 [&_path]:fill-gray-700" />
                            Select 2x per week
                        </p>
                    </Form.InputHelperText>
                </div>
                <div>
                    <Form.Label required>Time</Form.Label>
                    <TimePicker
                        value={timeOfDay}
                        onChange={(value) => {
                            onChange({ timeOfDay: value });
                        }}
                    />
                </div>
                <div>
                    <Form.SearchSelect
                        required
                        name="TimeZone"
                        label="Time Zone"
                        value={timeZone}
                        onChange={(option) => {
                            onChange({ timeZone: option.value });
                        }}
                        options={timezoneNames}
                    />
                </div>
                <div>
                    <Typography variant="lg" className="font-bold">
                        Spark Announcements
                    </Typography>
                    <Form.Description>
                        <p className="!m-0">
                            Text message notifications are sent to participating employees notifying
                            them on days a new Spark starts.
                        </p>
                    </Form.Description>
                </div>
            </div>
            <div>
                {(isUpdateEnabled || isAnnounceEnabled) && (
                    <div className="sample-phone-messages">
                        <Suspense fallback={<Spinner />}>
                            <SamplePhoneMessages
                                userName={userName}
                                showMessage1={isUpdateEnabled}
                                showMessage2={isAnnounceEnabled}
                            />
                        </Suspense>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationsContainer;
