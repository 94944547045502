import { useMemo } from 'react';

import moment from 'moment';

import { UIEvent } from '@sparkplug/lib';

import { EventDisplayStatus, getEventDisplayStatus } from '@features/events/helpers/eventUtils';

import Chip, { TChipVariant } from '@components/chips/Chip';

import { TStandardColors } from '@app/types/UITypes';

const EventStatusChip = ({
    event,
    accountType,
    dense = false,
}: {
    event: UIEvent;
    accountType: 'retailer' | 'brand';
    dense?: boolean;
}) => {
    const now = moment();
    const daysUntilStart = moment(event.startTime).diff(now.startOf('day'), 'days');

    const tagConfig: Record<
        EventDisplayStatus,
        { color: TStandardColors; variant: TChipVariant; label: string }
    > = {
        complete: {
            color: 'neutral',
            variant: 'flat',
            label: 'Complete',
        },
        active: {
            color: 'green',
            variant: 'flat',
            label: 'Active',
        },
        expired: {
            color: 'light-gray',
            variant: 'flat',
            label: 'Expired',
        },
        pending: {
            color: 'neutral',
            variant: 'outlined',
            label: 'Pending',
        },
        declined: {
            color: 'red',
            variant: 'flat',
            label: 'Declined',
        },
        upcoming: {
            color: 'yellow',
            variant: 'flat',
            label: `In ${daysUntilStart} Days`,
        },
    };

    const status = useMemo(
        () => getEventDisplayStatus({ event, accountType }),
        [event, accountType],
    );

    const selectedTag = tagConfig[status];

    return (
        <Chip
            label={selectedTag.label}
            color={selectedTag.color}
            variant={selectedTag.variant}
            dense={dense}
        />
    );
};

export default EventStatusChip;
