import { useMemo } from 'react';

import { AccountMarket, HydratedVendorPosProduct } from '@sparkplug/lib';

import { UpdateInventoryEntitlementEmptyState } from '@features/inventory/components/InventoryEmptyStates/InventoryEmptyStates';
import ProductInventoryTable from '@features/inventory/components/ProductInventoryTable';
import { useProductInventoryTableFilters } from '@features/inventory/components/ProductInventoryTable/useProductInventoryTableFilters';
import { SparkDetailsInventoryToolbar } from '@features/inventory/components/SparkDetailsInventoryToolbar/SparkDetailsInventoryToolbar';
import { prepareInventoryData } from '@features/inventory/utils';

import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount, useSparkplugBrandRetailer } from '@hooks/SparkplugAccountsHooks';

import { IPosProduct } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';

export interface SparkDetailsInventoryViewProps {
    showLocationsDropdown: boolean;
    locations: IOption[];
    products: (HydratedVendorPosProduct | IPosProduct)[];
    accountType?: 'brand' | 'retailer';
    retailerAccountId: string;
    inventoryEnabled: boolean;
    isLoading: boolean;
    market: string;
}

const SparkDetailsInventoryView = ({
    showLocationsDropdown,
    locations,
    products,
    accountType,
    retailerAccountId,
    inventoryEnabled,
    isLoading,
    market,
}: SparkDetailsInventoryViewProps) => {
    const { userIsSuperAdmin, appIsMobile } = useApp();
    const { hasInventoryEntitlement, inventoryEntitlementMarkets } = useSparkplugAccount();
    const inventoryFeatureEnabled = import.meta.env.REACT_APP_RETAILER_INVENTORY === 'true';

    const isMarketEntitled = useMemo(
        () =>
            hasInventoryEntitlement &&
            (inventoryEntitlementMarkets.length === 0 ||
                inventoryEntitlementMarkets.includes(market as AccountMarket)),
        [hasInventoryEntitlement, inventoryEntitlementMarkets, market],
    );

    const showInventoryForVendor =
        hasInventoryEntitlement && inventoryFeatureEnabled && isMarketEntitled;

    const showUpgradeModal = !showInventoryForVendor && !userIsSuperAdmin;

    const {
        numProductsMissingInventory,
        productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    } = useMemo(() => prepareInventoryData(products, locations), [products, locations]);

    const {
        searchFilter,
        applySearch,
        filters,
        tableFilters,
        updateFilters,
        onChangeSearchFilter,
        visibleLocationOptions,
        selectedLocationCountProps,
    } = useProductInventoryTableFilters({
        initialFilters: {},
        isRetailerDashboard: false,
        initialProducts: productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    });

    if (showUpgradeModal) {
        return (
            <Paper className="section">
                <UpdateInventoryEntitlementEmptyState />
            </Paper>
        );
    }

    return (
        <Paper className="section">
            <SparkDetailsInventoryToolbar
                {...{
                    showLocationsDropdown: showLocationsDropdown || numProductsMissingInventory > 0,
                    onChangeSearchFilter,
                    searchFilter,
                    filters,
                    updateFilters,
                    locationOptions,
                    visibleLocationOptions,
                    selectedLocationCountProps,
                    appIsMobile,
                }}
            />
            <ProductInventoryTable
                numProductsMissingInventory={numProductsMissingInventory}
                accountType={accountType}
                products={productsByLocation}
                inventoryEnabled={inventoryEnabled}
                retailerAccountId={retailerAccountId}
                isLoading={isLoading}
                filters={[applySearch, ...tableFilters]}
            />
        </Paper>
    );
};

export default SparkDetailsInventoryView;
