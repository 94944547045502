import axios from 'axios';

import { AccountMarket, AccountReachResponse } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

type GetAccountReachParams = {
    accountId?: string;
    markets?: AccountMarket[];
    retailers?: string[];
};

const API = {
    getAccountReach: async ({ accountId, markets, retailers }: GetAccountReachParams) => {
        return (
            await axios.post<AccountReachResponse>(`/api/v1/accounts/${accountId}/reach`, {
                markets,
                retailers,
            })
        ).data;
    },
};

export const reachCacheKey = 'reach';

export const useGetAccountReachQuery = (params: GetAccountReachParams) => {
    const { data, isLoading, error } = useAdvancedQuery(
        [reachCacheKey, params],
        () => API.getAccountReach(params),
        {
            refetchOnMount: true,
            enabled: !!params.accountId,
        },
    );

    return {
        data,
        isLoadingReach: isLoading,
        reachError: error,
    };
};
