import EventTable from '@features/events/components/EventTable';

import CalloutMessage from '@components/layout/CalloutMessage';

export const RetailerScheduledEvents = () => {
    return (
        <div data-testid="retailer-scheduled-events" className="flex flex-col gap-4">
            <CalloutMessage message="Manage pop-up, promos, trainings and other events with your Brand partners to keep employees informed and engaged." />
            <EventTable />
        </div>
    );
};
