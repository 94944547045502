import { useCallback } from 'react';

import axios from 'axios';

import { ListEmployeeEventsResponseBody, UIEvent } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedQuery, useQueryClient } from '@hooks/QueryHooks';

const EmployeeEventAPI = {
    fetchEmployeeEvents: async () => {
        return (
            (await axios.get<ListEmployeeEventsResponseBody>('/api/v1/employee-data/events')).data
                .data ?? ([] as UIEvent[])
        );
    },
};

export const getEmployeeEventsCacheKey = (userId: string) => ['employee-events', userId];
export const useEmployeeEvents = (options?: { autoFetch?: boolean }) => {
    const { userId = '' } = useApp();

    const {
        data: events = [],
        isFetched: eventsAreReady,
        refetch: refetchEvents,
    } = useAdvancedQuery(
        getEmployeeEventsCacheKey(userId),
        () => EmployeeEventAPI.fetchEmployeeEvents(),
        {
            enabled: !!userId && (options?.autoFetch ?? true),
        },
    );

    const queryClient = useQueryClient();
    const prefetchEvents = useCallback(async () => {
        queryClient.prefetchQuery(getEmployeeEventsCacheKey(userId), () =>
            EmployeeEventAPI.fetchEmployeeEvents(),
        );
    }, [queryClient]);

    return {
        events,
        eventsAreReady,
        refetchEvents,
        prefetchEvents,
    };
};
