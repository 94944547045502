import axios from 'axios';

import { ListEmployeeLocationsResponseBody } from '@sparkplug/lib';

export default class EmployeeDataAPI {
    static async getEmployeeLocations(): Promise<ListEmployeeLocationsResponseBody> {
        return (await axios.get<ListEmployeeLocationsResponseBody>('/api/employee-data/locations'))
            .data;
    }
}
