import { z } from 'zod';
import { PagedApiResponseBody } from '../http';
import { BrandProductTag } from '../product-tags';
import { objectIdRegex } from '../util';
import { PosProductLocationMetric } from './productMetrics';

export interface PosProductLocationInventory {
  quantityAvailable: number;
  unit: string;
  updatedAt: Date;
  active?: boolean;
}

export interface PosProduct {
  _id: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  groupId: string;
  name: string;
  posConfigIds: string[];
  brandId: string;
  categoryId: string;
  externalId: string;
  internalKey: string;
  lastSoldAt: string;
  inventory?: { [locationId: string]: PosProductLocationInventory };
  // Note: below properties may not appear on all POS data but each will appear on data from at least one POS type
  weight?: string;
  strain?: string;
  flowerType?: string;
  price?: number;
  thcContent?: string;
  cbdContent?: string;
  description?: string;
  medical?: boolean;
  active?: boolean;
  byGram?: boolean;
  byPrepackage?: boolean;
  unitCost?: number;
  totalCost?: number;
  weightPerUnit?: string;
  sample?: boolean;
  availableOnline?: boolean;
  subcategoryName?: string;
  vendorName?: string;
  masterCategory?: string;
  flavor?: string;
  defaultUnit?: string;
}
export interface HydratedPosProduct extends PosProduct {
  brands: Array<{ _id: string; name: string }>;
  categories: Array<{ _id: string; name: string }>;
}

export interface HydratedVendorPosProduct extends HydratedPosProduct {
  sparkBrand: { _id: string; name: string };
  productMetrics?: PosProductLocationMetric;
}
export interface HydratedBrandProductTag extends BrandProductTag {
  groupName: string;
}

export interface HydratedBrandProduct extends HydratedPosProduct {
  tags: HydratedBrandProductTag[];
}

export const CondensedPosProductProperties = [
  '_id',
  'groupId',
  'name',
  'posConfigIds',
  'brandId',
  'categoryId',
  'externalId',
  'internalKey',
  'lastSoldAt',
  'price',
  'inventory',
] as const;
export type CondensedPosProduct = Pick<PosProduct, typeof CondensedPosProductProperties[number]>;

export interface CondensedHydratedPosProduct extends CondensedPosProduct {
  brands: Array<{ _id: string; name: string }>;
  categories: Array<{ _id: string; name: string }>;
}
export interface CondensedHydratedBrandProduct extends CondensedHydratedPosProduct {
  tags: HydratedBrandProductTag[];
  predictedTags?: HydratedBrandProductTag[];
}

export interface ListPosProductsQueryParams {
  group_id?: string;
  include_association_details?: boolean;
  brand_id?: string;
  category_id?: string;
  limit?: number;
  offset?: number;
  posProductIds?: string[];
}

export interface ListPosProductsMappedToVendorQueryParams {
  retailerAccountId: string;
  vendorAccountId: string;
  shareSalesData: boolean;
  limit?: number;
  offset?: number;
}

export type ListPosProductsResponseBody = PagedApiResponseBody<
  PosProduct | HydratedPosProduct | HydratedVendorPosProduct
>;

export const listProductsWithSalesRequestBodySchema = z.object({
  startDate: z.string(),
  endDate: z.string(),
  retailerAccountId: z.string().regex(objectIdRegex),
  locationIds: z.array(z.string().regex(objectIdRegex)),
});
export type ListProductsWithSalesRequestBody = z.infer<
  typeof listProductsWithSalesRequestBodySchema
>;

export type ListProductsWithSalesResponseBody = {
  productsWithSales: string[];
};
