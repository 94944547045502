import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';

import {
    HydratedVendorPosProduct,
    PosProductLocationInventory,
    PosProductMetric,
} from '@sparkplug/lib';

import { ExclamationIcon, Info } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { IPosProduct } from '@app/types/PosTypes';
import { THeadCell } from '@app/types/TableTypes';

export interface ProductInventoryRow
    extends Omit<HydratedVendorPosProduct | IPosProduct, 'inventory'> {
    key: string;
    categoryName?: string;
    inventory: PosProductLocationInventory;
    locationId?: string;
    locationName: string;
    sparkBrand?: HydratedVendorPosProduct['sparkBrand'];
    productMetrics?: PosProductMetric;
}

const NotApplicableLabel = () => (
    <div className="not-applicable">
        N/A
        <Tooltip
            placement="right"
            title="Unable to retrieve the inventory information for this product at this time. This does not imply the remaining inventory is 0."
        >
            <Info fontSize="small" />
        </Tooltip>
    </div>
);

const InventoryValueLabel = ({ quantity }: { quantity: number }) => {
    return quantity === 0 ? (
        <div className="text-buttercup-700 flex items-center">
            out-of-stock
            <ExclamationIcon className="pl-1" />
        </div>
    ) : (
        <div>{quantity}</div>
    );
};

export const useProductInventoryHeadCells = (
    isRetailerDashboard: boolean,
    userIsSuperAdmin: boolean,
    shareSalesData: boolean,
): THeadCell<ProductInventoryRow>[] => {
    return [
        {
            id: 'name',
            sortType: 'string',
            label: 'Product',
            render: (row) => {
                return <Table.Cell>{row.name}</Table.Cell>;
            },
        },
        {
            id: 'sparkBrand.name',
            sortType: 'string',
            isHidden: !isRetailerDashboard,
            label: 'Brand',
            render: (row: ProductInventoryRow) => {
                return <Table.Cell>{row.sparkBrand?.name ?? '--'}</Table.Cell>;
            },
        },
        {
            id: 'categoryName',
            sortType: 'string',
            label: 'Category',
            render: (row) => {
                return <Table.Cell>{row.categoryName ?? '--'}</Table.Cell>;
            },
        },
        {
            id: 'locationName',
            isHidden: isRetailerDashboard,
            label: 'Location',
            render: (row) => {
                return <Table.Cell>{row.locationName ?? '--'}</Table.Cell>;
            },
        },
        {
            id: 'productMetrics.averageDailyUnits',
            isHidden: !isRetailerDashboard || !shareSalesData,
            info: 'The average daily sales over the last 60 days. For new products, this number may not be as reliable.',
            sortType: 'numeric',
            label: 'Avg Daily Units',
            render: (row) => {
                const firstDate = row.productMetrics?.firstTransactionDate;
                const formattedDate = firstDate ? moment(firstDate).format('MMM D') : '--';

                const startDate = moment(moment().startOf('day'))
                    .subtract(60, 'days')
                    .startOf('day');

                return (
                    <Table.Cell>
                        <Tooltip
                            disabled={userIsSuperAdmin}
                            placement="top-start"
                            title={`starting ${row.productMetrics?.numDaysUsedForCalc} ${pluralize(
                                'day',
                                row.productMetrics?.numDaysUsedForCalc,
                            )} ago (${
                                row.productMetrics?.numDaysUsedForCalc !== 60
                                    ? `${formattedDate}`
                                    : ` ${startDate.format('MMM D')}`
                            })`}
                        >
                            <span>{row.productMetrics?.averageDailyUnits ?? '--'}</span>
                        </Tooltip>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'inventory.quantityAvailable',
            sortType: 'numeric',
            label: 'Inventory',
            render: (row) => {
                const quantityAvailable = row.inventory.quantityAvailable;
                const isNotApplicableValue =
                    quantityAvailable < 0 ||
                    isUndefined(quantityAvailable) ||
                    isNull(quantityAvailable);
                return (
                    <Table.Cell>
                        <div className={isNotApplicableValue ? 'not-applicable' : ''}>
                            {isNotApplicableValue ? (
                                <NotApplicableLabel />
                            ) : (
                                <InventoryValueLabel quantity={row.inventory.quantityAvailable} />
                            )}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'productMetrics.numDaysUsedForCalc',
            isHidden: !(userIsSuperAdmin && isRetailerDashboard) || !shareSalesData,
            sortType: 'numeric',
            info: 'If a new product is added to the system, the sales period will be the number of days since the product was added instead of 60 for better accuracy',
            label: 'Sales Days',
            render: (row) => {
                const firstDate = row.productMetrics?.firstTransactionDate;
                const formattedDate = firstDate ? moment(firstDate).format('MMM D') : '--';

                const startDate = moment(moment().startOf('day'))
                    .subtract(60, 'days')
                    .startOf('day');
                return (
                    <Table.Cell>
                        {row.productMetrics?.firstTransactionDate !== undefined ? (
                            <div className="flex flex-col">
                                <Tooltip
                                    placement="top-start"
                                    title={
                                        row.productMetrics?.numDaysUsedForCalc !== 60
                                            ? `${formattedDate}`
                                            : ` ${startDate.format('MMM D')}`
                                    }
                                >
                                    <span>
                                        {row.productMetrics?.numDaysUsedForCalc}&nbsp;
                                        {pluralize('day', row.productMetrics?.numDaysUsedForCalc)}
                                    </span>
                                </Tooltip>
                            </div>
                        ) : (
                            '--'
                        )}
                    </Table.Cell>
                );
            },
        },
        {
            id: 'productMetrics.estimatedDaysOnHand',
            isHidden: !isRetailerDashboard || !shareSalesData,
            sortType: 'numeric',
            info: 'The estimated number of days the current inventory will last based on the average daily units sold',
            label: 'Days on Hand',
            render: (row) => {
                return (
                    <Table.Cell>
                        {row.productMetrics?.estimatedDaysOnHand !== undefined
                            ? `${row.productMetrics?.estimatedDaysOnHand} ${pluralize(
                                  'day',
                                  row.productMetrics?.estimatedDaysOnHand,
                              )}`
                            : '--'}
                    </Table.Cell>
                );
            },
        },
    ];
};
