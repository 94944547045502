import clsx from 'clsx';

import { AccountMarketLabel, UIEvent } from '@sparkplug/lib';

import Button from '@components/buttons/Button/Button';
import Form from '@components/form/Form';
import Drawer from '@components/overlays/Drawer';

import InputGroup from '@app/componentLibrary/Input/InputGroup';
import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';

import { EventFormDateTimeSection } from './components/EventFormDateTimeSection';
import { EventFormTypeSelector } from './components/EventFormTypeSelector';
import { useEventFormData } from './hooks/useEventFormData';
import { useEventFormDependencyManager } from './hooks/useEventFormDependencyManager';
import { useEventFormState } from './hooks/useEventFormState';
import { useEventFormSubmit } from './hooks/useEventFormSubmit';

export interface EventFormProps {
    className?: string;
    account: IAccount;
    initialEvent?: UIEvent;
    /**
     * Called when the form is successfully submitted.
     */
    onSuccess: () => void;
    /**
     * Called when the form is not successfully submitted.
     */
    onError: () => void;
    /**
     * Called when the form is exited.
     */
    onExit: () => void;
}

const EventForm = ({
    className,
    account,
    initialEvent,
    onSuccess,
    onError,
    onExit,
}: EventFormProps) => {
    // Access and update the EventForm fields
    const { form, updateForm } = useEventFormState(initialEvent);

    // Form option data given the account and current form state
    const { brandOptions, retailerOptions, locationOptions, timeZone, state, formDataIsReady } =
        useEventFormData({
            account,
            form,
        });

    // When certain fields change, dependent fields should be reset. This hook manages these dependencies.
    const { retailerInputRef } = useEventFormDependencyManager({ form, updateForm });

    // Submit the form while handling validation and bubbling up any submission errors.
    const { handleSubmit, errors, isSubmitting } = useEventFormSubmit({
        account,
        form,
        timeZone,
        onSuccess,
        onError,
    });

    const isEditMode: boolean = !!initialEvent?._id;
    const hasRetailer: boolean = !!(account.type === 'retailer' || form.retailerAccountId);
    const isVendorAccount: boolean = account.type === 'brand';

    return (
        <>
            <Form
                className={cn(
                    'flex flex-col gap-4 w-full h-full px-6 pt-4 pb-[140px] overflow-y-auto',
                    className,
                )}
            >
                {isVendorAccount && (
                    <InputGroup label="Brand*" error={errors.brandId} isLoading={!formDataIsReady}>
                        <Form.SearchSelect
                            name="brand-select"
                            value={form.brandId ?? ''}
                            onChange={(newBrand) => updateForm({ brandId: newBrand.value })}
                            options={brandOptions}
                        />
                    </InputGroup>
                )}

                <InputGroup label="Event Type*" error={errors.type}>
                    <EventFormTypeSelector
                        value={form.type as UIEvent['type']}
                        onChange={(newEventType) => updateForm({ type: newEventType })}
                    />
                </InputGroup>

                {!!form.brandId && !!form.type && (
                    <InputGroup
                        label="Retailer*"
                        error={errors.retailerAccountId}
                        isLoading={!formDataIsReady}
                    >
                        <Form.SearchSelect
                            inputRef={retailerInputRef}
                            name="retailer-select"
                            value={form.retailerAccountId ?? ''}
                            onChange={(newRetailer) =>
                                updateForm({ retailerAccountId: newRetailer.value })
                            }
                            options={retailerOptions}
                        />
                    </InputGroup>
                )}

                {hasRetailer && (
                    <>
                        <InputGroup
                            label="Location(s)*"
                            error={errors.locationIds}
                            isLoading={!formDataIsReady}
                        >
                            <Form.TreeSelect
                                required
                                controlled
                                name="create-event-locations"
                                placeholder="Select Locations"
                                className={clsx([
                                    'hide-toggles hide-search w-full',
                                    form.locationIds.length >= 1 && 'all-markets',
                                ])}
                                selected={form.locationIds ?? []}
                                onChange={(values: string[]) => {
                                    if (values.includes('all')) {
                                        updateForm({
                                            locationIds: [
                                                'all',
                                                ...locationOptions.map(
                                                    (location) => location.value,
                                                ),
                                            ],
                                        });
                                    } else {
                                        updateForm({ locationIds: values });
                                    }
                                }}
                                options={[
                                    {
                                        value: 'all',
                                        label: 'All Locations',
                                        expanded: true,
                                        children: locationOptions,
                                    },
                                ]}
                            />
                        </InputGroup>

                        <EventFormDateTimeSection
                            error={errors.startTime || errors.endTime}
                            timeZone={timeZone}
                            state={state as AccountMarketLabel}
                            startDateTime={form.startTime ?? ''}
                            endDateTime={form.endTime ?? ''}
                            onDateTimeChange={(values) => {
                                updateForm({
                                    startTime: values.startDateTime,
                                    endTime: values.endDateTime,
                                });
                            }}
                        />

                        <InputGroup
                            label="Event Name*"
                            subtext={`${form.name?.length ?? 0} / 32`}
                            error={errors.name}
                        >
                            <Form.TextField
                                name="event-name"
                                value={form.name ?? ''}
                                onChange={(e) => updateForm({ name: e.target.value })}
                                maxLength={32}
                            />
                        </InputGroup>

                        <InputGroup
                            label="Event Description*"
                            subtext={`${form.description?.length ?? 0} / 120`}
                            error={errors.description}
                        >
                            <Form.TextField
                                name="event-description"
                                value={form.description ?? ''}
                                onChange={(e) => updateForm({ description: e.target.value })}
                                maxLength={120}
                                multiline
                                rows={3}
                                className="[&_.MuiInputBase-root]:!p-0"
                            />
                        </InputGroup>

                        {isVendorAccount && (
                            <InputGroup
                                label="Event Note (Optional)"
                                subtext="Send a note to the store manager with any additional details."
                            >
                                <Form.TextField
                                    name="event-note"
                                    value={form.requestNote ?? ''}
                                    onChange={(e) => updateForm({ requestNote: e.target.value })}
                                    multiline
                                    rows={4}
                                    maxLength={180}
                                    className="[&_.MuiInputBase-root]:!p-0"
                                />
                            </InputGroup>
                        )}
                    </>
                )}
            </Form>

            <Drawer.Footer className="flex space-x-2 justify-end">
                <Button variant="flat" color="neutral" onClick={onExit}>
                    Cancel
                </Button>
                <Button
                    variant="smooth"
                    color="blue"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !formDataIsReady}
                >
                    {isEditMode ? 'Save' : 'Launch Event'}
                </Button>
            </Drawer.Footer>
        </>
    );
};

export default EventForm;
