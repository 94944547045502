import { useCallback } from 'react';

import { DateTime } from 'luxon';

import { useUpsertEventMutation } from '@features/events/mutations/EventMutations';
import { EventFormFields } from '@features/events/types';

import { IAccount } from '@app/types/AccountsTypes';

import { useEventFormValidator } from './useEventFormValidator';

/**
 * Given date time is in the local time zone. We need to adjust that time based on the difference between the local time zone and the time zone of the event and then convert to UTC
 */
const localToUTC = ({
    isoDateTime,
    eventTimeZone,
}: {
    isoDateTime?: string;
    eventTimeZone: string;
}) => {
    if (!isoDateTime) return undefined;
    const localToEventTimeZone =
        DateTime.now().setZone(eventTimeZone).offset - DateTime.local().offset;
    const timeAdjustmentInMins = localToEventTimeZone * -1;
    return (
        DateTime.fromISO(isoDateTime, { zone: eventTimeZone })
            .plus({ minutes: timeAdjustmentInMins })
            .toUTC()
            .toISO() || undefined
    );
};

const sanitizeEventFormFields = ({
    account,
    form,
    eventTimeZone,
}: {
    account: IAccount;
    form: EventFormFields;
    eventTimeZone: string;
}): EventFormFields => {
    const payload = {
        ...form,
        ...(account.type === 'brand'
            ? { vendorAccountId: account._id }
            : { retailerAccountId: account._id }),
    };

    if (form.startTime) {
        const utcStartDateTime = localToUTC({
            isoDateTime: DateTime.fromISO(form.startTime).toISO()!,
            eventTimeZone,
        });
        payload.startTime = utcStartDateTime || undefined;
    }
    if (form.endTime) {
        const utcEndDateTime = localToUTC({
            isoDateTime: DateTime.fromISO(form.endTime).toISO()!,
            eventTimeZone,
        });
        payload.endTime = utcEndDateTime || undefined;
    }
    if (form.locationIds.length > 0) {
        payload.locationIds = form.locationIds.filter(
            (formOptionValue) => formOptionValue !== 'all',
        );
    }

    return payload;
};

export const useEventFormSubmit = ({
    account,
    form,
    timeZone,
    onSuccess,
    onError,
}: {
    account: IAccount;
    form: EventFormFields;
    timeZone: string;
    onSuccess: () => void;
    onError: () => void;
}) => {
    const { errors, validate } = useEventFormValidator({ account });

    const toastMessageType = form?._id ? 'update' : 'create';
    const { isUpsertingEvent, upsertEventAsync } = useUpsertEventMutation(toastMessageType);
    const handleSubmit = useCallback(async () => {
        const payload = {
            ...sanitizeEventFormFields({ account, form, eventTimeZone: timeZone }),
        };
        if (validate(payload)) {
            upsertEventAsync(payload).then(onSuccess).catch(onError);
        }
    }, [account, form]);

    return { handleSubmit, isSubmitting: isUpsertingEvent, errors };
};
