import { FC, useState } from 'react';

import { formatCurrency } from '@sparkplug/lib';

import { CashOutDetailsType } from '@features/wallet/constants';

import Button from '@components/buttons/Button';
import { InfoOutlined, RightArrow } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Spinner from '@components/layout/Spinner';
import Drawer from '@components/overlays/Drawer';

import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

interface CashOutConfirmationDrawerProps {
    showConfirmation: boolean;
    setShowConfirmation: (show: boolean) => void;
    selectedMethodDetails?: CashOutDetailsType;
    totalAmount: number;
    cashOutWallet: () => void;
    isCashingOut: boolean;
}

const CashOutConfirmationDrawer: FC<CashOutConfirmationDrawerProps> = ({
    showConfirmation,
    setShowConfirmation,
    selectedMethodDetails,
    totalAmount,
    cashOutWallet,
    isCashingOut,
}) => {
    const withdrawalFee = selectedMethodDetails?.fee ? totalAmount * selectedMethodDetails.fee : 0;

    const [isInfoDrawerOpen, setIsInfoDrawerOpen] = useState(false);

    const closeConfirmationDrawer = () => {
        if (isCashingOut) {
            return;
        }
        setShowConfirmation(false);
        setIsInfoDrawerOpen(false);
    };

    if (!selectedMethodDetails) {
        return null;
    }

    return (
        <Drawer
            isVisible={showConfirmation}
            className="bring-drawer-to-front [&_.MuiPaper-root]:!h-full [&_.MuiPaper-root]:items-center [&_.drawer-header]:w-full [&_.drawer-header]:border-b-[1px] [&_.drawer-header]:border-solid [&_.drawer-header]:border-gray-100 [&_.drawer-header]:shadow-[0_0_4px_0_#62676e33] [&_.drawer-header]:!px-6 [&_.drawer-header]:!py-4 [&_.drawer-body]:max-w-[480px] [&_.drawer-body]:!px-4 [&_.drawer-body]:!pb-32 [&_.drawer-body]:overflow-y-scroll"
            title="Confirm Cash Out"
            variant="bottomPartial"
            onCloseHandler={closeConfirmationDrawer}
            swipeable={false}
            disableTransition
            drawerHeader={
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={closeConfirmationDrawer}
                >
                    <RightArrow className="rotate-180 [&>path]:stroke-gray-900" />
                    <span>Back</span>
                </div>
            }
        >
            <>
                <div className="flex flex-col gap-3 text-left pt-6">
                    <Typography variant="2xl" className="font-bold text-gray-950">
                        Does everything look right?
                    </Typography>
                    <CalloutMessage
                        color="yellow"
                        className="px-4"
                        message={
                            <Typography className="text-gray-950">
                                <span
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: selectedMethodDetails.calloutDescription,
                                    }}
                                />
                            </Typography>
                        }
                    />
                    <div className="flex flex-col gap-2 border-b border-solid border-gray-100 pb-4">
                        <Typography>Method</Typography>
                        <div className="flex gap-2 items-center justify-between">
                            <div className="flex gap-2 items-center">
                                {selectedMethodDetails.icon}
                                <Typography variant="lg" className="font-semibold">
                                    {selectedMethodDetails.label}
                                </Typography>
                                {selectedMethodDetails.infoDetails ? (
                                    <InfoOutlined
                                        className="w-4 h-4 cursor-pointer fill-gray-800"
                                        onClick={() => setIsInfoDrawerOpen((val) => !val)}
                                    />
                                ) : null}
                            </div>
                            <div>
                                <Button
                                    variant="flat"
                                    color="neutral"
                                    onClick={closeConfirmationDrawer}
                                    className="!px-0"
                                >
                                    <Typography
                                        variant="lg"
                                        className="text-cerulean-600 font-normal"
                                    >
                                        Change
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                        {selectedMethodDetails.infoDetails ? (
                            <div
                                className={cn(
                                    'flex flex-col gap-2',
                                    isInfoDrawerOpen ? '' : 'hidden',
                                    'transition-all duration-300',
                                    'ml-2',
                                )}
                            >
                                {selectedMethodDetails.infoDetails.map((detail) => (
                                    <Typography
                                        key={detail}
                                        className="text-gray-900"
                                    >{`• ${detail}`}</Typography>
                                ))}
                            </div>
                        ) : null}
                    </div>
                    <div className="flex flex-col gap-4 border-b border-solid border-gray-100 pb-4">
                        <div className="flex justify-between">
                            <Typography variant="lg" className="text-gray-800">
                                Your Cash Balance
                            </Typography>
                            <Typography variant="lg" className="text-gray-950">
                                {formatCurrency(totalAmount, true)}
                            </Typography>
                        </div>
                        <div className="flex justify-between">
                            <Typography variant="lg" className="text-gray-800">
                                Fee ({selectedMethodDetails.fee * 100}%)
                            </Typography>
                            <Typography variant="lg" className="text-gray-950">
                                {`${withdrawalFee ? '- ' : ''}${formatCurrency(
                                    withdrawalFee,
                                    true,
                                )}`}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-center">
                            <Typography variant="lg" className="text-gray-950 font-semibold">
                                Cash Out Total
                            </Typography>
                            <Typography variant="lg" className="text-gray-950 font-semibold">
                                <strong>{formatCurrency(totalAmount - withdrawalFee, true)}</strong>
                            </Typography>
                        </div>
                    </div>
                    {selectedMethodDetails.warningMessage ? (
                        <div className="flex flex-col gap-2">
                            <Typography className="text-gray-900">
                                {selectedMethodDetails.warningMessage}
                            </Typography>
                        </div>
                    ) : null}
                </div>
                <Drawer.Footer className="py-4">
                    <div className="flex flex-col gap-4 items-center">
                        <Typography className="text-gray-900">
                            Cash outs are final and cannot be reversed.
                        </Typography>
                        <Button
                            className="w-full"
                            variant="filled"
                            color="blue"
                            onClick={() => {
                                cashOutWallet();
                            }}
                            disabled={isCashingOut}
                        >
                            {isCashingOut ? (
                                <div className="flex items-center gap-2">
                                    <Spinner className="[&>svg>circle]:stroke-white" />
                                    <Typography variant="lg" className="!text-[17px]">
                                        {`Cashing Out ${formatCurrency(
                                            totalAmount - withdrawalFee,
                                            true,
                                        )}...`}
                                    </Typography>
                                </div>
                            ) : (
                                <Typography variant="lg" className="!text-[17px]">
                                    Confirm Cash Out{' '}
                                    {formatCurrency(totalAmount - withdrawalFee, true)}
                                </Typography>
                            )}
                        </Button>
                    </div>
                </Drawer.Footer>
            </>
        </Drawer>
    );
};

export default CashOutConfirmationDrawer;
