import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';

import { FormControl } from '@mui/material';

import InputLabel from '@components/inputs/InputLabel';

import { appendClasses } from '@helpers/ui';

import { IFormField, IOptionTree } from '@app/types/UITypes';

import './DropdownTreeSelect.scss';

type DropDownTreeSelectOption = IOptionTree<{ value: string | number }>;
export interface IDropdownTreeSelectProps extends Omit<IFormField, 'onChange'> {
    className?: string;
    mode?: 'simpleSelect' | 'multiSelect';
    color?: 'neutral' | 'blue' | 'green' | 'red';
    variant?: 'flat' | 'filled' | 'raised' | 'smooth' | 'outlined';
    label?: string;
    required?: boolean;
    selected: (string | number)[];
    options: DropDownTreeSelectOption[];
    onChange: (selected: string[], currentSelection: DropDownTreeSelectOption) => void;
    controlled?: boolean;
}

const assignObjectPaths = (
    obj: DropDownTreeSelectOption[],
    stack?: string,
    selectedValues: any[] = [],
) => {
    const options: any[] = obj.map((item, k) => {
        const path = stack ? `${stack}.${k}` : k;
        const children =
            item?.children != null
                ? assignObjectPaths(item?.children, path as string, selectedValues)
                : [];
        const allChildrenSelected =
            children.length > 0 ? children.every(({ checked }) => checked) : false;
        const checked = selectedValues.includes(item.value) || allChildrenSelected;

        return {
            ...item,
            path,
            checked,
            children,
        };
    });

    return options;
};

const getAllSelectedValues = (nodes: any[]): string[] => {
    let values: string[] = [];

    nodes.forEach((node) => {
        values.push(node.value);

        if (node.childNodes != null && node.childNodes.length > 0) {
            values = values.concat(getAllSelectedValues(node.childNodes));
        }
    });

    return values;
};

const DropdownTreeSelect = ({
    required,
    className,
    mode = 'multiSelect',
    label,
    disabled,
    selected,
    options,
    onChange,
    variant = 'raised',
    color = 'blue',
    error = false,
    controlled = false,
}: IDropdownTreeSelectProps) => {
    if (mode === 'simpleSelect') {
        // eslint-disable-next-line no-console
        console.log(
            'WARNING: DropdownTreeSelect will be deprecated. Use `SearchSelect` for searchable select, and `ListSelector` for TreeSelect.',
        );
    }

    const [data, setData] = useState(options);
    const didActionRef = useRef(false);
    const classes = appendClasses([
        className,
        'form-control',
        'form-control-custom',
        'dropdown-tree-select',
        `dropdown-tree-select-color-${color}`,
        `dropdown-tree-select-variant-${variant}`,
        selected != null && selected.length > 0 ? 'has-items' : 'has-no-items',
        mode === 'multiSelect' ? 'is-multiSelect' : 'is-singleSelect',
    ]);

    // Listen to `selected` and `options` until the
    // user interacts with the component (`didAction`).
    // This allows TreeSelect to operate on its own
    // and not update when selections are made.
    useEffect(() => {
        const newData = assignObjectPaths(options, undefined, selected);
        if (controlled || !didActionRef.current) {
            setData(newData);
        }
    }, [options, selected]);

    const onNodeSelect = (
        currentNode: DropDownTreeSelectOption,
        selectedNodes: DropDownTreeSelectOption[],
    ) => {
        const selectedValues = getAllSelectedValues(selectedNodes);
        didActionRef.current = true;
        onChange(selectedValues, currentNode);
    };

    const TreeSelect = useMemo(
        () => (
            <ReactDropdownTreeSelect
                mode={mode}
                disabled={disabled}
                data={data}
                onChange={onNodeSelect}
            />
        ),
        [data, disabled],
    );

    return (
        <FormControl error={error} className={classes}>
            {label && <InputLabel required={required}>{label}</InputLabel>}
            {TreeSelect}
        </FormControl>
    );
};

export default DropdownTreeSelect;
