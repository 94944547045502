import { useState } from 'react';

import { formatCurrency } from '@sparkplug/lib';

import { useGetEmployeeLearningResources } from '@features/learn-and-earn/queries/useGetEmployeeLearningResources';

import Button from '@components/buttons/Button';
import { NoCoursesGraphic } from '@components/graphics/EmptyStates';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';

import { useApp } from '@hooks/AppHooks';

import { Badge } from '@app/componentLibrary/Badge';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

import SparkStatusIcon from '../../../../components/sparks/SparkStatusIcon/SparkStatusIcon';

const EmptyState = ({variant}: {variant: 'new' | 'complete'}) => {
    return (
        <EmptyStateDisplay
            graphic={<NoCoursesGraphic />}
            label={variant === 'new' ? 'No New Courses' : 'No Completed Courses'}
            className="pt-10"
        />
    );
};

const LearnAndEarn = () => {
    const { history } = useApp();
    const { learningResources } = useGetEmployeeLearningResources();
    const [activeTab, setActiveTab] = useState('new');

    const incompleteResources = learningResources.filter(
        (resource) => resource.surveyResponse?.status !== 'completed',
    );
    const completeCourses = learningResources.filter(
        (resource) => resource.surveyResponse?.status === 'completed',
    );

    const tabOptions = [
        { value: 'new', label: 'New!', startIcon: <SparkStatusIcon status="active" /> },
        { value: 'complete', label: 'Complete', startIcon: <SparkStatusIcon status="completed" /> },
    ] as const;

    return (
        <>
            <div className="flex gap-2 mb-4">
                {tabOptions.map((option) => (
                    <Button
                        key={option.value}
                        color="neutral"
                        startIcon={option.startIcon}
                        variant="smooth"
                        className={`h-10 w-full spark-status-button !hover:bg-bluewood-0 ${
                            activeTab === option.value ? '!bg-gray-50' : ''
                        }`}
                        onClick={() => setActiveTab(option.value)}
                    >
                        <Typography variant="base" className="font-medium">
                            {option.label}
                        </Typography>
                    </Button>
                ))}
            </div>

            {activeTab === 'new' &&
                (incompleteResources.length > 0 ? (
                    incompleteResources.map((resource) => (
                        <Card key={resource.learningResourceId} className="mb-4">
                            <CardHeader className="mb-3">
                                <CardTitle className="flex flex-row items-center justify-between">
                                    <img
                                        src={resource.brandPhoto ?? ''}
                                        alt={resource.brandName ?? ''}
                                        className="w-10 h-10 rounded-full mr-2"
                                    />
                                    <div className="flex flex-col space-y-1 items-end">
                                        <Badge
                                            color="blue"
                                            label={resource.brandName}
                                            className="w-fit"
                                        />
                                        <Badge
                                            color="green"
                                            className="w-fit"
                                            label={`+ ${
                                                resource.reward
                                                    ? formatCurrency(resource.reward / 100)
                                                    : 0
                                            }`}
                                        />
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardContent>
                                <Typography variant="sm" className="font-semibold uppercase mb-1">
                                    Course
                                </Typography>
                                <Typography variant="base" className="text-gray-950 font-bold">
                                    {resource.name}
                                </Typography>
                                <Typography variant="base" className="text-gray-900">
                                    {resource.description}
                                </Typography>
                            </CardContent>
                            <CardFooter>
                                <Button
                                    variant="outlined"
                                    color="blue"
                                    className="w-full"
                                    onClick={() => {
                                        history.push(
                                            `/user/learn-and-earn/${resource.learningResourceId}`,
                                        );
                                    }}
                                >
                                    {(() => {
                                        if (
                                            resource.budget &&
                                            resource.budget > 0 &&
                                            resource.reward
                                        ) {
                                            return `Earn ${formatCurrency(
                                                resource.reward / 100,
                                            )} (Limited Rewards)`;
                                        }
                                        return resource.surveyResponse
                                            ? 'Continue Course'
                                            : 'Start Course';
                                    })()}
                                </Button>
                            </CardFooter>
                        </Card>
                    ))
                ) : (
                    <EmptyState variant="new" />
                ))}

            {activeTab === 'complete' &&
                (completeCourses.length > 0 ? (
                    completeCourses.map((resource) => (
                        <Card key={resource.learningResourceId} className="mb-4">
                            <CardHeader className="mb-3">
                                <CardTitle className="flex flex-row items-center justify-between">
                                    <img
                                        src={resource.brandPhoto ?? ''}
                                        alt={resource.brandName ?? ''}
                                        className="w-10 h-10 rounded-full mr-2"
                                    />
                                    <div className="flex flex-col space-y-1 items-end">
                                        <Badge
                                            color="blue"
                                            label={resource.brandName}
                                            className="w-fit"
                                        />
                                        <Badge
                                            color="dark-gray"
                                            label={`Earned ${
                                                resource.reward
                                                    ? formatCurrency(resource.reward / 100)
                                                    : 0
                                            }`}
                                        />
                                    </div>
                                </CardTitle>
                            </CardHeader>
                            <CardContent>
                                <Typography variant="sm" className="font-semibold uppercase mb-1">
                                    Course
                                </Typography>
                                <Typography variant="base" className="text-gray-950 font-bold">
                                    {resource.name}
                                </Typography>
                                <Typography variant="base" className="text-gray-900">
                                    {resource.description}
                                </Typography>
                            </CardContent>
                            <CardFooter>
                                <Button
                                    variant="outlined"
                                    color="blue"
                                    className="w-full"
                                    onClick={() => {
                                        history.push(
                                            `/user/learn-and-earn/${resource.learningResourceId}`,
                                        );
                                    }}
                                >
                                    View completed course
                                </Button>
                            </CardFooter>
                        </Card>
                    ))
                ) : (
                    <EmptyState variant="complete" />
                ))}
        </>
    );
};

const LearnAndEarnTab = () => {
    return <LearnAndEarn />;
};

export default LearnAndEarnTab;
