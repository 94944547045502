import axios from 'axios';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getCourseAssets: async (accountId: string) => {
        return (
            await axios.get<{
                assets: {
                    url: string;
                    name: string;
                    type: string;
                    lastModified: string;
                }[];
            }>(`/api/v1/learning-resource/${accountId}/assets`)
        ).data;
    },
};

export const useGetAllCourseAssetsQuery = (accountId: string) => {
    const { data, isLoading, error } = useAdvancedQuery(
        ['assets', accountId],
        () => API.getCourseAssets(accountId),
        {
            enabled: !!accountId,
        },
    );

    return {
        assets: data?.assets ?? [],
        isLoadingAssets: isLoading,
        assetsError: error,
    };
};
