import { useEffect } from 'react';

import { sumBy } from 'lodash';

import Intercom from '@helpers/Intercom';

import { useCurrentUserWallet } from '../queries';

export const useSaveYearlyEarningsToIntercom = () => {
    const { currentUserWallet, currentUserWalletIsReady } = useCurrentUserWallet();

    useEffect(() => {
        if (currentUserWallet?.deposits && currentUserWalletIsReady && Intercom?.isReady) {
            const currentYear = new Date().getUTCFullYear();
            const yearlyEarnings = sumBy(
                currentUserWallet.deposits,
                ({ confirmedAt, amount = 0 }) => {
                    if (!confirmedAt) return 0;
                    const confirmedDate = new Date(confirmedAt);
                    return confirmedDate.getUTCFullYear() === currentYear ? amount : 0;
                },
            );
            Intercom?.update({ yearlyEarnings });
        }
    }, [currentUserWallet?.deposits, currentUserWalletIsReady]);
};
