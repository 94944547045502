import { groupBy, isNull, isUndefined } from 'lodash';
import moment from 'moment/moment';

import { HydratedVendorPosProduct } from '@sparkplug/lib';

import { IPosProduct } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';

import { ProductInventoryRow } from '../components/ProductInventoryTable/useProductInventoryHeadCells';
import { LocationInventoryRow } from '../views/RetailerInventoryView/getRetailerInventoryHeadCells';

const getCategoryLabel = (product: HydratedVendorPosProduct | IPosProduct) => {
    return product.categories.find((category) => category._id === product.categoryId)?.name;
};

const isHydratedVendorPosProduct = (
    product: HydratedVendorPosProduct | IPosProduct,
): product is HydratedVendorPosProduct => {
    return 'sparkBrand' in product;
};

export const prepareInventoryData = (
    unProcessedproducts: (HydratedVendorPosProduct | IPosProduct)[],
    locations: IOption[],
) => {
    const brandMap = new Map<string, string>();
    const categoryMap = new Map<string, string>();
    const productMap = new Map<string, string>();
    let numProductsMissingInventory = 0;
    const last60days = moment().subtract(60, 'days').startOf('day');

    const productsByLoc = unProcessedproducts.flatMap((product) => {
        const categoryName = getCategoryLabel(product);
        const isHydratedProduct = isHydratedVendorPosProduct(product);

        if (isHydratedProduct && product.sparkBrand) {
            brandMap.set(product.sparkBrand._id, product.sparkBrand.name);
        }
        product.categories.forEach((category) => categoryMap.set(category._id, category.name));
        productMap.set(product._id, product.name);

        if (!product.inventory) {
            numProductsMissingInventory += 1;
            return [
                {
                    ...product,
                    categoryName,
                    locationName: '--',
                    locationId: 'missing_location',
                    inventory: {
                        quantityAvailable: Number.MIN_SAFE_INTEGER,
                        unit: '',
                    },
                    key: `${product._id}unknown`,
                    productMetrics: undefined,
                } as ProductInventoryRow,
            ];
        }

        return Object.entries(product.inventory)
            .map(([locationId, inventory]) => {
                const productLocation = locations.find((loc) => loc.value === locationId);
                let quantity = inventory?.quantityAvailable;

                if (typeof inventory.quantityAvailable !== 'number') {
                    quantity = Number.isNaN(parseFloat(inventory.quantityAvailable))
                        ? Number.MIN_SAFE_INTEGER
                        : parseFloat(parseFloat(inventory.quantityAvailable).toFixed(2));
                } else if (quantity < 0) {
                    quantity = Number.MIN_SAFE_INTEGER;
                } else {
                    quantity = Number(inventory.quantityAvailable.toFixed(2));
                }

                return {
                    ...product,
                    categoryName,
                    locationId,
                    locationName: productLocation?.label || 'DISABLED_LOCATION',
                    inventory: { ...inventory, quantityAvailable: quantity },
                    key: product._id + locationId,
                    productMetrics: isHydratedProduct
                        ? product.productMetrics?.[locationId]
                        : undefined,
                } as ProductInventoryRow;
            })
            .filter((processedProduct) => {
                const updatedAt = processedProduct.inventory.updatedAt;
                return updatedAt ? moment(updatedAt).isAfter(last60days) : true;
            });
    });
    const productsByLocation = productsByLoc.filter(
        (product) => product.locationName !== 'DISABLED_LOCATION',
    );

    const hasMissingLocations = productsByLocation.some(
        (product) => product.locationId === 'missing_location',
    );

    const locationOptions = [...locations];
    if (hasMissingLocations) {
        locationOptions.push({
            value: 'missing_location',
            label: 'Missing Location',
        });
    }

    const brandOptions = Array.from(brandMap).map(([id, name]) => ({
        value: id,
        label: name,
        key: id,
    }));

    const categoryOptions = Array.from(categoryMap).map(([id, name]) => ({
        value: id,
        label: name,
        key: id,
    }));

    const productOptions = Array.from(productMap).map(([id, name]) => ({
        value: id,
        label: name,
        key: id,
    }));

    return {
        numProductsMissingInventory,
        productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    };
};

export const getLocationInventoryRows = (
    productsByLocation: ProductInventoryRow[],
): LocationInventoryRow[] => {
    const groupedProductsByLocation = groupBy(productsByLocation, 'locationId');
    const rows = Object.entries(groupedProductsByLocation).map(([locationId, products]) => {
        const outOfStockCount = products.filter(
            (product) => product.inventory.quantityAvailable === 0,
        ).length;

        const locationNumProductsMissingInventory = products.filter((product) => {
            const { quantityAvailable } = product.inventory;
            return (
                quantityAvailable < 0 || isUndefined(quantityAvailable) || isNull(quantityAvailable)
            );
        }).length;

        const locationName =
            products[0]?.locationName === '--' ? 'Missing Data' : products[0]?.locationName || '';

        return {
            key: locationName === 'Missing Location' ? 'missing_location' : locationId,
            locationId,
            locationName,
            productData: products,
            outOfStockCount,
            numProductsMissingInventory: locationNumProductsMissingInventory,
        };
    });

    // Sort the rows, putting "Missing Location" at the bottom
    return rows.sort((a, b) => {
        if (a.key === 'missing_location') return 1;
        if (b.key === 'missing_location') return -1;
        return a.locationName.localeCompare(b.locationName);
    });
};
