import toast from 'react-hot-toast';

import { EventAttributes, createEvent } from 'ics';
import { DateTime } from 'luxon';

import { UIEvent } from '@sparkplug/lib';

import { IAccount } from '@app/types/AccountsTypes';

const formatEventDescription = ({
    event,
    account,
}: {
    event: UIEvent;
    account?: IAccount;
}): string => {
    const isVendorCreated: boolean = !!event.vendorAccount;
    const accountPerspective = account?.type === 'brand' ? 'Vendor' : 'Retailer';

    const isAllLocations: boolean = event.locations.length === event.totalRetailerLocationCount;
    const icsDescription: string[] = [
        `Type: ${event.type}`,
        accountPerspective === 'Vendor' ? `Retailer: ${event.retailerAccount.name}` : '',
        isVendorCreated ? `Vendor: ${event.vendorAccount?.name}` : '',
        event.brand ? `Brand: ${event.brand.name}` : '',
        isAllLocations
            ? 'All Locations'
            : `Locations: ${event.locations.map((loc) => loc.name).join(', ')}`,
        `Description: ${event.description}`,
    ];

    return icsDescription.filter(Boolean).join('\n');
};

const generateCalendarFilename = (event: UIEvent): string => {
    const startDate = DateTime.fromISO(event.startTime).toFormat('yyyy-MM-dd');

    return `${startDate}_${event.retailerAccount.name}_${event.type}.ics`;
};

export const addToCalendar = ({
    event,
    account,
    organizerEmail,
}: {
    event: UIEvent;
    account?: IAccount;
    organizerEmail?: string;
}): void => {
    if (!account) {
        return;
    }

    const startDateTime = DateTime.fromISO(event.startTime);
    const endDateTime = DateTime.fromISO(event.endTime);

    const calendarEvent: EventAttributes = {
        start: [
            startDateTime.year,
            startDateTime.month,
            startDateTime.day,
            startDateTime.hour,
            startDateTime.minute,
        ],
        startInputType: 'local' as const,
        end: [
            endDateTime.year,
            endDateTime.month,
            endDateTime.day,
            endDateTime.hour,
            endDateTime.minute,
        ],
        endInputType: 'local' as const,
        title: event.name,
        description: formatEventDescription({ event, account }),
        location: event.locations[0]?.name || '',
        organizer: organizerEmail
            ? { name: account.name || 'Event Organizer', email: organizerEmail }
            : undefined,
    };

    createEvent(calendarEvent, (error, value) => {
        if (error) {
            toast.error('Error creating calendar event');
            return;
        }

        const blob = new Blob([value], { type: 'text/calendar;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);

        // Create temporary link element for download
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = generateCalendarFilename(event);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Cleanup
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
    });
};
