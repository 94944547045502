import axios from 'axios';

import { GetSurveyResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getLearningResourceSurvey: async (
        learningResourceId: string,
    ): Promise<GetSurveyResponseBody> => {
        const response = await axios.get<GetSurveyResponseBody>(
            `/api/v1/learning-resource/${learningResourceId}/survey`,
        );
        return response.data;
    },
    getSurvey: async (surveyId: string): Promise<GetSurveyResponseBody> => {
        const response = await axios.get<GetSurveyResponseBody>(`/api/v1/survey/${surveyId}`);
        return response.data;
    },
};

export const getLearningResourceSurveyCacheKey = (learningResourceId: string) => [
    'survey',
    learningResourceId,
];

export const useGetLearningResourceSurvey = (learningResourceId: string) => {
    const {
        data: survey,
        isLoading,
        error,
    } = useAdvancedQuery(
        getLearningResourceSurveyCacheKey(learningResourceId),
        () => API.getLearningResourceSurvey(learningResourceId),
        {
            enabled: !!learningResourceId,
        },
    );

    return {
        survey,
        isLoadingSurvey: isLoading,
        surveyError: error,
    };
};

export const getSurveyCacheKey = (surveyId: string) => ['survey', surveyId];

export const useGetSurvey = (surveyId: string) => {
    const {
        data: survey,
        isLoading,
        error,
    } = useAdvancedQuery(getSurveyCacheKey(surveyId), () => API.getSurvey(surveyId), {
        enabled: !!surveyId,
    });

    return {
        survey,
        isLoadingSurvey: isLoading,
        surveyError: error,
    };
};
