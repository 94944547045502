import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GoalLabels, PrizeLabels, PrizeOptions } from '@constants/SparkConstants';

import { SparkAward, SparkFulfillmentType } from '@sparkplug/lib';

import Form from '@components/form/Form';
import { Info } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import RestrictedWordsModal, {
    containsRestrictedWordsByRef,
} from '@components/overlays/RestrictedWordsModal';
import Table from '@components/table/Table';
import toast from '@components/toast';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { formatNumberToCurrency, stringIsCurrency } from '@helpers/ui';

import { THeadCell } from '@app/types/TableTypes';

import MinThresholdField from '../MinThresholdField';
import { MinTransactionField } from './MinTransactionField';
import MultiRetailerSparkPrizeCalloutMessage from './MultiRetailerSparkPrizeCalloutMessage';
import {
    RewardMethod,
    RewardMethodRadioGroup,
    getInitialRewardMethodFromSpark,
} from './RewardMethodRadioGroup';
import SparkPrizeCalloutMessage from './SparkPrizeCalloutMessage/SparkPrizeCalloutMessage';

import './SparkPrizesFormFields.scss';

const defaultSparkPrize = {
    threshold: 0,
    award: { award: '' },
};

export const checkForGoalPrizes = (type: string, prizes: TSparkPrize[]): boolean => {
    if (type === 'goal' && prizes.length === 0) {
        toast.error('Number of goals must be selected');
        return false;
    }

    return true;
};

export const updatePrizeTableRowAward =
    ({
        isFulfilledBySparkplug,
        i,
        onPrizeChanged,
    }: {
        isFulfilledBySparkplug: boolean;
        i: number;
        onPrizeChanged: (i: number, key: 'award' | 'claimInstructions', value: string) => void;
    }) =>
    (key: 'award' | 'claimInstructions', value: string) => {
        onPrizeChanged(
            i,
            key,
            isFulfilledBySparkplug && value ? formatNumberToCurrency(value) : value,
        );
    };

function onPrizeTierChanged(
    prizeTier: string,
    setPrizes: Dispatch<SetStateAction<TSparkPrize[]>>,
    setFulfillmentTypes: Dispatch<SetStateAction<SparkFulfillmentType[]>>,
    setIsUnlimited: Dispatch<SetStateAction<boolean>>,
) {
    const isUnlimited = prizeTier === 'unlimited';
    const length = isUnlimited ? 1 : parseInt(prizeTier, 10);

    setIsUnlimited(isUnlimited);

    setPrizes((prevState) => {
        const stateChange = [...prevState];

        while (stateChange.length < length) {
            stateChange.push({ ...defaultSparkPrize });
        }

        stateChange.length = length;

        return [...stateChange];
    });

    setFulfillmentTypes((prevState) => {
        const stateChange = [...prevState];

        while (stateChange.length < length) {
            stateChange.push('sparkplug');
        }

        stateChange.length = length;

        return [...stateChange];
    });
}

const fbsTooltipMessage = 'Rewards must be a cash value in order to be fulfilled by SparkPlug';
const FBSTableHead = () => (
    <div className="fsb-th">
        <span>Fulfilled by SparkPlug</span>
        <Table.Tooltip title={fbsTooltipMessage}>
            <Info />
        </Table.Tooltip>
    </div>
);

const getHeadCells = (type: string, metric: string, hasNFBS: boolean) => {
    const defaultLabel = (i: number) => (type === 'goal' ? GoalLabels[i] : PrizeLabels[i]);

    let thresholdEndIcon: string | undefined;

    const isPercentOfTotalSales = metric === 'percent_of_total_sales';
    const thresholdMax = isPercentOfTotalSales ? 100 : undefined;
    if (metric === 'total_units') {
        thresholdEndIcon = 'units';
    }

    if (isPercentOfTotalSales) {
        thresholdEndIcon = '%';
    }

    const headCells: THeadCell<PrizeRow>[] = [
        {
            id: type,
            label: type === 'goal' ? 'Goal' : 'Place',
            render: (row) => (
                <Table.Cell className="max-sm:text-nowrap">
                    {row.isUnlimited ? 'Unlimited Goals' : defaultLabel(row.i)}
                </Table.Cell>
            ),
        },
        {
            id: 'fulfillmentType',
            component: FBSTableHead,
            render: (row) => {
                return (
                    <Table.Cell>
                        <Form.Switch
                            className="max-sm:[&_.MuiFormControlLabel-label]:text-sm"
                            label={row.fulfillmentType === 'sparkplug' ? 'Yes' : 'No'}
                            name={`fbs-${row.i}`}
                            value={row.fulfillmentType === 'sparkplug'}
                            onChange={() => {
                                const newIsSparkplug = row.fulfillmentType !== 'sparkplug';
                                return row.onFulfillmentTypesChanged(row.i, newIsSparkplug);
                            }}
                        />
                    </Table.Cell>
                );
            },
        },
        ...(type === 'goal'
            ? [
                  {
                      id: 'threshold',
                      label: 'Threshold*',
                      render: (row) => (
                          <Table.Cell className="goal-threshold">
                              <Form.TextField
                                  required
                                  endIcon={thresholdEndIcon}
                                  name={`threshold-${row.i}`}
                                  className={metric === 'total_sales' ? 'money-textfield' : ''}
                                  min={0}
                                  max={thresholdMax}
                                  placeholder="0"
                                  type="number"
                                  /*
                                This must be an uncontrolled field to disable the
                                field from being deselected on keystrokes. Do not
                                change to `value`. The `checkValidation` function
                                will make sure this is valid (greater than zero)
                              */
                                  defaultValue={+row.threshold > 0 ? row.threshold : undefined}
                                  onChange={(event) => {
                                      row.onPrizeChanged(row.i, 'threshold', event.target.value);
                                  }}
                                  onValidationError={(stringValue?: string) => {
                                      try {
                                          const value = Number(stringValue);

                                          const isGreaterThanZero = value > 0;
                                          const isBelowMax = thresholdMax
                                              ? value <= thresholdMax
                                              : true;

                                          if (!isGreaterThanZero) {
                                              toast.error('Threshold must be greater than zero');
                                              return;
                                          }

                                          if (!isBelowMax) {
                                              toast.error(
                                                  `Threshold cannot be greater than "${thresholdMax}"`,
                                              );
                                              return;
                                          }
                                      } catch {
                                          toast.error('Threshold is invalid');
                                      }
                                  }}
                              />
                          </Table.Cell>
                      ),
                  } as THeadCell<PrizeRow>,
              ]
            : []),
        {
            id: 'reward',
            label: () => {
                return hasNFBS ? (
                    <div className="reward-title">
                        <div className="reward-inner max-sm:!min-w-[200px]">Reward*</div>
                        <div>Claim Instructions*</div>
                    </div>
                ) : (
                    <div>Reward*</div>
                );
            },
            render: (row) => {
                return (
                    <Table.Cell className="reward-cell">
                        <div className="reward-column">
                            <Form.TextField
                                isMoneyField
                                required
                                placeholder={
                                    row.fulfillmentType !== 'sparkplug'
                                        ? 'Describe the reward...'
                                        : '0.00'
                                }
                                moneyFieldIsActive={row.fulfillmentType === 'sparkplug'}
                                moneyFieldErrorMessage="Rewards Fulfilled by SparkPlug must be a cash value. Toggle off on the left to enter any type reward."
                                className="reward max-sm:!min-w-[200px]"
                                name={`prize-${row.i}`}
                                defaultValue={row.award.award}
                                onChange={(event) => {
                                    updatePrizeTableRowAward({
                                        i: row.i,
                                        isFulfilledBySparkplug: row.fulfillmentType === 'sparkplug',
                                        onPrizeChanged: row.onPrizeChanged,
                                    })('award', event.target.value);
                                }}
                            />
                            {row.fulfillmentType !== 'sparkplug' && (
                                <Form.TextField
                                    required
                                    className="reward-details max-sm:!min-w-[300px]"
                                    placeholder="Describe to the winner how they should redeem their prize..."
                                    name={`prize-details-${row.i}`}
                                    defaultValue={row.award.claimInstructions}
                                    onChange={(event) => {
                                        updatePrizeTableRowAward({
                                            i: row.i,
                                            isFulfilledBySparkplug:
                                                row.fulfillmentType === 'sparkplug',
                                            onPrizeChanged: row.onPrizeChanged,
                                        })('claimInstructions', event.target.value);
                                    }}
                                />
                            )}
                        </div>
                    </Table.Cell>
                );
            },
        },
    ];

    return headCells;
};
type PrizeRow = ReturnType<typeof buildPrizeRow>;

const unlimitedIndividualGoalMessage =
    'Everytime an employee hits the threshold the corresponding reward amount will be applied. There is no cap on how much an employee can earn.';
const unlimitedTeamGoalMessage =
    'Every time the selected location(s) collectively hit the threshold, the corresponding reward amount and reward method will be applied. There is no cap on how much a team or individual employee can earn.';

type TSparkPrize = {
    threshold: number;
    award: SparkAward;
    fulfilledBySparkplug?: boolean;
    isUnlimited?: boolean;
};

const buildPrizeRow = (
    prize: TSparkPrize,
    i: number,
    isUnlimited: boolean,
    fulfillmentTypes: SparkFulfillmentType[],
    onPrizeChanged: (
        i: number,
        key: 'award' | 'threshold' | 'claimInstructions',
        value: string,
        claimInstructions?: string,
    ) => void,
    onFulfillmentTypesChanged: (i: number, value: boolean) => void,
) => ({
    fulfilledBySparkplug: prize.fulfilledBySparkplug,
    key: `${i}-${isUnlimited}`,
    i,

    threshold: (prize?.threshold || 0).toString(),
    award: prize?.award || { award: '' },
    fulfillmentType: fulfillmentTypes[i] || 'external',
    onPrizeChanged,
    onFulfillmentTypesChanged,
    isUnlimited,
    id: '',
    label: '',
});

const SparkPrizesFormFields = () => {
    const { spark, updateSpark, sparkSubGroups, isCreatingMultiRetailerSpark } = useSpark();
    const { type, goals, awards } = spark || {};

    const isTeamGoal = spark.type === 'goal' && spark.goalType === 'team';
    const rewardMethodRequired =
        ['leaderboardLocation', 'goalManager'].includes(spark.detailedSparkType ?? '') ||
        isTeamGoal;
    const [rewardMethod, setRewardMethod] = useState<RewardMethod | undefined>(() =>
        getInitialRewardMethodFromSpark(spark),
    );

    const { updateValidationFn, updateBackFn } = useModal();

    const restrictedWordsRef = useRef();

    const [prizes, setPrizes] = useState<TSparkPrize[]>(() => {
        const initialPrizes =
            type === 'leaderboard'
                ? awards.map((award) => ({
                      threshold: 0,
                      award,
                  })) || []
                : goals;
        const defaultPrizeState =
            type === 'leaderboard' ? [{ threshold: 0, award: { award: '' } }] : [];
        return initialPrizes.length > 0 ? initialPrizes : defaultPrizeState;
    });

    const [isUnlimited, setIsUnlimited] = useState(spark?.goals?.[0]?.isUnlimited || false);

    const [fulfillmentTypes, setFulfillmentTypes] = useState<SparkFulfillmentType[]>(() => {
        return spark?.fulfillmentTypes || prizes.map(() => 'sparkplug');
    });

    const hasNonFBS = fulfillmentTypes.some((value) => value === 'external');
    const updateSparkData = () => {
        const splitPayout = rewardMethod ? rewardMethod === 'split' : undefined;

        if (type === 'leaderboard') {
            updateSpark({
                awards: prizes.map(({ award }) => award),
                goals: [],
                fulfillmentTypes,
                splitPayout,
            });
        }

        if (type === 'goal') {
            const newSparkGoals = [...prizes];

            // Before saving unlimited goal, add `isUnlimited` to object
            newSparkGoals[0].isUnlimited = isUnlimited ? true : undefined;

            updateSpark({
                awards: [],
                goals: newSparkGoals,
                fulfillmentTypes,
                splitPayout,
            });
        }
        return true;
    };
    const checkValidation = useCallback(() => {
        const validation = [
            () => checkForGoalPrizes(spark.type, prizes),
            () => {
                const hasValidAwards = prizes.every((goal) => !!(goal.award.award ?? '').trim());

                if (!hasValidAwards) {
                    toast.error('All rewards are required');
                }

                return hasValidAwards;
            },
            () => {
                if (type === 'goal') {
                    const isValidThreshold = prizes.every((goal, i) => {
                        const nextGoal = prizes[i + 1];
                        const threshold = goal.threshold;
                        const nextThreshold = nextGoal
                            ? nextGoal.threshold
                            : Number.MAX_SAFE_INTEGER;
                        return threshold > 0 && threshold < nextThreshold;
                    });

                    if (!isValidThreshold) {
                        toast.error('Thresholds must be in ascending order');
                    }

                    return isValidThreshold;
                }

                return true;
            },
            () => {
                if (rewardMethodRequired) {
                    if (!['split', 'individual'].includes(rewardMethod ?? '')) {
                        toast.error('Select a reward method before proceeding');
                        return false;
                    }

                    return true;
                }

                return true;
            },
            () => {
                const allAwards = prizes.map(({ award }) => award).join('|');
                return containsRestrictedWordsByRef(restrictedWordsRef, allAwards);
            },
        ];

        const allValid = validation.every((fn) => fn());

        if (allValid) {
            updateSparkData();
        }

        return allValid;
    }, [isUnlimited, prizes, fulfillmentTypes, rewardMethod]);

    const onPrizeChanged = (
        i: number,
        key: 'award' | 'threshold' | 'claimInstructions',
        value?: string,
    ) => {
        setPrizes((prevState) => {
            const stateChange = [...prevState];
            const objectKey = key === 'claimInstructions' ? 'award' : key;

            stateChange[i] = {
                ...stateChange[i],
                [objectKey]:
                    key === 'threshold' && value
                        ? Math.floor(parseFloat(value))
                        : {
                              ...stateChange[i].award,
                              ...(key === 'award' && { award: value }),
                              ...(key === 'claimInstructions' && { claimInstructions: value }),
                          },
            };

            return [...stateChange];
        });
    };

    const onFulfillmentTypesChanged = (i: number, value: boolean) => {
        setFulfillmentTypes((prevState) => {
            const stateChange = [...prevState];

            stateChange[i] = value ? 'sparkplug' : 'external';

            return [...stateChange];
        });
        if (!stringIsCurrency(prizes[i].award.award)) {
            onPrizeChanged(i, 'award', '');
        }
        if (value) {
            onPrizeChanged(i, 'claimInstructions', '');
        }
    };

    useEffect(() => {
        updateBackFn(updateSparkData);

        updateValidationFn(checkValidation);
    }, [checkValidation]);

    const prizeTableData = useMemo(
        () =>
            prizes.map((prize, i) =>
                buildPrizeRow(
                    prize,
                    i,
                    isUnlimited,
                    fulfillmentTypes,
                    onPrizeChanged,
                    onFulfillmentTypesChanged,
                ),
            ),
        [prizes, fulfillmentTypes],
    );

    const headCells = useMemo(
        () => getHeadCells(type, spark.metric, hasNonFBS),
        [type, spark.metric, hasNonFBS],
    );

    /**
     * This is a hotfix until we find an appropriate refactor for handling
     * the threshold field losing focus on inputting a character
     * */
    const memoizedTable = useMemo(
        () => (
            <Table
                showPagination={false}
                variant="flat"
                rows={prizeTableData}
                headCells={headCells}
            >
                <Table.RenderHead />

                {!prizes.length && spark.type === 'goal' ? (
                    <Table.Body>
                        <tr>
                            <td colSpan={4} className="select-goals">
                                Choose a <strong>Number of Goals</strong> from above to start
                                entering rewards
                            </td>
                        </tr>
                    </Table.Body>
                ) : (
                    <Table.RenderBody />
                )}
            </Table>
        ),
        [prizes.length, fulfillmentTypes],
    );

    return (
        <div className="form-container prizes-container">
            {isCreatingMultiRetailerSpark ? (
                <>
                    <MultiRetailerSparkPrizeCalloutMessage />
                </>
            ) : (
                <SparkPrizeCalloutMessage spark={spark} sparkSubGroups={sparkSubGroups} />
            )}

            {rewardMethodRequired && (
                <RewardMethodRadioGroup
                    sparkType={spark.type}
                    detailedSparkType={spark.detailedSparkType}
                    rewardMethod={rewardMethod}
                    onRewardMethodChange={(v) => setRewardMethod(v)}
                />
            )}

            <Form.Select
                className="prize-count-selector"
                name="prize-count"
                requiredWithExternalValidation
                label={type === 'leaderboard' ? 'Number of Winners' : 'Number of Goals'}
                value={isUnlimited ? 'unlimited' : prizes.length || ''}
                onChange={(event) =>
                    onPrizeTierChanged(
                        event.target.value,
                        setPrizes,
                        setFulfillmentTypes,
                        setIsUnlimited,
                    )
                }
                options={PrizeOptions[type] ?? []}
            />

            <Paper className="prizes-list-container" variant="outlined">
                {hasNonFBS && (
                    <CalloutMessage
                        className="non-fbs-callout max-sm:text-sm max-sm:[&>svg]:h-[14px] max-sm:[&>svg]:w-[14px"
                        color="yellow"
                        variant="filled"
                        message={
                            <span>
                                <strong>Just a heads up!</strong> There are non-cash rewards on this
                                Spark that <strong>you</strong> will be responsible for fulfilling!
                                Claim instructions will only be visible to winners.
                            </span>
                        }
                    />
                )}

                {memoizedTable}
            </Paper>

            {spark?.type === 'leaderboard' && (
                <div className="min-threshold-container max-sm:pb-3 max-sm:flex-col">
                    <MinThresholdField />
                    <MinTransactionField />
                </div>
            )}

            {isUnlimited && (
                <Form.Description>
                    <p className="unlimited-goal-message">
                        {spark.goalType === 'team'
                            ? unlimitedTeamGoalMessage
                            : unlimitedIndividualGoalMessage}
                    </p>
                </Form.Description>
            )}

            <RestrictedWordsModal ref={restrictedWordsRef} />
        </div>
    );
};

export default SparkPrizesFormFields;
