import { FC, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import { useGetQuestionResponseSummary } from '@features/courses/mutations/useGetQuestionResponseSummary';

import { SparklesIcon } from '@components/icons';
import SearchField from '@components/inputs/SearchField';

import { Progress } from '@app/componentLibrary/Progress';
import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

export const RatingResponses = ({ responses }: { responses: any[] }) => {
    const groupedAnswers = _.groupBy(responses, 'answer');
    const totalResponses = responses.length;

    return (
        <div className="space-y-3">
            {[1, 2, 3, 4, 5].map((rating) => {
                const count = groupedAnswers[rating]?.length || 0;
                const percentage = totalResponses ? Math.round((count / totalResponses) * 100) : 0;
                return (
                    <div key={rating} className="flex items-center gap-4">
                        <div>{rating} Stars:</div>
                        <Progress value={percentage} max={100} />
                        <div className="text-sm text-gray-600">
                            {count} ({percentage}%)
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const CommentResponses = ({
    responses,
    learningResourceId,
    questionId,
}: {
    responses: any[];
    learningResourceId: string;
    questionId: string;
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [summary, setSummary] = useState('');
    const [isSummarizing, setIsSummarizing] = useState(false);
    const { getQuestionResponseSummary } = useGetQuestionResponseSummary();

    const handleSummarize = async () => {
        setIsSummarizing(true);
        try {
            const summaryResponse = await getQuestionResponseSummary({
                learningResourceId,
                questionId,
            });
            setSummary(summaryResponse);
        } finally {
            setIsSummarizing(false);
        }
    };

    const filteredResponses = responses.filter((r) => {
        const ans = r.answer?.toString().toLowerCase();
        return (
            ans &&
            (ans.includes(searchTerm?.toLowerCase()) ||
                r.userName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                r.retailerName?.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    return (
        <div className="space-y-4">
            <div className="flex gap-2 w-full bg-purple-0 py-3 px-4 rounded-md">
                <div>
                    <SparklesIcon className="w-4 h-4 [&_path]:fill-purple-700" />
                </div>
                <div className={cn('flex flex-col', summary ? 'gap-1' : 'gap-3')}>
                    <div className="flex flex-col">
                        <Typography variant="lg" className="font-semibold">
                            AI Summary
                        </Typography>
                        {!summary && (
                            <Typography variant="base">
                                Use AI to summarize your text responses (currently in beta)
                            </Typography>
                        )}
                    </div>
                    {summary ? (
                        <Typography variant="base">{summary}</Typography>
                    ) : (
                        <button
                            type="button"
                            className={cn(
                                'w-fit border-purple-300 rounded border border-solid text-purple-700 py-[10px] px-4 cursor-pointer bg-transparent transition-all duration-300 font-bold',
                                isSummarizing && 'opacity-50 cursor-not-allowed',
                            )}
                            onClick={handleSummarize}
                            disabled={isSummarizing}
                        >
                            {isSummarizing ? 'Summarizing...' : 'Summarize'}
                        </button>
                    )}
                </div>
            </div>
            <div className="mb-4">
                <SearchField
                    name="search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                {searchTerm && (
                    <span className="text-sm text-gray-600">
                        {filteredResponses.length} responses found
                    </span>
                )}
            </div>

            {filteredResponses.map((response: any, index: number) => (
                <div key={index} className="pb-2 shadow-gray-4dp p-4 rounded">
                    <div
                        className="text-sm text-gray-600 capitalize"
                        title={moment(response.updatedAt).format('MMM DD, YYYY hh:mm a')}
                    >
                        {moment(response.updatedAt).fromNow(false)}
                    </div>

                    <Typography className="font-semibold mb-3">{response.answer}</Typography>
                    <div className="flex items-center gap-2">
                        <Typography className="text-sm font-medium text-gray-600">
                            <span className="text-gray-950"> {response.userName}</span> at{' '}
                            {response.retailerName}
                        </Typography>
                    </div>
                </div>
            ))}

            {filteredResponses.length === 0 && (
                <div className="text-gray-500 text-center py-4">No matching responses found</div>
            )}
        </div>
    );
};

export const MultipleChoiceResponses = ({
    responses,
    choices = [],
}: {
    responses: any[];
    choices?: Array<{ value: string; text: string }>;
}) => {
    const totalResponses = responses.length;
    const valueToText = _.keyBy(choices, 'value');
    const optionCounts = _.countBy(
        responses
            .filter((response) => response.answer !== 'other')
            .map((response) => valueToText[response.answer]?.text || response.answer)
            .filter(Boolean),
    );

    return (
        <div className="space-y-3 shadow-md p-6 rounded-md">
            {Object.entries(optionCounts).map(([option, count]) => {
                const percentage = Math.round((count / totalResponses) * 100);
                return (
                    <div key={option} className="flex items-center gap-4">
                        <div className="w-32 truncate">{option}:</div>
                        <Progress value={percentage} max={100} />

                        <div className="w-20 text-sm text-gray-600">
                            {count} ({percentage}%)
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const BooleanResponses = ({ responses }: { responses: any[] }) => {
    const groupedAnswers = _.groupBy(responses, 'answer');
    const totalResponses = responses.length;

    return (
        <div className="space-y-3">
            {['true', 'false'].map((answer) => {
                const count = groupedAnswers[answer]?.length || 0;
                const percentage = totalResponses ? Math.round((count / totalResponses) * 100) : 0;
                const label = answer === 'true' ? 'Yes' : 'No';

                return (
                    <div key={answer} className="flex items-center gap-4">
                        <div className="w-16">{label}:</div>
                        <Progress value={percentage} max={100} />
                        <div className="w-20 text-sm text-gray-600">
                            {count} ({percentage}%)
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const CheckboxResponses = ({
    responses,
    choices = [],
}: {
    responses: any[];
    choices?: Array<{ value: string; text: string }>;
}) => {
    const totalResponses = responses.length;
    const valueToText = _.keyBy(choices, 'value');
    const otherResponses = responses
        .filter((response) => Array.isArray(response.answer) && response.answer.includes('other'))
        .map((response) => ({
            userName: response.userName,
            otherValue: response.otherAnswer || 'No value provided',
        }));

    const optionCounts = _.countBy(
        responses.flatMap((response) =>
            Array.isArray(response.answer)
                ? response.answer
                      .filter((answer: string) => answer !== 'other')
                      .map((answer: string) => valueToText[answer]?.text || answer)
                : [],
        ),
    );

    return (
        <div className="space-y-6">
            <div className="space-y-3">
                {Object.entries(optionCounts).map(([option, count]) => {
                    const percentage = Math.round((count / totalResponses) * 100);
                    return (
                        <div key={option} className="flex items-center gap-4">
                            <div className="w-32 truncate">{option}:</div>
                            <progress value={percentage} max={100} />
                            <div className="w-32 text-sm text-gray-600">
                                {count} of {totalResponses} ({percentage}%)
                            </div>
                        </div>
                    );
                })}
            </div>

            {otherResponses.length > 0 && (
                <div className="border-t pt-4">
                    <div className="font-medium mb-2">
                        Other Responses ({otherResponses.length})
                    </div>
                    <div className="space-y-2">
                        {otherResponses.map((response, index) => (
                            <div key={index} className="text-sm">
                                <span className="text-gray-600">{response.userName}:</span>{' '}
                                {response.otherValue}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export const UnknownResponseType: FC<{ responses: any[] }> = ({ responses }) => (
    <div className="p-4 border rounded bg-gray-50">
        <Typography className="text-gray-600">
            {responses.length} response{responses.length !== 1 ? 's' : ''} (Unsupported format)
        </Typography>
    </div>
);

export const responseRenderers: Record<
    string,
    FC<{
        responses: any[];
        choices?: Array<{ value: string; text: string }>;
        learningResourceId: string;
        questionId: string;
    }>
> = {
    rating: RatingResponses,
    comment: CommentResponses,
    text: CommentResponses,
    radiogroup: MultipleChoiceResponses,
    checkbox: CheckboxResponses,
    boolean: BooleanResponses,
    default: UnknownResponseType,
};
