import { z } from 'zod';

export const EVENT_REQUEST_STATUSES = ['approved', 'declined', 'pending'] as const;
const EventRequestStatusEnum = z.enum(EVENT_REQUEST_STATUSES);
export const EVENT_REQUEST_STATUSES_TABLE = ['approved', 'pending', 'expired', 'declined'] as const;
const EventRequestStatusTableEnum = z.enum(EVENT_REQUEST_STATUSES_TABLE);
export const EVENT_REQUEST_SCHEDULED_STATUSES = ['active', 'upcoming', 'complete'] as const;
const EventRequestScheduledStatusEnum = z.enum(EVENT_REQUEST_SCHEDULED_STATUSES);
export const EVENT_TYPES = ['promo', 'training', 'popup', 'other'] as const;
const EventTypeEnum = z.enum(EVENT_TYPES);
export const EVENT_COUNT_VARIANTS = ['all', 'inbox'] as const;
const EventCountVariantEnum = z.enum(EVENT_COUNT_VARIANTS);
export type EventRequestStatus = z.infer<typeof EventRequestStatusEnum>;
export type EventRequestStatusTable = z.infer<typeof EventRequestStatusTableEnum>;
export type EventRequestScheduledStatus = z.infer<typeof EventRequestScheduledStatusEnum>;
export type EventType = z.infer<typeof EventTypeEnum>;
export type EventCountVariant = z.infer<typeof EventCountVariantEnum>;

export interface UIEvent {
  _id: string;
  startTime: string;
  endTime: string;
  totalRetailerLocationCount: Number;
  vendorAccount?: {
    _id: string;
    name: string;
  };
  retailerAccount: {
    _id: string;
    name: string;
    photo: string;
  };
  brand?: {
    _id: string;
    name: string;
    photo: string;
  };
  locations: {
    _id: string;
    name: string;
    timeZone: string;
  }[];
  requestStatus?: typeof EVENT_REQUEST_STATUSES[number];
  type: typeof EVENT_TYPES[number];
  isExpired?: boolean;
  name: string;
  description?: string;
  requestNote?: string;
  createdAt: Date;
  updatedAt: Date;
  quickApprovalCode?: string;
}

type SortableKeys<T> = {
  [K in keyof T]: T[K] extends string | number | Date ? K : never;
}[keyof T];

export const SORTABLE_EVENT_FIELDS = ['createdAt', 'startTime', 'endTime', 'name'] as const;
const SortableEventFieldsEnum = z.enum(SORTABLE_EVENT_FIELDS);
export type SortableEventFields = typeof SORTABLE_EVENT_FIELDS[number] & SortableKeys<UIEvent>;

export const listEventsQueryParamsSchema = z.object({
  accountId: z.string(),
  brandId: z.string().optional(),
  requestStatus: z.preprocess(
    (val) => (typeof val === 'string' ? val.split(',') : val),
    z.array(EventRequestStatusTableEnum).optional(),
  ),
  eventScheduleStatus: z.preprocess(
    (val) => (typeof val === 'string' ? val.split(',') : val),
    z.array(EventRequestScheduledStatusEnum).optional(),
  ),
  type: z.preprocess(
    (val) => (typeof val === 'string' ? val.split(',') : val),
    z.array(EventTypeEnum).optional(),
  ),
  limit: z
    .preprocess((val) => (val ? Number(val) : undefined), z.number().positive().optional())
    .default(10),
  offset: z
    .preprocess((val) => (val ? Number(val) : undefined), z.number().nonnegative().optional())
    .default(0),
  orderBy: SortableEventFieldsEnum.optional(),
  order: z.enum(['asc', 'desc']).optional(),
  search: z.string().optional(),
});

export type ListEventsQueryParams = z.infer<typeof listEventsQueryParamsSchema>;

export const listEventsBodySchema = z.object({
  locationIds: z.array(z.string()).optional(),
});
export type ListEventsRequestBody = z.infer<typeof listEventsBodySchema>;

export const upsertEventBodySchema = z.object({
  _id: z.string().optional(),
  type: EventTypeEnum.optional(), // required if _id is not provided
  startTime: z.string().optional(), // required if _id is not provided
  endTime: z.string().optional(), // required if _id is not provided
  vendorAccountId: z.string().optional(),
  retailerAccountId: z.string().optional(), // required if _id is not provided
  brandId: z.string().optional(),
  locationIds: z.array(z.string()).optional(), // required if _id is not provided
  name: z.string().optional(), // required if _id is not provided
  description: z.string().optional(),
  requestNote: z.string().optional(),
});

// TODO: https://linear.app/sparkplug/issue/X-1483/secure-events-routes
// export const upsertRetailEventBodySchema = z.object({
//   _id: z.string().optional(),
//   type: EventTypeEnum,
//   startTime: z.string().min(1),
//   endTime: z.string().min(1),
//   locationIds: z.array(z.string().min(1)).min(1),
//   retailerAccountId: z.string().min(1),
//   name: z.string().min(1).max(32),
//   description: z.string().max(120),
// });
// export type UpsertRetailEventRequestBody = z.infer<typeof upsertRetailEventBodySchema>;

// TODO: https://linear.app/sparkplug/issue/X-1483/secure-events-routes
// export const upsertVendorEventBodySchema = z.object({
//   _id: z.string().optional(),
//   type: EventTypeEnum,
//   startTime: z.string().min(1),
//   endTime: z.string().min(1),
//   vendorAccountId: z.string().min(1),
//   retailerAccountId: z.string().min(1),
//   brandId: z.string().min(1),
//   locationIds: z.array(z.string().min(1)).min(1),
//   name: z.string().min(1).max(32),
//   description: z.string().max(120),
//   requestNote: z.string().max(180).optional(),
// });
// export type UpsertVendorEventRequestBody = z.infer<typeof upsertVendorEventBodySchema>;

export type UpsertEventRequestBody = z.infer<typeof upsertEventBodySchema>;

export const getEventCountQueryParamsSchema = z.object({
  accountId: z.string(),
  variant: EventCountVariantEnum,
});

export type GetEventCountQueryParams = z.infer<typeof getEventCountQueryParamsSchema>;
