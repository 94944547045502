import { useEffect, useMemo, useState } from 'react';

import FullscreenModal from '@components/fullscreen-modal/fullscreen-modal';
import ImageCarousel from '@components/image-carousel/image-carousel';

import Typography from '@app/componentLibrary/Typography';

type EmployeePreviewPages = 'sparks' | 'snaps' | 'events' | 'courses';

const EmployeePreviewData: Record<
    EmployeePreviewPages,
    { images: string[]; title: string; description: string[] }
> = {
    sparks: {
        images: [
            '/static/images/preview-sparks/Preview-Sparks_Active.png',
            '/static/images/preview-sparks/Preview-Sparks_Upcoming.png',
            '/static/images/preview-sparks/Preview-Sparks_Complete.png',
            '/static/images/preview-sparks/Preview-Sparks_Details.png',
            '/static/images/preview-sparks/Preview-Sparks_Products.png',
            '/static/images/preview-sparks/Preview-Sparks_Leaderboard.png',
            '/static/images/preview-sparks/Preview-Sparks_SMS.png',
        ],
        title: 'How do Sparks work for employees?',
        description: [
            `Sparks are gamified incentives for employees in the form of leaderboards, commissions and goals. Sparks display directly on the employee homepage where employees can view their standings in real-time and stay motivated throughout the incentive period.`,
            `Employees receive helpful SMS reminders when a Spark begins, along with regular updates on active Sparks. These messages direct them to log in to view detailed information about the incentive, including qualifying products and their standings compared to other participants.`,
        ],
    },
    snaps: {
        images: [
            '/static/images/preview-snaps/Preview-Snaps_Featured.png',
            '/static/images/preview-snaps/Preview-Snaps_Spark.png',
            '/static/images/preview-snaps/Preview-Snaps_Open.png',
        ],
        title: 'How do Snaps work for employees?',
        description: [
            `Snaps aims to boost product knowledge, increase sales, and strengthen the connection between brands, retailers, and their employees. Similar to popular social media stories, Snaps allows brands and retailers to create and share bite-sized, interactive content directly with frontline employees. Whether it's showcasing new products or soliciting feedback, Snaps offers a way to keep employees informed and motivated. `,
            `With two types of Snaps - Spark Snaps and Featured Snaps, you can tailor your audience. Spark Snaps are viewable by Spark participants only, and Featured Snaps can be targeted by market. This allows you to reach the right audience at the right time.`,
        ],
    },
    events: {
        images: [
            '/static/images/preview-events/Preview-Events_Agenda.png',
            '/static/images/preview-events/Preview-Events_Details.png',
            '/static/images/preview-events/Preview-Events_SMS.png',
        ],
        title: 'How do Events work for employees?',
        description: [
            `Events keep employees in the loop with scheduled brand initiatives like pop-ups, promotions and trainings. They’re displayed directly to employees on their home page under the Events tab using a simple agenda view with all the essential info—event name, type, brand details, date, time and location. Employees can click on the event to see more details in the description.`,
            `Employees also receive helpful SMS reminders on the days events are going down. As an additional measure, if an event is pending approval by a store manager, employees can still view it and help move the approval process along.`,
        ],
    },
    courses: {
        images: [
            '/static/images/preview-courses/Preview-Courses_Available.png',
            '/static/images/preview-courses/Preview-Courses_Details.png',
        ],
        title: 'How do Courses work for employees?',
        description: [
            `Courses keep your team informed, trained, and ready to sell. Once you publish a course, it appears front and center on the homepage under the Earn tab, where employees can see the brand, course title, and potential rewards. This makes it easy for them to dive in, sharpen their knowledge, and drive more sales for you.`,
            `Each course is fully customizable to reflect your brand's unique vibe—add your colors, images, videos, and anything else to make it truly yours.`,
            `When an employee completes a course and hits the passing threshold, they'll earn a reward. This reward is automatically added to their cash balance in their wallet. Turn all employees into your brand advocates through enhancing product knowledge! `,
        ],
    },
};

const useEmployeePreview = ({ page }: { page: EmployeePreviewPages }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { title, description, images } = EmployeePreviewData[page];

    useEffect(() => {
        images.forEach((imageSrc) => {
            const img = new Image();
            img.src = imageSrc;
        });
    }, [images]);

    const previewModal = useMemo(
        () => (
            <FullscreenModal
                isVisible={isModalVisible}
                left={<ImageCarousel images={images} />}
                right={
                    <div className="flex flex-col gap-4 sm:p-28 max-sm:p-8 h-full justify-center">
                        <Typography variant="2xl" className="text-gray-900 font-bold">
                            {title}
                        </Typography>
                        <Typography variant="lg" className="text-gray-900">
                            {description.map((desc, index) => (
                                <div key={index} className="mb-4">
                                    {desc}
                                </div>
                            ))}
                        </Typography>
                    </div>
                }
                title="Employee Preview"
                onClose={() => setIsModalVisible(false)}
            />
        ),
        [isModalVisible, images, title, description],
    );

    const showPreviewModal = () => {
        setIsModalVisible(true);
    };

    return {
        showPreviewModal,
        previewModal,
        isEmployeePreviewEnabled: import.meta.env.REACT_APP_EMPLOYEE_PREVIEW === 'true',
    };
};

export default useEmployeePreview;
