import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';

import {
    ConfirmSmsLoginRequestBody,
    ConfirmSmsLoginResponseBody,
    FrillAuthDetailsQueryParams,
    FrillAuthDetailsResponseBody,
    LoginRequestBody,
    LoginResponseBody,
    PagedApiResponseBody,
    SmsLoginRequestBody,
    SmsLoginResponseBody,
} from '@sparkplug/lib';

import factory from '../log/Log';

const log = factory('API');

let instance: any = null;

const JwtTokenKey = 'sparkplug::jwtToken';

// Defaulting to `local` for cypress
const { REACT_APP_API = 'local' } = import.meta.env;

axios.defaults.baseURL =
    REACT_APP_API === 'local'
        ? 'http://localhost:8881'
        : `https://api-server-${REACT_APP_API}.sparkplug-technology.io`;

/* eslint-disable no-param-reassign */
axios.interceptors.request.use((config) => {
    const jwtToken = localStorage.getItem(JwtTokenKey);
    if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
    }

    return config;
});

export default class API {
    observers: any;

    constructor() {
        this.observers = new Map();
    }

    static get(): API {
        if (instance == null) {
            instance = new API();
        }
        return instance;
    }

    static get isAxiosError() {
        return axios.isAxiosError;
    }

    getServerUrl() {
        // Defaulting to `local` for cypress
        const { REACT_APP_API: api = 'local' } = import.meta.env;
        return api === 'local'
            ? 'http://localhost:8881'
            : `https://api-server-${api}.sparkplug-technology.io`;
    }

    isAuthenticated() {
        const jwtToken = localStorage.getItem(JwtTokenKey);

        return jwtToken != null && jwtToken !== '';
    }

    getOptions(method: Method, path: string, data?: any, headers?: { [key: string]: string }) {
        return this.buildAxiosRequest({ method, path, data, headers });
    }

    static async fetchBatchedData(
        fetchFn: (offset: number, limit: number) => Promise<PagedApiResponseBody<any>>,
    ) {
        let count = 0;
        let data: any[] = [];
        let limit = 1000;
        let offset = 0;

        const firstResponse = await fetchFn(offset, limit);
        data = firstResponse.data;
        limit = firstResponse.meta.limit;

        const subsequentPromises: any[] = [];
        const total = firstResponse?.meta?.total;
        offset += limit;

        // Create timeout to offset parallel async fetches
        const timeoutFn = (index: number, _offset: number, _limit: number) => {
            return new Promise((res, rej) => {
                setTimeout(() => {
                    fetchFn(_offset, _limit)
                        .then(({ data: batch }) => batch)
                        .then(res)
                        .catch(rej);
                }, 250 * index);
            });
        };

        while (offset < total) {
            subsequentPromises.push(timeoutFn(count, offset, total));

            count += 1;
            offset += limit;
        }

        const subsequentResponses = await Promise.all(subsequentPromises);
        data = data.concat(subsequentResponses.flat());

        return { data };
    }

    buildAxiosRequest(obj: {
        method: Method;
        path: string;
        data?: any;
        headers?: { [key: string]: string };
    }): AxiosRequestConfig {
        const jwtToken = localStorage.getItem(JwtTokenKey);
        const headers = {
            ...obj.headers,
            ...(jwtToken != null
                ? {
                      Authorization: `Bearer ${jwtToken}`,
                  }
                : {}),
        };

        return {
            method: obj.method,
            data: obj.data,
            headers,
            url: `${this.getServerUrl()}${obj.path}`,
        };
    }

    async authenticate(body: LoginRequestBody): Promise<string> {
        try {
            const response = await axios.post<LoginResponseBody>('/api/v1/authenticate', body);
            if (!response.data.token) {
                throw new Error('Token not returned');
            }
            localStorage.setItem(JwtTokenKey, response.data.token);
            return response.data.token;
        } catch (error: unknown) {
            log.e(`authenticate: ${error}`);

            if (API.isAxiosError(error)) {
                throw new Error(
                    (error as AxiosError<{ details: string }>).response?.data?.details ??
                        'Login failed',
                );
            } else {
                throw error;
            }
        }
    }

    async getFrillAuthDetails({ groupType }: FrillAuthDetailsQueryParams) {
        return (
            await axios.get<FrillAuthDetailsResponseBody>('/api/v1/authenticate/service/frill', {
                params: { groupType },
            })
        ).data;
    }

    async requestSmsCode(body: SmsLoginRequestBody): Promise<SmsLoginResponseBody> {
        try {
            return (await axios.post<SmsLoginResponseBody>(`/api/v1/authenticate/sms`, body)).data;
        } catch (error: unknown) {
            log.e(`authenticate: ${error}`);

            if (API.isAxiosError(error)) {
                throw new Error(
                    (error as AxiosError<{ details: string }>).response?.data?.details ??
                        'Login failed',
                );
            } else {
                throw error;
            }
        }
    }

    async verifySmsCode(body: ConfirmSmsLoginRequestBody): Promise<string> {
        try {
            const response = await axios.post<ConfirmSmsLoginResponseBody>(
                '/api/v1/authenticate/sms/confirm',
                body,
            );
            if (!response.data.token) {
                throw new Error('Token not returned');
            }
            localStorage.setItem(JwtTokenKey, response.data.token);
            return response.data.token;
        } catch (error: any) {
            log.e(`authenticate: ${error}`);

            const errorStatus = String(error?.response?.status) || '';
            if (errorStatus === '402') {
                throw new Error('This code has expired.');
            } else {
                throw new Error('This code is invalid');
            }
        }
    }
}
