import { HTMLAttributes, ReactNode, forwardRef } from 'react';

import Skeleton from '@components/layout/Skeleton';

import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

import InputLabel from '../InputLabel';

export interface InputGroupProps extends HTMLAttributes<HTMLElement> {
    children: ReactNode;
    label?: string;
    subtext?: string;
    error?: boolean;
    isLoading?: boolean;
    loadingStateClassName?: string;
}
export const InputGroup = forwardRef<HTMLElement, InputGroupProps>(
    (
        {
            children,
            className,
            label,
            subtext,
            error,
            isLoading,
            loadingStateClassName,
            ...htmlProps
        },
        ref,
    ) => {
        return (
            <section
                ref={ref}
                role="group"
                className={cn('flex flex-col gap-1', className)}
                {...htmlProps}
            >
                {label && <InputLabel error={error}>{label}</InputLabel>}
                {isLoading ? (
                    <Skeleton className={cn('h-8 w-full', loadingStateClassName)} />
                ) : (
                    <>
                        {children}
                        {subtext && (
                            <Typography variant="sm" className="text-gray-800">
                                {subtext}
                            </Typography>
                        )}
                    </>
                )}
            </section>
        );
    },
);

InputGroup.displayName = 'InputGroup';
