import { useMemo } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import AccountLoadingView from '@views/auth/AccountLoadingView';
import AppLoadingView from '@views/auth/AppLoadingView';
import UnauthorizedView from '@views/auth/UnauthorizedView';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { IAuthUser } from '@app/types/UsersTypes';

export type AccountCondition = (account?: IAccount) => boolean;
export type UserCondition = (user: IAuthUser, account?: IAccount) => boolean;

export const getNonAuthenticatedPath = (pathname: string, search?: string) => {
    const validRedirectPaths = ['sparks/requests', 'events'];
    if (validRedirectPaths.some((path) => pathname.includes(path))) {
        const redirectPath = encodeURIComponent(`${pathname}${search ?? ''}`);
        return `/login?redirectTo=${redirectPath}`;
    }
    return `/login`;
};

interface ConditionalRouteProps extends RouteProps {
    userConditions?: UserCondition[];
    accountConditions?: AccountCondition[];
    unauthorizedRedirect?: string;
}
const ConditionalRoute = ({
    userConditions = [],
    accountConditions = [],
    unauthorizedRedirect,
    ...props
}: ConditionalRouteProps) => {
    const { appIsReady, user, userLoadingStatus, logOut } = useApp();

    const { accountIsReady, account } = useSparkplugAccount();

    const location = useLocation();

    const Component = useMemo(() => {
        if (userLoadingStatus === 'error') {
            logOut().then(() => {
                return <Redirect to="/login" />;
            });
        }

        if (!appIsReady) {
            return <AppLoadingView />;
        }

        if (!user) {
            const unauthenticatedRedirect = getNonAuthenticatedPath(
                location.pathname,
                location.search,
            );
            return <Redirect to={unauthenticatedRedirect} />;
        }

        if (!accountIsReady) {
            return <AccountLoadingView />;
        }

        if (accountConditions.length) {
            if (!accountConditions.every((fn) => fn(account))) {
                return unauthorizedRedirect ? (
                    <Redirect to={unauthorizedRedirect} />
                ) : (
                    <UnauthorizedView />
                );
            }
        }

        if (userConditions.length) {
            if (user?.role !== 'super-admin') {
                if (!userConditions.every((fn) => fn(user, account))) {
                    return unauthorizedRedirect ? (
                        <Redirect to={unauthorizedRedirect} />
                    ) : (
                        <UnauthorizedView />
                    );
                }
            }
        }

        return <Route {...props} />;
    }, [
        appIsReady,
        user,
        accountIsReady,
        account,
        accountConditions,
        userConditions,
        userLoadingStatus,
    ]);

    return Component;
};

export default ConditionalRoute;
