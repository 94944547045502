import { FC, ReactElement, useMemo, useState } from 'react';

import MuiAvatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import pluralize from 'pluralize';

import {
    Spark,
    SparkFulfillmentType,
    SparkGoal,
    TChartDataMetric,
    calculateUnlimitedGoalProgress,
    formatFloat,
    isEligibleParticipant,
    percentOfGoal,
} from '@sparkplug/lib';

import { getFirstApplicableTransactionDate } from '@core/users';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import NonParticipantChip, {
    NonParticipantLeader,
    NonParticipantReason,
} from '@components/chips/NonParticipantChip';
import { ChevronRight as ForwardIcon } from '@components/icons';
import { Check, Info } from '@components/icons';
import Avatar from '@components/layout/Avatar';
import ClickableArea from '@components/layout/ClickableArea';
import FormattedMetricValue from '@components/layout/FormattedMetricValue';
import LazyLoad from '@components/layout/LazyLoad';
import PanelField from '@components/layout/PanelField';
import Tooltip from '@components/layout/Tooltip';
import ViewUserSparkTransactionsDrawer from '@components/overlays/ViewUserSparkTransactionsDrawer';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useRouteTabs, useSearch } from '@hooks/UIHooks';

import {
    currencyFormatterFactory,
    numberFormatterFactory,
    percentFormatterFactory,
} from '@helpers/chartFormatters';
import { getChartMetricLabel } from '@helpers/charts';
import { isPercentIncreaseSpark } from '@helpers/sparks';
import { appendClasses } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import { ChartLeader, TChartType } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import IndividualMissingCommissionCallout from './IndividualMissingCommissionCallout';
import { PercentIncreaseUserStats } from './PercentIncreaseUserStats';
import SmsEnrollmentInfoIcon from './SmsEnrollmentInfoIcon';

import './UserCharts.scss';

const ProgressLineBar = ({
    showValue,
    value,
    percent,
}: {
    showValue: boolean;
    value: string;
    percent: number;
}) => {
    return (
        <div className="progress-bar-and-value">
            {showValue ? (
                <div className="person-value">{value}</div>
            ) : (
                <div className="person-value">
                    <span className="masked">.</span>
                </div>
            )}
            <div className="progress-bar">
                <LinearProgress
                    classes={{
                        root: 'root',
                        colorPrimary: 'color-primary',
                        barColorPrimary: 'bar-color-primary',
                    }}
                    value={percent}
                    variant="determinate"
                />
            </div>
        </div>
    );
};

const ProgressLineIcon = ({
    isCheck = false,
    value,
}: {
    isCheck?: boolean;
    value?: string | number;
}) => {
    if (isCheck) {
        return (
            <div className="progress-mui check-circle">
                <MuiAvatar>
                    <Check />
                </MuiAvatar>
            </div>
        );
    }

    return (
        <div className="progress-mui number">
            <MuiAvatar>{value}</MuiAvatar>
        </div>
    );
};

const ProgressLine = ({
    goalNumber,
    value,
    showValue,
    barValue,
    barGoal,
    thisLevelGoal,
    formatter,
}: {
    goalNumber: number;
    value: any;
    showValue: boolean;
    barValue: number;
    barGoal: number;
    thisLevelGoal: number;
    formatter: (value: any) => string;
}) => {
    return (
        <>
            <ProgressLineBar
                showValue={showValue}
                value={formatter(value)}
                percent={percentOfGoal(barValue, barGoal)}
            />
            <ProgressLineIcon isCheck={value >= thisLevelGoal} value={goalNumber} />
        </>
    );
};

type TThresholdProgressBar = {
    value: number;
    showValue: boolean;
    barValue: number;
    barGoal: number;
    thisLevelGoal: number;
};

const calculateThresholdProgressBars = (value: number, thresholds: number[]) => {
    const progressBars: TThresholdProgressBar[] = [];

    for (let i = 0; i < thresholds.length; i += 1) {
        let previousLevelGoal = 0;
        const thisLevelGoal = thresholds[i];

        if (thresholds[i - 1] != null) {
            previousLevelGoal = thresholds[i - 1];
        }

        // determine whether value is shown with the progress bar
        let showValue = value === 0 && i === 0;
        if (value <= thisLevelGoal && value > previousLevelGoal) {
            showValue = true;
        }
        if (value >= thisLevelGoal && i === thresholds.length - 1) {
            showValue = true;
        }

        let barValue = value;
        let barGoal = thisLevelGoal;

        // set bar value for each progress bar
        if (value < thisLevelGoal && value <= previousLevelGoal) {
            // zero progress
            barValue = 0;
        } else if (value < thisLevelGoal && value > previousLevelGoal) {
            // partial progress
            // adjust scale
            barValue = value - previousLevelGoal;
            barGoal = thisLevelGoal - previousLevelGoal;
        } else if (value >= thisLevelGoal) {
            // full progress
            barValue = barGoal;
        }

        progressBars.push({
            value,
            showValue,
            barValue,
            barGoal,
            thisLevelGoal,
        });
    }

    return progressBars;
};

const SparkProgressIndicator = ({
    goals,
    fulfillmentTypes,
    metric,
    value,
}: {
    goals: SparkGoal[];
    fulfillmentTypes: SparkFulfillmentType[];
    metric: string;
    value: number;
}) => {
    let formatter: any;

    switch (metric) {
        case 'total_sales':
            formatter = currencyFormatterFactory(0);
            break;
        case 'total_units':
            formatter = numberFormatterFactory(0);
            break;
        case 'percent_of_total_sales':
            formatter = percentFormatterFactory(2);
            break;
        default:
            break;
    }

    const isUnlimitedThreshold = goals?.[0]?.isUnlimited || false;
    const thresholds = goals?.map(({ threshold }) => threshold);

    if (isUnlimitedThreshold) {
        const isFBS = fulfillmentTypes?.[0] === 'sparkplug';
        const { previousGoal, percentOfNextGoal, nextGoal, earned } =
            calculateUnlimitedGoalProgress(value, goals?.[0], isFBS);

        return (
            <>
                <div className="person-col progress">
                    <ProgressLineIcon value={previousGoal} />
                    <ProgressLineBar
                        showValue
                        value={formatter(value)}
                        percent={percentOfNextGoal}
                    />
                    <ProgressLineIcon value={nextGoal} />
                </div>
                <div className="person-col unlimited-goal-earned">
                    <div className="value-earned">{earned}</div>
                    <div>
                        <em>Earned</em>
                    </div>
                </div>
            </>
        );
    }

    const thresholdProgressBars = calculateThresholdProgressBars(value, thresholds);

    return (
        <div className="person-col progress">
            {thresholdProgressBars.map((thresholdProgressBarProps, i) => (
                <ProgressLine
                    key={i}
                    goalNumber={i + 1}
                    formatter={formatter}
                    {...thresholdProgressBarProps}
                />
            ))}
        </div>
    );
};

const NewMemberChip: FC<{ firstTransactionDate: string }> = ({ firstTransactionDate }) => {
    return (
        <Tooltip title={`First sale made on ${firstTransactionDate}`} placement="top">
            <span>
                <Chip className="new-member-chip" label="New Team Member" color="yellow" />
            </span>
        </Tooltip>
    );
};

interface IUserChartsRow {
    rank?: number;
    person: ChartLeader;
    locations: string | ReactElement;
    type?: TChartType;
    maximumIndividualLeaderValue?: number;
    metric: TChartDataMetric;
    goals?: SparkGoal[];
    fulfillmentTypes?: SparkFulfillmentType[];
    isThreshold?: boolean;
    highlight?: boolean;
    showRanks?: boolean;
    hideLocations?: boolean;
    hideAvatar?: boolean;
    account?: IAccount | null;
    isDashboardView?: boolean;
    highlightNewMember?: boolean;
    nonParticipantReason?: NonParticipantReason;
    showNonParticipantChip?: boolean;
    spark?: Spark;
    percentIncreaseComparison?: {
        currentTotal: number;
        previousTotal: number;
    };
    accountType?: 'brand' | 'retailer';
    onViewTransactions?: (selectedEmployee: ChartLeader) => void;
    userIsSuperAdmin?: boolean;
    userIsAdmin?: boolean;
    hideTransactions?: boolean;
}

export const UserChartsRow = ({
    rank,
    person,
    locations,
    type,
    maximumIndividualLeaderValue,
    metric,
    percentIncreaseComparison,
    goals = [],
    fulfillmentTypes = [],
    highlight = false,
    showRanks = true,
    hideLocations = false,
    isThreshold = false,
    hideAvatar = false,
    account,
    isDashboardView = false,
    highlightNewMember = false,
    nonParticipantReason,
    showNonParticipantChip = false,
    spark,
    accountType,
    onViewTransactions,
    userIsSuperAdmin = false,
    userIsAdmin = false,
    hideTransactions = true,
}: IUserChartsRow) => {
    const firstApplicableTransactionDate = useMemo(() => {
        if (highlightNewMember) {
            const firstTransactionDateByLocation = person?.firstTransactionDateByLocation;
            return getFirstApplicableTransactionDate({ firstTransactionDateByLocation });
        }
        return undefined;
    }, [person, highlightNewMember]);

    const isUnlimitedGoal = goals?.[0]?.isUnlimited || false;
    const showEnrollmentIcon =
        account?.type === 'retailer' &&
        userIsAdmin &&
        !isDashboardView &&
        (person.leaderType ?? 'user') === 'user';

    const classNamesAppended = appendClasses([
        'user-chart_person-row',
        highlight ? 'is-highlighted' : null,
        'hide-chart',
        hideAvatar ? 'hide-avatar' : null,
        isThreshold ? 'is-threshold' : null,
        isUnlimitedGoal ? 'is-unlimited-goal' : null,
        firstApplicableTransactionDate ? 'highlight-row' : null,
    ]);

    const isNonParticipant = nonParticipantReason !== undefined;

    return (
        <div className={classNamesAppended}>
            {showRanks && (
                <div className="person-col rank">
                    <span>{rank ? `#${rank}` : 'N/A'}</span>
                </div>
            )}
            <div className="person-col employee">
                {!hideAvatar && <Avatar firstName={person.name} />}
                <div className="person-name-and-location">
                    <span className={`name ${hideLocations ? 'hide-location' : ''}`}>
                        {person.name}

                        {showNonParticipantChip && isNonParticipant ? (
                            <NonParticipantChip nonParticipantReason={nonParticipantReason} />
                        ) : (
                            <>
                                {showEnrollmentIcon && (
                                    <SmsEnrollmentInfoIcon
                                        smsStatus={person.smsStatus}
                                        isInactive={person?.groupRole === 'none'}
                                    />
                                )}

                                {firstApplicableTransactionDate && (
                                    <NewMemberChip
                                        firstTransactionDate={firstApplicableTransactionDate}
                                    />
                                )}
                            </>
                        )}
                    </span>
                    {!hideLocations && <span className="location">{locations}</span>}
                </div>
            </div>
            {!isThreshold ? (
                <>
                    <div className="person-col value">
                        {!isPercentIncreaseSpark(spark) && (
                            <div className="value_primary-value">
                                <FormattedMetricValue metric={metric} value={person.value} />
                                <IndividualMissingCommissionCallout
                                    sparkId={spark?._id}
                                    hasMissingCommissions={person.hasMissingCommissions}
                                />
                            </div>
                        )}

                        {isPercentIncreaseSpark(spark) && (
                            <PercentIncreaseUserStats
                                percentIncrease={person.value}
                                comparisonTotals={percentIncreaseComparison}
                                percentIncreaseMetric={spark.percentIncreaseData.metric}
                            />
                        )}

                        {metric === 'commission' && (
                            <div className="unit-count">
                                {person.unitCount && formatFloat(person.unitCount, 0)} units
                            </div>
                        )}

                        {(metric === 'order_average' || metric === 'units_per_transaction') &&
                            spark?.detailedSparkType !== 'leaderboardLocation' && (
                                <div className="transaction-count">
                                    {`${person.transactionCount ?? 0} ${pluralize(
                                        'Transaction',
                                        person.transactionCount,
                                    )}`}
                                </div>
                            )}
                    </div>
                </>
            ) : (
                <SparkProgressIndicator
                    goals={goals}
                    metric={metric}
                    value={person.value}
                    fulfillmentTypes={fulfillmentTypes}
                />
            )}
            {(accountType === 'retailer' || userIsSuperAdmin) &&
                onViewTransactions &&
                !hideTransactions && (
                    <div className="person-col transactions !justify-end mr-8">
                        <Tooltip title="View Transactions" placement="top">
                            <span>
                                <ClickableArea
                                    className="hover:bg-cerulean-0 rounded-full p-2"
                                    onClick={() => onViewTransactions(person)}
                                >
                                    <div>
                                        <ForwardIcon className="text-cerulean-600" />
                                    </div>
                                </ClickableArea>
                            </span>
                        </Tooltip>
                    </div>
                )}
        </div>
    );
};

interface IUserChartsProps {
    title?: string;
    people: ChartLeader[];
    excludedPeople?: NonParticipantLeader[];
    type?: TChartType;
    metric: TChartDataMetric | 'commission';
    goals?: SparkGoal[];
    fulfillmentTypes?: SparkFulfillmentType[];
    showRanks?: boolean;
    locations?: IPosLocation[];
    hideLocations?: boolean;
    hideTransactions?: boolean;
    nameColumnTitle?: string;
    separator?: {
        listItemId?: string;
        element: ReactElement;
    };
    showSmsStatusIcon?: boolean;
    highlightNewMembers?: boolean;
    showNonParticipantChip?: boolean;
    spark?: Spark;
}

const UserCharts = ({
    title,
    people,
    excludedPeople = [],
    type,
    metric,
    goals = [],
    fulfillmentTypes = [],
    showRanks = true,
    hideLocations = false,
    hideTransactions = true,
    nameColumnTitle,
    separator,
    highlightNewMembers = false,
    showNonParticipantChip = false,
    spark,
}: IUserChartsProps) => {
    const { userIsSuperAdmin, userIsAdmin } = useApp();
    const { account } = useSparkplugAccount();
    const { tabValue = '' } = useRouteTabs();
    const [selectedEmployee, setSelectedEmployee] = useState<ChartLeader>();
    const [isViewTransactionsDrawerVisible, setIsViewTransactionsDrawerVisible] = useState(false);

    const handleViewTransactions = (employee: ChartLeader) => {
        setSelectedEmployee(employee);
        setIsViewTransactionsDrawerVisible(true);
    };

    const isDashboardView = tabValue.includes('dashboard');

    const isThreshold = goals?.length > 0;

    const trendLabel = metric === 'commission' ? 'Commission Chart' : 'Trend';
    const unitLabel = useMemo(() => {
        if (spark?.metric === 'percent_increase') {
            return getChartMetricLabel(spark?.percentIncreaseData?.metric).toUpperCase();
        }

        if (metric === 'commission') {
            return 'Earned';
        }

        return getChartMetricLabel(metric).toUpperCase();
    }, [spark, metric]);

    const hasExcludedPeople = excludedPeople.length > 0;

    const leaderLabel =
        nameColumnTitle ??
        (people.length && people.every(({ leaderType }) => leaderType === 'location'))
            ? 'Location'
            : 'Employee';

    const maximumIndividualLeaderValue = useMemo(() => {
        const allParticipants = [...people, ...excludedPeople];
        const allParticipantValues = allParticipants.map(
            ({ maxChartDatapoint, value }) => maxChartDatapoint ?? value,
        );
        return Math.max(...allParticipantValues);
    }, [people, excludedPeople]);

    const rankedPeople = useMemo(
        () =>
            people.map((person, i) => {
                const minThreshold = spark?.minimumThresholdToQualify ?? 0;
                const minTransactionCount = spark?.minimumTransactionsToQualify ?? 0;
                const isEligibleForPrizes = isEligibleParticipant({
                    participant: person,
                    minThreshold,
                    minTransactionCount,
                });
                return { ...person, rank: isEligibleForPrizes ? i + 1 : 0 };
            }),
        [people, spark],
    );

    const { applySearch, onChangeSearchFilter } = useSearch(['name']);
    const filteredPeople = applySearch(rankedPeople);
    const filteredExcluded = applySearch(excludedPeople);

    return (
        <div className="user-charts">
            <div className="section-title">
                <h2>{title || `${getChartMetricLabel(metric)} By ${leaderLabel}`}</h2>
                <Toolbar>
                    <Toolbar.Search
                        name="commission-product-search-filter"
                        onChange={onChangeSearchFilter}
                    />
                </Toolbar>
            </div>
            <div className="people-container">
                <div className="title-row">
                    {showRanks && <div className="title-col title-rank">RANK</div>}
                    <div className="title-col title-employee">{leaderLabel}</div>
                    {!isThreshold ? (
                        <div className="title-col title-contest-type">{unitLabel}</div>
                    ) : (
                        <div className="title-col title-trend">Progress</div>
                    )}
                    {(account?.type === 'retailer' || userIsSuperAdmin) && !hideTransactions && (
                        <div className="title-col title-transactions" />
                    )}
                </div>
                {filteredPeople.map((person, i) => {
                    const topIneligibleParticipantId = separator?.listItemId;
                    return (
                        <LazyLoad
                            height={80}
                            offset={300}
                            key={`${i}-${person.name}-${metric}-${person.value}-${maximumIndividualLeaderValue}`}
                        >
                            {!!topIneligibleParticipantId &&
                                person.flexibleEmployeeId === topIneligibleParticipantId && (
                                    <div className="user-charts-separator">{separator.element}</div>
                                )}
                            <UserChartsRow
                                {...{
                                    rank: person.rank,
                                    showRanks,
                                    spark,
                                    person,
                                    hideLocations,
                                    locations: person.locations ?? 'N/A',
                                    isThreshold,
                                    type,
                                    maximumIndividualLeaderValue,
                                    metric,
                                    percentIncreaseComparison: person.percentIncreaseComparison,
                                    goals,
                                    fulfillmentTypes,
                                    account,
                                    leaderType: person.leaderType,
                                    isDashboardView,
                                    highlightNewMember: highlightNewMembers,
                                    accountType: account?.type,
                                    onViewTransactions: handleViewTransactions,
                                    userIsSuperAdmin,
                                    userIsAdmin,
                                    hideTransactions,
                                }}
                            />
                        </LazyLoad>
                    );
                })}
                {hasExcludedPeople && (
                    <>
                        <div className="user-charts-separator">
                            <Button
                                className="non-participating-separator"
                                startIcon={<Info />}
                                color="neutral"
                                variant="smooth"
                            >
                                Non-participating Sales
                            </Button>
                        </div>
                        {filteredExcluded.map((person) => (
                            <LazyLoad
                                height={80}
                                offset={300}
                                key={`${person.name}-${metric}-${person.value}-${maximumIndividualLeaderValue}`}
                            >
                                <UserChartsRow
                                    {...{
                                        rank: 0,
                                        showRanks: true,
                                        spark,
                                        person,
                                        hideLocations,
                                        locations: person.locations ?? 'N/A',
                                        isThreshold,
                                        type,
                                        maximumIndividualLeaderValue,
                                        metric,
                                        percentIncreaseComparison: person.percentIncreaseComparison,
                                        goals,
                                        fulfillmentTypes,
                                        leaderType: person.leaderType,
                                        highlightNewMember: highlightNewMembers,
                                        nonParticipantReason: person.nonParticipantReason,
                                        showNonParticipantChip,
                                        onViewTransactions: handleViewTransactions,
                                        userIsSuperAdmin,
                                        userIsAdmin,
                                        hideTransactions,
                                    }}
                                />
                            </LazyLoad>
                        ))}
                    </>
                )}
                <ViewUserSparkTransactionsDrawer
                    isVisible={isViewTransactionsDrawerVisible}
                    onClose={() => setIsViewTransactionsDrawerVisible(false)}
                    sparkId={spark?._id ?? ''}
                    selectedEmployee={selectedEmployee}
                    startDate={spark?.startDate ?? ''}
                    endDate={spark?.endDate ?? ''}
                    userIsSuperAdmin={userIsSuperAdmin}
                />
            </div>
        </div>
    );
};

export default UserCharts;
