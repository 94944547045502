import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Linkify from 'react-linkify';

import { Emoji } from 'emoji-mart';
import { debounce } from 'lodash';

import { WalletEmployeeDeposit, formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import { ConfettiGraphic } from '@components/graphics/Confetti/Confetti';
import Skeleton from '@components/layout/Skeleton';
import Drawer from '@components/overlays/Drawer/Drawer';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';

import { useClaimRewardMutation } from '../../mutations/WalletMutations';
import { useCurrentUserWallet } from '../../queries/WalletQueries';

import './ClaimRewardDrawer.scss';

interface ClaimRewardDrawerPropsBase {
    rewardId: string;
    onClose: () => void;
}

interface ClaimRewardDrawerProps extends ClaimRewardDrawerPropsBase {
    isVisible: boolean;
}

interface ClaimRewardDrawerWrapperProps extends ClaimRewardDrawerPropsBase {
    updateRewardId: (newRewardId: string) => void;
}

interface ClaimRewardDrawerBodyProps {
    rewardText: string;
    claimInstructions?: string;
    nextReward?: WalletEmployeeDeposit;
    rewardType: 'payout' | 'other';
    spark: WalletEmployeeDeposit['spark'];
    learningResourceBrandInfo?: WalletEmployeeDeposit['learningResourceBrandInfo'];
    account?: IAccount;
    userRewardsAreReady: boolean;
    isClaimed: boolean;
    isClaiming: boolean;
    resetSwipe: boolean;
    handleClaim: () => void;
    takeNextAction: () => void;
    setNextActionClicked: Dispatch<SetStateAction<boolean>>;
}

export const ClaimRewardDrawerBody: FC<ClaimRewardDrawerBodyProps> = ({
    rewardText,
    claimInstructions = 'Talk to your manager',
    nextReward,
    rewardType,
    spark,
    learningResourceBrandInfo,
    account,
    userRewardsAreReady,
    isClaimed,
    isClaiming,
    resetSwipe,
    handleClaim,
    takeNextAction,
    setNextActionClicked,
}) => {
    const { name, emoji, sparkBrand } = spark ?? {};
    const labelName = sparkBrand?.name ?? account?.name;

    const { rewardTitle, rewardDetails } = useMemo(() => {
        const title = isClaimed ? 'Reward Claimed' : 'Claim Reward';
        const details = isClaimed
            ? 'You have claimed your reward for'
            : 'Click to claim your reward for';
        return { rewardTitle: title, rewardDetails: details };
    }, [isClaimed]);

    const mainImg = useMemo(() => {
        if (learningResourceBrandInfo) {
            return (
                <img
                    className={cn(
                        'reward-image',
                        rewardType === 'other' && 'w-[88px] h-[88px] !rounded-[50%]',
                    )}
                    src={learningResourceBrandInfo?.photo}
                    alt={learningResourceBrandInfo?.name}
                />
            );
        }
        if (sparkBrand) {
            return (
                <img
                    className={cn(
                        'reward-image',
                        rewardType === 'other' && 'w-[88px] h-[88px] !rounded-[50%]',
                    )}
                    src={sparkBrand?.photo}
                    alt={sparkBrand?.name}
                />
            );
        }
        return (
            <div className="emoji-wrapper">
                <Emoji emoji={emoji ?? ''} set="apple" size={50} />
            </div>
        );
    }, [rewardType, spark, isClaimed, learningResourceBrandInfo]);

    const nextAction = () => {
        takeNextAction();
        setNextActionClicked(true);
    };

    const action = useMemo(() => {
        if (rewardType === 'other' && isClaimed) {
            return (
                <div className="non-fbs-claimed">
                    <div>Claim Instructions:</div>
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        {claimInstructions && claimInstructions.length > 0
                            ? claimInstructions
                            : 'Talk to Manager'}
                    </Linkify>
                </div>
            );
        } else if (isClaimed) {
            return (
                <Button
                    className="next-action-button"
                    variant="smooth"
                    onClick={nextAction}
                    showSpinner={!userRewardsAreReady}
                >
                    {nextReward ? 'Claim Next Reward' : 'Go To Wallet'}
                </Button>
            );
        }
        return (
            <Button
                className="claim-swipe"
                variant="filled"
                onClick={debounce(handleClaim, 200)}
                disabled={isClaiming}
            >
                Claim Reward
            </Button>
        );
    }, [rewardType, spark, isClaiming, isClaimed, resetSwipe, userRewardsAreReady, nextReward]);

    const rewardDisplayText = isClaimed && rewardType === 'payout' ? `+ ${rewardText}` : rewardText;

    return (
        <>
            {mainImg}
            {labelName && (
                <div className={cn(rewardType === 'other' ? 'm-6' : 'm-7')}>
                    <Chip dense label={labelName} color="blue" />
                </div>
            )}
            <div>
                <h2>{rewardTitle}</h2>
                <div className="claim-message">
                    Congrats! {rewardDetails}
                    <div>
                        <strong>{learningResourceBrandInfo?.name ?? name}</strong>
                    </div>
                    <div className={`payout-text ${isClaimed && 'reward-claimed'}`}>
                        {rewardDisplayText}
                    </div>
                </div>
            </div>
            {action}
        </>
    );
};

const ClaimRewardDrawerWrapper: FC<ClaimRewardDrawerWrapperProps> = ({
    rewardId,
    updateRewardId,
    onClose,
}) => {
    const [isClaimed, setIsClaimed] = useState(false);
    const [nextActionClicked, setNextActionClicked] = useState(false);
    const [resetSwipe, setResetSwipe] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    const { currentUserWalletIsReady, currentUserWallet } = useCurrentUserWallet();

    const reward = useMemo(() => {
        return currentUserWallet?.deposits.filter(
            ({ _id, status }) => status === 'Confirmed' && _id === rewardId,
        )?.[0];
    }, [rewardId, nextActionClicked]);
    // not using currentUserWallet as change criteria here because when we do, we try to reset the reward immediately upon claim - but we want to instead show the animation, and not move on until the user actually clicks to "claim next reward" (or close the drawer)

    const nextReward = useMemo(() => {
        return currentUserWallet?.deposits.filter(
            ({ _id, status }) => status === 'Confirmed' && _id !== rewardId,
        )?.[0];
    }, [reward, currentUserWalletIsReady, nextActionClicked]);

    const { account } = useSparkplugAccount();

    const { isClaimingReward, claimReward } = useClaimRewardMutation();

    if (!currentUserWalletIsReady) {
        return <Skeleton height={200} />;
    }

    if (!reward) {
        // this shouldn't happen, but let's not crash the app if it does
        return <></>;
    }

    const handleClaim = () => {
        if (reward?._id && !isClaimingReward) {
            claimReward(
                { rewardId: reward._id },
                {
                    onSuccess: () => {
                        setIsClaimed(true);
                        setShowConfetti(true);
                        setResetSwipe(true);
                    },
                    onError: () => {
                        setResetSwipe(true);
                        toast.error(
                            'Something went wrong - please try again and contact support if this issue persists.',
                        );
                    },
                },
            );
        }
    };

    const claimNextReward = () => {
        if (nextReward) {
            updateRewardId(nextReward._id);
            setIsClaimed(false);
            setResetSwipe(true);
            setNextActionClicked(false);
        }
    };

    const takeNextAction = nextReward ? () => claimNextReward() : () => onClose();

    const rewardText = reward?.amount ? formatCurrency(reward.amount / 100, true) : reward.name;

    return (
        <>
            {showConfetti && (
                <ConfettiGraphic
                    initialVelocityY={25}
                    numberOfPieces={750}
                    tweenDuration={2000}
                    onComplete={() => setShowConfetti(false)}
                />
            )}
            <ClaimRewardDrawerBody
                rewardText={rewardText ?? ''}
                nextReward={nextReward}
                rewardType={reward?.amount ? 'payout' : 'other'}
                claimInstructions={reward?.claimInstructions}
                spark={reward.spark}
                learningResourceBrandInfo={reward.learningResourceBrandInfo}
                account={account}
                userRewardsAreReady={currentUserWalletIsReady}
                isClaimed={isClaimed}
                isClaiming={isClaimingReward}
                resetSwipe={resetSwipe}
                handleClaim={handleClaim}
                takeNextAction={takeNextAction}
                setNextActionClicked={setNextActionClicked}
            />
        </>
    );
};

const ClaimRewardDrawer: FC<ClaimRewardDrawerProps> = ({
    isVisible = false,
    onClose,
    rewardId: initialRewardId,
}) => {
    const [rewardId, setRewardId] = useState(initialRewardId);

    // useState does not update with every rerender, so we need useEffect here
    useEffect(() => {
        if (initialRewardId) {
            setRewardId(initialRewardId);
        }
    }, [initialRewardId]);

    return (
        <Drawer
            isVisible={isVisible}
            className="bring-drawer-to-front"
            title="Claim Reward"
            variant="bottomPartial"
            onCloseHandler={() => onClose()}
        >
            <ClaimRewardDrawerWrapper
                rewardId={rewardId ?? initialRewardId}
                updateRewardId={setRewardId}
                onClose={onClose}
            />
        </Drawer>
    );
};

export default ClaimRewardDrawer;
