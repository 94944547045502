import axios from 'axios';

import { ILearningResource, ISurveyResponse, PagedApiResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery, useQueryClient } from '@hooks/QueryHooks';

const API = {
    getLearningResource: async (learningResourceId: string) => {
        return (
            await axios.get<ILearningResource>(`/api/v1/learning-resource/${learningResourceId}`)
        ).data;
    },
    getSurveyResponses: async (learningResourceId: string) => {
        return (
            await axios.get<PagedApiResponseBody<ISurveyResponse>>(
                `/api/v1/learning-resource/${learningResourceId}/response`,
            )
        ).data;
    },
    getSurveyResponseCount: async (learningResourceId: string) => {
        return (
            await axios.get<{ count: number }>(
                `/api/v1/learning-resource/${learningResourceId}/response/count`,
            )
        ).data.count;
    },
};

export const getLearningResourceCacheKey = (learningResourceId: string) => [
    'learning-resource',
    learningResourceId,
];

export const useGetCourse = (learningResourceId: string) => {
    const {
        data: learningResource,
        isLoading,
        error,
    } = useAdvancedQuery(
        getLearningResourceCacheKey(learningResourceId),
        () => API.getLearningResource(learningResourceId),
        {
            enabled: !!learningResourceId,
        },
    );

    return {
        learningResource,
        isLoadingLearningResource: isLoading,
        learningResourceError: error,
    };
};

export const getSurveyResponsesCacheKey = (learningResourceId: string) => [
    'survey-responses',
    learningResourceId,
];

export const useGetSurveyResponses = (learningResourceId: string) => {
    const queryClient = useQueryClient();

    // Separate query for the count
    useAdvancedQuery(
        [...getSurveyResponsesCacheKey(learningResourceId), 'count'],
        () => API.getSurveyResponseCount(learningResourceId),
        {
            enabled: !!learningResourceId,
            refetchInterval: 5000, // Lightweight polling is fine for just a count
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: true,
            onSuccess: (newCount) => {
                const currentResponses = queryClient.getQueryData<
                    PagedApiResponseBody<ISurveyResponse>
                >(getSurveyResponsesCacheKey(learningResourceId));
                if (currentResponses?.data?.length !== newCount) {
                    queryClient.invalidateQueries(getSurveyResponsesCacheKey(learningResourceId));
                }
            },
        },
    );

    const { data: surveyResponses, isLoading: isLoadingSurveyResponses } = useAdvancedQuery(
        getSurveyResponsesCacheKey(learningResourceId),
        () => API.getSurveyResponses(learningResourceId),
        {
            enabled: !!learningResourceId,
        },
    );

    return {
        surveyResponses: surveyResponses?.data,
        isLoadingSurveyResponses,
    };
};
