import { SparkCommissionType } from '@sparkplug/lib';

import { UseSparkProductSelectorTableFilters } from '@features/spark-wizard/components/SparkProductSelector/SparkProductSelectorTable';

import Toolbar from '@components/toolbar/Toolbar';

import { IOption } from '@app/types/UITypes';

import { CommissionMap } from '../../../helpers/getHeadCells';
import ToolbarCommissionInput from '../../ToolbarCommissionInput';

import './DefaultCommissionTableToolbar.scss';

interface DefaultCommissionTableToolbarProps {
    commissionType: SparkCommissionType;
    brandOptions: IOption[];
    categoryOptions: IOption[];
    tableFilters: UseSparkProductSelectorTableFilters;
    updateDisplayedCommissionValues: (
        productIds: string[],
        applyAllValue: number | undefined,
    ) => void;
    isVendorEditExperience: boolean;
    originalCommissionValuesMap: CommissionMap;
}

const DefaultCommissionTableToolbar = ({
    commissionType,
    brandOptions,
    categoryOptions,
    tableFilters,
    updateDisplayedCommissionValues,
    isVendorEditExperience,
    originalCommissionValuesMap,
}: DefaultCommissionTableToolbarProps) => {
    const { filters, setFilters, onChangeSearchFilter } = tableFilters;

    return (
        <>
            <Toolbar
                justifyContentStart
                className="max-sm:pt-2 max-sm:overflow-x-auto max-sm:flex max-sm:[&_.toolbar-group-end]:ml-0"
            >
                <Toolbar.Group className="max-sm:py-1">
                    <Toolbar.Search
                        name="commission-product-search-filter"
                        onChange={onChangeSearchFilter}
                    />
                    <Toolbar.MultiSelectDropdown
                        label="Categories"
                        options={categoryOptions}
                        selected={filters.categoryIds}
                        onApply={(value) =>
                            setFilters((prevValue) => ({
                                ...prevValue,
                                categoryIds: value,
                            }))
                        }
                    />
                    <Toolbar.MultiSelectDropdown
                        label="Brands"
                        options={brandOptions}
                        selected={filters.brandIds}
                        onApply={(value) =>
                            setFilters((prevValue) => ({
                                ...prevValue,
                                brandIds: value,
                            }))
                        }
                    />
                </Toolbar.Group>
                <ToolbarCommissionInput
                    updateDisplayedCommissionValues={updateDisplayedCommissionValues}
                    commissionType={commissionType}
                    isVendorEditExperience={isVendorEditExperience}
                    originalCommissionValuesMap={originalCommissionValuesMap}
                />
            </Toolbar>
        </>
    );
};

export default DefaultCommissionTableToolbar;
