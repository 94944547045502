import { useEffect } from 'react';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@componentLibrary';

import { useEmployeeSparks } from '@core/employees/queries/EmployeeSparksQueries';

import { useEmployeeEvents } from '@features/events/queries/EmployeeEventQueries';
import { useGetEmployeeLearningResources } from '@features/learn-and-earn/queries/useGetEmployeeLearningResources';
import { FilterFns } from '@features/spark-dashboard/hooks/useSparkFilters';
import FeaturedSnapsList from '@features/spark-snaps/components/FeaturedSnapsList';
import { useEmployeeFeaturedSnaps } from '@features/spark-snaps/queries/FeaturedSnapQueries';
import { Events } from '@features/user-home/components/Events';
import { LearnAndEarn } from '@features/user-home/components/LearnAndEarn';
import { SparksModule } from '@features/user-home/components/SparksModule';

import { CalendarIcon, DollarCoinIcon, Spark as SparkIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import { useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';
import { getUrlParameter } from '@helpers/ui';

import './HomeView.scss';

interface IHomeViewProps {
    initialSparkStatus?: 'active' | 'upcoming' | 'complete';
}

const HomeView = ({ initialSparkStatus = 'active' }: IHomeViewProps) => {
    const { tab } = useQueryParams() as { tab: string };
    const { user, history } = useApp();

    const { employeeFeaturedSnaps } = useEmployeeFeaturedSnaps();
    const { account } = useSparkplugAccount();
    const { events } = useEmployeeEvents();
    const { sparks } = useEmployeeSparks();
    const { learningResources } = useGetEmployeeLearningResources();

    const eventsFeatureEnabled = import.meta.env.REACT_APP_EVENTS === 'true';

    const handleTabChange = (newVal: string) => {
        if (newVal !== 'your-sparks') {
            history.push(`?tab=${newVal}`);
            return;
        }
        history.push('');
    };

    const sparksCount = sparks.length
        ? sparks?.filter(FilterFns.status('active', account?.type)).length
        : 0;

    const incompleteLearningResourcesCount = learningResources.filter(
        (resource) => resource.surveyResponse?.status !== 'completed',
    ).length;

    const employeeEventsCount = events.length;

    useEffect(() => {
        if (getUrlParameter('openIntercom')) {
            Intercom.open();
        }
    }, []);

    return (
        <>
            <AppHeaderMobile title={`Welcome${user?.firstName ? `, ${user?.firstName}` : ''}`} />
            {employeeFeaturedSnaps && employeeFeaturedSnaps.length > 0 && (
                <FeaturedSnapsList snaps={employeeFeaturedSnaps} accountId={account?._id ?? ''} />
            )}

            <Tabs defaultValue="your-sparks" value={tab} onValueChange={handleTabChange}>
                <TabsList className="!w-full sm:w-fit flex mb-4 sticky sm:top-0 top-[60px] z-[250] bg-gray-25 rounded-none">
                    <TabsTrigger value="your-sparks" className="flex-1 text-nowrap !px-2">
                        <div className="flex items-center gap-2 justify-center text-[15px]">
                            <SparkIcon
                                className={`h-[20px] w-[20px] ${
                                    !tab || tab === 'your-sparks'
                                        ? '[&_path]:fill-cerulean-600'
                                        : '[&_path]:fill-gray-800'
                                }`}
                            />
                            Sparks
                            {sparksCount > 0 && tab && tab !== 'your-sparks' && (
                                <div className="flex items-center justify-center w-5 h-5 rounded-full bg-gray-100 text-gray-950 text-xs">
                                    {sparksCount}
                                </div>
                            )}
                        </div>
                    </TabsTrigger>
                    <TabsTrigger value="learn-and-earn" className="flex-1 text-nowrap !px-2">
                        <div className="flex items-center gap-2 justify-center text-[15px]">
                            <DollarCoinIcon
                                className={`h-[20px] w-[20px] ${
                                    tab === 'learn-and-earn'
                                        ? '[&_path]:fill-cerulean-600'
                                        : '[&_path]:fill-gray-800'
                                }`}
                            />
                            Earn
                            {incompleteLearningResourcesCount > 0 && tab !== 'learn-and-earn' && (
                                <div className="flex items-center justify-center w-5 h-5 rounded-full bg-gray-100 text-gray-950 text-xs">
                                    {incompleteLearningResourcesCount}
                                </div>
                            )}
                        </div>
                    </TabsTrigger>
                    {eventsFeatureEnabled && (
                        <TabsTrigger value="events" className="flex-1 text-nowrap !px-2">
                            <div className="flex items-center gap-2 justify-center text-[15px]">
                                <CalendarIcon
                                    className={`h-[20px] w-[20px] ${
                                        tab === 'events'
                                            ? '[&_path]:fill-cerulean-600'
                                            : '[&_path]:fill-gray-800'
                                    }`}
                                />
                                Events
                                {employeeEventsCount > 0 && tab !== 'events' && (
                                    <div className="flex items-center justify-center w-5 h-5 rounded-full bg-gray-100 text-gray-950 text-xs">
                                        {employeeEventsCount}
                                    </div>
                                )}
                            </div>
                        </TabsTrigger>
                    )}
                </TabsList>
                <TabsContent value="your-sparks">
                    <SparksModule initialSparkStatus={initialSparkStatus} />
                </TabsContent>
                <TabsContent value="learn-and-earn">
                    <LearnAndEarn />
                </TabsContent>
                {eventsFeatureEnabled && (
                    <TabsContent value="events">
                        <Events />
                    </TabsContent>
                )}
            </Tabs>
        </>
    );
};

export default HomeView;
