import NotAvailable from '@components/graphics/EmptyStates/NotAvailable.svg?react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import clsx from 'clsx';
import moment from 'moment';

import { DisabledDataIcon, Info, OpenInNew } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Tooltip from '@components/layout/Tooltip';

import Intercom from '@helpers/Intercom';

import Typography from '@app/componentLibrary/Typography';

import './InventoryEmptyStates.scss';

export const InventoryDisabledEmptyState = () => {
    return (
        <EmptyStateDisplay
            className="inventory-empty-state"
            graphic={<DisabledDataIcon />}
            label="Inventory data not available"
            smallText={<span>This Retailer has disabled inventory data sharing</span>}
        />
    );
};

export const InventoryNotAvailableEmptyState = () => {
    return (
        <EmptyStateDisplay
            className="inventory-empty-state"
            graphic={<DisabledDataIcon />}
            label="Inventory data not available"
            smallText={<span>This Retailer&apos;s Point-of-Sale does not support inventory</span>}
        />
    );
};

export const UpdateInventoryEntitlementEmptyState = ({
    isSparkDetailsView = false,
}: {
    isSparkDetailsView?: boolean;
}) => {
    return (
        <EmptyStateDisplay
            className={clsx(
                'inventory-upgrade-empty-state bg-gray-0',
                isSparkDetailsView ? '!h-[424px]' : '!h-[456px]',
            )}
            graphic={<NotAvailable />}
            label="Upgrade for Inventory insights"
            smallText={
                <Typography variant="base">
                    Your current plan does not provide Inventory access for retailers in this
                    market.
                </Typography>
            }
            actionButton={{
                label: 'Upgrade Your Plan',
                onClick: () => Intercom.open(),
                startIcon: <OpenInNew />,
            }}
        />
    );
};

export const NoActiveProductsEmptyState = () => {
    const last90days = moment().subtract(90, 'days');
    return (
        <EmptyStateDisplay
            className="inventory-empty-state"
            graphic={<DisabledDataIcon />}
            label="No Active Products"
            smallText={
                <div className="flex flex-row justify-center items-center">
                    No Locations with Active Products&nbsp;
                    <Tooltip
                        placement="right"
                        title={`"Active Products" are products that have sold at any location within since ${last90days.format(
                            DATE_DISPLAY_FORMAT,
                        )} (last 90 days)`}
                    >
                        <Info fontSize="small" className="text-gray-700 !h-[13px] !w-[13px]" />
                    </Tooltip>
                </div>
            }
        />
    );
};
