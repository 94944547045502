import { useState } from 'react';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { useEventNavigation } from '@features/events/hooks/useEventNavigation';

import Button from '@components/buttons/Button';
import { Add as AddIcon, Lock as LockIcon } from '@components/icons';
import UpgradeModalV2 from '@components/overlays/UpgradeModal/UpgradeModalV2';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';

export const CreateEventButton = () => {
    const { account, userCan } = useSparkplugAccount();
    const { userIsSuperAdmin } = useApp();
    const nav = useEventNavigation();
    const accountCanCreateEvent =
        account?.metaData?.subscriptionType === 'paid' || userIsSuperAdmin;
    const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);

    const userIsPermitted = userCan('createSpark');

    const onClick: () => void = accountCanCreateEvent
        ? nav.actions.goToCreateEventDrawer
        : () => setUpgradeModalIsVisible(true);

    const onCloseUpgradeModal = () => setUpgradeModalIsVisible(false);

    const userPermissionTooltip = !userIsPermitted ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined;

    return (
        <>
            <Button
                className="py-3 px-4 mt-2"
                variant="filled"
                tooltip={userPermissionTooltip}
                disabled={!userIsPermitted}
                color="blue"
                startIcon={
                    accountCanCreateEvent ? (
                        <AddIcon className="w-5 h-5" />
                    ) : (
                        <LockIcon className="w-5 h-5" />
                    )
                }
                onClick={onClick}
            >
                Create Event
            </Button>

            <UpgradeModalV2
                title="Create and manage your own team Events"
                content={
                    <div className="leading-5 flex flex-col">
                        Easily plan and schedule events to keep your team informed and engaged.
                        SparkPlug&apos;s Events feature allows you to create custom events, manage
                        team training sessions, and share promotions—all in one place. Quickly set
                        up event details, choose locations, and notify your team so everyone stays
                        on the same page.
                        <span className="pt-3 text-gray-950 font-semibold">
                            {`Let your team know what's on the calendar! Upgrade your subscription.`}
                        </span>
                    </div>
                }
                image={<img src="/static/images/events-upgrade.jpg" alt="Update to use events" />}
                isVisible={upgradeModalIsVisible}
                onClose={onCloseUpgradeModal}
                onConfirm={() => {
                    onCloseUpgradeModal();
                    Intercom.open();
                }}
            />
        </>
    );
};
