import { FC, ReactElement } from 'react';

import clsx from 'clsx';

import { SparkDisplayStatus } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import CountChip from '@components/chips/CountChip';
import { Copy, Inbox as InboxIcon, Send as SendIcon } from '@components/icons';
import SparkStatusIcon from '@components/sparks/SparkStatusIcon';

import Typography from '@app/componentLibrary/Typography';

import './SparkStatusButtons.scss';

export const StatusOptions = [
    {
        label: 'Active',
        value: 'active',
        to: '/sparks',
        startIcon: <SparkStatusIcon status="active" />,
    },
    {
        label: 'Upcoming',
        value: 'upcoming',
        to: '/sparks/upcoming',
        startIcon: <SparkStatusIcon status="upcoming" />,
    },
    {
        label: 'Complete',
        value: 'complete',
        to: '/sparks/complete',
        startIcon: <SparkStatusIcon status="completed" />,
    },
] as const;

type CoreStatusOption = (typeof StatusOptions)[number]['value'];

const AdditionalOptions = [
    {
        value: 'inbox/sent',
    },
] as const;

const VendorOptions = [
    {
        value: 'inbox/requests',
    },
] as const;

type Option = {
    value: string;
    to?: string;
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    label?: string;
};

const getInboxOverrideData = (
    isVendorAccount: boolean,
    path: string,
    inboxCount?: number,
): {
    startIcon: ReactElement;
    endIcon?: ReactElement;
    label: string;
    to: string;
} => {
    const iconClassName = 'status-icon icon-color-blue';
    const isRequestInfo = path.includes('requests');
    const vendorTo = isRequestInfo ? '/sparks/requests?status=open' : '/sparks/sent';

    return {
        startIcon:
            isVendorAccount && !isRequestInfo ? (
                <SendIcon className={iconClassName} />
            ) : (
                <InboxIcon className={iconClassName} />
            ),
        endIcon: inboxCount ? <CountChip count={inboxCount} /> : undefined,
        label: isVendorAccount && !isRequestInfo ? 'Sent' : 'Inbox',
        to: isVendorAccount ? vendorTo : '/sparks/inbox',
    };
};

type OverrideOptions = {
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    label?: string;
};

const SparkStatusButton = ({
    option,
    value,
    onSelect,
    optionOverride = {},
}: {
    option: Option;
    value: SparkDisplayStatus | 'templates';
    onSelect: (v: Option) => void;
    optionOverride?: OverrideOptions;
}) => {
    const optionMerged = {
        ...option,
        ...optionOverride,
    };

    return (
        <Button
            color="neutral"
            startIcon={optionMerged?.startIcon || null}
            endIcon={optionMerged?.endIcon || null}
            variant="smooth"
            className={`h-10 spark-status-button !hover:bg-bluewood-0 ${
                value === optionMerged.value ? 'active' : ''
            }`}
            onClick={() => onSelect && onSelect(optionMerged)}
        >
            <Typography variant="base" className="font-medium">
                {optionMerged.label}
            </Typography>
        </Button>
    );
};

interface SparkStatusButtonsProps {
    onSelect: (v: Option) => void;
    value: SparkDisplayStatus | 'templates';
    isBrand: boolean;
    unclaimedCount?: number;
    inboxCount?: number;
    requestCount?: number;
    isAdmin?: boolean;
    connectEnabled?: boolean;
}

export const SparkStatusButtons: FC<SparkStatusButtonsProps> = ({
    onSelect,
    value,
    isBrand: isVendorAccount,
    unclaimedCount = 0,
    inboxCount,
    requestCount = 0,
    isAdmin = false,
    connectEnabled = true,
}) => {
    const optionOverrideMap = {} as Record<CoreStatusOption, OverrideOptions>;

    if (unclaimedCount > 0) {
        optionOverrideMap.complete = {
            endIcon: <CountChip count={unclaimedCount} />,
        };
    }

    const options = [
        ...(isVendorAccount && import.meta.env.REACT_APP_REQUEST_FOR_SPARK === 'true'
            ? VendorOptions
            : []),
        ...AdditionalOptions,
    ];
    const showTemplatesTab = isAdmin;

    return (
        <>
            <div className="spark-view__status-buttons max-sm:flex max-sm:flex-row max-sm:overflow-x-auto">
                {isAdmin && connectEnabled && (
                    <div
                        className={clsx('tab-group max-sm:flex max-sm:!flex-row mr-0', {
                            'status-tab-group': showTemplatesTab,
                        })}
                    >
                        {options.map((option) => (
                            <SparkStatusButton
                                key={option.value}
                                onSelect={onSelect}
                                value={value}
                                option={option}
                                optionOverride={getInboxOverrideData(
                                    isVendorAccount,
                                    option.value,
                                    option.value === 'inbox/requests' ? requestCount : inboxCount,
                                )}
                            />
                        ))}
                    </div>
                )}
                <div className="status-tab-group tab-group max-sm:flex max-sm:!flex-row">
                    {StatusOptions.map((option) => (
                        <SparkStatusButton
                            key={option.value}
                            onSelect={onSelect}
                            value={value}
                            option={option}
                            optionOverride={optionOverrideMap[option.value]}
                        />
                    ))}
                </div>
                {showTemplatesTab && (
                    <div className="tab-group">
                        <SparkStatusButton
                            key="templates"
                            onSelect={onSelect}
                            value={value}
                            option={{
                                value: 'templates',
                                to: '/sparks/templates',
                                startIcon: <Copy className="icon-color-blue status-icon" />,
                                label: 'Templates',
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default SparkStatusButtons;
