import { useMemo } from 'react';

import { groupBy, maxBy, uniqBy } from 'lodash';
import moment from 'moment-timezone';

import { ISurveyResponse } from '@sparkplug/lib';

import { NavigationCallbacks } from '../types';

export const useCourseCompletionStats = (
    surveyResponses: ISurveyResponse[],
    navigationCallbacks?: NavigationCallbacks,
) => {
    const processedData = useMemo(() => {
        if (!surveyResponses?.length) {
            return {
                byMarket: [],
                byRetailer: [],
                byLocation: [],
                byEmployee: [],
            };
        }

        // Common data processing functions
        const calculateCompletion = (responses: ISurveyResponse[]) => {
            const completedCount = responses.filter(
                (r) => (r.data?.status as string) === 'completed',
            ).length;
            const total = responses.length;
            return total > 0 ? Number((completedCount / total).toFixed(2)) || 0 : 0;
        };

        const getLastCompletion = (responses: ISurveyResponse[]) => {
            return moment(
                maxBy(
                    responses.filter((r) => (r.data?.status as string) === 'completed'),
                    'updatedAt',
                )?.updatedAt,
            ).fromNow();
        };

        // Flatten multi-market responses
        const flattenedResponses = surveyResponses.flatMap((response) => {
            if (!response.markets?.length) {
                return [{ ...response, market: 'Unknown' }];
            }
            return response.markets.map((market) => ({
                ...response,
                market,
            }));
        });

        // Market view
        const byMarket = Object.entries(groupBy(flattenedResponses, 'market')).map(
            ([market, responses]) => ({
                key: market,
                market,
                completion: calculateCompletion(responses),
                retailers: uniqBy(responses, 'retailerId').length,
                locations: uniqBy(responses, 'locationName').length,
                activeEmployees: uniqBy(responses, 'userId').length,
                lastCompletion: getLastCompletion(responses),
                onClick: () => navigationCallbacks?.onMarketClick?.(market),
            }),
        );

        // Retailer view
        const byRetailer = Object.entries(groupBy(flattenedResponses, 'retailerId'))
            .map(([retailerId, responses]) => ({
                key: retailerId,
                retailerId,
                retailerName: responses[0].retailerName || 'Unknown',
                market: responses[0].market,
                completion: calculateCompletion(responses),
                locations: uniqBy(responses, 'locationName').length,
                activeEmployees: uniqBy(responses, 'userId').length,
                lastCompletion: getLastCompletion(responses),
                onClick: () =>
                    navigationCallbacks?.onRetailerClick?.(retailerId, responses[0].market),
            }))
            .filter((r) => r.retailerId);

        // Location view
        const byLocation = Object.entries(
            groupBy(flattenedResponses, (r) => `${r.locationName}-${r.market}-${r.retailerId}`),
        )
            .map(([_, responses]) => ({
                key: `${responses[0].locationName}-${responses[0].market}-${responses[0].retailerId}`,
                locationName: responses[0].locationName,
                retailerName: responses[0].retailerName || 'Unknown',
                market: responses[0].market,
                completion: calculateCompletion(responses),
                activeEmployees: uniqBy(responses, 'userId').length,
                lastCompletion: getLastCompletion(responses),
                onClick: () =>
                    navigationCallbacks?.onLocationClick?.(
                        `${responses[0].locationName}-${responses[0].market}-${responses[0].retailerId}`,
                        responses[0].market,
                    ),
            }))
            .filter((l) => l.locationName);

        // Employee view
        const byEmployee = flattenedResponses.map((response) => ({
            key: response.userId,
            userId: response.userId,
            firstName: response.firstName || '',
            lastName: response.lastName || '',
            retailerName: response.retailerName || 'Unknown',
            market: response.market,
            locationName: response.locationName || '',
            completionStatus: (response.data?.status as string) || 'not_started',
            completedAt:
                response.data?.status === 'completed' ? new Date(response.updatedAt) : undefined,
            amount: response.employeeDeposit?.amount / 100 || 0,
        }));

        return {
            byMarket,
            byRetailer,
            byLocation,
            byEmployee,
        };
    }, [surveyResponses, navigationCallbacks]);

    return processedData;
};
