import { useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router';

import { Menu, SidebarLayout } from '@componentLibrary';

import Button from '@components/buttons/Button';
import { PhoneAndroid, ReadIcon } from '@components/icons';
import PageHeader from '@components/layout/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import useEmployeePreview from '@hooks/useEmployeePreview';

import { IAccount } from '@app/types/AccountsTypes';

import { CoursesList } from '../components/CoursesList';
import { CreateCourseButton } from '../components/CreateCourseButton';

const AdminCoursesView = ({ account }: { account: IAccount }) => {
    const basePath = `/${account._id}/courses`;

    const { pathname } = useLocation();
    const currentView = useMemo(() => {
        if (pathname.includes('courses')) {
            return 'courses';
        }
        return 'courses';
    }, [pathname]);

    const { showPreviewModal, previewModal, isEmployeePreviewEnabled } = useEmployeePreview({
        page: 'courses',
    });

    return (
        <>
            <SidebarLayout
                header={
                    <PageHeader title={account?.name ?? ''} heading="Courses">
                        <Toolbar collapseOnMobile>
                            <Toolbar.Group className="toolbar-group-end">
                                {isEmployeePreviewEnabled && (
                                    <Button
                                        variant="flat"
                                        onClick={showPreviewModal}
                                        className="!mr-2 hover:!bg-transparent hover:!shadow-none"
                                    >
                                        <span className="flex items-center gap-2 text-gray-800 hover:text-gray-900">
                                            <PhoneAndroid />
                                            Preview
                                        </span>
                                    </Button>
                                )}
                                <CreateCourseButton />
                            </Toolbar.Group>
                        </Toolbar>
                    </PageHeader>
                }
                sidebar={
                    <Menu
                        navigation={[
                            {
                                name: 'Courses',
                                href: `/${basePath}/courses`,
                                icon: <ReadIcon className="text-cerulean-600" />,
                                current: currentView === 'courses',
                            },
                        ]}
                    />
                }
                content={
                    <Switch>
                        <Route path={`${basePath}`} component={() => <CoursesList />} />
                        <Route
                            path={`${basePath}/:courseId`}
                            component={() => <>course details</>}
                        />
                    </Switch>
                }
            />
            {previewModal}
        </>
    );
};

export default () => {
    const { account } = useSparkplugAccount();

    if (!account) {
        return <></>;
    }

    return <AdminCoursesView account={account} />;
};
