import { ReactNode, useEffect } from 'react';

import { Close } from '@mui/icons-material';

import IconButton from '@components/buttons/IconButton';
import { SparkplugWhiteLogo } from '@components/logos';
import Modal from '@components/overlays/Modal';

import Typography from '@app/componentLibrary/Typography';

export default ({
    isVisible,
    left,
    right,
    title,
    onClose,
}: {
    isVisible: boolean;
    left: ReactNode;
    right: ReactNode;
    title?: string;
    onClose: () => void;
}) => {
    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
    }, [onClose]);

    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
            className="p-0 h-full w-full"
            size="fullscreen"
        >
            <Modal.Content className="h-full w-full flex !p-0 max-sm:flex-col">
                <div className="h-full sm:w-1/2 max-sm:w-full flex flex-col gap-12 bg-cerulean-600 relative">
                    <div className="w-full h-16 min-h-16 p-2 pl-4 flex items-center gap-4 absolute top-0 left-0 ">
                        <SparkplugWhiteLogo height={28} />
                        {title && (
                            <>
                                <span className="h-7 w-px bg-white" />
                                <Typography variant="xl" className="text-white font-semibold">
                                    {title}
                                </Typography>
                            </>
                        )}
                        <div className="sm:hidden ml-auto">
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                className="!bg-gray-50 hover:!bg-gray-100 !text-gray-900"
                                data-testid="fullscreen-modal-close-button-mobile"
                            >
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                    {left}
                </div>
                <div className="h-full sm:w-1/2 max-sm:w-full flex flex-col gap-12 relative">
                    <div className=" max-sm:hidden flex justify-end p-3 absolute top-0 right-0">
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            className="!bg-gray-50 hover:!bg-gray-100 !text-gray-900"
                            data-testid="fullscreen-modal-close-button"
                        >
                            <Close />
                        </IconButton>
                    </div>
                    {right}
                </div>
            </Modal.Content>
        </Modal>
    );
};
