import { DateTime } from 'luxon';
import moment from 'moment';

import { EventDateTimeOnChange } from '@features/events/types';

import Form from '@components/form/Form';

interface EventDatePickerProps {
    variant: 'single-day' | 'multi-day';
    startDateTime: string;
    endDateTime?: string;
    onChange: EventDateTimeOnChange;
}
export const EventDatePicker = ({
    startDateTime,
    endDateTime,
    variant,
    onChange,
}: EventDatePickerProps) => {
    if (variant === 'single-day') {
        return (
            <Form.DatePicker
                id="single-event-date-picker"
                name="single-event-date-picker"
                required
                placeholder="Select Date"
                value={startDateTime}
                numberOfMonths={1}
                isOutsideRange={(day) => day.isBefore(moment().startOf('day'))}
                onChange={(newStartDateTime) => {
                    const startDateTimeValue = newStartDateTime?.toISOString();
                    if (!startDateTimeValue || !DateTime.fromISO(startDateTimeValue).isValid) {
                        // should not happen but given this short-circuit, we can comfortably infer that endDateTimeValue operations are valid
                        return;
                    }

                    // When the single day is changed, set the endDateTime to the same day but 1 hour later
                    const newEndDateTimeValue = DateTime.fromISO(startDateTimeValue)
                        .plus({ hours: 1 })
                        .setZone('UTC')
                        .toISO()!;

                    onChange({
                        startDateTime: startDateTimeValue,
                        endDateTime: newEndDateTimeValue,
                    });
                }}
            />
        );
    }

    return (
        <Form.DateRangePicker
            label=""
            id="multi-event-date-picker"
            dateStart={startDateTime}
            dateEnd={endDateTime}
            onApply={(newStartDateTime, newEndDateTime) => {
                const startDateTimeValue = newStartDateTime?.toISOString();
                const endDateTimeValue = newEndDateTime?.toISOString();

                // If we only have a startDateTime, the default endDateTimeValue is the next day
                if (startDateTimeValue && DateTime.fromISO(startDateTimeValue).isValid) {
                    const newEndDateTimeValue =
                        endDateTimeValue ||
                        DateTime.fromISO(startDateTimeValue).plus({ days: 1 }).toISO()!;
                    onChange({
                        startDateTime: startDateTimeValue,
                        endDateTime: newEndDateTimeValue,
                    });
                }
            }}
            isOutsideRange={(day) => {
                return day.isBefore(moment());
            }}
            variant="outlined"
            color="neutral"
        />
    );
};
