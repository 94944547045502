import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { VersionService } from './VersionService';

const VersionEnforcer = () => {
    const location = useLocation();

    useEffect(() => {
        const pathname = location?.pathname;
        const isCreatingSpark = pathname && pathname.includes('/sparks/create');
        const isEditingSpark =
            pathname && pathname.includes('/sparks') && pathname.includes('/edit');
        // when creating a spark, all the data entered is stored in context UI-side
        // if we enforce a reload while an admin is in this state, all their progress is lost
        // similarly, when editing a spark, any new data could be lost
        // if/when we tackle https://linear.app/sparkplug/issue/X-1513/find-a-better-way-to-store-spark-data-during-create-process we can remove this workaround
        if (!isCreatingSpark && !isEditingSpark) {
            VersionService.enforce();
        }
    }, [location]);

    return null;
};

export default VersionEnforcer;
