import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { useEventNavigation } from '@features/events/hooks/useEventNavigation';
import { useGetEventQuery } from '@features/events/queries';

import Skeleton from '@components/layout/Skeleton';
import Drawer from '@components/overlays/Drawer';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { EventForm } from '../EventForm';

import './EventFormDrawer.scss';

interface EventFormDrawerProps {
    isVisible: boolean;
    onClose: () => void;
}
export const EventFormDrawer = ({ isVisible, onClose }: EventFormDrawerProps) => {
    const { account } = useSparkplugAccount();

    const nav = useEventNavigation();
    const { event, eventHasError } = useGetEventQuery(nav.state.editId ?? '', {
        enabled: !!nav.state.editId,
    });

    useEffect(() => {
        if (eventHasError) {
            toast.error('Unable to edit event.');
            onClose();
        }
    }, [eventHasError]);

    // True if there is no initialEvent to load or the initialEvent has been loaded
    const initialEventDataIsReady: boolean = !nav.state.editId || !!event;
    const isNewBrandCreatedEvent: boolean = !nav.state.editId && account?.type === 'brand';

    return (
        <Drawer
            className="event-form-drawer"
            title={nav.state.editId ? 'Edit Event' : 'Schedule Event'}
            variant="right"
            isVisible={isVisible}
            onCloseHandler={onClose}
            drawerBodyClassName="h-full w-full !p-0"
            PaperProps={{
                className: 'md:!max-w-[660px]',
            }}
        >
            {!!account && initialEventDataIsReady ? (
                <EventForm
                    account={account}
                    initialEvent={event}
                    onSuccess={isNewBrandCreatedEvent ? nav.actions.goToSentView : onClose}
                    onError={onClose}
                    onExit={onClose}
                />
            ) : (
                <div
                    className="flex flex-col gap-4 w-full h-full px-6 pt-4"
                    data-testid="event-form-drawer-skeleton"
                >
                    <Skeleton className="h-20 w-full" />
                    <Skeleton className="h-40 w-full" />
                    <Skeleton className="h-20 w-full" />
                </div>
            )}
        </Drawer>
    );
};
