import axios from 'axios';

import { UpdateAccountSnapRequestBody, UpdateAccountSnapResponseBody } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

const API = {
    updateSnap: async ({
        accountId,
        snapId,
        payload,
    }: {
        accountId: string;
        snapId: number;
        payload: UpdateAccountSnapRequestBody;
    }) => {
        return (
            await axios.put<UpdateAccountSnapRequestBody, { data: UpdateAccountSnapResponseBody }>(
                `/api/v1/accounts/${accountId}/snaps/${snapId}`,
                payload,
            )
        ).data;
    },

    deleteSnap: async ({ accountId, snapId }: { accountId: string; snapId: number }) => {
        await axios.delete(`/api/v1/accounts/${accountId}/snaps/${snapId}`);
    },
};

export const useUpdateFeaturedSnapMutation = ({
    accountId,
    snapId,
}: {
    accountId: string;
    snapId: number;
}) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        (payload: UpdateAccountSnapRequestBody) =>
            API.updateSnap({
                accountId,
                snapId,
                payload,
            }),
        {
            updateQuery: {
                queryKey: 'featuredSnaps',
            },
            toastOptions: {
                loading: 'Republishing Featured Snap...',
                success: 'Snap Republished.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isUpdatingSnap: isLoading, updateSnapAsync: mutateAsync };
};

export const useDeleteSnapMutation = ({ accountId }: { accountId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({ snapId }: { snapId: number }) =>
            API.deleteSnap({
                accountId,
                snapId,
            }),
        {
            updateQuery: { queryKey: 'featuredSnaps' },

            toastOptions: {
                loading: 'Deleting...',
                success: 'Snap deleted.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isDeletingSnap: isLoading, deleteSnapAsync: mutateAsync };
};

export const useRemoveFeaturedSnapMutation = ({ accountId }: { accountId: string }) => {
    const { mutateAsync, isLoading } = useAdvancedMutation(
        ({ snapId, featuredPeriodId }: { snapId: number; featuredPeriodId: string }) =>
            API.updateSnap({
                accountId,
                snapId,
                payload: {
                    featuredPeriod: {
                        _id: featuredPeriodId,
                        endDate: new Date().toISOString(),
                    },
                },
            }),
        {
            updateQuery: { queryKey: 'featuredSnaps' },

            toastOptions: {
                loading: 'Removing...',
                success: 'Featured Snap Removed.',
                error: 'Something went wrong.',
            },
        },
    );

    return { isRemovingSnap: isLoading, removeSnapAsync: mutateAsync };
};
