import { z } from 'zod';

import { AccountMarket, AccountSparkBrand } from '../account';
import { RECURRING_SCHEDULE_OPTIONS, RecurringSchedule } from '../calendar';
import { ITrainingCourse } from '../training-course';
import { SparkDeclineResponse } from './spark.types';
import { objectIdRegex } from '../util';
import { PosLocation } from '../pos';

export const SPARK_REQUEST_STATES = ['none', 'accepted', 'pending', 'rejected', 'expired'] as const;

/**
 * @description The various request states a spark can live within, primarily applicable to
 * sparks created by brands for retailers.
 *
 * @member **none**: If a spark is created by a retailer or a super-admin, it's assigned
 * a none state, because the spark doesn't go through the brand -> retailer request/accept flow
 *
 * @member **accepted**: A brand-created spark that was accepted by the retailer.
 *
 * @member **rejected**: A brand-created spark that was rejected by the retailer.
 *
 * @member **pending**: Because brand accounts have no linked POS data (only retailers), which
 * is needed for a spark, all sparks created by a brand admin must be linked to a
 * retailer and sent as a spark request (groupId being the retailers id and
 * originatorGroupId being the brand id). Prior to the start date, that spark must
 * either be accepted/rejected or it will expire (see "expired"), and the requestState
 * is updated accordingly. Until that time, the spark is in "pending" requestState.
 *
 * @member **expired**: A brand-created spark that was neither accepted nor rejected by a retailer,
 * and the "dateEnd" has already passed.
 */
export type SparkRequestState = typeof SPARK_REQUEST_STATES[number];
export const participatingRequestStates: SparkRequestState[] = ['none', 'accepted'];
export const nonParticipatingRequestStates: SparkRequestState[] = [
  'pending',
  'rejected',
  'expired',
];

export const SPARK_INTERNAL_INVOICE_STATUSES = [
  'not-sent',
  'sent',
  'paid',
  'void',
  'none',
  'failed',
] as const;
export type SparkInternalInvoiceStatus = typeof SPARK_INTERNAL_INVOICE_STATUSES[number];

export const SPARK_INTERNAL_PAYOUT_STATUSES = [
  'not-paid',
  'paid',
  'special-payout',
  'none',
] as const;
export type SparkInternalPayoutStatus = typeof SPARK_INTERNAL_PAYOUT_STATUSES[number];
interface IPosLocation extends PosLocation {
  label: string;
  value: string;
}

export const SPARK_DISPLAY_STATUSES = [
  'active',
  'upcoming',
  'complete',
  'inbox/sent',
  'inbox/requests',
] as const;
export type SparkDisplayStatus = typeof SPARK_DISPLAY_STATUSES[number];

export type SparksViewFilters = {
  location: IPosLocation[];
  type: string[];
  schedule: (RecurringSparkScheduleOption | 'one-time')[];
  status: SparkDisplayStatus;
  training: SparkTrainingOption[];
  requestState?: Extract<SparkRequestState, 'pending' | 'expired' | 'rejected'>;
  search?: string;
};
export const TEAM_TYPES = ['custom', 'all'] as const;
export type TeamType = typeof TEAM_TYPES[number];

export const DETAILED_SPARK_TYPES = [
  'leaderboard',
  'leaderboardMulti',
  'leaderboardLocation',
  'goal',
  'goalTeam',
  'goalManager',
  'commissionFlat',
  'commissionPercentage',
] as const;
export type DetailedSparkType = typeof DETAILED_SPARK_TYPES[number];

export const SPARK_METRICS = [
  'total_sales',
  'total_units',
  'order_average',
  'guest_check_average',
  'transaction_count',
  'units_per_transaction',
  'percent_of_total_sales',
  // an integration test relies on this being the last item in the array
  'percent_increase',
] as const;
export type SparkMetric = typeof SPARK_METRICS[number];

export const PERCENT_INCREASE_SPARK_METRICS = [
  'total_sales',
  'total_units',
  'transaction_count',
  'units_per_transaction',
  'order_average',
] as const;
export type PercentIncreaseMetric = typeof PERCENT_INCREASE_SPARK_METRICS[number];

export const SPARK_TYPES = ['commission', 'leaderboard', 'goal'] as const;
export type SparkType = typeof SPARK_TYPES[number];
export const SPARK_TYPE_LABELS_RECORD: Record<SparkType, string> = {
  leaderboard: 'Leaderboard',
  goal: 'Goal',
  commission: 'Commission',
};

const SPARK_DEPRECATED_INTERNAL_STATUSES = [
  'verify',
  'announcement',
  'reminder-1',
  'update-1',
  'reminder-2',
  'update-2',
] as const;
/**
 * @deprecated These statuses are deprecated and should not be used.
 */
type SparkDeprecatedInternalStatus = typeof SPARK_DEPRECATED_INTERNAL_STATUSES[number];

const SPARK_CURRENT_INTERNAL_STATUSES = [
  'update',
  'do-not-payout',
  'ready-for-payout',
  'none',
] as const;
type SparkCurrentInternalStatus = typeof SPARK_CURRENT_INTERNAL_STATUSES[number];

export const SPARK_INTERNAL_STATUSES = [
  ...SPARK_DEPRECATED_INTERNAL_STATUSES,
  ...SPARK_CURRENT_INTERNAL_STATUSES,
] as const;
export type SparkInternalStatus = SparkDeprecatedInternalStatus | SparkCurrentInternalStatus;

export const SPARK_GOAL_TYPES = ['team', 'individual'] as const;
export type SparkGoalType = typeof SPARK_GOAL_TYPES[number];

export const SPARK_FULFILLMENT_TYPES = ['sparkplug', 'external'] as const;
export type SparkFulfillmentType = typeof SPARK_FULFILLMENT_TYPES[number];

export const RECURRING_SPARK_SCHEDULE_OPTIONS = RECURRING_SCHEDULE_OPTIONS;
export type RecurringSparkScheduleOption = typeof RECURRING_SPARK_SCHEDULE_OPTIONS[number];

export const SPARK_TRAINING_OPTIONS = ['none', 'required', 'optional'] as const;
export type SparkTrainingOption = typeof SPARK_TRAINING_OPTIONS[number];

export const RecurringScheduleLabels = [
  'Daily',
  'One-Time',
  'Weekly',
  'Twice Monthly',
  'Monthly',
] as const;
export type RecurringScheduleLabel = typeof RecurringScheduleLabels[number];

export interface SparkAward {
  award: string;
  claimInstructions?: string;
}

export const sparkAwardSchema = z.object({
  award: z.string().min(1),
  claimInstructions: z.string().optional(),
});

export interface SparkGoal {
  award: SparkAward;
  threshold: number;
  isUnlimited?: boolean;
}

export const sparkGoalSchema = z.object({
  award: sparkAwardSchema,
  threshold: z.number(),
  isUnlimited: z.boolean().optional(),
});
export type InternalActivity = {
  key: string;
  oldValue: any;
  newValue: any;
  username: string;
  timestamp: Date;
  action: string;
  userId: string;
};
export interface SparkInternalTracking {
  invoiceId?: string;
  invoiceLastFinalizationError?: string;
  invoiceUrl?: string;
  status: SparkInternalStatus;
  invoiceStatus: SparkInternalInvoiceStatus;
  payoutFinalizedAt?: string;
  payoutStatus: SparkInternalPayoutStatus;
  notes: string;
  changelog?: InternalActivity[];
}

export const SPARK_COMMISSION_TYPES = ['flat', 'percentage'] as const;
export type SparkCommissionType = typeof SPARK_COMMISSION_TYPES[number];

export interface SparkCommission {
  posProductId: string;
  value: number;
  type: SparkCommissionType;
}

export const COMMISSION_TYPE_RECORD: Record<string, SparkCommissionType> = {
  commissionFlat: 'flat',
  commissionPercentage: 'percentage',
};

export interface VendorSparkCommission extends SparkCommission {
  type: 'flat';
  name: string;
}

export interface MultiRetailerProductSelection {
  [retailerAccountId: string]: { posProductIds: string[]; commissions?: VendorSparkCommission[] };
}

export const getSparkIdPathParamsSchema = z.object({
  sparkId: z.string().min(1),
});
export type GetSparkIdPathParams = z.infer<typeof getSparkIdPathParamsSchema>;

export const sparkCommissionSchema = z.object({
  posProductId: z.string().min(1),
  value: z.number(),
  type: z.enum(SPARK_COMMISSION_TYPES),
});

export interface SparkProductFilters {
  brandIds?: string[];
  categoryIds?: string[];
}
export const sparkProductFiltersSchema = z.object({
  brandIds: z.array(z.string().min(1)).optional(),
  categoryIds: z.array(z.string().min(1)).optional(),
});
export interface SparkParticipantFilters {
  excludedPosEmployeeProfileIds?: string[];
}

export const sparkParticipantFiltersSchema = z.object({
  excludedPosEmployeeProfileIds: z.array(z.string().min(1)).optional(),
});

export enum LastSoldAtTypes {
  '-60days' = '-60days',
  '-90days' = '-90days',
  '-120days' = '-120days',
  'allTime' = 'allTime',
}

const lastSoldAtValues = ['-60days', '-90days', '-120days', 'allTime'] as const;

// use enum as type
export type LastSoldAt = `${LastSoldAtTypes}`;

export const lastSoldAtSchema = z.enum(lastSoldAtValues);

export const PRODUCT_NAME_FILTER_TYPES = {
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'does not contain',
  STARTS_WITH: 'starts with',
  ENDS_WITH: 'ends with',
} as const;

export type ProductNameFilterType = keyof typeof PRODUCT_NAME_FILTER_TYPES;

const productNameFilterTypes = [
  'CONTAINS',
  'DOES_NOT_CONTAIN',
  'STARTS_WITH',
  'ENDS_WITH',
] as const;

export type ProductNameFilter = {
  filterType: ProductNameFilterType;
  text: string;
};

export const primaryFilterOptions = ['brands', 'categories'] as const;

export interface ProductTagFilter {
  tagGroupName: string;
  tagName: string;
}

export const productNameFilterSchema = z.object({
  filterType: z.enum(productNameFilterTypes),
  text: z.string().min(1),
});

export const retailerSparkFiltersSchema = z.object({
  /**
   * @description `primaryFilter` is used to determine whether brand or category was selected first
   */
  primaryFilter: z.enum(primaryFilterOptions).nullish().optional(),
  posBrandIds: z.array(z.string().min(1)),
  posCategoryIds: z.array(z.string().min(1)),
  lastSoldAt: lastSoldAtSchema,
  hideSampleProducts: z.boolean(),
  productNameFilters: z.array(productNameFilterSchema),
  productNameContains: z.array(z.string().min(1)),
  productNameDoesNotContain: z.array(z.string().min(1)),
  excludedProductIds: z.array(z.string().min(1)),
});
export type RetailerSparkFilters = z.infer<typeof retailerSparkFiltersSchema>;

export const productTagFilterSchema = z.object({
  tagGroupName: z.string().min(1),
  tagName: z.string().min(1),
});

export const vendorSparkFiltersSchema = z.object({
  productTags: z.array(productTagFilterSchema),
  lastSoldAt: lastSoldAtSchema,
  hideSampleProducts: z.boolean(),
  productNameContains: z.array(z.string().min(1)),
  productNameDoesNotContain: z.array(z.string().min(1)),
});
export type VendorSparkFilters = z.infer<typeof vendorSparkFiltersSchema>;

export const commissionRuleTypes = ['fixed', 'manual'] as const;
export type CommissionRuleType = typeof commissionRuleTypes[number];
const fixedCommissionSchema = z.object({
  type: z.literal('fixed'),
  fixedValue: z.number().min(0.01),
});
const manualCommissionSchema = z.object({
  type: z.literal('manual'),
  fixedValue: z.undefined(),
});
export const commissionRulesSchema = z.union([fixedCommissionSchema, manualCommissionSchema]);
export type CommissionRules = z.infer<typeof commissionRulesSchema>;

export const sparkSnapDataSchema = z.object({
  accountId: z.string().regex(objectIdRegex),
  storifymeAccountId: z.string().min(1),
  snapIds: z.array(z.number().min(1)),
  creatorHasSnapsEntitlement: z.boolean().optional(),
});

export type SparkSnapData = z.infer<typeof sparkSnapDataSchema>;

export interface PercentIncreaseData {
  metric: PercentIncreaseMetric;
  startDate: string;
}

export interface UIPercentIncreaseData extends PercentIncreaseData {
  endDate: string;
}

export const percentIncreaseSchema = z.object({
  metric: z.enum(PERCENT_INCREASE_SPARK_METRICS).optional(),
  startDate: z.string().min(1).optional(),
});

export type RecurringSparkSchedule = RecurringSchedule<RecurringSparkScheduleOption>;

export interface CondensedUIAccount {
  _id: string;
  name: string;
  photo?: string;
  market?: string;
}

export interface CondensedUIUser {
  _id: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface Spark {
  _id: string;

  archivedAt?: string;
  awards: SparkAward[];
  commissions: SparkCommission[];
  createdAt?: string;
  createdBy?: string;
  creatorUserId: string;
  courseData?: ITrainingCourse;
  deletedAt?: string;
  description: string;
  detailedSparkType?: DetailedSparkType;
  emoji: string;
  endDate: string;
  finalizedAt?: string;
  fulfillmentTypes?: SparkFulfillmentType[];
  /**
   * @deprecated `goalType` is deprecated in favor of `detailedSparkType`
   */
  goalType?: SparkGoalType;
  goals: SparkGoal[];
  groupId: string;
  internalTracking?: SparkInternalTracking;
  isPublic: boolean;
  locationIds: string[];
  metric: SparkMetric;
  minimumThresholdToQualify?: number;
  minimumTransactionsToQualify?: number;
  name: string;
  originatorGroupId?: string;
  posBrandIds: string[];
  posCategoryIds: string[];
  posProductIds: string[];
  posEmployeeProfileIds: string[];
  productFilters?: SparkProductFilters;
  participantFilters?: SparkParticipantFilters;
  recurringSchedule?: RecurringSparkSchedule;
  recurringSparkScheduleId?: string;
  reminderSentAt?: string;
  requestState: SparkRequestState;
  splitPayout?: boolean;
  startDate: string;
  /**
   * @description The `tag` property is a randomly generated UUID
   * used to appropriately link multi-leaderboard sparks.
   *
   * @deprecated Simply use `detailedSparkType === 'multiLeaderboard'` instead
   */
  tag: string;
  trainingCourseId?: string;
  trainingEnabled?: boolean;
  type: SparkType;
  updatedAt?: string;
  updatedBy?: string;

  sparkBrandId?: string;
  sparkBrand?: Pick<AccountSparkBrand, 'name' | 'photo'>;

  sparkCreator?: CondensedUIUser;
  sparkRetailer?: CondensedUIAccount;
  sparkVendor?: CondensedUIAccount;
  sparkSponsor?: CondensedUIAccount;

  unitsSold?: number;
  payoutAmount?: number;
  participantCount?: number;
  missingCommissionCount?: number;

  /**
   * @description This was originally written for Retailers, but now we will also use it in some cases with
   * Vendor. Eventually, we want to rename this more closely to how it functions such as `posRulesBasedFilters`
   */
  retailerFilters?: RetailerSparkFilters;
  vendorFilters?: VendorSparkFilters;
  percentIncreaseData?: UIPercentIncreaseData;
  commissionRules?: CommissionRules;

  /**
   * @deprecated This is a temporary flag to track which Sparks that CS have enabled multiple-brand support for.
   */
  brandOverrideIsEnabled?: boolean;

  declinedByUser?: string;
  declineResponse?: SparkDeclineResponse;

  snaps?: SparkSnapData;
  /**
   * This is an option to set what type of team is involved in a goal team spark
   * in order to do things like determine whether or not to auto add participants
   * the options are "custom" or "all"
   */
  teamType?: TeamType;
  /**
   * This is a unique identifier for a spark template
   */
  templateId?: string;
  clonedFromSparkId?: string;
  markets?: AccountMarket[];
}

export type ControlCenterSpark = Pick<
  Spark,
  | '_id'
  | 'startDate'
  | 'endDate'
  | 'type'
  | 'detailedSparkType'
  | 'tag'
  | 'fulfillmentTypes'
  | 'requestState'
  | 'groupId'
  | 'originatorGroupId'
  | 'internalTracking'
  | 'vendorFilters'
  | 'recurringSparkScheduleId'
  | 'retailerFilters'
  | 'tag'
  | 'payoutAmount'
  | 'finalizedAt'
  | 'name'
  | 'commissionRules'
  | 'missingCommissionCount'
> & {
  vendorName?: string;
  vendorAccountId?: string;
  retailerName?: string;
  retailerAccountId?: string;
  recurringScheduleInterval?: RecurringSparkScheduleOption;
};
