import { useMemo } from 'react';

import { uniqBy } from 'lodash';

import { isLinkUnmapped, useAccountLinks } from '@features/account-links';
import { EventFormFields } from '@features/events/types';

import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { IPosLocation } from '@app/types/PosTypes';

/**
 * Outputs the correct baseline form based on the account type and the selected brand/retailer (when applicable)
 */
export const useEventFormData = ({
    form,
    account,
}: {
    form: EventFormFields;
    account: IAccount;
}) => {
    const { accountPosLocations = [], accountPosLocationsDataIsReady } = useAccountPosDataQuery(
        account._id,
        {
            includedDatasets: ['locations'],
            isEnabled: account?.type === 'retailer',
        },
    );

    const { accountLinks = [], accountLinksAreReady } = useAccountLinks(
        account._id,
        account.type === 'brand',
        ['enabled'],
    );

    const brandOptions = useMemo(() => {
        if (account?.type === 'retailer') {
            return [];
        }

        const brands = accountLinks
            .filter((link) => !isLinkUnmapped(link))
            .flatMap((link) => {
                return (
                    link?.brandLinks?.map((brand) => ({
                        value: brand.brandId,
                        label: brand.name,
                    })) ?? []
                );
            });

        return uniqBy(brands, 'value');
    }, [account?.type, accountLinks]);

    const mappedAccountLinks = useMemo(() => {
        const mappedLinks = accountLinks.filter((link) => !isLinkUnmapped(link));
        if (form.brandId) {
            return mappedLinks.filter((link) => {
                return link.brandLinks.some((brand) => brand.brandId === form.brandId);
            });
        }
        return mappedLinks;
    }, [accountLinks, form.brandId]);

    const retailerOptions = useMemo(() => {
        if (account?.type === 'retailer') {
            return [];
        }

        return mappedAccountLinks.map((link) => ({
            label: link.accountName,
            value: link.accountId,
        }));
    }, [account?.type, mappedAccountLinks, form.retailerAccountId]);

    const locations = useMemo(() => {
        if (account?.type === 'retailer') {
            return accountPosLocations.filter((location) => !location.disabled);
        }
        if (account?.type === 'brand') {
            if (!form.retailerAccountId) {
                return [] as IPosLocation[];
            }
            return mappedAccountLinks
                .filter((link) => link.accountId === form.retailerAccountId)
                .flatMap((link) => link.locations ?? [])
                .filter((location) => !location.disabled);
        }

        return [] as IPosLocation[];
    }, [account?.type, mappedAccountLinks, accountPosLocations, form.retailerAccountId]);

    const locationOptions = useMemo(() => {
        return uniqBy(
            locations.map((location) => ({
                label: location.displayName ?? location.name,
                value: location._id,
            })),
            'value',
        );
    }, [locations]);

    const formDataIsReady = useMemo(() => {
        if (account.type === 'retailer') {
            return accountPosLocationsDataIsReady;
        }
        return accountLinksAreReady;
    }, [accountLinksAreReady, accountPosLocationsDataIsReady, account.type]);

    return {
        locationOptions,
        brandOptions,
        retailerOptions,
        formDataIsReady,
        // We can generically select the time zone of the first location because retailers have one timezone for all locations
        timeZone: locations[0]?.timeZone ?? 'America/Los_Angeles',
        state: locations[0]?.address?.state,
    };
};
