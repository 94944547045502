import { ReactElement, cloneElement, useMemo } from 'react';

import { UIEvent } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { MoreHoriz as MoreIcon } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';

import { GroupOfPeopleIcon } from '@app/componentLibrary/Icon/GroupOfPeople';
import { PercentageIcon } from '@app/componentLibrary/Icon/Percentage';
import { TrainingIcon } from '@app/componentLibrary/Icon/Training';
import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

interface EventTypeOption {
    eventType: UIEvent['type'];
    title: string;
    description: string;
    icon: ReactElement;
}
const EVENT_TYPES_OPTIONS: EventTypeOption[] = [
    {
        eventType: 'popup',
        title: 'Pop-up',
        description: 'Schedule an in-store activation or demo.',
        icon: <GroupOfPeopleIcon />,
    },
    {
        eventType: 'promo',
        title: 'Promo',
        description: 'Launch targeted promotions & discounts.',
        icon: <PercentageIcon />,
    },
    {
        eventType: 'training',
        title: 'Training',
        description: 'Empower staff by sharing product knowledge.',
        icon: <TrainingIcon />,
    },
    {
        eventType: 'other',
        title: 'Other',
        description: 'Create a custom event type.',
        icon: <MoreIcon />,
    },
];

interface EventTypeOptionCardProps extends EventTypeOption {
    className?: string;
    onSelect?: (eventType: UIEvent['type'] | undefined) => void;
    onResetSelection?: () => void;
}
const EventTypeOptionCard = ({
    className,
    eventType,
    icon,
    title,
    description,
    onSelect,
    onResetSelection,
}: EventTypeOptionCardProps) => {
    const EventTypeIcon = useMemo(
        () => () =>
            cloneElement(icon, {
                // Apply default icon styling but allow for more customization via the icon's current className
                className: cn('!w-8 !h-8 text-gray-800', icon.props.className),
            }),
        [icon],
    );

    return (
        <ClickableArea
            key={eventType}
            className={cn(
                'w-full flex justify-between border border-solid border-gray-200 rounded px-4 py-3',
                className,
            )}
            onClick={() => onSelect?.(eventType)}
        >
            <section className="flex items-center gap-4">
                <EventTypeIcon />

                <div className="flex flex-col">
                    <Typography variant="lg" className="font-semibold text-gray-950">
                        {title}
                    </Typography>
                    <Typography variant="base" className="text-gray-800">
                        {description}
                    </Typography>
                </div>
            </section>
            {onResetSelection && (
                <Button variant="flat" onClick={onResetSelection}>
                    Change
                </Button>
            )}
        </ClickableArea>
    );
};

interface EventFormTypeSelectorProps {
    value?: UIEvent['type'];
    onChange: (eventType: UIEvent['type'] | undefined) => void;
}
export const EventFormTypeSelector = ({ value, onChange }: EventFormTypeSelectorProps) => {
    const selection = useMemo(() => {
        return EVENT_TYPES_OPTIONS.find((type) => type.eventType === value);
    }, [value]);

    return (
        <>
            {selection ? (
                <EventTypeOptionCard {...selection} onResetSelection={() => onChange(undefined)} />
            ) : (
                <section className="flex flex-col gap-2">
                    {EVENT_TYPES_OPTIONS.map((eventTypeDetails) => (
                        <EventTypeOptionCard
                            {...eventTypeDetails}
                            key={eventTypeDetails.eventType}
                            className="hover:border-gray-900 transition-colors"
                            onSelect={onChange}
                        />
                    ))}
                </section>
            )}
        </>
    );
};
