import { ComponentProps, FC, PropsWithChildren, ReactNode, useCallback } from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentProps,
    DialogTitle,
} from '@mui/material';
import clsx from 'clsx';

import { ModalProvider } from '@contexts/ModalContext';

import Button, { IButtonProps } from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { ChevronLeft as BackIcon, Close } from '@components/icons';
import Stepper from '@components/layout/Stepper';

import { useModal } from '@hooks/ModalHooks';

import { appendClasses } from '@helpers/ui';

import './Modal.scss';

export interface ModalCloseFunction<T> {
    (actionOnClose: boolean, additionalData?: T): void;
}

export interface IDefaultModalProps<T extends unknown = any> {
    size?: 'default' | 'narrow' | 'extra-narrow' | 'wide' | 'extra-wide';
    className?: string;
    isVisible: boolean;
    onClose: ModalCloseFunction<T>;
}

const ModalOpenClass = 'modal-is-open';

interface IModalTitleProps {
    className?: string;
    onClose?: (actionOnClose: boolean) => void;
    endIcon?: ReactNode;
    borderBottom?: boolean;
    dense?: boolean;
}

const ModalTitle: FC<PropsWithChildren<IModalTitleProps>> = ({
    className,
    onClose,
    endIcon,
    children,
    borderBottom = false,
    dense = false,
}) => {
    const classNamesAppended = appendClasses([
        className,
        'modal-title',
        borderBottom ? 'modal-title-border-bottom' : null,
        dense ? 'modal-title-dense' : null,
    ]);

    const onExit = (actionOnClose: any) => {
        if (onClose != null) {
            document.body.classList.remove(ModalOpenClass);
            onClose(actionOnClose);
        }
    };

    return (
        <DialogTitle className={classNamesAppended}>
            <span>{children}</span>
            {endIcon}
            {onClose != null && (
                <IconButton className="modal-title_close" aria-label="close" onClick={onExit}>
                    <Close />
                </IconButton>
            )}
        </DialogTitle>
    );
};

interface IModalProps {
    size?: 'default' | 'narrow' | 'extra-narrow' | 'wide' | 'extra-wide' | 'fullscreen';
    className?: string;
    keepMounted?: boolean;
    isVisible: boolean;
    onClose: (actionOnClose: boolean) => void;
    disableBackdropClick?: boolean;
    maxWidth?: number;
    initialStage?: number;
    sx?: ComponentProps<typeof Dialog>['sx'];
}

const Modal = ({
    size,
    className,
    isVisible,
    onClose,
    children,
    keepMounted = false,
    disableBackdropClick = false,
    maxWidth,
    initialStage,
    sx,
}: IModalProps & { children: ReactNode }) => {
    const classes = appendClasses(['modal', className, size]);

    const muiOnClose = useCallback(
        (event: any, reason: string) => {
            if (disableBackdropClick && reason === 'backdropClick') {
                return;
            }

            onClose(false);
        },
        [onClose, disableBackdropClick],
    );

    const paperProps = { style: { maxWidth } };

    return (
        <Dialog
            keepMounted={keepMounted}
            open={isVisible}
            PaperProps={paperProps}
            onClose={muiOnClose}
            className={classes}
            sx={sx}
        >
            <ModalProvider initialStage={initialStage}>{children}</ModalProvider>
        </Dialog>
    );
};

Modal.Title = ModalTitle;
Modal.Stepper = (props: { className?: string; stageNames: string[] }) => {
    const { currentStage } = useModal();

    return <Stepper stage={currentStage} {...props} />;
};
Modal.Content = (props: DialogContentProps) => {
    const { modalContentRef } = useModal();
    return <DialogContent ref={modalContentRef} className="modal-content content" {...props} />;
};
Modal.Actions = (props: any) => {
    const { className, ...rest } = props;
    return <DialogActions className={clsx('modal-actions', className)} {...rest} />;
};

Modal.ValidationButton = ({ onClick: _onClick, ...props }: IButtonProps) => {
    const { stageValidationFn } = useModal();

    const onClick = useCallback(() => {
        if (stageValidationFn()) {
            _onClick?.();
        }
    }, [_onClick, stageValidationFn]);

    return <Button onClick={onClick} {...props} />;
};

interface MobileHeaderProps {
    title?: string;
    onClose?: (doActionOnClose: boolean) => void;
}

Modal.MobileHeader = ({ onClose, title }: MobileHeaderProps) => {
    return (
        <div className="mobile-info-modal-header">
            {onClose && (
                <Button
                    className="back-to-spark-button"
                    color="neutral"
                    variant="flat"
                    startIcon={<BackIcon />}
                    disabled={false}
                    onClick={() => {
                        onClose(false);
                    }}
                >
                    {title && <h1>{title}</h1>}
                </Button>
            )}
        </div>
    );
};

export default Modal;
