import React from 'react';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';

import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';

interface SurveyCreatorWrapperProps {
    surveyCreator: SurveyCreator;
    heightOffset?: number;
}

const SurveyCreatorWrapper = ({ surveyCreator, heightOffset = 64 }: SurveyCreatorWrapperProps) => {
    return (
        <div data-testid="survey-creator-component">
            <SurveyCreatorComponent
                creator={surveyCreator}
                style={{ height: `calc(100vh - ${heightOffset}px)` }}
                data-testid="survey-creator-component"
            />
        </div>
    );
};

export default SurveyCreatorWrapper;
