import { ReactElement } from 'react';

import { Emoji } from 'emoji-mart';
import moment from 'moment-timezone';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import ManagerChip from '@components/chips/ManagerChip';
import { RecurringSparkIcon } from '@components/icons';
import PanelField from '@components/layout/PanelField';
import Paper from '@components/layout/Paper';
import Tooltip from '@components/layout/Tooltip';
import SparkGroupChip from '@components/sparks/SparkGroupChip';
import SparkStatusChip from '@components/sparks/SparkStatusChip';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import {
    formatSparkInfo,
    getDetailedSparkTypeDisplayName,
    getMinThresholdLabel,
    getOtherGroupName,
    getSelectedLocationsFromSparkSubGroups,
    getSelectedParticipantsFromSparkSubGroups,
    getSparkTypeDisplayName,
} from '@helpers/sparks';
import { formatRecurringTooltip } from '@helpers/ui';

import { ISparkPosData, ISparkSubGroup } from '@app/types/SparksTypes';

import './SparkDetailsPanel.scss';

interface SharedSparkDetailsPanelProps {
    spark: Spark;
    detailedSparkType?: DetailedSparkType;
    onLocationsClick?: () => void;
    onProductClick?: () => void;
    onParticipantsClick?: () => void;
    moreButton?: ReactElement;
}
interface SparkDetailsPanelProps extends SharedSparkDetailsPanelProps {
    otherGroupName?: string;
    vendorGroupName?: string;
    locationCount?: number;
    participantCount?: number;
    productCount?: number;
    sparkPosDataIsReady?: boolean;
}

/**
 * This is a controlled version of the SparkDetailsPanel component where we can manually
 * pass in the location, participant, and product counts as well as the other group name
 * and the vendor name.
 */
export const SparkDetailsPanel = ({
    spark,
    detailedSparkType,
    otherGroupName,
    vendorGroupName,
    locationCount = 0,
    participantCount = 0,
    productCount = 0,
    onLocationsClick,
    onProductClick,
    onParticipantsClick,
    moreButton,
    sparkPosDataIsReady = false,
}: SparkDetailsPanelProps) => {
    const {
        name,
        description,
        type: sparkType,
        emoji,
        startDate,
        endDate,
        recurringSchedule,
    } = spark;

    const statusChipTooltip = spark.requestState === 'pending' && (
        <>
            {spark.reminderSentAt
                ? `Pending Spark reminder sent on ${moment(spark.reminderSentAt).format('LL')}`
                : `Spark sent on ${moment(spark.createdAt).format('LL')}`}
        </>
    );

    const {
        formattedStartDate,
        formattedEndDate,
        totalDays: duration,
        status,
    } = formatSparkInfo(startDate, endDate, spark.requestState, recurringSchedule);

    const nameClassName = name.length > 20 ? 'name reduced-size' : 'name';
    const participantType = detailedSparkType === 'goalManager' ? 'Managers' : 'Employees';
    const minThresholdLabel = getMinThresholdLabel(spark);

    const isManagerGoal = detailedSparkType === 'goalManager';

    const detailedSparkTypeComponent = detailedSparkType && (
        <>
            {minThresholdLabel && sparkType === 'commission' ? (
                <PanelField
                    className="last-field min-payout"
                    label="Minimum Payout"
                    value={minThresholdLabel}
                />
            ) : (
                <PanelField
                    className="last-field detailed-spark-type"
                    label="Type"
                    value={getDetailedSparkTypeDisplayName(detailedSparkType)}
                />
            )}
        </>
    );

    let productText = '--';
    if (sparkPosDataIsReady) {
        productText = productCount > 0 ? `${productCount} Products` : '0 Products';
    }

    return (
        <Paper className="spark-details-panel">
            <div className="details-container">
                <section className="left">
                    {spark.sparkBrand ? (
                        <div className="spark-details-panel_spark-brand relative">
                            <img src={spark.sparkBrand?.photo} alt={spark.sparkBrand?.name} />
                            {!!recurringSchedule && (
                                <Tooltip title={formatRecurringTooltip(recurringSchedule)}>
                                    <span className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4 rounded-full p-1 bg-white flex items-center justify-center shadow-sm">
                                        <RecurringSparkIcon className="w-6 h-6" />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        <div className="emoji-wrapper relative">
                            <Emoji emoji={emoji} set="apple" size={40} />
                            {!!recurringSchedule && (
                                <Tooltip title={formatRecurringTooltip(recurringSchedule)}>
                                    <span
                                        id="recurring-icon-container"
                                        className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4 rounded-full p-1 bg-white flex items-center justify-center shadow-sm"
                                    >
                                        <RecurringSparkIcon className="w-6 h-6" />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    )}

                    <div className="spark-info">
                        <div className="spark-type-and-group">
                            <p className="spark-type">{getSparkTypeDisplayName(sparkType)}</p>
                            {isManagerGoal && <ManagerChip />}
                            {otherGroupName && (
                                <SparkGroupChip
                                    groupName={otherGroupName}
                                    vendorName={vendorGroupName}
                                />
                            )}
                        </div>
                        <section className="title">
                            <p className={nameClassName}>{name}</p>
                            <SparkStatusChip
                                status={status}
                                tooltip={statusChipTooltip ?? undefined}
                                customLabel={status === 'Rejected' ? 'Declined' : undefined}
                            />
                        </section>
                        <p className="description">{description}</p>
                    </div>
                </section>

                <section className="right">
                    <div>
                        <PanelField
                            className="start-date"
                            label="Start Date"
                            value={formattedStartDate}
                        />
                        <PanelField
                            className="end-date"
                            label="End Date"
                            value={formattedEndDate}
                        />
                        <PanelField
                            className="duration"
                            label="Duration"
                            value={`${duration} days`}
                        />
                        {detailedSparkTypeComponent}
                    </div>

                    <div>
                        <PanelField
                            className="location-count"
                            label="Participating Locations"
                            value={locationCount > 0 ? `${locationCount} Locations` : '--'}
                            onClick={locationCount ? onLocationsClick : null}
                        />
                        <PanelField
                            className="participant-count"
                            label={`Participating ${participantType}`}
                            value={
                                participantCount > 0
                                    ? `${participantCount} ${participantType}`
                                    : '--'
                            }
                            onClick={participantCount ? onParticipantsClick : null}
                        />
                        <PanelField
                            className="product-count"
                            label="Qualifying Products"
                            value={productText}
                            onClick={productCount ? onProductClick : null}
                        />
                    </div>
                </section>
            </div>

            {moreButton}
        </Paper>
    );
};

/**
 * This is a wrapped version of the SparkDetailsPanel for when we want to use the
 * sparkPosData and sparkSubGroups to calculate the location, participant, and product counts
 * as well as the other group name and vendor group name.
 */
export default ({
    spark,
    sparkPosData,
    sparkPosDataIsReady,
    sparkSubGroups,
    detailedSparkType,
    ...sharedProps
}: SharedSparkDetailsPanelProps & {
    sparkPosData: ISparkPosData;
    sparkPosDataIsReady: boolean;
    sparkSubGroups: ISparkSubGroup[];
}) => {
    const { account } = useSparkplugAccount();

    const { associatedProducts, locations, participants } = sparkPosData;

    const otherGroupName = getOtherGroupName(spark, account);
    const vendorGroupName = account?.type !== 'brand' ? spark.sparkVendor?.name : '';
    const subGroupsLocations = getSelectedLocationsFromSparkSubGroups(sparkSubGroups);
    const subGroupsParticipants = getSelectedParticipantsFromSparkSubGroups(sparkSubGroups);

    const productCount = associatedProducts?.length;

    const locationCount =
        detailedSparkType === 'leaderboardMulti' ? subGroupsLocations.length : locations?.length;

    const participantCount =
        detailedSparkType === 'leaderboardMulti'
            ? subGroupsParticipants.length
            : participants?.length;

    return (
        <SparkDetailsPanel
            spark={spark}
            detailedSparkType={detailedSparkType}
            otherGroupName={otherGroupName}
            vendorGroupName={vendorGroupName}
            locationCount={locationCount}
            participantCount={participantCount}
            sparkPosDataIsReady={sparkPosDataIsReady}
            productCount={productCount}
            {...sharedProps}
        />
    );
};
