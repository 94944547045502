import { z } from 'zod';
import { IPagingProperties } from '..';

export const creditTypes = ['plan', 'promotion', 'referral'] as const;
export type CreditType = typeof creditTypes[number];

interface CoreLedgerEntry {
  _id: string;
  amount: number;
  authorizedBy: string;
  createdAt?: string;
  creditType: CreditType;
  groupId: string;

  /**
   * If a DEBIT ledger entry, this field references the group whose credits are
   * to be pulled from for this particular debit.
   * If a CREDIT ledger entry, this field references the group said credit is designated to be used on.
   */
  forGroupId?: string;
}

export type Credit = CoreLedgerEntry;

export interface Debit extends CoreLedgerEntry {
  sparkId: string;
  withdrawnAt?: string;
}

export type LedgerEntry = Credit | Debit | (Credit & Debit);

export interface ListLedgerEntriesQueryParams {
  creditType?: CreditType;
  groupId?: string;
  limit?: number;
  offset?: number;
  creditsOnly?: boolean;
}

export interface ListLedgerEntriesResponseBody {
  data: LedgerEntry[];
  paging: IPagingProperties;
}

export interface LedgerBalanceQueryParams {
  groupId?: string;
}

export interface LedgerBalanceResponseBody {
  data: number;
}

export const createCreditSchema = z.object({
  amount: z.number().positive(),
  authorizedBy: z.string().optional(),
  creditType: z.enum(creditTypes),
  forGroupId: z.string().optional(),
});
export type CreateCreditRequest = z.infer<typeof createCreditSchema>;

export const createDebitSchema = z.object({
  amount: z.number().negative(),
  authorizedBy: z.string().optional(),
  creditType: z.enum(creditTypes),
  forGroupId: z.string().optional(),
  sparkId: z.string(),
});
export type CreateDebitRequest = z.infer<typeof createDebitSchema>;

export const createLedgerEntrySchema = z.union([createCreditSchema, createDebitSchema]);
createLedgerEntrySchema.refine(
  (data) => !['plan', 'promotion'].includes(data.creditType) || !data.forGroupId,
  {
    message: 'Cannot create plan/promo credits that are valid towards external groups',
  },
);

export type CreateLedgerEntryRequestBody = z.infer<typeof createLedgerEntrySchema>;

export type CreateLedgerEntryResponseBody = LedgerEntry;

export interface RetailerBalance {
  balance: number;
  groupName: string;
  groupId: string;
  lastEarnedDate?: string;
  logo?: string;
}
export interface ListRetailerBalancesRequestParams {
  brandId: string;
}
export type ListRetailerBalancesResponseBody = RetailerBalance[];

export const createExternalReferralSchema = z.object({
  prospectCompanyName: z.string().min(1),
  prospectContactName: z.string().min(1),
  prospectEmail: z.string().email(),
  prospectPhone: z.string().min(1).optional(),
  prospectWebsite: z.string(),
});
export type CreateExternalReferralRequest = z.infer<typeof createExternalReferralSchema>;
