import { useEffect, useMemo } from 'react';

import { SparkMetricOption } from '@constants/SparkConstants';
import moment from 'moment-timezone';

import { PercentIncreaseMetric, getPreviousPeriodEndDate } from '@sparkplug/lib';

import Form from '@components/form/Form';
import DateRangePicker from '@components/inputs/DateRangePicker';
import Select from '@components/inputs/Select';

import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { getSparkComparisonStartDate } from './percentIncreaseUtils';

const PercentIncreaseOption = ({
    options,
    durationInDays,
}: {
    options: SparkMetricOption[];
    durationInDays: number;
}) => {
    const { spark, updateSpark } = useSpark();
    const initialStartDate = useMemo(
        () => getSparkComparisonStartDate(spark),
        [spark.startDate, durationInDays],
    );
    const initialEndDate = useMemo(
        () =>
            getPreviousPeriodEndDate({
                prevStartDate: initialStartDate,
                currentPeriod: {
                    startDate: spark.startDate,
                    endDate: spark.endDate,
                },
            }),
        [spark.startDate, spark.endDate, initialStartDate],
    );

    useEffect(() => {
        if (spark.percentIncreaseData?.metric) return;
        // init with first option
        updateSpark({
            percentIncreaseData: {
                startDate: spark.percentIncreaseData?.startDate || '',
                endDate: spark.percentIncreaseData?.endDate || '',
                metric: options[0].value as PercentIncreaseMetric,
            },
        });
    }, []);
    return (
        <div className="percent-increase-select-container">
            <div className="percent-increase-select">
                <Form.Label>Metric:</Form.Label>
                <Select
                    options={options}
                    value={spark.percentIncreaseData?.metric}
                    onChange={({ target }) =>
                        updateSpark({
                            percentIncreaseData: {
                                startDate: spark.percentIncreaseData?.startDate || '',
                                endDate: spark.percentIncreaseData?.endDate || '',
                                metric: target.value,
                            },
                        })
                    }
                />
            </div>
            <div className="percent-increase-date-range">
                <Form.Label>Prev Period:</Form.Label>
                <DateRangePicker
                    pickerClassName="percent-increase-date-range-picker"
                    calendarInfo={`Select a ${durationInDays} day previous period to compare to this Spark.`}
                    isOutsideRange={(day) => day.isAfter(moment(spark.startDate))}
                    maxDate={moment(spark.startDate)}
                    endDateOffset={(day) => day.add(durationInDays, 'days')}
                    id="spark-percent-increase-date-range-picker"
                    dateStart={
                        spark.percentIncreaseData?.startDate
                            ? moment(spark.percentIncreaseData?.startDate)
                            : initialStartDate
                    }
                    dateEnd={
                        spark.percentIncreaseData?.endDate
                            ? moment(spark.percentIncreaseData?.endDate)
                            : initialEndDate
                    }
                    onApply={(startDate, endDate) => {
                        updateSpark({
                            percentIncreaseData: {
                                startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
                                endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
                                metric: spark.percentIncreaseData?.metric || 'total_units',
                            },
                        });
                    }}
                />
            </div>
        </div>
    );
};

export default PercentIncreaseOption;
