import { useQuery } from 'react-query';

import EmployeeDataAPI from '@api/EmployeeDataAPI';

export const useEmployeeLocationsQuery = (options = {}) => {
    const { data, isLoading, ...rest } = useQuery(
        ['employee-locations'],
        () => EmployeeDataAPI.getEmployeeLocations(),
        {
            staleTime: 5 * 60 * 1000, // 5 minutes
            ...options,
        },
    );

    return {
        employeeLocations: data?.data?.locations || [],
        employeeLocationsAreLoading: isLoading,
        ...rest,
    };
};
