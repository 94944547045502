import { useContext, useEffect, useMemo, useState } from 'react';

import SparksAPI from '@api/SparksAPI';
import { sortBy } from 'lodash';

import {
    Spark,
    SparkCheckoutPayout,
    formatCurrency as formatNumberToCurrency,
} from '@sparkplug/lib';

import { SparkContext } from '@contexts/SparkContext';

import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useChartData } from '@hooks/ChartDataHooks';
import { useAdvancedQuery, useQueries } from '@hooks/QueryHooks';
import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import {
    checkIfIsSparkOriginatorAccount,
    findAndUpdateSparkPayout,
    generateSparkPosProductData,
    getDetailedSparkType,
    getSelectedLocationsFromSparkSubGroups,
    getSpark,
    getSparkPosArchive,
    saveSparkPayouts,
} from '@helpers/sparks';
import SparksUI from '@helpers/toast/SparksUI';

import { IChartDataSettings } from '@app/types/ChartDataTypes';
import { ISparkPayout, ISparkPayoutGroup, ISparkSubGroup } from '@app/types/SparksTypes';

import { getDefaultSparkChartDataSettings } from './useSparkCloudChartData';
import { useSparkStandings } from './useSparkStandings';

export const useSpark = () => useContext(SparkContext);

export const getSparkQueryKey = (sparkId: string) => ['spark', sparkId];
export const useSparkQuery = (sparkId: string) => {
    const { isFetched: sparkIsReady, data: spark } = useAdvancedQuery(
        getSparkQueryKey(sparkId),
        () => getSpark(sparkId),
    );

    return { sparkIsReady, spark };
};

export const useSparkPosArchive = (sparkId?: string, isEnabled: boolean = false) => {
    const { isLoading, data, isFetched } = useAdvancedQuery(
        ['sparkPosArchive', sparkId],
        () => getSparkPosArchive(sparkId),
        {
            enabled: !!sparkId && isEnabled,
        },
    );

    return {
        isFetched,
        isLoading,
        data,
    };
};

export const useSparkPosArchives = (
    sparkIds: (string | undefined)[],
    isEnabled: boolean = false,
) => {
    const queryResults = useQueries(
        sparkIds.map((sparkId) => ({
            queryKey: ['sparkPosArchive', sparkId],
            queryFn: () => getSparkPosArchive(sparkId),
            enabled: !!sparkId && isEnabled,
        })),
    );

    return queryResults;
};
export interface ICustomSparkChartSettings
    extends Partial<Pick<IChartDataSettings, 'locationIds' | 'precision' | 'type'>> {}

export const useSparkChartData = ({
    spark,
    isEnabled = true,
    includeChartDisplayData = true,
}: {
    spark: Spark;
    isEnabled?: boolean;
    includeChartDisplayData?: boolean;
}) => {
    const defaultSparkChartSettings = useMemo(
        () => getDefaultSparkChartDataSettings(spark),
        [spark],
    );

    const chartData = useChartData({
        initialSettings: defaultSparkChartSettings,
        isEnabled: !!spark._id && isEnabled,
        accountId: spark.groupId,
        includeChartDisplayData,
    });
    const { sparkStandings, flexibleEmployeeIdsWithSales } = useSparkStandings({
        spark,
        chartSettings: defaultSparkChartSettings as IChartDataSettings,
        isEnabled,
        includeChartDisplayData,
    });

    const value = useMemo(
        () => ({
            isCalculatingSparkChartData: chartData.isCalculatingChartData,
            sparkChartData: chartData,
            sparkChartSettings: chartData.chartSettings,
            updateSparkChartSettings: (updatedSettings: ICustomSparkChartSettings) =>
                chartData.updateChartSettings(updatedSettings),
            sparkStandings,
            sparkTransactionSummariesExist: chartData.chartHasTransactions,
            flexibleEmployeeIdsWithSales,
        }),
        [chartData, sparkStandings],
    );

    return value;
};

const mapSparkRewards = (rewards: Partial<SparkCheckoutPayout>[]) => {
    return rewards.map((reward) => {
        const {
            amount = 0,
            name = '',
            flexibleEmployeeId = '',
            posEmployeeProfileId = '',
            userId = '',
            posEmployeeProfileIds = [],
            status = 'pending',
            _id,
            claimInstructions,
            ui = {},
        } = reward;

        const fulfilledBySparkplug = reward?.type === 'payout';

        // API stores amount as cents
        const amountAsDollars = amount / 100;
        const text = fulfilledBySparkplug ? formatNumberToCurrency(amountAsDollars, true) : name;

        const payout: ISparkPayout = {
            _id,
            amount: amountAsDollars,
            fulfilledBySparkplug,
            text,
            flexibleEmployeeId,
            userId,
            posEmployeeProfileId,
            posEmployeeProfileIds,
            status: status === 'confirmed' ? 'approved' : ui?.status ?? (status as any),
            claimInstructions,
            isModified: ui.isModified ?? false,
        };

        return payout;
    });
};

export const useSparkRewardsQuery = (sparkId: string) => {
    const {
        isLoading: isLoadingSparkRewards,
        data: sparkRewards = [],
        refetch: refetchSparkRewards,
    } = useAdvancedQuery(
        ['spark', sparkId, 'rewards'],
        async () => (await SparksAPI.getSparkRewards(sparkId)).data,
        { enabled: !!sparkId },
    );

    return {
        isLoadingSparkRewards,
        sparkRewards,
        refetchSparkRewards,
    };
};

const useMultiLeaderboardSparkPayouts = (
    currentSparkId: string,
    initialSparkSubGroups: ISparkSubGroup[],
    isEnabled: boolean,
) => {
    const { sparkSubGroups, queries } = useMemo(() => {
        return {
            sparkSubGroups: initialSparkSubGroups,
            queries: initialSparkSubGroups.map(({ sparkId = '' }) => {
                return {
                    queryKey: ['spark', sparkId, 'rewards'],
                    queryFn: async () => (await SparksAPI.getSparkRewards(sparkId)).data,
                    enabled: !!sparkId && isEnabled,
                };
            }),
        };
    }, [currentSparkId, initialSparkSubGroups, isEnabled]);

    const queryResults = useQueries(queries);

    const payoutGroups: ISparkPayoutGroup[] = useMemo(() => {
        if (isEnabled) {
            const archivedSparkRewards = queryResults.map(({ data: sparkRewards = [] }, i) => {
                const sparkSubGroup = sparkSubGroups?.[i];

                const { sparkId = '', locations, finalizedAt } = sparkSubGroup;

                return {
                    sparkId,
                    locationLabel: locations.map(({ label }) => label).join(', '),
                    payouts: mapSparkRewards(sparkRewards),
                    payoutsAreFinalized: !!finalizedAt,
                    payoutsFinalizedAt: finalizedAt,
                };
            });

            return sortBy(archivedSparkRewards, 'locationLabel');
        }

        return [];
    }, [isEnabled, queryResults, sparkSubGroups]);

    const payoutsDidInitialize = queryResults.some(({ isFetched }) => isFetched);
    const isLoadingPayouts = queryResults.some(({ isLoading }) => isLoading);

    return {
        payoutsDidInitialize,
        isLoadingPayouts,
        payouts: payoutGroups.flatMap(({ payouts }) => payouts),
        payoutGroups,
    };
};

export const useSparkPayouts = (spark: Spark, sparkSubGroups: ISparkSubGroup[]) => {
    // const [mounted, setMounted] = useState(true);
    const detailedSparkType = getDetailedSparkType(spark);
    const isMultiLeaderboard = detailedSparkType === 'leaderboardMulti';

    const [isLoading, setIsLoading] = useState(true);
    const [isFinalized, setIsFinalized] = useState<boolean>(false);
    const [finalizedAt, setFinalizedAt] = useState<string>();

    const {
        isLoadingSparkRewards,
        sparkRewards: currentSparkRewards,
        refetchSparkRewards,
    } = useSparkRewardsQuery(spark?._id);

    const {
        payoutsDidInitialize: multiLeaderboardPayoutsDidInitialize,
        payouts: multiLeaderboardPayouts,
        payoutGroups = [],
    } = useMultiLeaderboardSparkPayouts(spark?._id, sparkSubGroups, isMultiLeaderboard);

    const multiLeaderboardPayoutsAreFinalized = payoutGroups.every(
        ({ payoutsAreFinalized }) => payoutsAreFinalized,
    );

    const multiLeaderboardPayoutsFinalizedAt = useMemo(() => {
        const { payoutsFinalizedAt } =
            payoutGroups.find(
                ({ payoutsFinalizedAt: _payoutsFinalizedAt }) => _payoutsFinalizedAt,
            ) || {};
        return payoutsFinalizedAt;
    }, [payoutGroups]);

    useEffect(() => {
        if (!isLoadingSparkRewards) {
            const { confirmedAt } =
                currentSparkRewards.find(({ confirmedAt: _confirmedAt }) => _confirmedAt != null) ||
                {};

            const _isFinalized =
                confirmedAt != null || spark.internalTracking?.payoutStatus === 'paid';

            setIsLoading(isLoadingSparkRewards);
            setIsFinalized(_isFinalized);
            setFinalizedAt(confirmedAt);
        }

        // return () => {
        //     setMounted(false);
        // };
    }, [isLoadingSparkRewards, currentSparkRewards]);

    const payouts: ISparkPayout[] = useMemo(
        () => mapSparkRewards(currentSparkRewards),
        [currentSparkRewards],
    );

    const updatePayouts = (updatedPartialPayouts: Partial<ISparkPayout>[]) => {
        const updatedPayouts: ISparkPayout[] = [];

        // Update existing
        updatedPartialPayouts.forEach((updatedPartialPayout) => {
            const { flexibleEmployeeId, status = 'pending' } = updatedPartialPayout;

            if (flexibleEmployeeId) {
                const customPayout = findAndUpdateSparkPayout({
                    payouts,
                    flexibleEmployeeId,
                    updatedData: {
                        ...updatedPartialPayout,
                        status,
                    },
                });

                updatedPayouts.push(customPayout);
            }
        });

        toast.promise(saveSparkPayouts(spark, updatedPayouts), {
            loading: null,
            success: () => {
                refetchSparkRewards();

                return 'Payouts saved';
            },
            error: 'Something went wrong',
        });
    };

    const confirmPayouts = async () => {
        const { finalizedAt: updatedFinalizedAt } = await SparksUI.finalizeSpark(spark);

        // if (mounted) {
        setIsFinalized(true);
        setFinalizedAt(updatedFinalizedAt);
        refetchSparkRewards();
        // }
    };

    return {
        isLoadingPayouts: isMultiLeaderboard ? !multiLeaderboardPayoutsDidInitialize : isLoading,
        payoutsAreFinalized: isFinalized,
        payoutsFinalizedAt: finalizedAt,
        payouts: isMultiLeaderboard ? multiLeaderboardPayouts : payouts,
        payoutGroups,
        multiLeaderboardPayoutsAreFinalized,
        multiLeaderboardPayoutsFinalizedAt: multiLeaderboardPayoutsAreFinalized
            ? multiLeaderboardPayoutsFinalizedAt
            : undefined,
        updatePayouts,
        confirmPayouts,
        refetchSparkRewards,
    };
};

export const useSparkInvoiceUrl = (sparkId: string, accountId: string | undefined) => {
    const { userIsAdmin, userIsSuperAdmin } = useApp();
    const { spark } = useSparkQuery(sparkId);

    const { isFetched: sparkInvoiceUrlIsReady, data } = useAdvancedQuery(
        ['spark', sparkId, 'invoiceUrl'],
        async () => (await SparksAPI.getSparkInvoiceUrl(sparkId!)).data,
        {
            enabled: !!(
                spark &&
                accountId &&
                checkIfIsSparkOriginatorAccount({ spark, accountId }) &&
                userIsAdmin &&
                !userIsSuperAdmin
            ),
        },
    );
    const { invoiceUrl: sparkInvoiceUrl } = data ?? {};

    return { sparkInvoiceUrlIsReady, sparkInvoiceUrl };
};

export const useSparkLocations = () => {
    const { detailedSparkType, sparkSubGroups = [], sparkPosData } = useSpark();

    if (detailedSparkType === 'leaderboardMulti') {
        return getSelectedLocationsFromSparkSubGroups(sparkSubGroups);
    }

    return sparkPosData?.locations ?? [];
};

export const useSparkPosProductData = (spark: Spark | undefined) => {
    const { accountPosBrands, accountPosCategories, accountPosProducts, accountPosDataIsReady } =
        useAccountPosDataQuery(spark?.groupId ?? '', undefined, spark?.posProductIds);

    return {
        sparkPosProductDataIsReady: accountPosDataIsReady,
        sparkPosProductData: generateSparkPosProductData({
            spark,
            accountPosBrands,
            accountPosCategories,
            accountPosProducts,
        }),
    };
};

export const getSparkRetailersQueryKey = (accountId: string, status?: string) => [
    'sparkRetailers',
    accountId,
    status,
];

export const getSparkMarketsQueryKey = (accountId: string, status?: string) => [
    'sparkMarkets',
    accountId,
    status,
];

export const getSparkBrandsQueryKey = (accountId: string, status?: string) => [
    'sparkBrands',
    accountId,
    status,
];

export const getSparkVendorsQueryKey = (accountId: string, status?: string) => [
    'sparkVendors',
    accountId,
    status,
];

export const useSparkRetailersQuery = (
    accountId: string,
    status?: string,
    isEnabled: boolean = true,
) => {
    const {
        data,
        isLoading,
        isFetched,
        refetch: refetchSparkRetailers,
    } = useAdvancedQuery(
        getSparkRetailersQueryKey(accountId, status),
        () => SparksAPI.getSparkRetailers(accountId, status),
        {
            enabled: isEnabled && !!accountId,
        },
    );

    return {
        sparkRetailers: data?.retailers ?? [],
        sparkRetailersAreLoading: isLoading,
        sparkRetailersAreReady: isFetched,
        refetchSparkRetailers,
    };
};

export const useSparkMarketsQuery = (
    accountId: string,
    status?: string,
    isEnabled: boolean = true,
) => {
    const {
        data,
        isLoading,
        isFetched,
        refetch: refetchSparkMarkets,
    } = useAdvancedQuery(
        getSparkMarketsQueryKey(accountId, status),
        () => SparksAPI.getSparkMarkets(accountId, status),
        {
            enabled: isEnabled && !!accountId,
        },
    );
    return {
        sparkMarkets: data?.markets ?? [],
        sparkMarketsAreLoading: isLoading,
        sparkMarketsAreReady: isFetched,
        refetchSparkMarkets,
    };
};

export const useSparkBrandsQuery = (
    accountId: string,
    status?: string,
    isEnabled: boolean = true,
) => {
    const {
        data,
        isLoading,
        isFetched,
        refetch: refetchSparkBrands,
    } = useAdvancedQuery(
        getSparkBrandsQueryKey(accountId, status),
        () => SparksAPI.getSparkBrands(accountId, status),
        {
            enabled: isEnabled && !!accountId,
        },
    );

    return {
        sparkBrands: data?.sparkBrands ?? [],
        sparkBrandsAreLoading: isLoading,
        sparkBrandsAreReady: isFetched,
        refetchSparkBrands,
    };
};

export const useSparkVendorsQuery = (
    accountId: string,
    status?: string,
    isEnabled: boolean = true,
) => {
    const {
        data,
        isLoading,
        isFetched,
        refetch: refetchSparkVendors,
    } = useAdvancedQuery(
        getSparkVendorsQueryKey(accountId, status),
        () => SparksAPI.getSparkVendors(accountId, status),
        {
            enabled: isEnabled && !!accountId,
        },
    );

    return {
        sparkVendors: data?.sparkVendors ?? [],
        sparkVendorsAreLoading: isLoading,
        sparkVendorsAreReady: isFetched,
        refetchSparkVendors,
    };
};
