import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { clsx } from 'clsx';

import { Spark, SparkResponseNotification, formatCurrency } from '@sparkplug/lib';

import { getCourseTypeByAccount } from '@core/accounts';

import { useUpdateSparkSnapsMutation } from '@features/spark-snaps';
import SparkSnapsPanel from '@features/spark-snaps/components/SparkSnapsPanel';

import { SparkProvider } from '@contexts/SparkContext';
import { SparkManagementModalProvider } from '@contexts/SparkManagementModalContext';

import Button from '@components/buttons/Button';
import ManagerChip from '@components/chips/ManagerChip';
import { SparkNotFound } from '@components/graphics';
import { SettingsApplications as SettingsIcon } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import PageLoading from '@components/layout/PageLoading';
import Paper from '@components/layout/Paper';
import ConfirmModal from '@components/overlays/ConfirmModal';
import { CreateEditTrainingModal } from '@components/overlays/CreateEditTrainingModal/CreateEditTrainingModal';
import LinearProgress from '@components/progress/LinearProgress';
import { useParams } from '@components/router';
import SparkDetailsPanel from '@components/sparks/SparkDetailsPanel';
import SparkMoreButton from '@components/sparks/SparkMoreButton';
import { WrappedSparkPaymentSummaryPanel } from '@components/sparks/SparkPaymentSummaryPanel/SparkPaymentSummaryPanel';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkResponseNotification } from '@hooks/NotificationsHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import Intercom from '@helpers/Intercom';
import {
    checkIfIsSparkOriginatorAccount,
    formatSparkInfo,
    getOtherGroupName,
    getSelectedLocationsFromSparkSubGroups,
    getSelectedParticipantsFromSparkSubGroups,
    userCanCloneSpark,
} from '@helpers/sparks';
import { downloadStringAsTxt, isEmpty } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';
import { TSparkStatusColor } from '@app/types/SparksTypes';

import InternalSparkTrackingPanel from './InternalSparkTrackingPanel';
import MoreDataModal, { TMoreDataTitles } from './MoreDataModal';
import SparkDetailsCharts from './SparkDetailsCharts';
import SparkProductsModal from './SparkProductsModal';
import { SparkRewardsPanel } from './SparkRewardsPanel';
import SparkTrainingPanel from './SparkTrainingPanel/SparkTrainingPanel';

import './SparkDetailsView.scss';

const SparkProgressPanel = ({
    progress,
    color,
}: {
    progress: number;
    color?: TSparkStatusColor;
}) => (
    <Paper className="spark-details-header">
        <LinearProgress value={progress} color={color} size="lg" />
    </Paper>
);

const SparkRejectedMessage = ({ spark, account }: { spark: Spark; account: IAccount }) => {
    const otherGroupName = getOtherGroupName(spark, account);
    const { notification } = useSparkResponseNotification(spark._id);

    // Old rejection notice: just a string on the notification
    /* eslint-disable */
    const message = (
        <span>
            <strong>{otherGroupName}</strong>
            {' declined this proposed Spark with the response "'}
            <em>{(notification?.data as SparkResponseNotification)?.responseText}</em>
            {'"'}
        </span>
    );
    /* eslint-enable */

    // Updated rejection notice: declining user's name with required core message and optional detailed message
    const detailedMessageBase = `${spark.declinedByUser} at ${otherGroupName} declined this proposed Spark with the reason: `;
    const detailedMessage = (
        <span>
            {detailedMessageBase}
            <strong>{spark.declineResponse?.reason}</strong>
            {spark.declineResponse?.message && (
                <span className="decline-details"> - {spark.declineResponse?.message}</span>
            )}
        </span>
    );

    return (
        <CalloutMessage
            className="spark-rejected-message"
            color="red"
            variant="default"
            title="Spark Declined"
            message={spark.declinedByUser ? detailedMessage : message}
        />
    );
};

export const SparkDetailsView: FC = () => {
    const { history, user, userIsSuperAdmin } = useApp();

    const {
        sparkIsReady,
        detailedSparkType,
        spark,
        isRecurringSpark,
        sparkPosData,
        sparkPosDataIsReady,
        sparkSubGroups,
        sparkCommissionMap,
        sparkNotFound,
        refetchSparkData,
    } = useSpark();

    const { account } = useSparkplugAccount();

    const [confirmModalProps, setConfirmModalProps] = useState({
        isVisible: false,
        title: '',
        message: '',
    });

    const [moreDataModalProps, setMoreDataModalProps] = useState<{
        isVisible: boolean;
        data: any;
        title?: TMoreDataTitles;
    }>({ isVisible: false, data: null });

    const [showCreateEditTrainingModal, setShowCreateEditTrainingModal] = useState(false);
    const closeMoreDataModal = useCallback(
        () => setMoreDataModalProps({ isVisible: false, data: null, title: null }),
        [],
    );

    const openMoreLocationsModal = useCallback(() => {
        const locations =
            detailedSparkType === 'leaderboardMulti'
                ? getSelectedLocationsFromSparkSubGroups(sparkSubGroups)
                : sparkPosData.locations;
        const locationLabels = locations.map(({ label }) => label);

        setMoreDataModalProps({
            isVisible: !isEmpty(locationLabels),
            data: locationLabels,
            title: 'Locations',
        });
    }, [sparkPosData, sparkSubGroups]);

    const isManagerGoal = detailedSparkType === 'goalManager';

    const openMoreParticipantsModal = useCallback(() => {
        const participants =
            detailedSparkType === 'leaderboardMulti'
                ? getSelectedParticipantsFromSparkSubGroups(sparkSubGroups)
                : sparkPosData.participants;
        const participantLabels = participants.map(
            ({ fullName, locationNames, managedLocationIds }) => (
                <>
                    {fullName}{' '}
                    {!isManagerGoal &&
                        managedLocationIds &&
                        managedLocationIds.length > 0 &&
                        account?.type === 'retailer' && <ManagerChip />}{' '}
                    {isManagerGoal && `(${locationNames.join(', ')})`}
                </>
            ),
        );

        setMoreDataModalProps({
            isVisible: !isEmpty(participantLabels),
            data: participantLabels,
            title: isManagerGoal ? 'Managers' : 'Employees',
        });
    }, [detailedSparkType, sparkPosData, sparkSubGroups]);

    const [sparkProductsModalIsVisible, setSparkProductsModalIsVisible] = useState(false);

    const openSparkProductsModal =
        userIsSuperAdmin || spark.type !== 'commission'
            ? () => {
                  setSparkProductsModalIsVisible(true);
              }
            : undefined;

    const closeSparkProductsModal = () => {
        setSparkProductsModalIsVisible(false);
    };

    const isAcceptedSpark = ['accepted', 'none'].includes(spark?.requestState || '');

    useEffect(() => {
        if (sparkIsReady) {
            if (account?._id !== spark?.groupId && account?._id !== spark?.originatorGroupId) {
                history.push(`/${account?._id}/sparks`);
            }
        }
    }, [sparkIsReady, spark]);

    const sparkInfo = formatSparkInfo(
        spark.startDate || '',
        spark.endDate || '',
        spark.requestState,
        spark.recurringSchedule,
    );

    // TODO: `canShowMoreDataModal` is a temporary fix for a `useTable` bug where data is not recalculated
    // when it is initially empty
    const canShowMoreDataModal = moreDataModalProps.data?.length;

    const isSuperAdmin = user?.role === 'super-admin';
    const isSparkOriginatorAccount = checkIfIsSparkOriginatorAccount({
        spark,
        accountId: account?._id,
    });

    const canCloneSpark = useMemo(
        () => !!user && !!account && userCanCloneSpark(user, account, spark),
        [user, account, spark],
    );

    const showInvoiceButton = !isSuperAdmin && isSparkOriginatorAccount && !!spark.finalizedAt;
    const isComplete = sparkInfo?.status === 'Complete';
    const showCheckoutButton = isComplete && isSuperAdmin;
    const showPaymentSummaryPanel = showInvoiceButton || showCheckoutButton;

    const exportSparkDetails = () => {
        const brandIds = spark.posBrandIds.length
            ? spark.posBrandIds
            : spark.productFilters?.brandIds ?? [];

        const brandStrs = brandIds.reduce((res, brandId) => {
            const brand = sparkPosData.brands.find(({ _id }) => _id === brandId);

            return brand ? `${res}${brand?.name}\n` : res;
        }, 'BRANDS\n');

        const productStr = sparkPosData.products.reduce((res, { _id, name }) => {
            const commission = spark.commissions.find(({ posProductId }) => posProductId === _id);

            if (commission) {
                const formattedAmount = commission.type
                    ? formatCurrency(commission.value, true)
                    : `${commission.value}%`;
                return `${res}${name} - ${formattedAmount}\n`;
            }

            return `${res}${name}\n`;
        }, 'PRODUCTS\n');

        const str = `${brandStrs}\n\n${productStr}`;
        downloadStringAsTxt(str, `${account?.name} Sparks ${spark.name}`);
    };

    const { updateSparkSnapsAsync } = useUpdateSparkSnapsMutation({
        sparkId: spark._id,
    });
    if (sparkNotFound) {
        return (
            <Box sx={{ textAlign: 'center' }}>
                <EmptyStateDisplay
                    graphic={<SparkNotFound />}
                    label="Spark not found"
                    smallText="We’re sorry, the Spark you’re looking for was deleted or does not exist."
                    actionButton={
                        <Button
                            onClick={() => history.push(`/${account?._id}/sparks/`)}
                            color="blue"
                            variant="filled"
                        >
                            View Active Sparks
                        </Button>
                    }
                />
            </Box>
        );
    }
    const { status } = formatSparkInfo(spark.startDate || '', spark.endDate || '');

    return (
        <>
            {sparkIsReady ? (
                <div
                    className={clsx(
                        'spark-details-view',
                        `spark-request-state-${spark?.requestState}`,
                    )}
                >
                    <PageHeader
                        className="spark-details-header"
                        title={[
                            {
                                url:
                                    history?.location?.state?.prevUrl || `/${account?._id}/sparks/`,
                                title: `Sparks`,
                            },
                            spark.name,
                        ]}
                        metaTitle={`${spark.name} | Sparks`}
                    >
                        {isSuperAdmin && isAcceptedSpark && (
                            <Toolbar collapseOnMobile>
                                {sparkPosDataIsReady && (
                                    <Toolbar.Button
                                        variant="flat"
                                        color="blue"
                                        onClick={exportSparkDetails}
                                    >
                                        Export Spark Details
                                    </Toolbar.Button>
                                )}
                                <Toolbar.Button
                                    startIcon={<SettingsIcon />}
                                    variant="filled"
                                    color="blue"
                                    onClick={() => {
                                        history.push(
                                            `/${account?._id}/sparks/${spark?._id}/manage`,
                                        );
                                    }}
                                >
                                    Manage Spark
                                </Toolbar.Button>
                            </Toolbar>
                        )}
                    </PageHeader>

                    {spark?.requestState === 'rejected' && (
                        <SparkRejectedMessage spark={spark} account={account!} />
                    )}

                    <div className="app-view spark-details">
                        <div className="spark-details-body">
                            <div className="content">
                                {isSuperAdmin && <InternalSparkTrackingPanel />}

                                <SparkProgressPanel
                                    progress={sparkInfo.progress}
                                    color={sparkInfo.color}
                                />

                                <SparkDetailsPanel
                                    spark={spark}
                                    sparkPosData={sparkPosData}
                                    sparkPosDataIsReady={sparkPosDataIsReady}
                                    sparkSubGroups={sparkSubGroups}
                                    detailedSparkType={detailedSparkType}
                                    onLocationsClick={openMoreLocationsModal}
                                    onProductClick={openSparkProductsModal}
                                    onParticipantsClick={openMoreParticipantsModal}
                                    moreButton={
                                        <SparkMoreButton
                                            originRoute="spark-details"
                                            variant="vertical"
                                            spark={spark}
                                            showViewOption={false}
                                            hasExistingTrainingCourse={spark.trainingEnabled}
                                            onTraining={
                                                account?.zoltrainEnabled ||
                                                (account?.seedTalentEnabled &&
                                                    !isComplete &&
                                                    !isRecurringSpark)
                                                    ? () => setShowCreateEditTrainingModal(true)
                                                    : undefined
                                            }
                                        />
                                    }
                                />

                                {spark.trainingEnabled && spark.courseData && (
                                    <SparkTrainingPanel />
                                )}

                                <SparkSnapsPanel
                                    sparkId={spark._id}
                                    accountId={spark?.originatorGroupId ?? spark.groupId}
                                    initialSnapIds={spark.snaps?.snapIds}
                                    onChange={updateSparkSnapsAsync}
                                    creatorHasSnapsEntitlement={
                                        spark.snaps?.creatorHasSnapsEntitlement
                                    }
                                    sparkStatus={status}
                                />
                                <SparkRewardsPanel
                                    spark={spark}
                                    associatedProducts={sparkPosData?.associatedProducts}
                                    sparkCommissionMap={sparkCommissionMap}
                                />

                                {showPaymentSummaryPanel && (
                                    <WrappedSparkPaymentSummaryPanel
                                        showCheckoutButton={showCheckoutButton}
                                        showInvoiceButton={showInvoiceButton}
                                    />
                                )}
                                <SparkDetailsCharts
                                    spark={spark}
                                    sparkPosData={sparkPosData}
                                    detailedSparkType={detailedSparkType!}
                                    isAcceptedSpark={isAcceptedSpark}
                                    openParticipantsModal={openMoreParticipantsModal}
                                />

                                <ConfirmModal
                                    isVisible={confirmModalProps?.isVisible}
                                    title={confirmModalProps?.title}
                                    message={<p>{confirmModalProps?.message}</p>}
                                    onConfirm={() => {
                                        Intercom.open();
                                    }}
                                    onClose={() => {
                                        setConfirmModalProps((prevProps) => ({
                                            ...prevProps,
                                            isVisible: false,
                                        }));
                                    }}
                                    confirmText="Continue"
                                />

                                {showCreateEditTrainingModal && (
                                    <CreateEditTrainingModal
                                        isVisible={showCreateEditTrainingModal}
                                        onClose={() => {
                                            refetchSparkData();
                                            setShowCreateEditTrainingModal(false);
                                        }}
                                        accountCanAddCourse={
                                            account?.zoltrainEnabled || account?.seedTalentEnabled
                                        }
                                        accountCourseType={getCourseTypeByAccount(account)}
                                        spark={spark}
                                    />
                                )}

                                {canShowMoreDataModal && (
                                    <MoreDataModal
                                        {...moreDataModalProps}
                                        onClose={closeMoreDataModal}
                                    />
                                )}

                                {sparkProductsModalIsVisible && (
                                    <SparkProductsModal
                                        isVisible={sparkProductsModalIsVisible}
                                        onClose={closeSparkProductsModal}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <PageLoading label="Loading Spark..." />
            )}
        </>
    );
};

export default () => {
    const { sparkId } = useParams<{ sparkId: string }>();

    return (
        <SparkProvider sparkId={sparkId}>
            <SparkManagementModalProvider>
                <SparkDetailsView />
            </SparkManagementModalProvider>
        </SparkProvider>
    );
};
