import { useLayoutEffect } from 'react';

import TimePicker from '@components/inputs/TimePicker';

import { ArrowRightIcon } from '@app/componentLibrary/Icon/ArrowRight';
import InputGroup from '@app/componentLibrary/Input/InputGroup';
import Typography from '@app/componentLibrary/Typography';

interface EventTimePickerProps {
    /**
     * @param startTime - Format: HH:mm:ss
     */
    startTime: string;
    /**
     * @param endTime - Format: HH:mm:ss
     */
    endTime: string;
    /**
     * @param startTime - Format: HH:mm:ss
     */
    onStartTimeChange: (startTime: string) => void;
    /**
     * @param endTime - Format: HH:mm:ss
     */
    onEndTimeChange: (endTime: string) => void;
    /**
     * @param timeZone
     */
    timeZoneLabel: string;
}
export const EventTimePicker = ({
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
    timeZoneLabel,
}: EventTimePickerProps) => {
    // Shift the endTime to match the startTime if the startTime is greater than the endTime
    useLayoutEffect(() => {
        if (startTime && startTime > endTime) {
            onEndTimeChange(startTime);
        }
    }, [startTime]);

    // Shift the startTime to match the endTime if the endTime is less than the startTime
    useLayoutEffect(() => {
        if (endTime && endTime < startTime) {
            onStartTimeChange(endTime);
        }
    }, [endTime]);

    return (
        <section className="flex flex-row gap-3">
            <InputGroup label="Start Time*">
                <TimePicker value={startTime} onChange={onStartTimeChange} />
            </InputGroup>
            <section className="flex items-center pt-6">
                <ArrowRightIcon className="h-5 w-5 text-gray-800" />
            </section>
            <InputGroup label="End Time*">
                <TimePicker value={endTime} onChange={onEndTimeChange} />
            </InputGroup>
            <section className="flex items-center pt-6">
                <Typography variant="sm" className="text-gray-800 max-w-[180px] break-words">
                    {timeZoneLabel}
                </Typography>
            </section>
        </section>
    );
};
