import axios from 'axios';

import { useAdvancedMutation, useQueryClient } from '@hooks/QueryHooks';

import { coursesCacheKey } from '../queries/useGetAllCourses';

const API = {
    deleteLearningResource: async (id: string) => {
        return axios.delete(`/api/v1/learning-resource/${id}`);
    },
};

export const useDeleteLearningResource = () => {
    const queryClient = useQueryClient();
    const { mutateAsync: deleteLearningResource } = useAdvancedMutation(
        (id: string) => API.deleteLearningResource(id),
        {
            customOptions: {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['learning-resource'] });
                    queryClient.invalidateQueries({ queryKey: ['survey'] });
                    queryClient.invalidateQueries({ queryKey: [coursesCacheKey] });
                },
            },
            toastOptions: {
                loading: 'Deleting learning resource...',
                success: 'Course deleted',
                error: 'Failed to delete learning resource.',
            },
        },
    );
    return { deleteLearningResource };
};
