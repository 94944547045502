import { Spark } from '../spark';
import { z } from 'zod';

/**
 * - `Pending`: A Tremendous link has been successfully generated
 * - `Complete`: The actual transfer of funds has been performed
 */
export const WithdrawalStatuses = ['Pending', 'Completed'] as const;

/**
 * - `Pending`: A Tremendous link has been successfully generated
 * - `Complete`: The actual transfer of funds has been performed
 */
export type WithdrawalStatus = typeof WithdrawalStatuses[number];

/**
 * - returns the WithdrawalFee percentage based on the WithdrawalType
 */
export const WithdrawalFees: Record<WithdrawalType, number> = {
  'Virtual Visa': 0,
  'Amazon Gift Card': 0,
  'Virtual Visa International': 0,
  /**
   * We charge 2.5% for Venmo to minimize the user grief we'd experience were
   * Venmo to start charging us their standard fee for these types of transactions.  Users
   * would likely be much more upset going from zero charge to a sudden 2.5% fee than they
   * would if it was in place the entire time.
   */
  Venmo: 2.5,
  /**
   * We charge 2.5% for ACH to minimize the user grief we'd experience were
   * Venmo to start charging us their standard fee for these types of transactions.  Users
   * would likely be much more upset going from zero charge to a sudden 2.5% fee than they
   * would if it was in place the entire time.
   */
  ACH: 2.5,
};

/**
 * - returns the WithdrawalFee based off the wallet balance and the
 *   withdrawalFee that is associated with that withdrawalType
 */
export const calculateWithdrawalFee = (type: WithdrawalType, wallet: IWallet): number => {
  const withdrawalFee = WithdrawalFees[type];
  if (withdrawalFee === 0) {
    return 0;
  }

  return Math.round((wallet.balance * (withdrawalFee / 100) + Number.EPSILON) * 100) / 100;
};

/**
 * - returns the Tremendous ProductId associated with the WithdrawalType
 */
export const ProductIDs: Record<WithdrawalType, string> = {
  'Virtual Visa': 'Q24BD9EZ332JT',
  'Amazon Gift Card': 'OKMHM2X2OHYV',
  'Virtual Visa International': '',
  Venmo: 'TKIHHHAJU20C',
  ACH: 'ET0ZVETV5ILN',
};

export const ProdCampaignIds: Record<WithdrawalType, string> = {
  'Virtual Visa': 'WP8UP2SYGOLF',
  'Amazon Gift Card': 'NL61DHSKYF3D',
  'Virtual Visa International': 'EKISUXX1C9R3',
  Venmo: '855JU0JUH3RC',
  ACH: 'G2AGYA78ZS5J',
};

export const TestflightCampaignIds: Record<WithdrawalType, string> = {
  'Virtual Visa': 'HSW0M4YDBBAK',
  'Amazon Gift Card': '3EQI7QFN83MR',
  'Virtual Visa International': '',
  Venmo: '', //  Venmo doesn't have a testflight campaign
  ACH: 'MZCMX2G3WNDH',
};

export const WithdrawalTypes = [
  'Virtual Visa',
  'Virtual Visa International',
  'Amazon Gift Card',
  'Venmo',
  'ACH',
] as const;
export type WithdrawalType = typeof WithdrawalTypes[number];

export const InternationalWithdrawalTypes = [
  'Virtual Visa International',
  'Amazon Gift Card',
] as const;
export type InternationalWithdrawalType = typeof InternationalWithdrawalTypes[number];

export const DomesticWithdrawalTypes = [
  'Virtual Visa',
  'Amazon Gift Card',
  'Venmo',
  'ACH',
] as const;
export type DomesticWithdrawalType = typeof DomesticWithdrawalTypes[number];

/**
 * - `Pending`: The deposit was created, but has yet to be confirmed
 * - `Confirmed`: The deposit has been confirmed for the employee, but has yet to be claimed
 * - `Claimed`: The deposit has been entered successfully into the employee's ledger
 */
export const DepositStatuses = ['Pending', 'Confirmed', 'Claimed'] as const;
/**
 * - `Pending`: The deposit was created, but has yet to be confirmed
 * - `Confirmed`: The deposit has been confirmed for the employee, but has yet to be claimed
 * - `Claimed`: The deposit has been entered successfully into the employee's ledger
 */
export type DepositStatus = typeof DepositStatuses[number];

export interface IEmployeeDeposit {
  _id: string;
  amount?: number;
  claimedAt?: Date;
  claimInstructions?: string;
  confirmedAt?: Date;
  confirmedBy?: string;
  nonFbsReceiptConfirmedAt?: Date;
  isLegacy?: boolean;
  /**
   * The tremendous url of the corresponding legacy Reward.
   *
   * @deprecated This field _only_ exists for backwards compatibility with legacy Rewards.
   * Moving forward, deposits themselves shouldn't be associated with Tremendous payouts
   * **at all**, and will instead be a datapoint on the `EmployeeWithdrawal` model.
   *
   * So unless you are working **specifically** with legacy rewards, you should ignore this property.
   */
  deliveryLink?: string;
  name?: string;
  notifiedAt?: Date;
  posEmployeeProfileIds?: string[];
  sparkId?: string;
  learningResourceId?: string;
  status: DepositStatus;
  userId?: string;
}

export interface WalletEmployeeDeposit extends IEmployeeDeposit {
  createdAt?: Date;
  learningResourceBrandInfo?: {
    name: string;
    photo: string;
  };
  spark?: Pick<
    Spark,
    | 'name'
    | 'startDate'
    | 'endDate'
    | 'sparkBrand'
    | 'emoji'
    | 'recurringSchedule'
    | 'description'
    | 'type'
    | 'originatorGroupId'
    | 'groupId'
  >;
}

export interface IEmployeeWithdrawal {
  _id: string;
  createdAt: string;
  amount: number;
  status: WithdrawalStatus;
  tremendousLink: string;
  tremendousId?: string;
  redeemedAt?: string;
  userId: string;
  withdrawalFee?: number;
  withdrawalType: WithdrawalType;
}

export const createEmployeeWithdrawalSchema = z.object({
  withdrawalType: z.enum(WithdrawalTypes, {
    required_error: 'WithdrawalType is required',
    invalid_type_error: 'Must be supported withdrawalType',
  }),
});

export type CreateCashOutPayload = z.infer<typeof createEmployeeWithdrawalSchema>;

export interface CashOutResponseBody {
  data: IEmployeeWithdrawal;
}

export interface IWallet {
  balance: number;
  withdrawals: IEmployeeWithdrawal[];
  deposits: WalletEmployeeDeposit[];
  lifetimeEarnings: number;
}
export type GetWalletPathParams = {};
export type GetWalletResponseBody = IWallet;

export interface ClaimEmployeeDepositResponseBody {
  data: IEmployeeDeposit;
}

export interface ConfirmNonFbsReceiptEmployeeDepositResponseBody {
  data: IEmployeeDeposit;
}
