import { useEffect, useMemo, useState } from 'react';

import { omit } from 'lodash';

import { IPagingProperties, ListEventsQueryParams, UIEvent } from '@sparkplug/lib';

import { getEventDisplayStatus } from '@features/events/helpers/eventUtils';
import { usePreloadEventFormData } from '@features/events/hooks/usePreloadEventFormData';
import { useGetEventCountQuery, useGetEventsQuery } from '@features/events/queries/EventQueries';
import { EVENT_NAVIGATION_QUERY_PARAMS } from '@features/events/types';

import { EmptyCalendarGraphic } from '@components/graphics';
import { IListItem } from '@components/inputs/ListSelector/ListSelector';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import { useQueryParams } from '@components/router';
import AdvancedTable from '@components/table/AdvancedTable';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';
import { ITableRow } from '@app/types/TableTypes';

import { CreateEventButton } from '../CreateEventButton';
import EventTableToolbar from '../EventTableToolbar';
import { EventTableRowData } from './types';
import { useEventsHeadCell } from './useEventsHeadCell';

const EventTable = ({
    account,
    events,
    isLoading,
    meta,
    eventFilters,
    onRowClick,
}: {
    account?: IAccount;
    events: UIEvent[];
    isLoading: boolean;
    meta?: IPagingProperties;
    eventFilters: ListEventsQueryParams;
    onRowClick?: (event: UIEvent) => void;
}) => {
    const { headCells, stickyLeftCells, stickyRightCells } = useEventsHeadCell();

    const rowData: ITableRow<EventTableRowData>[] = useMemo(() => {
        return events.map((event) => ({
            ...event,
            key: event._id,
            displayStatus: getEventDisplayStatus({ event, accountType: account?.type }),
        }));
    }, [events, account?.type]);

    if (rowData.length === 0 && !isLoading) {
        return (
            <EmptyStateDisplay
                contentClassName="pb-4"
                graphic={<EmptyCalendarGraphic />}
                label="No Events Found"
                actionButton={<CreateEventButton />}
            />
        );
    }

    return (
        <AdvancedTable
            variant="raised"
            stickyLeft={stickyLeftCells}
            headCells={headCells}
            stickyRight={stickyRightCells}
            rows={rowData}
            className={cn(
                'events-table relative',
                '[&_.MuiTableCell-root]:px-4',
                '[&_.MuiTableCell-root]:py-2',
                '[&_.MuiTableCell-body]:h-12',
                '[&_.MuiTableCell-body]:text-gray-950',
                '[&_.MuiTableCell-body]:whitespace-nowrap',
                '[&_th]:whitespace-nowrap',
                '[&_td]:whitespace-nowrap',
                '[&_.sticky-header]:!h-full',
                '[&_.sticky-header]:shadow-[0_1px_2px_0_rgba(0,0,0,0.05)]',
                '[&_.sticky-header_.label]:flex-grow',
                '[&_.sticky-header_.label]:py-3',
                '[&_.sticky-header_.label]:pl-3',
                '[&_.sticky-left_.head-cell-wrapper]:flex',
                '[&_.sticky-left_.head-cell-wrapper]:items-center',
                '[&_.sticky-left_.head-cell-wrapper]:h-full',
                '[&_.sticky-left_.head-cell-wrapper]:pl-3',
                '[&_.sticky-left_.head-cell-wrapper>span]:pl-0',
                '[&_.sticky-right_.head-cell-wrapper]:flex',
                '[&_.sticky-right_.head-cell-wrapper]:items-center',
                '[&_.sticky-right_.head-cell-wrapper]:h-full',
                '[&_.sticky-left]:bg-white',
                '[&_.sticky-left]:shadow-[1px_0_2px_0_rgba(0,0,0,0.05)]',
                '[&_.sticky-right]:bg-white',
                '[&_.sticky-right]:shadow-[-1px_0_2px_0_rgba(0,0,0,0.05)]',
                '[&_.sticky-right]:w-8',
                '[&_.sticky-empty]:w-full',
                '[&_.sticky-empty]:p-3',
                '[&_.sticky-empty]:text-white',
                '[&_.sticky-empty]:shadow-[-1px_0_7px_0_rgba(0,0,0,0.13)]',
                '[&_.sticky-empty]:[clip-path:inset(0_0_0_-7px)]',
            )}
            enableQueryParams
            showPagination
            disableFrontendFiltering
            isLoading={isLoading}
            defaultOptions={{
                rowsPerPage: eventFilters.limit,
                order: eventFilters.order === 'asc' ? 'asc' : 'desc',
                orderBy: eventFilters.orderBy ?? 'createdAt',
            }}
            rowCountOverride={meta?.total}
        >
            <Table.RenderHead />
            <Table.RenderBody
                emptyStateText="No events found matching those filters"
                rowRenderKeyFn={(row: EventTableRowData) =>
                    `${row._id}-${row.requestStatus}-${row.updatedAt}`
                }
                onRowClick={(eventRow) => onRowClick?.(eventRow)}
            />
        </AdvancedTable>
    );
};

export default ({
    isSentInbox = false,
    onRowClick,
}: {
    isSentInbox?: boolean;
    onRowClick?: (event: UIEvent) => void;
}) => {
    const [locations, setLocations] = useState<IListItem[]>([]);

    const { account } = useSparkplugAccount();
    const { data: totalEventCount, isFetched: totalEventCountIsFetched } = useGetEventCountQuery({
        accountId: account?._id ?? '',
        variant: 'all',
    });

    const rawQueryParams = useQueryParams();

    const limit = Number(rawQueryParams.limit) || 10;
    const page = Number(rawQueryParams.p) || 0;

    const queryParams: ListEventsQueryParams = {
        limit: Number(rawQueryParams.limit) || 10,
        offset: Number(page * limit) || 0,
        requestStatus: isSentInbox ? ['pending', 'declined', 'expired'] : ['approved'],
        accountId: account?._id!,
        ...rawQueryParams,
    };

    // By omitting the navigation query params, we avoid unecessarily busting the table data cache when navigating between views
    const eventFilters = useMemo(
        () =>
            omit(
                queryParams,
                Object.values(EVENT_NAVIGATION_QUERY_PARAMS),
            ) as ListEventsQueryParams,
        [queryParams],
    );

    const { events, eventsAreLoading, meta, refetchEvents, eventsAreReady } = useGetEventsQuery({
        accountId: account?._id!,
        eventFilters,
        locationIds: locations.map((location) => location.value).filter(Boolean),
    });

    usePreloadEventFormData({ account, canPreload: eventsAreReady });

    useEffect(() => {
        refetchEvents();
    }, [locations]);

    return (
        <div className="flex flex-col gap-4">
            {totalEventCount === 0 && totalEventCountIsFetched ? (
                <EmptyStateDisplay
                    layout="horizontal"
                    addPaperWrapper
                    label={
                        account?.type === 'brand'
                            ? 'Coordinate In-Store Events with Retail Partners'
                            : 'Plan and Manage Events with Ease'
                    }
                    smallText={
                        account?.type === 'brand'
                            ? 'Schedule a pop-up, promo or training at one of your Retail partner locations to keep employees informed and engaged.'
                            : 'Manage pop-ups, promotions, and training requests, or create your own events to engage your team and customers.'
                    }
                    graphic={<img src="/static/images/events-onboarding.svg" alt="placeholder" />}
                    actionButton={<CreateEventButton />}
                />
            ) : (
                <>
                    <EventTableToolbar
                        locations={locations}
                        setLocations={setLocations}
                        isSentInbox={isSentInbox}
                    />
                    <EventTable
                        account={account}
                        events={events}
                        isLoading={eventsAreLoading}
                        meta={meta}
                        eventFilters={queryParams}
                        onRowClick={onRowClick}
                    />
                </>
            )}
        </div>
    );
};
