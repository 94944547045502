import { useAccountLinks } from '@features/account-links';

import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

export const usePreloadEventFormData = ({
    account,
    canPreload,
}: {
    account?: IAccount;
    canPreload: boolean;
}) => {
    useAccountPosDataQuery(account?._id ?? '', {
        includedDatasets: ['locations'],
        isEnabled: canPreload && account?.type === 'retailer',
    });

    useAccountLinks(account?._id ?? '', canPreload && account?.type === 'brand', ['enabled']);
};
