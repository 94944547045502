import { FC, useEffect, useMemo } from 'react';

import { groupBy } from 'lodash';
import moment from 'moment-timezone';

import {
    AccountLink,
    PosBrand,
    PosProduct,
    PublicBrandLinkV2,
    UpdateBrandLinkV2RequestBody,
} from '@sparkplug/lib';

import {
    useCreateBrandLink,
    useDeleteBrandLink,
    useUpdateAccountLink,
    useUpdateBrandLink,
} from '@features/account-links';

import Button from '@components/buttons/Button';
import { DeleteForever as DeleteIcon } from '@components/icons';
import FullscreenNav from '@components/layout/FullscreenNav';
import Skeleton from '@components/layout/Skeleton';
import { useParams, useQueryParams } from '@components/router';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import {
    useAccountPosDataQuery,
    useSparkplugAccount,
} from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import BrandMappings from '../../components/BrandMappings/BrandMappings';
import DataSharing from '../../components/DataSharing';
import LinkEnabled from '../../components/LinkEnabled';
import { useCurrentVendorLink } from './useCurrentVendorLink';

import './VendorLinksManageView.scss';

export const useSaveBrandLink = (accountId: string) => {
    const { updateBrandLink } = useUpdateBrandLink(accountId);
    const { createBrandLink } = useCreateBrandLink(accountId);

    return {
        saveBrandLink: (brandLink: PublicBrandLinkV2, posBrands: PosBrand[]) => {
            if (brandLink._id) {
                updateBrandLink({
                    brandLinkId: brandLink._id ?? '',
                    payload: {
                        posBrandIds: posBrands.map((b: { _id: string }) => b._id),
                    } as UpdateBrandLinkV2RequestBody,
                });
            } else {
                createBrandLink({
                    brandId: brandLink.brandId ?? '',
                    retailerAccountId: accountId,
                    posBrandIds: posBrands.map((b: { _id: string }) => b._id),
                });
            }
        },
    };
};

const useAccountLinkStatusEffect = ({
    accountId,
    vendorLink,
}: {
    accountId?: string;
    vendorLink?: AccountLink;
}) => {
    const autoLinkingFeatureEnabled = import.meta.env.REACT_APP_AUTO_LINKING === 'true';
    const { accountId: vendorAccountId, status: vendorLinkStatus } = vendorLink ?? {};
    const { updateAccountLink, isUpdatingAccountLink } = useUpdateAccountLink(accountId ?? '');

    const mappedBrandsCount = useMemo(() => {
        return vendorLink?.brandLinks?.flatMap((link) => link.posBrands ?? [])?.length ?? 0;
    }, [vendorLink?.brandLinks]);

    useEffect(() => {
        if (autoLinkingFeatureEnabled && vendorAccountId && !isUpdatingAccountLink) {
            const shouldUpdateAccountLink =
                (mappedBrandsCount === 0 &&
                    ['enabled', 'disabled'].includes(vendorLinkStatus ?? '')) ||
                (mappedBrandsCount > 0 && vendorLinkStatus === 'pending');

            if (shouldUpdateAccountLink) {
                updateAccountLink({
                    vendorAccountId,
                    body: { state: mappedBrandsCount === 0 ? 'pending' : 'enabled' },
                });
            }
        }
    }, [autoLinkingFeatureEnabled, vendorAccountId, vendorLinkStatus, mappedBrandsCount]);
};

const VendorLinksManageView: FC = () => {
    const autoLinkingFeatureEnabled = import.meta.env.REACT_APP_AUTO_LINKING === 'true';
    const { history, userIsSuperAdmin } = useApp();
    const { vendorId } = useParams<{ vendorId: string }>();
    const { isNewVendorLink } = useQueryParams();
    const { accountIsReady, account } = useSparkplugAccount();
    const { isDeletingBrandLink, deleteBrandLink } = useDeleteBrandLink(account?._id ?? '');
    const { saveBrandLink } = useSaveBrandLink(account?._id ?? '');

    const { vendorLinkIsReady, vendorLink } = useCurrentVendorLink({
        retailerAccountId: account?._id ?? '',
        vendorAccountId: vendorId,
    });
    const { accountPosDataIsReady, accountPosBrands, accountPosProducts } = useAccountPosDataQuery(
        account?._id ?? '',
        {
            includedDatasets: ['brands', 'products'],
        },
    );

    // Update the account link status based on the current number of mapped brands
    useAccountLinkStatusEffect({ accountId: account?._id, vendorLink });

    const POSBrandOptions = useMemo(() => {
        const groupedProducts = groupBy(accountPosProducts, 'brandId');
        return accountPosBrands.map((posBrand) => {
            const products = groupedProducts[posBrand._id];
            return {
                ...posBrand,
                labelComponent: () => (
                    <div className="brand-product-parent-label">
                        {posBrand.label}
                        <span className="brand-product-number">{products.length}</span>
                    </div>
                ),
                isSelectable: true,
                key: posBrand._id,
                children: accountPosDataIsReady
                    ? products?.map((obj: PosProduct) => {
                          return {
                              ...obj,
                              showArrow: true,
                              label: `${obj.name}${
                                  userIsSuperAdmin
                                      ? `\u00A0\u00A0\u00A0\u00A0${moment(obj.lastSoldAt).format(
                                            'MM/DD/YYYY',
                                        )}`
                                      : ''
                              }`,
                              value: obj._id,
                              isSelectable: false,
                          };
                      })
                    : [],
            };
        });
    }, [accountPosDataIsReady]);

    if (!accountIsReady || !vendorLinkIsReady || !accountPosDataIsReady) {
        return (
            <FullscreenNav className="manage-vendor-links" backLinkText="Linked Vendors">
                <Skeleton height={400} />
            </FullscreenNav>
        );
    }

    if (!vendorLink) {
        // Bad url - the id doesn't match any vendor for this account - go back
        history.goBack();
        toast.error(`No vendor associated with id ${vendorId} - please try again`);
        return null;
    }
    const {
        accountId: vendorAccountId,
        accountName,
        photo,
        brandLinks = [],
        shareSalesData,
        shareInventoryData,
        status: accountLinkStatus,
    } = vendorLink;

    const deleteAction = () => {
        deleteBrandLink(vendorAccountId, {
            onSuccess: () => {
                toast.success('Link Deleted');
                history.push(`/${account?._id}/partners`);
            },
            onError: () => {
                toast.error(
                    'Something went wrong - please try again and contact support if this issue persists.',
                );
            },
        });
    };

    const handleValidation = () => {
        const mappedBrands = brandLinks.flatMap((b) => b.posBrandIds);
        if (
            !autoLinkingFeatureEnabled &&
            mappedBrands.length === 0 &&
            accountLinkStatus === 'enabled'
        ) {
            return {
                success: false,
                message: 'Map products to at least one Brand before proceeding, or disable link',
            };
        }
        return {
            success: true,
        };
    };

    return (
        <FullscreenNav
            className="manage-vendor-links"
            backLinkText="Vendor Links"
            validation={handleValidation}
            backLinkFallbackUrl={`/${account?._id}/partners`}
        >
            {account && (
                <LinkEnabled
                    photoUrl={photo}
                    accountId={account._id}
                    vendorAccountId={vendorAccountId}
                    accountName={accountName}
                    accountLinkState={accountLinkStatus}
                    brandLinks={brandLinks}
                />
            )}
            <BrandMappings
                accountName={accountName}
                brandLinks={brandLinks}
                posBrandOptionsAreReady={accountPosDataIsReady}
                posBrandOptions={POSBrandOptions}
                onSave={saveBrandLink}
                accountLinkDisabled={accountLinkStatus === 'disabled'}
                isNewVendorLink={Boolean(isNewVendorLink)}
            />
            {account && (
                <DataSharing
                    accountName={accountName}
                    accountId={account._id}
                    accountLinkDisabled={accountLinkStatus === 'disabled'}
                    vendorAccountId={vendorAccountId}
                    shareSalesData={shareSalesData}
                    shareInventoryData={shareInventoryData}
                />
            )}
            {!autoLinkingFeatureEnabled && (
                <Button
                    data-testid="delete-link-button"
                    disabled={isDeletingBrandLink}
                    onClick={() => deleteAction()}
                    startIcon={<DeleteIcon />}
                    color="red"
                    variant="filled"
                >
                    Delete Link
                </Button>
            )}
        </FullscreenNav>
    );
};

export default VendorLinksManageView;
