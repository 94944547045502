import { useMemo } from 'react';

import { UIEvent } from '@sparkplug/lib';

import { EventDisplayStatus, getEventDisplayStatus } from '@features/events/helpers/eventUtils';

import Modal from '@components/overlays/Modal';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

const getEventContentHeadlineText = (eventStatus: EventDisplayStatus) => {
    switch (eventStatus) {
        case 'active':
            return `Approved Event`;
        case 'expired':
            return 'Expired Event';
        case 'declined':
            return 'Declined Event';
        case 'complete':
            return 'Completed Event';
        case 'pending':
            return 'Event Request';
        case 'upcoming':
            return 'Upcoming Event';
        default:
            return '';
    }
};

const getEventContentStatusIcon = (eventStatus: EventDisplayStatus) => {
    switch (eventStatus) {
        case 'active':
        case 'upcoming':
        case 'complete':
            return '✅';
        case 'expired':
            return '⏳';
        case 'declined':
            return '❌';
        default:
            return '';
    }
};

const EventDetailModalTitle = ({ event, onClose }: { event: UIEvent; onClose: () => void }) => {
    const { account } = useSparkplugAccount();

    const eventStatus = useMemo(
        () => getEventDisplayStatus({ event, accountType: account?.type as 'retailer' | 'brand' }),
        [event, account?.type],
    );

    const statusIcon = getEventContentStatusIcon(eventStatus);
    const headlineText = getEventContentHeadlineText(eventStatus);

    return (
        <Modal.Title className="w-full" onClose={onClose}>
            <div className="w-full flex items-center gap-3">
                <span>{statusIcon}</span>
                <span>{headlineText}</span>
            </div>
        </Modal.Title>
    );
};

export default ({ event, onClose }: { event?: UIEvent; onClose: () => void }) => {
    if (!event) {
        return <Modal.Title onClose={onClose}>Event Request</Modal.Title>;
    }

    return <EventDetailModalTitle event={event} onClose={onClose} />;
};
