import axios from 'axios';

import { GetSurveyUserResponseResponseBody } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    getSurveyUserResponse: async (
        surveyId: string,
        userId: string,
    ): Promise<GetSurveyUserResponseResponseBody> => {
        const response = await axios.get<GetSurveyUserResponseResponseBody>(
            `/api/v1/survey/${surveyId}/responses/${userId}`,
        );
        return response.data;
    },
};

export const getSurveyUserResponseCacheKey = (surveyId: string) => ['surveyUserResponse', surveyId];

export const useGetSurveyUserResponse = (surveyId: string, userId: string) => {
    const {
        data: surveyUserResponse,
        isLoading,
        error,
    } = useAdvancedQuery(
        getSurveyUserResponseCacheKey(surveyId),
        () => API.getSurveyUserResponse(surveyId, userId),
        {
            enabled: !!surveyId && !!userId,
            cacheTime: 1000,
        },
    );

    return {
        surveyUserResponse,
        isLoadingSurveyUserResponse: isLoading,
        surveyUserResponseError: error,
    };
};
