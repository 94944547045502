import { useCallback, useEffect, useState } from 'react';

import API from '@api/API';
import LocalStorage from '@data/LocalStorage';

import { AccountType } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { ChevronRight as NextIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import { useHistory, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';

import { InputEvent } from '@app/types/UITypes';
import { IAuthUser } from '@app/types/UsersTypes';

import './LoginView.scss';

const AccountIdKey = 'sparkplug::accountId';

type TLoginPageQueryParams = {
    redirectTo?: string;
    authService?: 'frill';
    groupType?: AccountType;
    redirect?: string;
};

const LoginView = () => {
    const { logIn, user: appUser, isAdminApp, setIsAdminApp, requiredToVerify } = useApp();
    const {
        redirectTo,
        authService,
        groupType,
        redirect: frillRedirectUrl,
    }: TLoginPageQueryParams = useQueryParams();
    const history = useHistory();
    const [credentials, setCredentials] = useState({
        email: '',
        phoneNumber: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const storedAccountId = LocalStorage.get(AccountIdKey) as string;

    const updateCredential = (key: string) => {
        return (event: InputEvent) => {
            setErrorMessage(null);
            setCredentials((prevState) => {
                return {
                    ...prevState,
                    [key]: event.target.value,
                };
            });
        };
    };
    const handleSuccessfulLogin = async (user: IAuthUser) => {
        let account: any;
        let accountId: string | null = null;

        // Redirect to help referrer is a help article
        const { referrer } = document;
        if (referrer.includes('https://help.sparkplug.app')) {
            history.push(`/redirect?externalUrl=${referrer}`);
            return;
        }

        if (redirectTo != null) {
            // Decode the redirectTo value before using it
            const decodedRedirectTo = decodeURIComponent(redirectTo);

            // Excluding http:// to disable insecure redirects
            if (decodedRedirectTo.includes('https://')) {
                window.location.href = decodedRedirectTo;
            } else {
                history.push(decodedRedirectTo);
            }

            return;
        }

        if (user?.accounts && user.accounts.length === 0) {
            setIsSubmitting(false);
            return;
        }

        if (user?.role === 'none') {
            history.push(`/user/sparks`);
        } else if (user?.accounts && user.accounts.length > 0) {
            if (storedAccountId != null) {
                account = (user?.accounts || []).find(({ _id }) => _id === storedAccountId);
            }

            accountId = account != null ? storedAccountId : user?.accounts[0]?._id;

            if (accountId != null) {
                history.push(`/${accountId}/`);
            }
        } else if (user?.role === 'super-admin') {
            history.push(`/control-center/accounts/add`);
        }

        if (authService === 'frill' && frillRedirectUrl && groupType) {
            const { frillToken } = await API.get().getFrillAuthDetails({ groupType });
            const frillRedirectLink = `${frillRedirectUrl}?ssoToken=${frillToken}`;
            window.location.replace(frillRedirectLink);
        }
    };
    const onLogIn = useCallback(async () => {
        const { email, phoneNumber, password } = credentials;

        setIsSubmitting(true);
        try {
            await logIn({ email, phoneNumber, password });
        } catch (error: any) {
            if (error?.response?.data?.details != null) {
                setErrorMessage(error?.response?.data?.details);
            } else if (error?.message != null) {
                setErrorMessage(error?.message);
            } else {
                setErrorMessage('An unknown error occurred');
            }
            setIsSubmitting(false);
        }
    }, [credentials]);

    const goToForgotPassword = () => {
        history.push(`/login/forgot-password`);
    };

    const goToEmployeeLogin = () => {
        setIsAdminApp(false);
        history.push(`/employee/login`);
    };

    useEffect(() => {
        if (!isAdminApp) {
            goToEmployeeLogin();
        }
    }, [isAdminApp]);

    useEffect(() => {
        if (requiredToVerify && credentials.email && isSubmitting) {
            history.push(`/login/2fa?email=${encodeURIComponent(credentials.email)}`);
            return;
        }
        if (appUser?._id) handleSuccessfulLogin(appUser);
    }, [appUser, requiredToVerify, credentials, isSubmitting]);

    return (
        <div className="login-view">
            <Paper className="login-container" elevation={5}>
                <Paper.Title className="paper-title">Log in to SparkPlug</Paper.Title>
                <Form>
                    <Form.Description>
                        <p>Log in to your admin account with your email.</p>
                    </Form.Description>
                    <Form.TextField
                        label="Email"
                        name="email"
                        required
                        autoComplete="on"
                        onChange={updateCredential('email')}
                    />
                    <Form.TextField
                        label="Password"
                        name="password"
                        type="password"
                        required
                        autoComplete="on"
                        onChange={updateCredential('password')}
                    />
                    {errorMessage != null && errorMessage.length > 0 && (
                        <Form.Description>
                            <p className="error-text">{errorMessage}</p>
                        </Form.Description>
                    )}
                    <Form.ActionGroup className="text-align-center" direction="vertical">
                        <Form.Button
                            disabled={isSubmitting}
                            color="blue"
                            variant="smooth"
                            onClick={onLogIn}
                            type="submit"
                        >
                            {!isSubmitting ? 'Log in' : 'Logging in...'}
                        </Form.Button>
                        <Button color="neutral" variant="flat" onClick={goToForgotPassword}>
                            Forgot Password
                        </Button>
                        <Button
                            className="text-blue"
                            variant="flat"
                            endIcon={<NextIcon />}
                            onClick={goToEmployeeLogin}
                        >
                            <>
                                <span className="text-question">
                                    Are you a Store Employee?&nbsp;
                                </span>
                                <span>Log in</span>
                            </>
                        </Button>
                    </Form.ActionGroup>
                </Form>
            </Paper>
        </div>
    );
};

export default LoginView;
