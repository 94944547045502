import * as React from 'react';

import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '../utils';

export type BadgeColor =
    | 'blue'
    | 'neutral'
    | 'green'
    | 'yellow'
    | 'red'
    | 'dark-blue'
    | 'dark-gray'
    | 'cerulean'
    | 'light-gray';

const badgeVariants = cva(
    'inline-flex items-center rounded-md border px-2 py-1 text-[11px] leading-[16px] font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 uppercase tracking-[0.0175em]',
    {
        variants: {
            variant: {
                default: 'border-transparent shadow',
                flat: '',
                outlined: 'bg-transparent',
            },
            color: {
                blue: '',
                neutral: '',
                green: '',
                yellow: '',
                red: '',
                'dark-blue': '',
                'dark-gray': '',
                cerulean: '',
                'light-gray': '',
            },
        },
        compoundVariants: [
            // Flat variants
            {
                variant: 'flat',
                color: 'blue',
                class: 'bg-cerulean-50 text-cerulean-900 hover:shadow-cerulean-2dp',
            },
            {
                variant: 'flat',
                color: 'neutral',
                class: 'bg-gray-50 text-gray-700 hover:shadow-gray-2dp',
            },
            {
                variant: 'flat',
                color: 'green',
                class: 'bg-aqua-50 text-aqua-900 hover:shadow-aqua-2dp',
            },
            {
                variant: 'flat',
                color: 'yellow',
                class: 'bg-buttercup-50 text-buttercup-900 hover:shadow-buttercup-2dp',
            },
            {
                variant: 'flat',
                color: 'red',
                class: 'bg-sienna-50 text-sienna-600 hover:shadow-sienna-2dp',
            },
            {
                variant: 'flat',
                color: 'dark-blue',
                class: 'bg-cerulean-900 text-cerulean-0 hover:shadow-cerulean-2dp',
            },
            {
                variant: 'flat',
                color: 'dark-gray',
                class: 'bg-gray-600 text-gray-0 hover:shadow-gray-2dp',
            },
            {
                variant: 'flat',
                color: 'cerulean',
                class: 'bg-[#07a0c3] text-white hover:shadow-cerulean-2dp',
            },
            {
                variant: 'flat',
                color: 'light-gray',
                class: 'bg-gray-50 text-gray-40 border-gray-40 hover:shadow-gray-2dp',
            },
            // Outlined variants
            {
                variant: 'outlined',
                color: 'blue',
                class: 'border  border-solid border-cerulean-80 text-cerulean-80',
            },
            {
                variant: 'outlined',
                color: 'neutral',
                class: 'border border-solid border-gray-40 text-gray-40',
            },
            {
                variant: 'outlined',
                color: 'green',
                class: 'border-solid border-aqua-70 border text-aqua-70',
            },
            {
                variant: 'outlined',
                color: 'yellow',
                class: 'border border-solid border-buttercup-900 text-buttercup-900',
            },
            {
                variant: 'outlined',
                color: 'red',
                class: 'border border-solid border-sienna-600 text-sienna-600',
            },
            {
                variant: 'outlined',
                color: 'dark-blue',
                class: 'border border-solid border-cerulean-900 text-cerulean-900',
            },
            {
                variant: 'outlined',
                color: 'dark-gray',
                class: 'border border-solid border-gray-40 text-gray-40',
            },
            {
                variant: 'outlined',
                color: 'light-gray',
                class: 'border border-solid border-gray-400 text-gray-400',
            },
        ],
        defaultVariants: {
            variant: 'flat',
            color: 'blue',
        },
    },
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {
    color: BadgeColor;
    dense?: boolean;
    label: string | React.ReactNode;
    size?: 'dense' | 'sm' | 'lg';
}

function Badge({ className, variant, color, dense, label, size, ...props }: BadgeProps) {
    return (
        <div
            className={cn(
                badgeVariants({ variant, color }),
                className,
                size === 'dense' && 'h-[18px] py-0',
                size === 'sm' && 'h-[18px] py-0',
                size === 'lg' && 'h-[24px] py-0 text-lg font-bold tracking-normal',
            )}
            {...props}
        >
            {label}
        </div>
    );
}

export { Badge, badgeVariants };
