import Button from '@components/buttons/Button';
import { PlusIcon } from '@components/icons';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

export const CreateCourseButton = () => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();
    const basePath = `/${account?._id}/courses`;

    return (
        <Button
            variant="filled"
            color="blue"
            startIcon={<PlusIcon />}
            onClick={() => {
                history.push(`${basePath}/create`);
            }}
        >
            Create Course
        </Button>
    );
};
