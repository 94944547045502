import Button from '@components/buttons/Button';
import { SparkplugLogo } from '@components/logos';
import Modal from '@components/overlays/Modal';

import Intercom from '@helpers/Intercom';
import { noop } from '@helpers/util';

import './UpgradeModal.scss';

const defaultTitle = 'Build and launch a Spark for your team';
const defaultDialog1 =
    'Your current plan allows you to run Spark incentive campaigns sent by brand partners.';
const defaultDialog2 =
    'To offer your own store-sponsored incentives to employees, you will need to upgrade your SparkPlug subscription.';
const defaultImage = '/static/images/UpgradeToSparkplugGraphic.svg';

export interface ISparkplugUpgradeModal {
    isVisible: boolean;
    onClose: (any: any) => any;
    title?: string;
    dialog1?: string;
    dialog2?: string;
    image?: string;
}
const UpgradeModal = ({
    isVisible = false,
    onClose = noop,
    title = defaultTitle,
    dialog1 = defaultDialog1,
    dialog2 = defaultDialog2,
    image = defaultImage,
}) => {
    return (
        <Modal className="upgrade-modal" isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>
                <SparkplugLogo className="sparkplug-logo" />
            </Modal.Title>
            <Modal.Content>
                <section className="upgrade-modal-content">
                    <h1>{title}</h1>
                    <p>{dialog1}</p>
                    <p>{dialog2}</p>
                    <Button
                        color="blue"
                        variant="filled"
                        className="upgrade-button"
                        onClick={() => {
                            onClose();
                            Intercom.open();
                        }}
                    >
                        Upgrade Plan
                    </Button>
                </section>
                <img
                    src={image}
                    alt="Upgrade to Sparkplug"
                    className="upgrade-modal-graphic max-w-[300px]"
                />
            </Modal.Content>
        </Modal>
    );
};

export default UpgradeModal;
