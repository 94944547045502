import { ReactElement, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import { formatCurrency } from '@sparkplug/lib';

import { useSaveUserProfileMutation } from '@core/users';

import { useCurrentUserWallet } from '@features/wallet/queries';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { AddUserIcon, Logout, NotificationsActive as NoticationIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import CalloutMessage from '@components/layout/CalloutMessage';
import Skeleton from '@components/layout/Skeleton';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';
import { useEmployeeLocationsQuery } from '@hooks/QueryHooks/EmployeeDataQueries';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import { formatPhoneNumber } from '@helpers/ui';

import { IPosEmployeeProfile } from '@app/types/PosTypes';
import { IAuthUser } from '@app/types/UsersTypes';

import './UserProfileViewMobile.scss';

const getUserPosEmployeeProfiles = (
    user: IAuthUser,
    accountPosEmployeeProfiles: IPosEmployeeProfile[],
) => {
    return user.posEmployeeProfileIds.length
        ? accountPosEmployeeProfiles.filter(({ _id }) => user.posEmployeeProfileIds.includes(_id))
        : [];
};

const UserDataGroup = ({ label, value }: { label: string; value: string | ReactElement }) => (
    <div className="user-data-group">
        <h3>{label}</h3>
        <span className="value">{value}</span>
    </div>
);

const UserProfileSettings = ({
    settings,
    updateSetting,
}: {
    settings: any;
    updateSetting: any;
}) => (
    <div className="user-profile-settings">
        <h3>Profile Settings</h3>
        <span>
            <NoticationIcon className="notification-icon" />
            <span className="value">Spark Updates</span>
        </span>
        <Form>
            <Form.Switch
                label="Receive occasional SMS reminders about your active Sparks"
                value={!settings.muteScheduledSms}
                onChange={() => updateSetting('muteScheduledSms', !settings?.muteScheduledSms)}
            />
        </Form>
    </div>
);

const UserProfileViewMobile = () => {
    const { user, history, logOut } = useApp();
    const { account } = useSparkplugAccount();
    const { currentUserWallet } = useCurrentUserWallet();
    const { deposits = [], lifetimeEarnings = 0 } = currentUserWallet ?? {};
    const claimed = deposits.filter(({ status }) => status === 'Claimed');
    const { employeeLocations, employeeLocationsAreLoading } = useEmployeeLocationsQuery();

    const { saveUserProfile } = useSaveUserProfileMutation({ isCurrentUser: true });

    useAppBodyClasses(['app-bgWhite'], []);

    const [settings, setSettings] = useState({
        muteScheduledSms: user?.muteScheduledSms,
    });

    const userLocationsStr = useMemo(() => {
        return employeeLocations
            .map((location: { name: string; _id: string }) => location.name)
            .join(', ');
    }, [employeeLocations]);

    const updateSetting = (key: string, value: unknown) => {
        setSettings((prevValues) => {
            return {
                ...prevValues,
                [key]: value,
            };
        });

        const updatedUserProperties = {
            [key]: value,
        };

        saveUserProfile({ userParams: updatedUserProperties, userId: user?._id });
    };

    const hasLifetimeEarnings = lifetimeEarnings > 0;

    return (
        <div className="user-profile-view-mobile">
            <AppHeaderMobile title="Your Profile" />

            {hasLifetimeEarnings && (
                <CalloutMessage
                    data-testid="callout-message"
                    color="light-blue"
                    customIcon="sparkles"
                    message={
                        <span>
                            Hey {user?.firstName ?? user?.fullName}, since joining SparkPlug
                            you&apos;ve earned a total of{' '}
                            <strong>{formatCurrency(lifetimeEarnings / 100, true)}</strong> in cash
                            rewards from {claimed.length} {pluralize('Spark', claimed.length)}!
                        </span>
                    }
                />
            )}

            <UserDataGroup label="Name" value={`${user?.firstName} ${user?.lastName}`} />

            <UserDataGroup label="Phone Number" value={formatPhoneNumber(user?.phoneNumber!)} />

            <UserDataGroup label="Retailer" value={account?.name ?? ''} />

            {employeeLocationsAreLoading ? (
                <Skeleton height={69} />
            ) : (
                <UserDataGroup label="Locations" value={userLocationsStr} />
            )}

            <UserDataGroup
                label="Referrals"
                value={
                    <Button
                        className="referral-button"
                        color="blue"
                        variant="filled"
                        onClick={() => {
                            history.push(`/user/referral`);
                        }}
                        startIcon={<AddUserIcon />}
                    >
                        Refer Teammates to SparkPlug
                    </Button>
                }
            />

            <UserProfileSettings settings={settings} updateSetting={updateSetting} />
            <div className="logout-button-container">
                <Button
                    className="logout-button"
                    startIcon={<Logout />}
                    color="neutral"
                    onClick={() => {
                        logOut().then(() => {
                            history.push('/login');
                        });
                    }}
                >
                    Logout
                </Button>
            </div>
        </div>
    );
};

export default UserProfileViewMobile;
