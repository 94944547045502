import { FC, useCallback, useMemo, useState } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import {
    DO_NOT_HAVE_PERMISSIONS_MESSAGE,
    DayNumber,
    DetailedSparkType,
    RecurringSparkSchedule,
    RecurringSparkScheduleOption,
    SparkDeclineResponse,
} from '@sparkplug/lib';

import { SparkProvider } from '@contexts/SparkContext';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Check, Close } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';
import SparkLoadingModal from '@components/overlays/SparkLoadingModal';
import SparkDetailsPanel from '@components/sparks/SparkDetailsPanel';
import toast from '@components/toast';

import { useFormRef } from '@hooks/FormHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks/SparksHooks';

import { getCommaSeparatedWeekDays, getIntervalString } from '@helpers/recurring';

import SparkApprovalModalTabs from './SparkApprovalModalTabs';
import SparkDeclineConfirmationModal from './SparkDeclineConfirmationModal/SparkDeclineConfirmationModal';

import './SparkApprovalModal.scss';

type TSparkApprovalModal = {
    sparkId?: string | null;
    isVisible: boolean;
    onClose: (a: boolean) => void;
};

const RecurringSparkCalloutFirstLine = ({
    interval,
    daysOfTheWeek,
}: {
    interval: RecurringSparkScheduleOption;
    daysOfTheWeek?: DayNumber[];
}) => {
    const daysList = useMemo(
        () => getCommaSeparatedWeekDays(daysOfTheWeek, { isShort: true }),
        [daysOfTheWeek],
    );

    switch (interval) {
        case 'daily':
            return (
                <>
                    Daily recurring Sparks will run for{' '}
                    <em>
                        <u>1 day</u>
                    </em>
                    , and recur on the following days of the week; {daysList}
                </>
            );
        case 'weekly':
            return (
                <>
                    Weekly recurring Sparks will run for{' '}
                    <em>
                        <u>7 days</u>
                    </em>
                    , and recur each week, Monday through Sunday.
                </>
            );
        case 'twice_monthly':
            return (
                <>
                    Twice-monthly recurring Sparks will run for{' '}
                    <em>
                        <u>14 days</u>
                    </em>{' '}
                    twice a month, starting on the 1st and the 15th.
                </>
            );
        case 'monthly':
            return (
                <>
                    Monthly recurring Sparks will run for a{' '}
                    <em>
                        <u>full month</u>
                    </em>
                    , and recur on the 1st of each month.
                </>
            );
        default:
            return null;
    }
};

const RecurringSparkCalloutMessageContent = ({
    interval,
    startDate,
    daysOfTheWeek,
}: {
    interval: RecurringSparkScheduleOption;
    startDate: string;
    daysOfTheWeek?: DayNumber[];
}) => {
    const intervalText = useMemo(() => {
        return getIntervalString(interval);
    }, [interval]);

    const startDateText = useMemo(() => {
        return moment(startDate).format(DATE_DISPLAY_FORMAT);
    }, [startDate]);

    const calloutFirstLine = useMemo(() => {
        return <RecurringSparkCalloutFirstLine interval={interval} daysOfTheWeek={daysOfTheWeek} />;
    }, [interval, daysOfTheWeek]);

    return (
        <ul>
            <li>
                <strong>This is a {intervalText} recurring Spark:</strong>
                <ul className="recurring-spark-callout-list" style={{ listStyleType: 'disc' }}>
                    <li className="callout-first-line">{calloutFirstLine}</li>
                    <li>This Spark is set to start on {startDateText}.</li>
                    <li>
                        Accepting this Spark will{' '}
                        <strong>automatically accept all future occurances</strong> of this Spark,
                        until deleted.
                    </li>
                </ul>
            </li>
        </ul>
    );
};

const RecurringSparkCalloutMessage = ({
    recurringSchedule,
}: {
    recurringSchedule: RecurringSparkSchedule;
}) => {
    const { interval, startDate, daysOfTheWeek } = recurringSchedule;

    const messageContent = useMemo(() => {
        return (
            <RecurringSparkCalloutMessageContent
                interval={interval}
                startDate={startDate}
                daysOfTheWeek={daysOfTheWeek}
            />
        );
    }, [interval, startDate, daysOfTheWeek]);

    return (
        <CalloutMessage
            className="recurring-spark-callout"
            message={messageContent}
            color="neutral"
            variant="default"
        />
    );
};

export const modalIsValid = ({
    isAccepted,
    isRecurringSpark,
    locationIds,
    posEmployeeProfileIds,
    detailedSparkType,
}: {
    isAccepted: boolean;
    isRecurringSpark: boolean;
    locationIds: string[];
    posEmployeeProfileIds: string[];
    detailedSparkType: DetailedSparkType;
}) => {
    if (isAccepted) {
        const hasParticipants = !!(isRecurringSpark
            ? locationIds.length
            : posEmployeeProfileIds.length);

        if (!hasParticipants) {
            toast.error(
                detailedSparkType === 'goalManager'
                    ? 'At least one manager must be selected'
                    : 'Select participants before proceeding.',
            );
            return false;
        }
    }

    return true;
};

interface SparkApprovalModalProps extends IDefaultModalProps {}

export const SparkApprovalModal: FC<SparkApprovalModalProps> = ({ isVisible, onClose }) => {
    const formRef = useFormRef();

    const [declineResponse, setDeclineResponse] = useState<SparkDeclineResponse>();
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    // Introduced `ignoreModalOnClose` to fix an undesired situation
    // where the modal's `onClose` was being triggered in conjuction with
    // the `onClose` in `handleResponse`
    const [ignoreModalsOnClose, setIgnoreModalsOnClose] = useState<boolean>(false);

    const {
        sparkIsReady,
        sparkPosDataIsReady,
        spark,
        detailedSparkType,
        sparkPosData,
        sparkSubGroups,
        respondToSparkRequest,
    } = useSpark();

    const { userCan } = useSparkplugAccount();

    const isRecurringSpark = !!spark.recurringSchedule;

    const handleResponse = useCallback(
        (isAccepted: boolean) => {
            const { posEmployeeProfileIds, locationIds } = spark;

            if (
                modalIsValid({
                    isAccepted,
                    isRecurringSpark,
                    locationIds,
                    posEmployeeProfileIds,
                    detailedSparkType: detailedSparkType!,
                })
            ) {
                respondToSparkRequest(isAccepted, declineResponse).then(() => {
                    onClose(true, {
                        isAccepted,
                    });
                });
            }
        },
        [spark, declineResponse, respondToSparkRequest],
    );

    return (
        <>
            {sparkPosDataIsReady && sparkIsReady ? (
                <>
                    <Modal
                        className="spark-approval-modal extra-wide"
                        isVisible={isVisible}
                        onClose={() => {
                            if (!ignoreModalsOnClose) {
                                onClose(false);
                            }
                        }}
                    >
                        <Modal.Title onClose={() => onClose(false)} />
                        <Form
                            formRef={formRef}
                            className="max-sm:p-[24px] [&_.spark-status-chip]:w-full"
                        >
                            <SparkDetailsPanel
                                spark={spark}
                                sparkPosData={sparkPosData}
                                sparkPosDataIsReady={sparkPosDataIsReady}
                                sparkSubGroups={sparkSubGroups}
                                detailedSparkType={detailedSparkType}
                            />

                            <Modal.Content className="max-sm:!p-0">
                                {isRecurringSpark && (
                                    <RecurringSparkCalloutMessage
                                        recurringSchedule={spark.recurringSchedule!}
                                    />
                                )}
                                <SparkApprovalModalTabs index={tabIndex} setIndex={setTabIndex} />
                            </Modal.Content>

                            <Modal.Actions>
                                <Button
                                    disabled={!userCan('createSpark')}
                                    tooltip={
                                        userCan('createSpark')
                                            ? undefined
                                            : DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                    }
                                    className="spark-approval-modal__decline"
                                    color="red"
                                    variant="smooth"
                                    startIcon={<Close />}
                                    onClick={() => setShowDeclineModal(true)}
                                >
                                    Decline
                                </Button>
                                <Button
                                    disabled={!userCan('createSpark')}
                                    tooltip={
                                        userCan('createSpark')
                                            ? undefined
                                            : DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                    }
                                    className="spark-approval-modal__accept"
                                    color="green"
                                    variant="smooth"
                                    startIcon={<Check />}
                                    onClick={() => {
                                        setIgnoreModalsOnClose(true);
                                        handleResponse(true);
                                    }}
                                >
                                    {spark.recurringSchedule
                                        ? 'Accept All Future Sparks'
                                        : 'Accept'}
                                </Button>
                            </Modal.Actions>
                        </Form>
                    </Modal>
                    <SparkDeclineConfirmationModal
                        isVisible={showDeclineModal}
                        declineResponse={declineResponse}
                        setDeclineResponse={setDeclineResponse}
                        onClose={(didSend) => {
                            setDeclineResponse(undefined);
                            setShowDeclineModal(false);

                            if (didSend) {
                                handleResponse(false);
                            }
                        }}
                    />
                </>
            ) : (
                <SparkLoadingModal isVisible={isVisible} onClose={() => onClose(false)} />
            )}
        </>
    );
};

export default ({ sparkId, ...otherProps }: TSparkApprovalModal) => {
    if (!sparkId) {
        return null;
    }

    return (
        <SparkProvider sparkId={sparkId}>
            <SparkApprovalModal {...otherProps} />
        </SparkProvider>
    );
};
