import { useEffect, useMemo } from 'react';

import AccountsAPI from '@api/AccountsAPI';
import axios, { AxiosRequestConfig } from 'axios';
import { keyBy } from 'lodash';

import {
    AccountLink,
    AccountLinkStatus,
    BrandLinksCountDataRequest,
    BrandLinksCountDataResponse,
    GetAccountVendorRetailerResponseBody,
    GetBulkAccountVendorRetailerPosDataRequestBody,
    GetBulkAccountVendorRetailerPosDataResponseBody,
    HydratedBrandProduct,
    ListAccountLinksResponseBody,
    ListProspectiveBrandLinksRequestBody,
} from '@sparkplug/lib';

import { useAdvancedQuery, useQueries, useQueryClient } from '@hooks/QueryHooks';

import { sortByString } from '@helpers/ui';

const API = {
    getAccountLinks: async ({
        accountId,
        statusFilters,
    }: {
        accountId: string;
        statusFilters?: AccountLinkStatus[];
    }): Promise<AccountLink[]> => {
        const params: AxiosRequestConfig['params'] = {
            /**
             * TODO: This was a way to filter out the markets that the user does not have permission for
             * now that Vendor Permissions is released we can remove this param and default to filter
             * the user's markets on the server
             * */
            filterMarkets: true,
        };

        if (statusFilters && statusFilters.length > 0) {
            params.statusFilters = statusFilters.join(',');
        }

        return (
            await axios.get<ListAccountLinksResponseBody>(
                `/api/v1/groups/${accountId}/account-links/`,
                { params },
            )
        ).data;
    },
    getBrandLinksTagCounts: async (body: BrandLinksCountDataRequest) => {
        return (
            await axios.post<BrandLinksCountDataResponse>(`/api/v1/brand-links/tag-counts`, body)
        ).data.data;
    },
    getProspectiveAccountLinks: async (
        body: ListProspectiveBrandLinksRequestBody,
    ): Promise<AccountLink[]> => {
        return (await axios.post(`/api/v1/brand-links/prospectives`, body)).data;
    },
    getBulkVendorRetailerPosData: async ({
        vendorAccountId,
        ...payload
    }: GetBulkAccountVendorRetailerPosDataRequestBody & { vendorAccountId: string }) => {
        return (
            await axios.post<GetBulkAccountVendorRetailerPosDataResponseBody>(
                `/api/v1/accounts/${vendorAccountId}/vendor-retailers/pos-data`,
                payload,
            )
        ).data;
    },
};

/**
 * @deprecated the intermediary update to use account links throughout the application
 * we want to remove this export and refactor the `getAccountByGroupId` fn in `@helpers/accounts`
 * into a custom hook
 */
export const getAccountLinks = async (accountId: string) => {
    const accountLinks = await API.getAccountLinks({ accountId });
    return accountLinks.sort(sortByString('accountName', 'asc'));
};

export const getAccountLinksQueryKey = (accountId: string, statusFilters?: AccountLinkStatus[]) => [
    'accountLinks',
    accountId,
    ...(statusFilters ? statusFilters.sort() : []),
];
export const useAccountLinks = (
    accountId: string,
    isEnabled: boolean = true,
    statusFilters?: AccountLinkStatus[],
) => {
    const {
        data: accountLinks,
        isFetched: accountLinksAreReady,
        isRefetching,
        refetch: refetchAccountLinks,
    } = useAdvancedQuery(
        getAccountLinksQueryKey(accountId, statusFilters),
        () => API.getAccountLinks({ accountId, statusFilters }),
        { enabled: isEnabled && !!accountId },
    );

    return {
        accountLinksAreReady,
        accountLinks,
        isRefetchingAccountLinks: isRefetching,
        refetchAccountLinks,
    };
};

export const getBrandLinkTagCountsQueryKey = (brandLinkId: string) => [
    'brandLink',
    brandLinkId,
    'tagCounts',
];
export const getAccountTagCountsQueryKey = (accountId: string) => [
    'accountLinks',
    accountId,
    'tagCounts',
];
export const useAccountTagCounts = ({
    accountId,
    isEnabled,
}: {
    accountId: string;
    isEnabled?: boolean;
}) => {
    const { accountLinks, accountLinksAreReady } = useAccountLinks(accountId, isEnabled);
    const queryClient = useQueryClient();

    const { data: accountTagCountsByBrandLinkId, isFetched: accountTagCountsAreReady } =
        useAdvancedQuery(
            getAccountTagCountsQueryKey(accountId),
            () =>
                API.getBrandLinksTagCounts({
                    brandLinkIds:
                        (accountLinks?.flatMap(({ brandLinks }) =>
                            brandLinks.filter(({ _id }) => _id).map(({ _id }) => _id),
                        ) as string[]) ?? [],
                }),
            { enabled: isEnabled && accountLinksAreReady && !!accountId && !!accountLinks?.length },
        );

    // This will update the queries for each brand link
    useEffect(() => {
        Object.entries(accountTagCountsByBrandLinkId ?? {}).forEach(([brandLinkId, data]) => {
            queryClient.setQueryData(getBrandLinkTagCountsQueryKey(brandLinkId), {
                [brandLinkId]: data,
            });
        });
    }, [accountTagCountsByBrandLinkId]);

    return { accountTagCountsAreReady, accountTagCountsByBrandLinkId };
};

export const useBrandLinksTagCounts = ({
    brandLinkIds,
    isEnabled,
}: {
    brandLinkIds: string[];
    isEnabled: boolean;
}) => {
    const brandLinksTagCountsResults = useQueries(
        brandLinkIds.map((brandLinkId) => ({
            queryKey: getBrandLinkTagCountsQueryKey(brandLinkId),
            queryFn: async () =>
                API.getBrandLinksTagCounts({
                    brandLinkIds,
                }),
            enabled: isEnabled && !!brandLinkIds?.length,
        })),
    );

    const value = useMemo(
        () =>
            brandLinksTagCountsResults.reduce<{
                brandLinksTagCountsAreReady: boolean;
                brandLinksTagCounts: BrandLinksCountDataResponse['data'];
            }>(
                (res, result) => {
                    res.brandLinksTagCountsAreReady =
                        res.brandLinksTagCountsAreReady && result.isFetched;

                    return {
                        brandLinksTagCountsAreReady:
                            res.brandLinksTagCountsAreReady && result.isFetched,
                        brandLinksTagCounts: {
                            ...res.brandLinksTagCounts,
                            ...result.data,
                        },
                    };
                },
                { brandLinksTagCountsAreReady: true, brandLinksTagCounts: {} },
            ),
        [brandLinksTagCountsResults],
    );

    return value;
};

export const useSuggestedAccountLinks = (accountId?: string, isEnabled: boolean = true) => {
    // TODO: When we remove this feature flag we need to also update the AccountLink query in listProspectiveLinks API-side
    const autoLinkingFeatureEnabled = import.meta.env.REACT_APP_AUTO_LINKING === 'true';

    const {
        isLoading: isLoadingSuggestedAccountLinks,
        isFetched: isSuggestedAccountLinksReady,
        data: suggestedAccountLinks = [],
        refetch: refetchSuggestedAccountLinks,
    } = useAdvancedQuery(
        ['account', accountId, 'suggestedAccountLinks'],
        () =>
            API.getProspectiveAccountLinks({ groupId: accountId || '', autoLinkingFeatureEnabled }),
        { enabled: isEnabled && !!accountId },
    );

    return {
        isLoadingSuggestedAccountLinks,
        isSuggestedAccountLinksReady,
        suggestedAccountLinks,
        refetchSuggestedAccountLinks,
    };
};

export const getVendorRetailerQueryKey = (vendorAccountId: string, retailerAccountId: string) => [
    'account',
    vendorAccountId,
    'brandRetailer',
    retailerAccountId,
];

export const getVendorRetailerPosDataQueryKey = (
    vendorAccountId: string,
    retailerAccountId: string,
) => ['account', vendorAccountId, 'brandRetailer', retailerAccountId, 'posData'];
export const getAllVendorRetailerPosDataQueryKey = (vendorAccountId: string) =>
    getVendorRetailerPosDataQueryKey(vendorAccountId, 'all');

export interface VendorBrandRetailer
    extends Pick<GetAccountVendorRetailerResponseBody, 'name' | 'activeLocations' | 'photo'> {
    retailerAccountId: string;
    markets?: string[];
}

/**
 * @description
 *
 * For a given vendor account, get all of the retailers that have a brand link
 * with the associated brand ID.
 */
export const useVendorBrandRetailersByBrandId = ({
    brandId,
    vendorAccountId,
    isEnabled: isEnabledExternally = true,
}: {
    brandId?: string;
    vendorAccountId?: string;
    isEnabled?: boolean;
}): {
    vendorBrandRetailersAreReady: boolean;
    vendorBrandRetailers: VendorBrandRetailer[];
} => {
    const isEnabled = isEnabledExternally && !!brandId;
    const { accountLinksAreReady, accountLinks } = useAccountLinks(
        vendorAccountId || '',
        isEnabled,
    );

    const vendorBrandRetailers =
        accountLinks
            ?.filter(
                ({ brandLinks, status }) =>
                    status === 'enabled' &&
                    brandLinks?.some(
                        (brandLink) =>
                            brandLink.brandId === brandId && brandLink.posBrandIds?.length,
                    ),
            )
            ?.map<VendorBrandRetailer>(
                ({
                    accountId: retailerAccountId,
                    accountName,
                    locations = [],
                    markets,
                    photo,
                }) => ({
                    retailerAccountId,
                    name: accountName,
                    markets,
                    activeLocations: locations.filter(({ disabled }) => !disabled),
                    photo,
                }),
            )
            ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    return { vendorBrandRetailersAreReady: accountLinksAreReady, vendorBrandRetailers };
};

interface VendorBrandRetailerProductsByRetailerAccountId {
    [retailerAccountId: string]: HydratedBrandProduct[];
}

/**
 * @description
 *
 * For a given vendor account and brand ID and array of retailer ids, get all of the brand products for
 * all the retailers that are included in the provided retailer ids (each of which will have an account link with the vendor).
 */
export const useVendorBrandRetailersProductsByBrandId = ({
    brandId,
    vendorAccountId,
    vendorBrandRetailerIds,
    isEnabled: isEnabledExternally = true,
}: {
    brandId?: string;
    vendorAccountId?: string;
    vendorBrandRetailerIds?: string[];
    isEnabled?: boolean;
}): {
    vendorBrandRetailerProductsAreReady: boolean;
    vendorBrandRetailerProducts: VendorBrandRetailerProductsByRetailerAccountId;
} => {
    const isEnabled = isEnabledExternally && !!brandId && !!vendorAccountId;
    const { vendorBrandRetailersAreReady, vendorBrandRetailers } = useVendorBrandRetailersByBrandId(
        {
            brandId,
            vendorAccountId,
            isEnabled,
        },
    );

    let retailerAccountIdsMatchingBrandId =
        vendorBrandRetailers?.map(({ retailerAccountId }) => retailerAccountId) ?? [];

    // If `vendorBrandRetailerIds` then we only want to query for the selected retailers
    if (vendorBrandRetailerIds?.length) {
        retailerAccountIdsMatchingBrandId = retailerAccountIdsMatchingBrandId.filter(
            (retailerAccountId) => vendorBrandRetailerIds.includes(retailerAccountId),
        );
    }

    const queryClient = useQueryClient();
    const bulkQueryState = queryClient.getQueryState(
        getAllVendorRetailerPosDataQueryKey(vendorAccountId ?? ''),
    );

    const enabledByBulkState =
        // Is Idle
        bulkQueryState?.status === 'idle' ||
        // Was successful fetched
        !!bulkQueryState?.dataUpdateCount ||
        // Was fetched with error
        !!bulkQueryState?.errorUpdateCount;

    const vendorRetailerPosDataResults = useQueries(
        retailerAccountIdsMatchingBrandId.map((retailerAccountId) => {
            return {
                queryKey: getVendorRetailerPosDataQueryKey(
                    vendorAccountId ?? '',
                    retailerAccountId,
                ),
                queryFn: async () =>
                    AccountsAPI.getVendorRetailerPosData({
                        groupId: vendorAccountId ?? '',
                        retailerAccountId,
                    }),
                enabled: isEnabled && !!retailerAccountId && enabledByBulkState,
            };
        }),
    );

    const vendorBrandRetailerProductsAreReady = isEnabled
        ? vendorBrandRetailersAreReady &&
          vendorRetailerPosDataResults.every(({ isFetched }) => isFetched)
        : true;

    const vendorBrandRetailerProducts =
        useMemo<VendorBrandRetailerProductsByRetailerAccountId>(() => {
            if (!brandId || !vendorAccountId || !vendorBrandRetailerProductsAreReady) {
                return {};
            }

            const vendorRetailerPosDataByRetailerAccountId = keyBy(
                vendorRetailerPosDataResults.map(({ data }, i) => ({
                    retailerAccountId: retailerAccountIdsMatchingBrandId[i],
                    ...data,
                })),
                'retailerAccountId',
            );

            return retailerAccountIdsMatchingBrandId.reduce<VendorBrandRetailerProductsByRetailerAccountId>(
                (res, retailerAccountId) => {
                    const vendorRetailerPosData =
                        vendorRetailerPosDataByRetailerAccountId[retailerAccountId];
                    const brandRetailerProducts =
                        vendorRetailerPosData?.productsVendorBrandMap?.[brandId]?.products ?? [];

                    res[retailerAccountId] = brandRetailerProducts;

                    return res;
                },
                {},
            );
        }, [vendorBrandRetailerProductsAreReady, vendorRetailerPosDataResults, brandId]);

    return {
        vendorBrandRetailerProductsAreReady,
        vendorBrandRetailerProducts,
    };
};

export const useAllVendorRetailerProductsByBrandId = ({
    vendorAccountId,
    brandId,
    isEnabled: isExternallyEnabled = true,
}: {
    vendorAccountId: string;
    brandId: string;
    isEnabled?: boolean;
}): {
    vendorBrandRetailerProductsAreReady: boolean;
    vendorBrandRetailerProducts: VendorBrandRetailerProductsByRetailerAccountId;
} => {
    const isEnabled = isExternallyEnabled && !!vendorAccountId;
    const { accountLinksAreReady, accountLinks } = useAccountLinks(
        vendorAccountId || '',
        isEnabled,
    );
    const retailerAccountIds = useMemo(
        () => accountLinks?.map(({ accountId }) => accountId) ?? [],
        [accountLinks],
    );

    const { isFetched: vendorBrandRetailerProductsAreReady, data } = useAdvancedQuery(
        getAllVendorRetailerPosDataQueryKey(vendorAccountId ?? ''),
        () => API.getBulkVendorRetailerPosData({ vendorAccountId, retailerAccountIds }),
        { enabled: isEnabled },
    );

    const vendorBrandRetailerProducts =
        useMemo<VendorBrandRetailerProductsByRetailerAccountId>(() => {
            if (
                !brandId ||
                !vendorAccountId ||
                !accountLinksAreReady ||
                !vendorBrandRetailerProductsAreReady ||
                !data
            ) {
                return {};
            }

            return Object.fromEntries(
                Object.entries(data).map(([retailerAccountId, vendorRetailerPosData]) => [
                    retailerAccountId,
                    vendorRetailerPosData?.productsVendorBrandMap?.[brandId]?.products ?? [],
                ]),
            );
        }, [accountLinksAreReady, vendorBrandRetailerProductsAreReady, data, brandId]);

    const queryClient = useQueryClient();
    useEffect(() => {
        if (isEnabled) {
            if (data) {
                // This will update all the other queries based on the data from our bulk query
                Object.entries(data).forEach(([retailerAccountId, vendorRetailerPosData]) => {
                    queryClient.setQueryData(
                        getVendorRetailerPosDataQueryKey(vendorAccountId ?? '', retailerAccountId),
                        vendorRetailerPosData,
                    );
                });
            } else {
                // This will trigger the query notifying the other queries that a query is already being made
                retailerAccountIds.forEach((retailerAccountId) => {
                    queryClient.setQueryData(
                        getVendorRetailerPosDataQueryKey(vendorAccountId ?? '', retailerAccountId),
                        undefined,
                    );
                });
            }

            return () => {
                if (!data) {
                    /**
                     * In case that query didn't execute in time, this will clear all the `undefined` queryKeys
                     * so that they can be queried by their individual quieries
                     * */
                    retailerAccountIds.forEach((retailerAccountId) => {
                        const queryKey = getVendorRetailerPosDataQueryKey(
                            vendorAccountId ?? '',
                            retailerAccountId,
                        );
                        const retailerQueryData = queryClient.getQueryData(queryKey);
                        if (!retailerQueryData) {
                            queryClient.resetQueries(queryKey);
                        }
                    });
                }
            };
        }

        return () => {};
    }, [isEnabled, retailerAccountIds, data]);

    return {
        vendorBrandRetailerProductsAreReady,
        vendorBrandRetailerProducts,
    };
};

export const useIsInitializingAllVendorRetailerPosData = ({
    vendorAccountId,
}: {
    vendorAccountId: string;
}) => {
    const queryClient = useQueryClient();
    const bulkQueryState = queryClient.getQueryState(
        getAllVendorRetailerPosDataQueryKey(vendorAccountId ?? ''),
    );

    return bulkQueryState?.status === 'loading';
};

/**
 * @description
 *
 * Only do this if the current account is a vendor account
 */
export const useVendorRetailerDataPermissions = ({
    vendorAccountId,
    retailerAccountId,
    isEnabled = true,
}: {
    vendorAccountId?: string;
    retailerAccountId?: string;
    isEnabled?: boolean;
}) => {
    const { accountLinks } = useAccountLinks(vendorAccountId ?? '', isEnabled);

    // Vendor data permissions
    return useMemo<{ shareInventoryData: boolean; shareSalesData: boolean }>(() => {
        const { shareInventoryData = false, shareSalesData = false } =
            accountLinks?.find(({ accountId }) => accountId === retailerAccountId) ?? {};

        return {
            shareInventoryData,
            shareSalesData,
        };
    }, [accountLinks]);
};
