import { FC, MouseEvent, ReactElement, useState } from 'react';

import clsx from 'clsx';

import {
    DO_NOT_HAVE_PERMISSIONS_MESSAGE,
    IPublicAccount,
    accountCanCreateSpark as accountCanCreateSparkFn,
} from '@sparkplug/lib';

import { SparkWizardOriginRoute } from '@features/spark-wizard/views/SparkWizardView/SparkWizardView';

import Button, { ButtonVariant } from '@components/buttons/Button';
import SplitButton from '@components/buttons/SplitButton';
import { Add as AddIcon, ArrowDropDown, Copy, Lock as LockIcon } from '@components/icons';
import UpgradeModal from '@components/overlays/UpgradeModal';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks/SparkplugAccountsHooks';

import {
    Card,
    CardDescription,
    CardHeader,
    CardHeaderGraphic,
    CardTitle,
} from '@app/componentLibrary/Card/Card';
import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';

interface CreateSparkButtonProps {
    className?: string;
    variant?: ButtonVariant;
    onClick?: () => void;
    showSplitButton?: boolean;
}

const CREATE_SPARK_BUTTON_TEXT = 'Create Spark';
const useSparkCreateButtonState = ({
    onClick: handleClick,
}: Pick<CreateSparkButtonProps, 'onClick'>) => {
    const { account } = useSparkplugAccount();
    const { history, userIsSuperAdmin } = useApp();
    const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);

    const defaultCreateSparkAction = () => {
        history.push(`/${account?._id}/sparks/create`);
    };

    const handleAccountCannotCreateSparks = () => setUpgradeModalIsVisible(true);

    const accountCanCreateSpark = accountCanCreateSparkFn(account ?? ({} as IPublicAccount));
    const startIcon = accountCanCreateSpark ? <AddIcon /> : <LockIcon />;
    const accountHasSparkEntitlement = userIsSuperAdmin || accountCanCreateSpark;
    const onClick: () => void = accountHasSparkEntitlement
        ? handleClick ?? defaultCreateSparkAction
        : handleAccountCannotCreateSparks;

    const onCloseUpgradeModal = () => setUpgradeModalIsVisible(false);

    return {
        accountHasSparkEntitlement,
        upgradeModalIsVisible,
        onCloseUpgradeModal,
        startIcon,
        onClick,
    };
};

export const SplitCreateSparkButton: FC = () => {
    return <div className="flex items-center">{CREATE_SPARK_BUTTON_TEXT}</div>;
};

const ButtonCard = ({
    onClick,
    icon,
    title,
    description,
    hasBorder = true,
    className,
}: {
    onClick: (event: MouseEvent<HTMLElement>) => void;
    hasBorder?: boolean;
    className?: string;
    icon: ReactElement;
    title: string;
    description: string;
}) => {
    return (
        <Card
            hasBorder={hasBorder}
            onClick={onClick}
            className={clsx(
                ' hover:bg-gray-50 cursor-pointer w-fit border-gray-100 px-3 py-2',
                className,
            )}
        >
            <CardHeader className="flex flex-row items-center">
                <CardHeaderGraphic className="bg-cerulean-0 w-9 h-9" graphic={icon} />
                <div className="!m-0">
                    <CardTitle>
                        <Typography variant="base" className="!m-0 !text-gray-900 font-semibold">
                            {title}
                        </Typography>
                    </CardTitle>
                    <CardDescription className="text-gray-800 !m-0">{description}</CardDescription>
                </div>
            </CardHeader>
        </Card>
    );
};

export const StartFromBlankButton: FC<{
    className?: string;
    hasBorder?: boolean;
    originRoute?: SparkWizardOriginRoute;
}> = ({ className, hasBorder = true, originRoute }) => {
    const { history, userIsSuperAdmin } = useApp();
    const { account } = useSparkplugAccount();
    const accountCanCreateSpark = accountCanCreateSparkFn(account ?? ({} as IPublicAccount));

    const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);

    const handleClick = () => {
        if (accountCanCreateSpark || userIsSuperAdmin) {
            history.push(`/${account?._id}/sparks/create`, { state: { originRoute } });
            return;
        }
        setUpgradeModalIsVisible(true);
    };

    return (
        <>
            <ButtonCard
                onClick={handleClick}
                icon={<AddIcon className="text-cerulean-600" />}
                title="Start from blank"
                description="Create a Spark from scratch"
                hasBorder={hasBorder}
                className={className}
            />
            <UpgradeModal
                isVisible={upgradeModalIsVisible}
                onClose={() => setUpgradeModalIsVisible(false)}
            />
        </>
    );
};

export const FromTemplateButton: FC<{ className?: string; hasBorder?: boolean }> = ({
    className,
    hasBorder = true,
}) => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();

    return (
        <ButtonCard
            onClick={() => {
                history.push(`/${account?._id}/sparks/templates`);
            }}
            icon={<Copy className="text-cerulean-600" />}
            title="From template"
            description="Browse templates to create a Spark"
            hasBorder={hasBorder}
            className={className}
        />
    );
};

const CreateSparkButton: FC<CreateSparkButtonProps> = ({
    variant = 'filled',
    onClick: handleClick,
    className = '',
    showSplitButton = true,
}) => {
    const {
        startIcon,
        onClick,
        upgradeModalIsVisible,
        onCloseUpgradeModal,
        accountHasSparkEntitlement,
    } = useSparkCreateButtonState({
        onClick: handleClick,
    });
    const { userCan } = useSparkplugAccount();

    const userCanCreateSpark = userCan('createSpark');

    return (
        <>
            {showSplitButton && accountHasSparkEntitlement && userCanCreateSpark ? (
                <SplitButton
                    dropdownBtnComponent={<SplitCreateSparkButton />}
                    dropdownBtnComponentProps={{
                        startIcon: <AddIcon />,
                        endIcon: <ArrowDropDown />,
                    }}
                    dropdownComponents={[
                        <StartFromBlankButton
                            key="start-from-blank"
                            hasBorder={false}
                            className="w-full shadow-none"
                        />,
                        <FromTemplateButton
                            key="from-template"
                            hasBorder={false}
                            className="w-full shadow-none pr-8"
                        />,
                    ]}
                />
            ) : (
                <Button
                    className={cn('create-spark-btn', 'whitespace-nowrap', className)}
                    color="blue"
                    variant={variant}
                    startIcon={startIcon}
                    onClick={onClick}
                    disabled={!userCanCreateSpark}
                    tooltip={!userCanCreateSpark ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : ''}
                >
                    {CREATE_SPARK_BUTTON_TEXT}
                </Button>
            )}

            <UpgradeModal isVisible={upgradeModalIsVisible} onClose={onCloseUpgradeModal} />
        </>
    );
};

export default CreateSparkButton;
