import { z } from 'zod';

export const EVENT_ENGAGEMENT_TYPES = ['passive-view', 'active-view', 'reaction'] as const;
const EventEngagementTypeEnum = z.enum(EVENT_ENGAGEMENT_TYPES);
export type EventEngagementType = z.infer<typeof EventEngagementTypeEnum>;

export const upsertEventEngagementParamSchema = z.object({
  eventId: z.string().min(1),
  type: EventEngagementTypeEnum,
});
export type UpsertEventEngagementParams = z.infer<typeof upsertEventEngagementParamSchema>;
