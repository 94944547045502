import { useEffect } from 'react';

import { useEventNavigation } from '@features/events/hooks/useEventNavigation';
import { useDeleteEventMutation } from '@features/events/mutations';
import { useGetEventQuery } from '@features/events/queries';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Skeleton from '@components/layout/Skeleton';
import Modal from '@components/overlays/Modal';
import toast from '@components/toast';

import Typography from '@app/componentLibrary/Typography';
import { IAccount } from '@app/types/AccountsTypes';

export const DeleteEventModal = ({
    isVisible,
    account,
    onClose,
}: {
    isVisible: boolean;
    account?: IAccount;
    onClose: () => void;
}) => {
    const nav = useEventNavigation();

    const { event, eventIsLoading, eventHasError } = useGetEventQuery(nav.state.deleteId, {
        enabled: !!nav.state.deleteId,
    });

    const isVendorCreatedEvent = !!event?.vendorAccount?._id && account?.type === 'brand';

    const { deleteEventAsync, isDeletingEvent, hasDeleteError } = useDeleteEventMutation();
    const handleDeleteEvent = async () => {
        if (nav.state.deleteId) {
            await deleteEventAsync(nav.state.deleteId);
        } else {
            toast.error('Something went wrong.');
        }
        onClose();
    };

    useEffect(() => {
        if (eventHasError || hasDeleteError) {
            toast.error('Something went wrong.');
            onClose();
        }
    }, [eventHasError, hasDeleteError]);

    let modalContent = <></>;
    if (eventIsLoading) {
        modalContent = (
            <Modal.Content className="flex items-center w-[456px] max-w-full [&>span]:w-full">
                <Skeleton className="w-full h-[200px]" />
            </Modal.Content>
        );
    } else if (event) {
        modalContent = (
            <>
                <Modal.Content className="flex flex-col gap-4 max-w-[456px] !pt-0">
                    {isVendorCreatedEvent && (
                        <CalloutMessage message="A cancellation email will be sent to the Retail admin." />
                    )}
                    <Typography variant="base" className="text-gray-950">
                        <span className="font-bold">
                            Are you sure you wish to delete this Event?
                        </span>{' '}
                        <span>{`It will be removed from your view${
                            isVendorCreatedEvent ? ' as well as the retailers.' : '.'
                        }`}</span>
                    </Typography>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onClose} color="neutral">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteEvent}
                        variant="filled"
                        color="red"
                        disabled={isDeletingEvent}
                    >
                        Delete
                    </Button>
                </Modal.Actions>
            </>
        );
    }

    return (
        <Modal isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>Delete Event?</Modal.Title>
            {modalContent}
        </Modal>
    );
};
